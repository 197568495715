import cx from "classnames";
import React, { FC, ButtonHTMLAttributes } from "react";

import * as styles from "./Button.module.scss";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: "primary" | "secondary" | "tertiary";
}

export const Button: FC<Props> = ({
    variant = "primary",
    className,
    ...props
}) => (
    <button
        className={cx(styles[variant], className, "hideOnPrint")}
        {...props}
    >
        {props.children}
    </button>
);

export default Button;
