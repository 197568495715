import React, { FC } from "react";
import cx from "classnames";
import * as styles from "./RelatedInTheNews.module.scss";
import { Link } from "../Link";
import { IconSprite } from "../IconSprite";
import Image, { FluidObject } from "gatsby-image";

interface Props {
    heading?: string | null;
    url?: string | null;
    leadQuote?: string | null;
    searchImage?: string | null;
    date?: string | null;
    author?: string | null;
    authorPosition?: string | null;
    primaryTag?: string | null;
    machineDate?: string | null;
    image?: FluidObject | null;
    className?: string;
    externalPublisher?: string;
}

// eslint-disable-next-line complexity
export const RelatedInTheNews: FC<Props> = ({
    heading,
    url,
    leadQuote,
    author,
    authorPosition,
    primaryTag,
    date,
    machineDate,
    searchImage,
    image,
    className,
    externalPublisher,
    ...props
}) => (
    <Link
        to={url ?? ""}
        aria-label={heading || ""}
        className={cx(
            styles.component,
            className,
            styles[searchImage ? "withImage" : "noImage"]
        )}
        {...props}
    >
        <div className={styles.imageTagWrapper}>
            <div>
                <span className={styles.primaryTag}>{primaryTag ?? " "}</span>
                <IconSprite
                    name="quote"
                    className={cx(styles.quoteIcon, styles.mobileOnly)}
                />
            </div>

            {image && (
                <figure className={styles.imageWrapper}>
                    <Image
                        // A source element that has a following sibling source element or img element with a srcset attribute must have a media attribute and/or type attribute.
                        fluid={{
                            ...image,
                            media: "(max-width: 256px)"
                        }}
                        alt={externalPublisher}
                    />
                </figure>
            )}

            {searchImage && !image && (
                <figure className={styles.imageWrapper}>
                    <img
                        className={styles.searchImage}
                        src={searchImage}
                        alt={heading ?? leadQuote ?? ""}
                    />
                </figure>
            )}
        </div>

        {leadQuote && (
            <div className={styles.quote}>
                <IconSprite
                    name="quote"
                    className={cx(styles.quoteIcon, styles.desktopOnly)}
                />
                <p>{leadQuote}</p>
            </div>
        )}

        {author && (
            <div>
                <p className={styles.author}>{author}</p>
                {authorPosition && (
                    <p className={styles.position}>{authorPosition}</p>
                )}
            </div>
        )}

        <div className={styles.bottom}>
            {date && machineDate && <time dateTime={machineDate}>{date}</time>}
            <IconSprite name="long-arrow" className={styles.arrowIcon} />
        </div>
    </Link>
);

export default RelatedInTheNews;
