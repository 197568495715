/* eslint-disable complexity */
import cx from "classnames";
import { graphql, PageProps } from "gatsby";
import React from "react";
import ExternalPublisher from "../../components/ExternalPublisher";
import { KeywordTags } from "../../components/KeywordTags";
import { organiseKeywordTags } from "../../components/KeywordTags/helpers";
import { TagCatagoryData } from "../../components/KeywordTags/KeywordTags";

import { Layout } from "../../components/Layout";
import { NewsQuote } from "../../components/NewsQuote/NewsQuote";
import { ParagraphNode, Paragraphs } from "../../components/Paragraphs";
import { PersonCard } from "../../components/PersonCard";
import { SEO } from "../../components/SEO";
import SocialShareBlock from "../../components/SocialShareBlock";
import TitleBlock from "../../components/TitleBlock";
import { BreadCrumbs } from "../../types/breadcrumbs";
import * as styles from "./ExternalPublication.module.scss";

interface Props extends PageProps {
    data: GatsbyTypes.ExternalPublicationQuery;
    pageContext: {
        id: string;
        breadcrumb: BreadCrumbs;
    };
}

const getFeaturedExpertsTitle = (numExperts: number) =>
    numExperts === 1 ? "Featured Expert" : "Featured Experts";

export const ExternalPublicationTemplate: React.FC<Props> = ({
    data,
    pageContext,
    location: { href: url }
}) => {
    const { node } = data;

    const {
        field_primary_tag,
        field_focus,
        field_organisation,
        field_authored_date,
        machineDate,
        field_external_pub_descriptor,
        relationships
    } = node ?? {};

    const { field_content_type, field_external_publication } =
        relationships || {};

    const exPublicationName = field_external_publication?.name ?? "";
    const exPublicationLink = field_organisation?.uri ?? "";
    const exPublicationLinkTitle = field_organisation?.title ?? "";
    const exPublicationImage =
        field_external_publication?.relationships
            ?.field_external_publication_logo?.relationships?.field_media_image
            ?.childImageKitAsset?.fluid;

    const title = node?.title || "";
    const breadcrumb = pageContext.breadcrumb.crumbs;

    const contentType = field_content_type?.name || "";
    const contentCatagoryUrl = field_content_type?.path?.alias || "";

    const experts = relationships?.field_author;
    const sections = relationships?.field_sections;

    const organisedKeywordTags = organiseKeywordTags(relationships);

    return (
        <Layout>
            <SEO title={title} description={field_focus} />
            <article className={styles.component}>
                <TitleBlock
                    breadcrumb={breadcrumb}
                    contentType={contentType}
                    contentTypeUrl={contentCatagoryUrl}
                    title={title}
                    url={url}
                    subText={`${field_external_pub_descriptor ??
                        ""} ${exPublicationName}`}
                    date={field_authored_date}
                />
                <hr className={styles.horizontalrule} />
                <div className={styles.layout}>
                    <div className={styles.body}>
                        {field_primary_tag && (
                            <p className={styles.primaryTag}>
                                {field_primary_tag}
                            </p>
                        )}
                        {field_focus && (
                            <NewsQuote
                                text={field_focus}
                                hideQuoteMarks={true}
                            />
                        )}
                        {sections && (
                            <Paragraphs
                                className={styles.sections}
                                data={sections as ParagraphNode}
                            />
                        )}

                        {(!!organisedKeywordTags?.length ||
                            !!experts?.length) && (
                            <hr className={styles.shortHr} />
                        )}

                        {!!organisedKeywordTags?.length && (
                            <>
                                <KeywordTags
                                    className={styles.keywordTags}
                                    tagCategories={
                                        organisedKeywordTags as TagCatagoryData[]
                                    }
                                />
                            </>
                        )}

                        {!!experts?.length && (
                            <div className={styles.experts}>
                                <label className={styles.featuredExpertsTitle}>
                                    {getFeaturedExpertsTitle(experts.length)}
                                </label>
                                {experts.map(expert => (
                                    <PersonCard
                                        key={expert?.id}
                                        variant="keyContact"
                                        url={expert?.path?.alias}
                                        name={`${expert?.field_first_names ??
                                            ""} ${expert?.title ?? ""}`}
                                        department={
                                            expert?.relationships
                                                ?.field_department?.[0]?.name ??
                                            ""
                                        }
                                        title={expert?.field_position ?? ""}
                                        image={
                                            expert?.relationships
                                                ?.field_user_picture
                                                ?.childImageKitAsset?.fluid
                                        }
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                    <div className={styles.padding} />
                    <div className={styles.sidebar}>
                        <aside>
                            {field_external_pub_descriptor && (
                                <label>{field_external_pub_descriptor}</label>
                            )}
                            <ExternalPublisher
                                name={exPublicationName}
                                url={exPublicationLink}
                                image={exPublicationImage}
                                linkTitle={exPublicationLinkTitle}
                                date={field_authored_date ?? ""}
                                machineDate={machineDate ?? ""}
                            />
                        </aside>
                    </div>
                </div>
                <SocialShareBlock
                    className={cx(styles.desktopOnly, styles.social)}
                    variant="block"
                    url={url}
                    title={title}
                />
            </article>
        </Layout>
    );
};

export default ExternalPublicationTemplate;

export const pageQuery = graphql`
    query ExternalPublication($path: String!) {
        node: nodeExternalPublication(path: { alias: { eq: $path } }) {
            id
            title
            field_authored_date(formatString: "D MMMM Y")
            machineDate: field_authored_date
            field_primary_tag
            field_focus
            field_external_pub_descriptor
            field_organisation {
                title
                uri
            }
            relationships {
                field_sections {
                    ...FieldSections
                }
                field_content_type {
                    path {
                        alias
                    }
                    name
                }
                field_external_publication {
                    name
                    path {
                        alias
                    }
                    relationships {
                        field_external_publication_logo {
                            relationships {
                                field_media_image {
                                    childImageKitAsset {
                                        fluid(maxWidth: 200) {
                                            ...ImageKitFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                field_author {
                    id
                    title
                    field_first_names
                    field_position
                    relationships {
                        field_user_picture {
                            childImageKitAsset {
                                fluid(
                                    maxWidth: 168
                                    transformations: ["fo-face", "ar-88-112"]
                                ) {
                                    ...ImageKitFluid
                                }
                            }
                        }
                    }
                    path {
                        alias
                    }
                }
                # tags
                field_region {
                    id
                    name
                    path {
                        alias
                    }
                }
                field_research_groups {
                    id
                    name
                    path {
                        alias
                    }
                }
                field_topics {
                    id
                    name
                    path {
                        alias
                    }
                }
                field_related_project {
                    id
                    name
                    path {
                        alias
                    }
                }
                field_networks {
                    id
                    name
                    path {
                        alias
                    }
                }
            }
        }
    }
`;
