// extracted by mini-css-extract-plugin
export var component = "Article-module--component--cb7c8";
export var contentArea = "Article-module--contentArea--ec83d";
export var decryptingMessageContainer = "Article-module--decryptingMessageContainer--c5ac5";
export var desktopOnly = "Article-module--desktopOnly--4400d";
export var fadePop = "Article-module--fade-pop--d174b";
export var growHeightIn = "Article-module--grow-height-in--23f2a";
export var mainBody = "Article-module--mainBody--8e321";
export var mobileOnly = "Article-module--mobileOnly--41ad7";
export var mobileReadingOptions = "Article-module--mobileReadingOptions--01696";
export var paragraphs = "Article-module--paragraphs--488a8";
export var section = "Article-module--section--bf2d8";
export var shortHr = "Article-module--shortHr--4c130";
export var sidebar = "Article-module--sidebar--ee1af";
export var socialLinks = "Article-module--socialLinks--14dc9";
export var topDownloadLink = "Article-module--topDownloadLink--2ca21";