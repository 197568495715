import cx from "classnames";
import Image, { FluidObject } from "gatsby-image";
import React, { FC, HTMLAttributes } from "react";

import { Link } from "../Link";
import { Crumb } from "../../types/breadcrumbs";
import { BreadCrumb } from "../BreadCrumb";
import { IconSprite, IconSpriteName } from "../IconSprite/IconSprite";
import { LinkOrSpan } from "../LinkOrSpan";
import * as styles from "./ProfileTitleBlock.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    title: string;
    breadcrumb?: Crumb[];
    image?: FluidObject | null;
    emailAddress?: string | null;
    twitterUrl?: string | null;
    linkedinUrl?: string | null;
    vCard?: string | null;
    position?: string | null;
    departments?: GatsbyTypes.Maybe<
        ReadonlyArray<
            GatsbyTypes.Maybe<GatsbyTypes.taxonomy_term__staff_departments>
        >
    >;
}

interface ProfileSocialLinkProps {
    iconName: IconSpriteName;
    url?: string | null;
}

const ProfileSocialLink: FC<ProfileSocialLinkProps> = ({ iconName, url }) =>
    url ? (
        <Link to={url}>
            <IconSprite name={iconName} />
        </Link>
    ) : null;

export const ProfileTitleBlock: FC<Props> = ({
    className,
    title,
    breadcrumb,
    emailAddress,
    twitterUrl,
    linkedinUrl,
    vCard,
    position,
    departments,

    image,
    ...props
}) => (
    <header className={cx(styles.component, className)} {...props}>
        {breadcrumb && <BreadCrumb crumbs={breadcrumb} />}
        <hr className={styles.underline} />
        <div className={styles.flex}>
            <div className={styles.text}>
                <h1>{title}</h1>
                <div className={styles.socialLinks}>
                    {emailAddress && (
                        <a href={`mailto:${emailAddress}`}>
                            <IconSprite name="email" />
                        </a>
                    )}
                    <ProfileSocialLink iconName="twitter" url={twitterUrl} />
                    <ProfileSocialLink iconName="linkedin" url={linkedinUrl} />
                    <ProfileSocialLink iconName="card" url={vCard} />
                </div>
                {position && <small>{position}</small>}
                {!!departments?.length &&
                    departments.map((dept, index) =>
                        dept ? (
                            <LinkOrSpan
                                className={styles.departmentLink}
                                url={dept?.path?.alias}
                                key={`dept-${dept?.path?.alias ?? index}`}
                            >
                                {`${dept.name}${
                                    index !== departments.length - 1 ? "," : ""
                                }`}
                            </LinkOrSpan>
                        ) : null
                    )}
            </div>
            {image && (
                <div className={styles.imageWrapper}>
                    <div className={styles.image}>
                        <Image fluid={image} />
                    </div>
                </div>
            )}
        </div>
    </header>
);

export default ProfileTitleBlock;
