import React, { FC, HTMLAttributes } from "react";
import cx from "classnames";
import * as styles from "./RelatedContentRow.module.scss";
import { RelatedArticle } from "../RelatedArticle";
import { stripHtml, truncateStringAtSpace } from "../../lib/stringUtils";
import { graphql } from "gatsby";

export type ItemType =
    | GatsbyTypes.ProjectSignpostDataFragment
    | GatsbyTypes.basicPageRelatedContentItemFragment
    | GatsbyTypes.topicRegionRelatedContentItemFragment;

interface Props extends HTMLAttributes<HTMLElement> {
    items: ItemType[];
    heading?: string;
}

// eslint-disable-next-line complexity
const getRelatedArticleProps = (item: ItemType) => {
    const heading = item.title ?? "";
    const url = item?.path?.alias;
    const summary = truncateStringAtSpace(
        stripHtml(
            (item as GatsbyTypes.topicRegionRelatedContentItemFragment)
                ?.field_abstract?.processed ?? ""
        ),
        165,
        "…"
    );
    const image =
        item?.relationships?.field_media_image?.relationships?.field_media_image
            ?.childImageKitAsset?.fluid || null;

    const publicationName =
        (item as GatsbyTypes.topicRegionRelatedContentItemFragment)
            .relationships?.field_content_type?.name ?? "";
    const publicationLink =
        (item as GatsbyTypes.topicRegionRelatedContentItemFragment)
            .relationships?.field_content_type?.path?.alias ?? "";

    const closed =
        (item as GatsbyTypes.ProjectSignpostDataFragment).field_closed ?? false;

    return {
        heading,
        closed,
        url,
        summary,
        image,
        publicationName,
        publicationLink
    };
};

export const RelatedContentRow: FC<Props> = ({
    items,
    heading = "Explore our related content",
    className,
    ...props
}) =>
    items && items.length ? (
        <section className={cx(styles.component, className)} {...props}>
            <hr />
            <h2>{heading}</h2>
            <div className={styles.items}>
                {items.slice(0, 3).map(item => (
                    <RelatedArticle
                        key={`related-item-${item.id}`}
                        variant="whiteBg"
                        {...getRelatedArticleProps(item)}
                    />
                ))}
            </div>
        </section>
    ) : null;

export default RelatedContentRow;

export const fragment = graphql`
    #TODO add project content type
    fragment basicPageRelatedContentItem on node__basic_page {
        __typename
        id
        title
        field_abstract {
            processed
        }
        path {
            alias
        }
        relationships {
            field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 330, transformations: ["h-216"]) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
    }

    fragment topicRegionRelatedContentItem on node__topic_region_page {
        __typename
        id
        title
        path {
            alias
        }
        field_abstract {
            processed
        }
        relationships {
            field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 330, transformations: ["h-216"]) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            field_content_type {
                name
                path {
                    alias
                }
            }
        }
    }
`;
