import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";

import { TagLink } from "../TagLink";
import * as styles from "./TagsList.module.scss";

export type TagData = { id: string; label: string; url: string };

interface Props extends HTMLAttributes<HTMLElement> {
    tags: Array<TagData | null> | null;
    variant?: "horizontal" | "vertical";
}

export const TagsList: FC<Props> = ({
    tags,
    variant = "horizontal",
    className,
    ...props
}) => (
    <ul className={cx(styles.component, styles[variant], className)} {...props}>
        {tags &&
            tags.map(tag => {
                if (!tag) return null;
                const { id, label, ...rest } = tag;
                return (
                    <li key={`tag-${id}-${label}`}>
                        <TagLink label={label} {...rest} />
                    </li>
                );
            })}
    </ul>
);

export default TagsList;
