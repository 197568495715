import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";
import { Link } from "../Link";
import * as styles from "./TagLink.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    label: string;
    url?: string;
}

export const TagLink: FC<Props> = ({ label, url, className, ...props }) => {
    return url ? (
        <Link
            to={url}
            activeClassName={styles.active}
            className={cx(styles.component, className)}
            {...props}
        >
            <span className={styles.text}>{label}</span>
        </Link>
    ) : (
        <span className={cx(styles.component, className)} {...props}>
            <span className={styles.text}>{label}</span>
        </span>
    );
};

export default TagLink;
