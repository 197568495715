import cx from "classnames";
import React, { FC, HTMLAttributes, useState } from "react";
import { useInPageNav } from "../../context/InPageNav/useInPageNav";
import { IconSprite } from "../IconSprite";

import {
    component,
    navigationLink,
    navigationSection,
    icon,
    dropdown,
    open,
    button,
    buttonTitle
} from "./InPageNavigation.module.scss";

interface InPageNavigationSectionProps extends HTMLAttributes<HTMLElement> {
    id: string;
}

interface InPageNavigationLinkProps extends HTMLAttributes<HTMLElement> {
    linkTo: string;
    label: string;
}

interface Props extends HTMLAttributes<HTMLElement> {}

export const InPageNavigationSection: FC<InPageNavigationSectionProps> = ({
    id,
    children,
    className,
    ...rest
}) => (
    <div className={cx(navigationSection, className)} id={id} {...rest}>
        {children}
    </div>
);

export const InPageNavigationLink: FC<InPageNavigationLinkProps> = ({
    className,
    linkTo,
    label,
    ...rest
}) => (
    <li className={cx(className, navigationLink)} {...rest}>
        <a href={linkTo}>{label}</a>
    </li>
);

export const InPageNavigationNav: FC<Props> = ({
    children,
    className,
    ...rest
}) => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <nav
            aria-label="on this page"
            className={cx(
                component,
                className,
                { [open]: menuOpen },
                "hideOnPrint"
            )}
            {...rest}
        >
            <div className={button} onClick={() => setMenuOpen(!menuOpen)}>
                <IconSprite
                    name={menuOpen ? "minus" : "plus"}
                    className={icon}
                />
                <label className={buttonTitle}>on this page</label>
            </div>
            <div className={cx(dropdown)}>
                <ul>{children}</ul>
            </div>
        </nav>
    );
};

export const InPageNavigationContainer: FC<HTMLAttributes<HTMLElement>> = ({
    ...props
}) => {
    const { navItems } = useInPageNav();
    if (!navItems?.length) return null;

    return (
        <InPageNavigationNav {...props}>
            {navItems.map(({ id, title }) => (
                <InPageNavigationLink
                    key={id}
                    label={title}
                    linkTo={`#${id}`}
                />
            ))}
        </InPageNavigationNav>
    );
};

export default InPageNavigationNav;
