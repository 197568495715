import { graphql, PageProps } from "gatsby";
import React from "react";
import cx from "classnames";
import * as styles from "./Networks.module.scss";

import { BreadCrumbs } from "../../types/breadcrumbs";
import { BodyText } from "../../components/BodyText";
import { HeroImage } from "../../components/HeroImage";
import { Layout } from "../../components/Layout";
import { MainImageCredit } from "../../components/MainImageCredit";
import { SEO } from "../../components/SEO";
import TitleBlock from "../../components/TitleBlock";
import ArticleLayout, {
    Body,
    Main,
    Sidebar
} from "../../components/ArticleLayout/ArticleLayout";
import ContentTemplate from "../../components/ContentTemplate";
import { RelatedContentRow } from "../../components/RelatedContentRow";
import Paragraphs from "../../components/Paragraphs";
import { DownloadFile } from "../../components/DownloadFile";
import { stripHtml } from "../../lib/stringUtils";
import { TabbedSection } from "../../components/TabbedSection/TabbedSection";
import { Section } from "../../components/Section/Section";

interface Props extends PageProps {
    data: GatsbyTypes.NodeNetworksQuery;
    pageContext: {
        id: string;
        breadcrumb?: BreadCrumbs;
    };
}

// eslint-disable-next-line complexity
export const NetworksTemplate: React.FC<Props> = ({
    data,
    pageContext,
    location
}) => {
    const { pageData } = data;

    if (!pageData) {
        return null;
    }

    const url = location.href;
    const breadcrumb = pageContext.breadcrumb?.crumbs;

    const seoTitle = pageData.title || "RUSI";

    const title = pageData.title || "";
    const text = pageData.body?.processed || pageData.body?.value || "";
    const lead = pageData.field_abstract?.value;
    const img =
        pageData.relationships?.field_media_image?.relationships
            ?.field_media_image?.childImageKitAsset?.fluid;
    const imgCredit =
        pageData.relationships?.field_media_image?.relationships
            ?.field_media_image?.relationships?.media__image?.[0]
            ?.field_credit ?? "";
    const imgCreditLink =
        pageData.relationships?.field_media_image?.relationships
            ?.field_media_image?.relationships?.media__image?.[0]
            ?.field_credit_link ?? "";

    const { relationships } = pageData;

    const {
        field_network_tab_container,
        field_main_content,
        field_secondary_content
    } = relationships ?? {};

    const renderBody = !!(
        text ||
        !!field_main_content?.length ||
        !!field_secondary_content?.length ||
        imgCredit
    );

    // Metadata
    const metaImageBanner = img?.src;
    const metaImageSignpost =
        pageData.relationships?.field_signpost_image?.relationships
            ?.field_media_image?.childImageKitAsset?.fixed?.src;

    return (
        <Layout>
            <SEO
                title={seoTitle}
                image={metaImageSignpost || metaImageBanner}
                description={lead && stripHtml(lead)}
            />
            <ArticleLayout>
                <TitleBlock
                    variant="basic-page"
                    title={title}
                    breadcrumb={breadcrumb}
                    summary={lead}
                    url={url}
                />

                {img && <HeroImage fluid={img} />}
                <hr className={styles.noMarginBottom} />

                {img && imgCredit && (
                    <>
                        <MainImageCredit
                            className={styles.mobileOnly}
                            text={imgCredit}
                            link={imgCreditLink}
                        />
                        <hr className={styles.mobileOnly} />
                    </>
                )}

                {renderBody && (
                    <Body>
                        <Main>
                            <BodyText html={text} />
                            <ContentTemplate
                                hideBorderTop={!text}
                                firstSection
                                sections={[
                                    {
                                        fields: {
                                            sections: field_main_content ?? []
                                        }
                                    }
                                ]}
                            />
                        </Main>
                        <Sidebar>
                            <MainImageCredit
                                className={styles.mobileOnly}
                                text={imgCredit}
                                link={imgCreditLink}
                            />
                            <Paragraphs
                                className={styles.sidebarParagraphs}
                                data={field_secondary_content}
                                isNetworksPage
                            />
                        </Sidebar>
                    </Body>
                )}

                {field_network_tab_container?.[0]?.relationships
                    ?.field_network_tab && (
                    <div>
                        <TabbedSection
                            title={field_network_tab_container?.[0]?.title}
                        >
                            {field_network_tab_container[0]?.relationships?.field_network_tab?.map(
                                tab => {
                                    return (
                                        <Section
                                            key={tab?.title}
                                            layout={
                                                tab?.field_paragraph_container_style
                                            }
                                            background={tab?.field_background}
                                        >
                                            <Paragraphs
                                                className={cx(
                                                    styles.sidebarParagraphs
                                                )}
                                                data={
                                                    tab?.fields?.sections || []
                                                }
                                                contentType="Networks"
                                                contentTitle={title}
                                                backgroundColor={tab?.field_background}
                                            />
                                        </Section>
                                    );
                                }
                            ) || []}
                        </TabbedSection>
                    </div>
                )}

                <div className={styles.underline} />
            </ArticleLayout>
        </Layout>
    );
};

interface NetworkTabsProps {
    data: GatsbyTypes.NodeNetworksQuery["pageData"]["relationships"]["field_network_tab_container"];
}

export default NetworksTemplate;

export const pageQuery = graphql`
    query NodeNetworks($path: String!, $title: String, $contentType: String) {
        pageData: nodeNetworks(path: { alias: { eq: $path } }) {
            __typename
            id
            title
            # field_profile_descriptors
            field_abstract {
                value
            }

            relationships {
                field_main_content {
                    ...FieldMainContent
                }

                field_network_tab_container {
                    title: field_network_tab_title
                    relationships {
                        field_network_tab {
                            id
                            title: field_network_block_title
                            field_paragraph_container_style
                            field_background
                            fields: relationships {
                                sections: field_content_paragr {
                                    ...FieldSectionsNetworks
                                }
                            }
                        }
                    }
                }

                field_media_image {
                    relationships {
                        field_media_image {
                            relationships {
                                media__image {
                                    field_credit
                                    field_credit_link
                                }
                            }
                            childImageKitAsset {
                                fluid(maxWidth: 1168) {
                                    ...ImageKitFluid
                                }
                            }
                        }
                    }
                }

                field_signpost_image {
                    relationships {
                        field_media_image {
                            childImageKitAsset {
                                fixed(width: 1200, transformations: ["h-630"]) {
                                    ...ImageKitFixed
                                }
                            }
                        }
                    }
                }

                # field_related_content {
                #     # TODO ADD PROJECT PAGES
                #     ...basicPageRelatedContentItem
                #     ...topicRegionRelatedContentItem
                # }
                field_secondary_content {
                    __typename
                    ...ProfileReferencesData
                    ...IconLinksData
                }
            }
        }
    }
`;
