import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";

import { PersonCard } from "../PersonCard";
import * as styles from "./EcpPeople.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    data?: GatsbyTypes.Maybe<
        ReadonlyArray<
            GatsbyTypes.Maybe<{
                readonly relationships: GatsbyTypes.Maybe<{
                    readonly field_author_people: GatsbyTypes.Maybe<
                        GatsbyTypes.PersonDataFragment
                    >;
                }>;
            }>
        >
    >;
}

const getMailToLink = (emailAddress?: string) =>
    emailAddress ? `mailto:${emailAddress}` : undefined;

export const EcpPeople: FC<Props> = ({ data, className, ...props }) => {
    if (!data || !data.length) {
        return null;
    }

    return (
        <div className={cx(styles.component, className)} {...props}>
            <div className={styles.titleWrapper}>
                <h3>Contact the team</h3>
            </div>
            <div className={styles.peopleWrapper}>
                {data.map(section => {
                    const person = section?.relationships?.field_author_people;

                    return person ? (
                        <PersonCard
                            email={getMailToLink(person.field_email_address)}
                            hideLink={!person.field_email_address}
                            key={person.id}
                            name={`${person.field_first_names} ${person.title}`}
                            position={person.field_position}
                            department={
                                person.relationships
                                    ?.field_staff_department?.[0]?.name || ""
                            }
                            image={
                                person.relationships?.field_user_picture
                                    ?.childImageKitAsset?.fluid
                            }
                            variant="ecp"
                        />
                    ) : null;
                })}
            </div>
        </div>
    );
};

export default EcpPeople;
