// Provides a way to access the search state and search results from a parent component
// See LatestPublicationsDynamic

import React, { FC, HTMLAttributes, useEffect } from "react";
import { SearchResults, SearchState } from "react-instantsearch-core";

interface Props extends HTMLAttributes<HTMLElement> {
    searchState: SearchState;
    searchResults: SearchResults;
    onStateChange: (state: SearchState) => void;
}

export const SearchStateResults: FC<Props> = ({
    searchState,
    searchResults,
    onStateChange
}) => {
    useEffect(() => {
        if (onStateChange) onStateChange({ searchState, searchResults });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchState, searchResults]);

    return null;
};

export default SearchStateResults;
