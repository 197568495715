// extracted by mini-css-extract-plugin
export var component = "DownloadFile-module--component--3f542";
export var downloadText = "DownloadFile-module--downloadText--bd39e";
export var fadePop = "DownloadFile-module--fade-pop--0a907";
export var growHeightIn = "DownloadFile-module--grow-height-in--e5678";
export var icon = "DownloadFile-module--icon--28259";
export var locked = "DownloadFile-module--locked--c2975";
export var lockedState = "DownloadFile-module--lockedState--6be77";
export var lockedStateIcon = "DownloadFile-module--lockedStateIcon--2cba1";
export var metadataText = "DownloadFile-module--metadataText--765d2";
export var sidebar = "DownloadFile-module--sidebar--111b4";
export var textWrapper = "DownloadFile-module--textWrapper--2f06c";
export var unlocked = "DownloadFile-module--unlocked--d30dd";