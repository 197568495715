import React from "react";
import * as styles from "./NewsQuote.module.scss";
import { IconSprite } from "../IconSprite";

interface Props {
    text: string;
    hideQuoteMarks?: boolean;
}

export const NewsQuote: React.FC<Props> = ({
    text,
    hideQuoteMarks = false
}) => (
    <div className={styles.component}>
        {!hideQuoteMarks && <IconSprite name="quote" className={styles.icon} />}
        <p className={styles.quote}>{text}</p>
    </div>
);
