import { NavItem } from "./InPageNavProvider";

const testId = (id: string, navItems: NavItem[], nextInt = 0): string => {
    let idToTest = id;
    if (nextInt) {
        idToTest = `${id}-${nextInt}`;
    }

    return navItems.find(i => i.id === idToTest)
        ? testId(id, navItems, (nextInt || 0) + 1)
        : idToTest;
};

export const getDomId = (title?: string, navItems?: NavItem[]) => {
    if (!title) return undefined;

    const id = title.replace(/ /g, "-").toLowerCase();

    return id;
};
