import React, { FC, HTMLAttributes } from "react";
import { graphql } from "gatsby";
import IndentedContent from "../../components/IndentedContent/IndentedContent";
import { stripHtml } from "../../lib/stringUtils";
import { useInPageNavId } from "../../context/InPageNav/useInPageNavId";
import { SignpostContainer } from "../../components/Signpost";

interface Props extends HTMLAttributes<HTMLElement> {
    node?: GatsbyTypes.IndentedContentParagraphDataFragment;
}

export const IndentedContentParagraph: FC<Props> = ({
    node,
    className,
    ...props
}) => {
    const id = useInPageNavId(node);

    const items =
        node?.relationships?.field_signposted_content ??
        node?.relationships?.field_content_paragr;

    if (!items || !items.length) return null;

    return (
        <IndentedContent
            title={node.field_title}
            subtext={stripHtml(node.field_text?.processed ?? "")}
            id={id}
            {...props}
        >
            {items.map((item, index) => (
                <SignpostContainer
                    key={`indented-signpost-${item?.relationships
                        ?.field_content?.[0]?.id ??
                        item?.id ??
                        index}-${index}`}
                    variant="indented"
                    backgroundColor="white"
                    node={item?.relationships?.field_content?.[0] ?? item}
                />
            ))}
        </IndentedContent>
    );
};

export const fragment = graphql`
    fragment IndentedContentParagraphData on paragraph__indented_signposts_block {
        id
        field_is_on_this_page_anchor
        field_title
        field_text {
            processed
        }
        relationships {
            field_signposted_content {
                ...ArticleSignpostData
                ...ExternalSignpostData
                ...BasicPageSignpostData
                ...EventsSignpostData
                ...ConferencesSignpostData
                ...TopicRegionSignpostData
                ...ResearchGroupSignpostData
                ...ProjectSignpostData
            }

            field_content_paragr {
                relationships {
                    field_content {
                        ...ArticleSignpostData
                        ...ExternalSignpostData
                        ...BasicPageSignpostData
                        ...EventsSignpostData
                        ...ConferencesSignpostData
                        ...TopicRegionSignpostData
                        ...ResearchGroupSignpostData
                        ...ProjectSignpostData
                    }
                }
            }
        }
    }
`;

export default IndentedContentParagraph;
