// extracted by mini-css-extract-plugin
export var body = "InTheNews-module--body--9c007";
export var component = "InTheNews-module--component--29509";
export var desktopOnly = "InTheNews-module--desktopOnly--ab479";
export var experts = "InTheNews-module--experts--370cc";
export var fadePop = "InTheNews-module--fade-pop--50719";
export var featuredExpertsTitle = "InTheNews-module--featuredExpertsTitle--9b81e";
export var growHeightIn = "InTheNews-module--grow-height-in--f2ed4";
export var keywordTags = "InTheNews-module--keywordTags--1cbbb";
export var layout = "InTheNews-module--layout--c82e6";
export var mobileOnly = "InTheNews-module--mobileOnly--04f7b";
export var node = "InTheNews-module--node--36b87";
export var padding = "InTheNews-module--padding--fbe34";
export var primaryTag = "InTheNews-module--primaryTag--83442";
export var sections = "InTheNews-module--sections--ec89d";
export var shortHr = "InTheNews-module--shortHr--b7b70";
export var sidebar = "InTheNews-module--sidebar--a20bf";
export var social = "InTheNews-module--social--7585a";
export var sticky = "InTheNews-module--sticky--20015";