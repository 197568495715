import React, { FC, HTMLAttributes, useMemo } from "react";
import { graphql } from "gatsby";
import { getFacetName, ContentTypeName } from "../../lib/dynamicContentHelpers";
import AlgoliaDynamicContent from "../../components/LatestDynamicContent/AlgoliaDynamicContent";

interface Props extends HTMLAttributes<HTMLElement> {
    node: GatsbyTypes.LatestPublicationsDataFragment;
    contentType?: string;
    contentTitle?: string;
    personFirstName?: string;
}

export const LatestPublicationsDynamic: FC<Props> = ({
    node,
    contentType,
    contentTitle,
    personFirstName,
    ...props
}) => {
    const facetName = getFacetName(contentType as ContentTypeName);

    const filterString = useMemo(() => {
        let str = `type:external_publication OR type:article AND NOT content_type:'RUSI News' AND NOT content_type:'Library News' `;
        if (facetName && contentTitle) {
            str += ` AND ${facetName}:'${contentTitle}'`;
        }

        if (personFirstName) {
            str += ` AND author_first_names:'${personFirstName}'`;
        }
        return str;
    }, [facetName, contentTitle, personFirstName]);

    if (!node) {
        return null;
    }

    const { field_title, field_is_on_this_page_anchor, field_link } = node;

    return (
        <AlgoliaDynamicContent
            heading={field_title}
            linkUrl={field_link?.url}
            linkText={field_link?.title}
            includedInPageNav={field_is_on_this_page_anchor}
            filterString={filterString}
            {...props}
        />
    );
};

export default LatestPublicationsDynamic;

export const fragment = graphql`
    fragment LatestPublicationsData on paragraph__topic_latest_publications {
        id
        field_title
        field_is_on_this_page_anchor
        field_link {
            title
            url
        }
    }
`;
