import Image, { FluidObject } from "gatsby-image";
import React from "react";

import { IconSprite } from "../IconSprite";
import * as styles from "./ExternalPublisher.module.scss";

interface Props {
    name: string;
    image?: FluidObject;
    date: string;
    machineDate: string;
    url: string;
    linkTitle?: string;
}

export const ExternalPublisher: React.FC<Props> = ({
    name,
    image,
    date,
    url,
    machineDate,
    linkTitle = "View the full article online"
}) => (
    <div className={styles.component}>
        <figure>
            {image && (
                <div className={styles.image}>
                    <Image fluid={image} alt={name} />
                </div>
            )}
            <figcaption>{name}</figcaption>
        </figure>
        <p>
            <time dateTime={machineDate}>{date}</time>
        </p>
        <a href={url}>
            {linkTitle}
            <IconSprite name="chevron-right" />
        </a>
    </div>
);
