import cx from "classnames";
import React, { FC, HTMLAttributes, useCallback, useState } from "react";
import { IconSprite } from "../IconSprite";

import * as styles from "./SearchFilterContainer.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    heading: string;
    currentLabel: string;
    active: boolean;
}

export const FiltersLayout: FC<HTMLAttributes<HTMLElement>> = ({
    className,
    children,
    ...props
}) => (
    <div className={cx(styles.filtersWrapper, className)} {...props}>
        {children}
    </div>
);

export const SearchFilterContainer: FC<Props> = ({
    className,
    heading,
    currentLabel,
    active,
    children,
    ...props
}) => {
    const [open, setOpen] = useState(false);

    const toggleOpen = useCallback(() => setOpen(current => !current), []);

    return (
        <label className={cx(styles.component, className)} {...props}>
            <span className={styles.label}>{heading}</span>
            <div>
                <button onClick={toggleOpen}>
                    <div
                        className={cx(styles.select, {
                            [styles.activeFilter]: active
                        })}
                    >
                        {currentLabel}
                        <IconSprite
                            className={cx(
                                styles.icon,
                                styles[open ? "pointUp" : "pointDown"]
                            )}
                            name="chevron-right"
                        />
                    </div>
                </button>
                <div
                    className={cx(
                        styles[open ? "visible" : "hidden"],
                        styles.dropdown
                    )}
                >
                    {open && (
                        <div className={styles.dropdownContent}>{children}</div>
                    )}
                </div>
            </div>
        </label>
    );
};

export default SearchFilterContainer;
