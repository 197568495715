import React from "react";
import { graphql } from "gatsby";
import { component, list_item, icon, flex } from "./RSSFeed.module.scss";
import useFetchXML from "../../lib/useFetchXML";
import { IconSprite } from "../../components/IconSprite";
import { parseXML } from "./helpers";

export interface RSSFeedProps {
    node: GatsbyTypes.ParagraphRSSFeedFragment;
}

export const RSSFeed: React.FC<RSSFeedProps> = ({ node: { link } }) => {
    const { data, loading, error } = useFetchXML(link?.uri || "");

    const fields = [
        "title",
        "link",
        "vacancydescription",
        "location",
        "department"
    ];

    return (
        <section className={component}>
            {loading && (
                <span>
                    <IconSprite name="rss" className={icon} />
                    Loading...
                </span>
            )}
            {!!error && <p>Error: {error}</p>}
            {data &&
                parseXML(data, fields).items?.map(item => (
                    <div key={item.link} className={list_item}>
                        <a href={item.link} target="_blank" rel="noreferrer">
                            <h2>{item.title}</h2>
                        </a>
                        <div className={flex}>
                            <p>
                                <b>{item.department}</b>
                            </p>
                            <p>{item.location}</p>
                        </div>
                        <p>{item.vacancydescription}</p>
                        <hr />
                    </div>
                ))}
        </section>
    );
};

export const fragment = graphql`
    fragment ParagraphRSSFeed on paragraph__rss_feed {
        id
        __typename
        link: field_rss_feed_url {
            uri
        }
    }
`;
