import React, { FC, HTMLAttributes } from "react";
import { stripHtml } from "../../lib/stringUtils";
import {
	Signpost,
	SignpostBackgroundColors,
	SignpostVariants,
} from "./Signpost";

type SignpostNode =
	| GatsbyTypes.ArticleSignpostDataFragment
	| GatsbyTypes.BasicPageSignpostDataFragment
	| GatsbyTypes.ExternalSignpostDataFragment
	| GatsbyTypes.EventsSignpostDataFragment
	| GatsbyTypes.ConferencesSignpostDataFragment
	| GatsbyTypes.TopicRegionSignpostDataFragment
	| GatsbyTypes.ResearchGroupSignpostDataFragment
	| GatsbyTypes.ProjectSignpostDataFragment
	| GatsbyTypes.LandingPageLevel2DataFragment;

interface SharedProps extends HTMLAttributes<HTMLElement> {
	backgroundColor?: SignpostBackgroundColors;
	variant?: SignpostVariants;
}

interface ArticleSignpostProps extends SharedProps {
	node: GatsbyTypes.ArticleSignpostDataFragment;
}

const ArticleSignpost: FC<ArticleSignpostProps> = ({ node, ...rest }) => (
	<Signpost
		title={node.title ?? ""}
		url={node.path?.alias ?? ""}
		date={node.created}
		type={node.relationships?.field_content_type?.name ?? ""}
		typeLink={node.relationships?.field_content_type?.path?.alias}
		volume={node.field_publication_volume}
		issue={node.field_publication_issue}
		image={
			node.relationships?.field_signpost_image?.relationships?.field_media_image
				?.childImageKitAsset?.fluid ??
			node.relationships?.field_media_image?.relationships?.field_media_image
				?.childImageKitAsset?.fluid
		}
		imgSrc={
			(
				node.relationships?.field_signpost_image?.relationships
					?.field_media_image as any
			)?.uri?.url ||
			(
				node.relationships?.field_media_image?.relationships
					?.field_media_image as any
			)?.uri?.url
		}
		imgAlt={
			(
				node.relationships?.field_signpost_image?.relationships
					?.field_media_image as any
			)?.alt ||
			(
				node.relationships?.field_media_image?.relationships
					?.field_media_image as any
			)?.alt
		}
		readTime={node.field_read_time}
		linkText="View online"
		{...rest}
	/>
);

interface ExternalSignpostProps extends SharedProps {
	node: GatsbyTypes.ExternalSignpostDataFragment;
}
const ExternalSignpost: FC<ExternalSignpostProps> = ({ node, ...rest }) => (
	<Signpost
		title={node.title ?? ""}
		url={node.field_external_url?.uri ?? ""}
		image={
			node.relationships?.field_signpost_image?.relationships?.field_media_image
				?.childImageKitAsset?.fluid
		}
		imgSrc={
			(
				node.relationships?.field_signpost_image?.relationships
					?.field_media_image as any
			)?.uri?.url
		}
		imgAlt={
			(
				node.relationships?.field_signpost_image?.relationships
					?.field_media_image as any
			)?.alt
		}
		summary={node.body?.summary ?? ""}
		linkText={node.field_external_url?.title}
		{...rest}
	/>
);

interface BasicPageSignpostProps extends SharedProps {
	node: GatsbyTypes.BasicPageSignpostDataFragment;
}
const BasicPageSignpost: FC<BasicPageSignpostProps> = ({ node, ...rest }) => (
	<Signpost
		title={node.title}
		url={node.path?.alias}
		image={
			node.relationships?.field_media_image?.relationships?.field_media_image
				?.childImageKitAsset?.fluid
		}
		summary={node.body?.summary ?? ""}
		linkText={"View online"}
		imgSrc={
			(
				node.relationships?.field_signpost_image?.relationships
					?.field_media_image as any
			)?.uri?.url ||
			(
				node.relationships?.field_media_image?.relationships
					?.field_media_image as any
			)?.uri?.url
		}
		imgAlt={
			(
				node.relationships?.field_signpost_image?.relationships
					?.field_media_image as any
			)?.alt ||
			(
				node.relationships?.field_media_image?.relationships
					?.field_media_image as any
			)?.alt
		}
		{...rest}
	/>
);

interface EventsSignpostProps extends SharedProps {
	node:
		| GatsbyTypes.EventsSignpostDataFragment
		| GatsbyTypes.ConferencesSignpostDataFragment;
}
const EventsSignpost: FC<EventsSignpostProps> = ({ node, ...rest }) => (
	<Signpost
		title={node.title ?? ""}
		url={node.path?.alias ?? ""}
		date={node.field_event_date_range?.value}
		machineDate={node.field_event_date_range?.machineDate}
		type={node.relationships?.field_event_type?.name ?? ""}
		typeLink={node.relationships?.field_event_type?.path?.alias}
		openTo={node.relationships?.field_open_to_tag?.name}
		image={
			node.relationships?.field_signpost_image?.relationships?.field_media_image
				?.childImageKitAsset?.fluid ??
			node.relationships?.field_media_image?.relationships?.field_media_image
				?.childImageKitAsset?.fluid
		}
		linkText="View details"
		imgSrc={
			(
				node.relationships?.field_signpost_image?.relationships
					?.field_media_image as any
			)?.uri?.url ||
			(
				node.relationships?.field_media_image?.relationships
					?.field_media_image as any
			)?.uri?.url
		}
		imgAlt={
			(
				node.relationships?.field_signpost_image?.relationships
					?.field_media_image as any
			)?.alt ||
			(
				node.relationships?.field_media_image?.relationships
					?.field_media_image as any
			)?.alt
		}
		{...rest}
	/>
);

interface TopicRegionSignpostProps extends SharedProps {
	node: GatsbyTypes.TopicRegionSignpostDataFragment;
}
// eslint-disable-next-line complexity
const TopicRegionSignpost: FC<TopicRegionSignpostProps> = ({
	node,
	variant,
	...rest
}) => {
	const summary =
		variant === "simple" &&
		node.field_abstract?.processed &&
		stripHtml(node.field_abstract?.processed);

	const type = node.relationships?.field_content_type?.name ?? "";
	const linkText =
		type.toLowerCase() === "topics" ? "View topic" : "View region";
	return (
		<Signpost
			title={node.title ?? ""}
			url={node.path?.alias ?? ""}
			type={type}
			typeLink={node.relationships?.field_content_type?.path?.alias}
			image={
				node.relationships?.field_signpost_image?.relationships
					?.field_media_image?.childImageKitAsset?.fluid ??
				node.relationships?.field_media_image?.relationships?.field_media_image
					?.childImageKitAsset?.fluid
			}
			imgSrc={
				(
					node.relationships?.field_signpost_image?.relationships
						?.field_media_image as any
				)?.uri?.url ||
				(
					node.relationships?.field_media_image?.relationships
						?.field_media_image as any
				)?.uri?.url
			}
			imgAlt={
				(
					node.relationships?.field_signpost_image?.relationships
						?.field_media_image as any
				)?.alt ||
				(
					node.relationships?.field_media_image?.relationships
						?.field_media_image as any
				)?.alt
			}
			linkText={linkText}
			summary={summary || null}
			variant={variant}
			{...rest}
		/>
	);
};

interface ResearchGroupSignpostProps extends SharedProps {
	node: GatsbyTypes.ResearchGroupSignpostDataFragment;
}
const ResearchGroupSignpost: FC<ResearchGroupSignpostProps> = ({
	node,
	...rest
}) => (
	<Signpost
		title={node.title ?? ""}
		url={node.path?.alias ?? ""}
		type={node.relationships?.field_content_type?.name ?? ""}
		typeLink={node.relationships?.field_content_type?.path?.alias}
		image={
			node.relationships?.field_media_image?.relationships?.field_media_image
				?.childImageKitAsset?.fluid
		}
		imgSrc={
			(
				node.relationships?.field_media_image?.relationships
					?.field_media_image as any
			)?.uri?.url
		}
		imgAlt={
			(
				node.relationships?.field_media_image?.relationships
					?.field_media_image as any
			)?.alt
		}
		linkText="View research group"
		{...rest}
	/>
);

interface ProjectSignpostProps extends SharedProps {
	node: GatsbyTypes.ProjectSignpostDataFragment;
}

const ProjectSignpost: FC<ProjectSignpostProps> = ({ node, ...rest }) => (
	<Signpost
		title={node.title ?? ""}
		url={node.path?.alias ?? ""}
		closed={node.field_closed}
		type={node.relationships?.field_content_type?.name ?? ""}
		typeLink={node.relationships?.field_content_type?.path?.alias}
		summary={node.field_abstract?.value}
		image={
			node.relationships?.field_signpost_image?.relationships?.field_media_image
				?.childImageKitAsset?.fluid ??
			node.relationships?.field_media_image?.relationships?.field_media_image
				?.childImageKitAsset?.fluid
		}
		imgSrc={
			(
				node.relationships?.field_media_image?.relationships
					?.field_media_image as any
			)?.uri?.url
		}
		imgAlt={
			(
				node.relationships?.field_media_image?.relationships
					?.field_media_image as any
			)?.alt
		}
		// linkText="View project"
		{...rest}
	/>
);

interface LandingPageLevel2SignpostProps extends SharedProps {
	node: GatsbyTypes.LandingPageLevel2DataFragment;
}

const LandingPageLevel2Signpost: FC<LandingPageLevel2SignpostProps> = ({
	node,
	...rest
}) => (
	<Signpost
		title={node.title ?? ""}
		url={node.path?.alias ?? ""}
		image={
			node.relationships?.field_media_image?.relationships?.field_media_image
				?.childImageKitAsset?.fluid
		}
		imgSrc={
			(
				node.relationships?.field_media_image?.relationships
					?.field_media_image as any
			)?.uri?.url
		}
		imgAlt={
			(
				node.relationships?.field_media_image?.relationships
					?.field_media_image as any
			)?.alt
		}
		linkText="View page"
		summary={stripHtml(node.field_abstract?.value ?? "")}
		{...rest}
	/>
);

interface Props extends SharedProps {
	node: SignpostNode;
}
// eslint-disable-next-line complexity
export const SignpostContainer: FC<Props> = ({ node, ...rest }) => {
	if (!node) {
		return null;
	}
	switch (node.__typename || node.internal.type) {
		case "node__article":
			return <ArticleSignpost node={node} {...rest} />;
		case "node__external_signpost":
			return <ExternalSignpost node={node} {...rest} />;
		case "node__basic_page":
			return <BasicPageSignpost node={node} {...rest} />;
		case "node__events":
		case "node__conference":
			return <EventsSignpost node={node} {...rest} />;
		case "node__topic_region_page":
			return <TopicRegionSignpost node={node} {...rest} />;
		case "node__research_group":
			return <ResearchGroupSignpost node={node} {...rest} />;
		case "node__project":
			return <ProjectSignpost node={node} {...rest} />;
		case "node__landing_page_level_2":
			return <LandingPageLevel2Signpost node={node} {...rest} />;
		default:
			return null;
	}
};

export default SignpostContainer;
