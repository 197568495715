import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";
import DangerousHTML from "../DangerousHTML";

import * as styles from "./IndentedContent.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    title?: string;
    subtext?: string;
    html?: string;
    hideBackgroundOnMobile?: boolean;
}

export const IndentedContent: FC<Props> = ({
    title,
    subtext,
    html,
    hideBackgroundOnMobile = false,
    className,
    ...props
}) => (
    <div className={cx(styles.component, className)} {...props}>
        <div className={styles.wrapper}>
            <div className={styles.leftCol}>
                {title && <h2>{title}</h2>}
                {(title || subtext) && <hr />}
                {subtext && <p>{subtext}</p>}
                {html && <DangerousHTML html={html} />}
            </div>
            <div className={styles.rightCol}>
                <div
                    className={cx(styles.greyBg, {
                        [styles.desktopOnly]: hideBackgroundOnMobile
                    })}
                />
                <div className={styles.content}>{props.children}</div>
            </div>
        </div>
    </div>
);

export default IndentedContent;
