import cx from "classnames";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import React, { FC, HTMLAttributes } from "react";

import * as styles from "./ImageParagraph.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
	node?: GatsbyTypes.ImageParagraphDataFragment;
}
interface CreditProps {
	text?: string | null;
}

interface ImageData {
	imageKit: GatsbyTypes.Maybe<
		Pick<GatsbyTypes.ImageKitAsset, "id"> & {
			readonly fluid: {
				readonly __typename: "ImageKitAssetFluid";
			} & GatsbyTypes.ImageKitFluidFragment;
		}
	>;
	credit: string | null;
	src?: string;
	alt?: string;
}

const Credit: FC<CreditProps> = ({ text }) => (
	<figcaption className={styles[text ? "credit" : "noCredit"]}>
		{text ? (
			<>
				<span className={styles.creditLabel}>Credit</span>
				{text}
			</>
		) : null}
	</figcaption>
);

export const ImageParagraph: FC<Props> = ({ node, className, ...props }) => {
	const images = node?.relationships?.field_images
		?.map((i) => {
			const imageKit = i?.relationships?.field_media_image?.childImageKitAsset;
			const credit = i?.field_credit;
			if (imageKit) return { imageKit, credit };
			// rome-ignore lint/suspicious/noExplicitAny: <explanation>
			const src = (i?.relationships?.field_media_image as any).uri?.url;
			// rome-ignore lint/suspicious/noExplicitAny: <explanation>
			const alt = (i?.relationships?.field_media_image as any).alt;
			if (src?.length) return { src, credit, alt };

			return null;
		})
		.filter(Boolean) as ImageData[];
	return images?.length ? (
		<div className={cx(styles.component, className)} {...props}>
			{images.map(({ imageKit, credit, src, alt }) =>
				imageKit?.fluid || (src && src?.length > 0) ? (
					<div className={styles.imageWrapper} key={imageKit?.id || src}>
						{imageKit && <Image fluid={imageKit.fluid} />}
						{src && <img src={src} alt={alt} width={780}/>}
						<Credit text={credit} />
					</div>
				) : null,
			)}
		</div>
	) : null;
};

// Exporting a fragment allows it to be used in any GraphQL query

export default ImageParagraph;

export const fragment = graphql`
    fragment ImageParagraphData on paragraph__media_image {
        id
        __typename
        relationships {
            field_images {
                id
                name
                field_credit
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            id
                            fluid {
                                __typename
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
