// From https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#reminder-use-link-only-for-internal-links

import React, { FC } from "react";
import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby";

type CustomGatsbyLinkProps = Omit<
    GatsbyLinkProps<Record<string, unknown>>,
    "ref"
>;

interface LinkProps extends CustomGatsbyLinkProps {}

export const Link: FC<LinkProps> = ({
    children,
    to,
    activeClassName,
    partiallyActive,
    ...other
}) => {
    const internal = /^\/(?!\/)/.test(to); // starts with '/' means it is internal

    if (internal) {
        return (
            <GatsbyLink
                to={to}
                activeClassName={activeClassName}
                partiallyActive={partiallyActive}
                {...other}
            >
                {children}
            </GatsbyLink>
        );
    }
    return (
        <a href={to} {...other}>
            {children}
        </a>
    );
};
export default Link;
