import { Link } from "../Link";
import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";

import { ReactComponent as RusiCrest } from "../../assets/rusi-crest.svg";
import { chunk } from "../../utilities/arrayUtils";
import { getCurrentYear } from "../../utilities/dateUtils";
import { FooterSocial } from "../FooterSocial";
import Underline from "../Underline";
import * as styles from "./Footer.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    data: GatsbyTypes.GlobalElementsQuery;
}

type MenuItems =
    | GatsbyTypes.GlobalElementsQuery["exploreRusiMenuItems"]["nodes"]
    | GatsbyTypes.GlobalElementsQuery["socialMenuItems"]["nodes"]
    | GatsbyTypes.GlobalElementsQuery["popularLinksMenuItems"]["nodes"];

const NUM_ITEMS_IN_COL = 4;

interface FooterMenuProps extends HTMLAttributes<HTMLElement> {
    title: string;
    menuItems: MenuItems;
}

const FooterMenu: FC<FooterMenuProps> = ({ title, menuItems, className }) => {
    const chunks = chunk([...menuItems], NUM_ITEMS_IN_COL);

    return (
        <div className={cx(styles.footerMenu, className)}>
            <h3>{title}</h3>
            <Underline className={styles.underline} />
            <div className={styles.menuItemsWrapper}>
                {chunks.map((chunk, chunkIndex) => (
                    <ul key={`chunk${chunkIndex}`}>
                        {chunk.map(({ link, title, id }) => (
                            <li key={id}>
                                <Link to={link?.uri ?? ""}>{title}</Link>
                            </li>
                        ))}
                    </ul>
                ))}
            </div>
        </div>
    );
};

export const Footer: FC<Props> = ({ data, className, ...props }) => {
    const {
        bottomMenuItems,
        socialMenuItems,
        exploreRusiMenuItems,
        popularLinksMenuItems,
        footerText
    } = data;

    return (
        <footer
            className={cx(styles.component, className, "hideOnPrint")}
            {...props}
        >
            <div className={cx(styles.footerSection, styles.mainFooterArea)}>
                <span className={cx(styles.crest, styles.largeScreenCrest)}>
                    <RusiCrest />
                </span>
                <FooterSocial
                    className={styles.social}
                    menuItems={socialMenuItems.nodes}
                />
                <div className={cx(styles.midSection)}>
                    <FooterMenu
                        title="Explore RUSI"
                        menuItems={exploreRusiMenuItems.nodes}
                        className={styles.firstFooterMenu}
                    />
                    <FooterMenu
                        title="Popular Links"
                        menuItems={popularLinksMenuItems.nodes}
                        className={styles.secondFooterMenu}
                    />

                    <div className={styles.missionStatement}>
                        <figure className={styles.crest}>
                            <RusiCrest />
                        </figure>
                        <p>{footerText?.field_statement ?? ""}</p>
                    </div>
                </div>
            </div>
            <div className={styles.footerBottom}>
                <div className={cx(styles.footerSection)}>
                    <div className={styles.footerMenu}>
                        <nav>
                            {bottomMenuItems.nodes.map(
                                ({ id, link, title }) => (
                                    <Link
                                        className={styles.bottomNavLink}
                                        to={link?.uri ?? ""}
                                        key={id}
                                    >
                                        {title}
                                    </Link>
                                )
                            )}
                        </nav>
                        <p
                            className={styles.copyright}
                        >{`Copyright ${getCurrentYear()} ${footerText?.field_copyright_statement ??
                            ""}`}</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
