import React, { HTMLAttributes } from "react";

import { IconSprite } from "../IconSprite";
import * as styles from "./SearchButton.module.scss";

export const SearchButton: React.FC<HTMLAttributes<HTMLButtonElement>> = (
	props,
) => (
	<div className={styles.buttonWrapper}>
		<button
			{...props}
			type="submit"
			title="Search RUSI"
			className={styles.component}
		>
			<IconSprite aria-label="Search" name="search" />
		</button>
	</div>
);
