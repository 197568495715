import { graphql } from "gatsby";
import React, { FC, HTMLAttributes } from "react";

import DangerousHTML from "../DangerousHTML";
import { MembershipGateContainer } from "../MembershipGateContainer";

import * as styles from "./IframeGrid.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    node: GatsbyTypes.ParagraphIframeGridFragment;
    loggedIn?: boolean;
}

export const IframeGrid: FC<Props> = ({ node, loggedIn }) => {
    const iframes = node.relationships?.field_iframes;

    if (!iframes?.length || iframes.length <= 0) return null;

    return (
        <div className={styles.container}>
            {iframes.map(paragraph => {
                if (!paragraph) return null;

                if (paragraph.field_gated_content && !loggedIn)
                    return <MembershipGateContainer variant="media" />;

                return (
                    <DangerousHTML
                        key={paragraph?.id}
                        className={styles.component}
                        html={paragraph?.field_embed_code?.processed || ""}
                    />
                );
            })}
        </div>
    );
};

export const fragment = graphql`
    fragment ParagraphIframeGrid on paragraph__iframe_grid {
        __typename
        id
        relationships {
            field_iframes {
                id
                field_gated_content
                field_embed_code {
                    processed
                }
            }
        }
    }
`;

export default IframeGrid;
