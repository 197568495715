import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";
import { SearchResults } from "react-instantsearch-core";

import * as styles from "./SearchStats.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    searchResults: SearchResults;
}

export const SearchStats: React.FC<Props> = ({ searchResults }) => {
    const { nbHits, page, hitsPerPage } = searchResults ?? {};

    return typeof nbHits === "number" ? (
        <p className={styles.component}>
            {nbHits === 0
                ? "Showing 0 results"
                : `Showing results 1 - ${Math.min(
                      (page + 1) * hitsPerPage,
                      nbHits
                  )} of ${nbHits}`}
        </p>
    ) : (
        <p className={styles.component}>Loading results...</p>
    );
};
