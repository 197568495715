export const stripHtml = (html: string): string => {
    return html.replace(/(<([^>]+)>)/gi, "");
};

export const truncateStringAtSpace = (
    string: string,
    maxLength: number,
    truncateWith?: string | null
): string => {
    if (string.length <= maxLength) {
        return string;
    }
    const lastSpace = string.lastIndexOf(" ", maxLength);
    return string.slice(0, lastSpace - 1) + (truncateWith || "");
};
