import React, {
    FC,
    HTMLAttributes,
    useCallback,
    useEffect,
    useState
} from "react";
import cx from "classnames";
import algoliasearch from "algoliasearch";
import qs from "qs";
import { InstantSearch, SearchState } from "react-instantsearch-core";
import * as styles from "./SearchComponent.module.scss";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

export type AlgoliaIndexName =
    | "algolia_global_search"
    | "global_by_date"
    | "algolia_event"
    | "algolia_event_desc";

interface Props extends HTMLAttributes<HTMLElement> {
    indexName: AlgoliaIndexName;
    disableUrl?: boolean;
}

const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID as string,
    process.env.GATSBY_ALGOLIA_SEARCH_API_KEY as string
);

const createURL = (state: SearchState) => `?${qs.stringify(state)}`;

export const SearchComponent: FC<Props> = ({
    indexName,
    className,
    disableUrl = true,
    children,
    ...props
}) => {
    const location = useLocation();
    const [searchState, setSearchState] = useState(location.state);

    useEffect(() => {
        setSearchState(location.state);
    }, [location.state]);

    const onSearchStateChange = useCallback(
        searchState => {
            setSearchState(searchState);
            // Update location state (so we can use back button, etc)
            navigate(location.pathname, { state: searchState, replace: true });
        },
        [location.pathname]
    );

    return (
        <div className={cx(styles.component, className)} {...props}>
            <InstantSearch
                searchClient={searchClient}
                indexName={indexName}
                searchState={searchState || { query: "" }}
                onSearchStateChange={onSearchStateChange}
                createURL={createURL}
            >
                {children}
            </InstantSearch>
        </div>
    );
};

export default SearchComponent;
