// extracted by mini-css-extract-plugin
export var background = "RelatedArticle-module--background--6a8da";
export var bottom = "RelatedArticle-module--bottom--47dba";
export var component = "RelatedArticle-module--component--cc401";
export var fadePop = "RelatedArticle-module--fade-pop--7c51a";
export var flex = "RelatedArticle-module--flex--534f6";
export var growHeightIn = "RelatedArticle-module--grow-height-in--f0163";
export var image = "RelatedArticle-module--image--fb203";
export var imageTitleWrapper = "RelatedArticle-module--imageTitleWrapper--3dade";
export var imageWrapper = "RelatedArticle-module--imageWrapper--ef650";
export var labels = "RelatedArticle-module--labels--15418";
export var mainLink = "RelatedArticle-module--mainLink--4c03e";
export var noImage = "RelatedArticle-module--noImage--dab3a";
export var readTime = "RelatedArticle-module--readTime--a4421";
export var searchImage = "RelatedArticle-module--searchImage--390cd";
export var titleContent = "RelatedArticle-module--titleContent--ab8e8";
export var whiteBg = "RelatedArticle-module--whiteBg--9da2b";
export var withImage = "RelatedArticle-module--withImage--81d92";