import { useContext } from "react";

import { InPageNavContext } from "./InPageNavProvider";

export const useInPageNav = () => {
    const context = useContext(InPageNavContext);

    if (!context) {
        throw new Error(
            "useInPageNav must be used within an InPageNavProvider"
        );
    }

    return context;
};
