import React from "react";

interface Context {
    facet: string;
    value: string;
}

export const UpcomingEventContext = React.createContext<Context>({
    facet: "",
    value: ""
});

interface Props {
    value: {
        facet: string;
        value: string;
    };
}

export const UpcomingEventContextProvider: React.FC<Props> = ({
    children,
    value
}) => (
    <UpcomingEventContext.Provider value={value}>
        {children}
    </UpcomingEventContext.Provider>
);
