import React, {
    FC,
    HTMLAttributes,
    useContext,
    useMemo,
    useState
} from "react";
import cx from "classnames";
import { graphql } from "gatsby";
import { useInPageNavId } from "../../context/InPageNav/useInPageNavId";
import { TitleForDynamicParagraphs } from "../../components/TitleForDynamicParagraphs";
import { Link } from "../../components/Link";
import { SearchComponent } from "../../components/SearchComponent";
import { Configure } from "react-instantsearch-core";
import { Hits } from "react-instantsearch-dom";
import { RelatedEventContainer } from "../../components/RelatedEvent";
import { getFilterStringForUpcomingEvents } from "../../lib/algoliaFiltersHelpers";
import { component } from "./UpcomingEventParagraph.module.scss";
import { UpcomingEventContext } from "../../templates/ResearchGroup/UpcomingEvent";
import { CustomStateResults } from "../../components/SearchComponent/customAlgoliaWidgets";

type ResultsState = "loading" | "none" | "present";

interface Props extends HTMLAttributes<HTMLElement> {
    node?: GatsbyTypes.UpcomingEventParagraphDataFragment;
}

// TODO: Swap this out for gatsby queries

// eslint-disable-next-line complexity
export const UpcomingEventParagraph: FC<Props> = ({
    node,
    className,
    ...props
}) => {
    const [resultsState, setResultsState] = useState<ResultsState>("loading");
    const { facet, value } = useContext(UpcomingEventContext);

    const id = useInPageNavId(node);

    const filterString = useMemo(
        () =>
            getFilterStringForUpcomingEvents(
                node?.relationships?.filters || []
            ) + (facet && value ? ` AND ${facet}:"${value}"` : ""),
        [node, facet, value]
    );

    return (
        <div
            id={id}
            hidden={resultsState !== "present"}
            className={cx(component, className, "hideOnPrint")}
            {...props}
        >
            <TitleForDynamicParagraphs>
                <h2>{node.field_title}</h2>
                {node.field_link && (
                    <Link to={node.field_link.url || ""}>
                        {node.field_link.title}
                    </Link>
                )}
            </TitleForDynamicParagraphs>
            <SearchComponent disableUrl indexName={"algolia_event"}>
                <CustomStateResults
                    onStateChange={({ searchResults }) => {
                        if (searchResults) {
                            setResultsState(
                                searchResults.nbHits > 0 ? "present" : "none"
                            );
                        }
                    }}
                />
                <Configure filters={filterString} hitsPerPage={1} />
                <Hits hitComponent={RelatedEventContainer} />
            </SearchComponent>
        </div>
    );
};

export const fragment = graphql`
    fragment UpcomingEventParagraphData on paragraph__topic_upcoming_event {
        id
        field_title
        field_is_on_this_page_anchor
        relationships {
            filters: field_filter {
                name
            }
        }
        field_link {
            title
            url
        }
    }
`;

export default UpcomingEventParagraph;
