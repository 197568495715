import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";
import { CiteThisPage } from "../CiteThisPage";

import { DownloadFile } from "../DownloadFile";
import { PdfData } from "../DownloadFile/__generated__/PdfData";
import { PrintThisPage } from "../PrintThisPage";
import * as styles from "./ReadingOptions.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
	pdf: Array<PdfData | null> | null;
	citation?: string;
}

export const ReadingOptions: FC<Props> = ({
	className,
	pdf,
	citation,
	...props
}) => {   
    return (
		<aside
			className={cx(styles.component, className, "hideOnPrint")}
			{...props}
		>
			<label>Reading options</label>
			{!!pdf?.length && <DownloadFile pdf={pdf[0]} variant="sidebar" />}
			<PrintThisPage />
			{citation && <CiteThisPage citation={citation} />}
		</aside>
)};

export default ReadingOptions;
