import cx from "classnames";
import { graphql } from "gatsby";
import React, { HTMLAttributes, useMemo } from "react";
import { sortAlphabeticallyByTitle } from "../../utilities/arrayUtils";
import { SignpostContainer } from "../Signpost";
import * as styles from "./SignpostParagraph.module.scss";

interface ParagraphProps extends HTMLAttributes<HTMLElement> {
	node: GatsbyTypes.SignpostParagraphDataFragment;
	backgroundColor?: "white" | "grey";
}

export const SignpostParagraph: React.FC<ParagraphProps> = ({
	node,
	className,
	backgroundColor = "white",
}) => {
	const shouldSortAlphabetically = node?.field_sort_alphabetically ?? false;

	const sorted = useMemo(
		() =>
			shouldSortAlphabetically
				? sortAlphabeticallyByTitle(node?.relationships?.field_content || [])
				: node?.relationships?.field_content,
		[node?.relationships?.field_content, shouldSortAlphabetically],
	);

	if (!sorted?.length) return null;
	return (
		<section className={cx(className, styles.component)}>
			{node?.field_title && (
				<>
					<h2>{node?.field_title}</h2>
					<hr />
				</>
			)}
			<div
				className={cx({
					[styles.grid]: node.field_grid,
				})}
			>
				{sorted.map((item) =>
					item ? (
						<SignpostContainer
							key={item.id}
							node={item}
							backgroundColor={backgroundColor === "grey" ? "white" : "grey"}
							variant={
								node?.field_show_as_simple_signposts ? "simple" : "normal"
							}
						/>
					) : null,
				)}
			</div>
		</section>
	);
};

export default SignpostParagraph;

export const fragment = graphql`
    fragment ArticleSignpostData on node__article {
        __typename
        id
        title
        created(formatString: "D MMMM Y")
        machineDate: created
        field_publication_issue
        field_publication_volume
        field_member_only
        field_read_time
        relationships {
            field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 168
                                transformations: ["ar-168-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            large_signpost_media_image: field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 330, transformations: ["h-216"]) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            large_hero: field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 330, transformations: ["h-216"]) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            field_signpost_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 168
                                transformations: ["ar-168-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            large_signpost: field_signpost_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 330, transformations: ["h-216"]) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            field_periodical_type {
                name
                path {
                    alias
                }
            }
            field_content_type {
                name
                path {
                    alias
                }
            }
        }
        path {
            alias
        }
    }

    fragment ExternalSignpostData on node__external_signpost {
        __typename
        id
        title
        relationships {
            field_signpost_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 168
                                transformations: ["ar-168-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
        path: field_external_url {
            alias: uri
            title
        }
        field_external_url {
            uri
            title
        }
        body {
            summary
        }
    }

    fragment BasicPageSignpostData on node__basic_page {
        __typename
        id
        title

        relationships {
            field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 168
                                transformations: ["ar-168-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            field_signpost_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 168
                                transformations: ["ar-168-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
        path {
            alias
        }
        body {
            summary
        }
    }

    fragment EventsSignpostData on node__events {
        __typename
        id
        title
        location: field_location_freetext
        timezone: field_time_locale
        field_event_date_range {
            machineDate: value
            value(formatString: "D MMMM Y")
            short: value(formatString: "D MMM Y")
            long: value(formatString: "dddd, D MMMM Y")
            time: value(formatString: "HH:mm")
        }
        relationships {
            large_hero: field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 330, transformations: ["h-216"]) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            large_signpost: field_signpost_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 330, transformations: ["h-216"]) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 168
                                transformations: ["ar-168-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            field_signpost_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 168
                                transformations: ["ar-168-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            field_event_type {
                name
                path {
                    alias
                }
            }
            field_open_to_tag {
                name
            }
        }
        path {
            alias
        }
    }

    fragment ConferencesSignpostData on node__conference {
        __typename
        id
        title
        location: field_location_freetext
        timezone: field_time_locale
        field_event_date_range {
            machineDate: value
            value(formatString: "D MMMM Y")
            short: value(formatString: "D MMM Y")
            long: value(formatString: "dddd, D MMMM Y")
            time: value(formatString: "HH:mm")
        }
        relationships {
            large_hero: field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 330, transformations: ["h-216"]) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            large_signpost: field_signpost_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 330, transformations: ["h-216"]) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            large_signpost_media_image: field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 330, transformations: ["h-216"]) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }

            field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 168
                                transformations: ["ar-168-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            field_signpost_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 168
                                transformations: ["ar-168-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            field_event_type {
                name
                path {
                    alias
                }
            }

            field_open_to_tag {
                name
            }
        }
        path {
            alias
        }
    }

    fragment TopicRegionSignpostData on node__topic_region_page {
        __typename
        id
        title
        field_abstract {
            processed
        }

        relationships {
            field_content_type {
                name
                path {
                    alias
                }
            }

            field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 168
                                transformations: ["ar-168-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            field_signpost_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 168
                                transformations: ["ar-168-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            large_signpost_media_image: field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 330, transformations: ["h-216"]) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
        path {
            alias
        }
    }

    fragment ResearchGroupSignpostData on node__research_group {
        __typename
        id
        title

        relationships {
            field_content_type {
                name
                path {
                    alias
                }
            }

            field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 168
                                transformations: ["ar-168-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            large_signpost_media_image: field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 330, transformations: ["h-216"]) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
        path {
            alias
        }
    }

    fragment ProjectSignpostData on node__project {
        __typename
        id
        title
        field_closed
        field_abstract {
            value
        }
        relationships {
            field_content_type {
                name
                path {
                    alias
                }
            }

            field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 168
                                transformations: ["ar-168-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            field_signpost_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 168
                                transformations: ["ar-168-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            large_signpost_media_image: field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 330, transformations: ["h-216"]) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            large_signpost: field_signpost_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 330, transformations: ["h-216"]) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
        path {
            alias
        }
    }

    fragment LandingPageLevel2Data on node__landing_page_level_2 {
        __typename
        id
        title
        path {
            alias
        }
        field_abstract {
            value
        }
        relationships {
            field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 168
                                transformations: ["ar-168-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
    }

    fragment SignpostParagraphData on paragraph__signposted_content {
        __typename
        id
        field_sort_alphabetically
        field_show_as_simple_signposts
        field_title
        field_grid
        relationships {
            field_content {
                ...ArticleSignpostData
                ...ExternalSignpostData
                ...BasicPageSignpostData
                ...EventsSignpostData
                ...ConferencesSignpostData
                ...TopicRegionSignpostData
                ...ResearchGroupSignpostData
                ...ProjectSignpostData
                ...LandingPageLevel2Data
            }
        }
    }
`;
