// extracted by mini-css-extract-plugin
export var color1 = "BrandSubstantiationBlock-module--color1--433a4";
export var color2 = "BrandSubstantiationBlock-module--color2--9bb38";
export var color3 = "BrandSubstantiationBlock-module--color3--902cf";
export var color4 = "BrandSubstantiationBlock-module--color4--96080";
export var crest = "BrandSubstantiationBlock-module--crest--c9eb0";
export var fadePop = "BrandSubstantiationBlock-module--fade-pop--08a03";
export var figure = "BrandSubstantiationBlock-module--figure--b98ce";
export var filter = "BrandSubstantiationBlock-module--filter--4af54";
export var growHeightIn = "BrandSubstantiationBlock-module--grow-height-in--3c8d8";
export var stat = "BrandSubstantiationBlock-module--stat--3c825";
export var stats = "BrandSubstantiationBlock-module--stats--620c7";