import React, { FC, HTMLAttributes } from "react";
import { graphql, useStaticQuery } from "gatsby";
import cx from "classnames";
import * as styles from "./UnsupportedBrowserWarning.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    node?: GatsbyTypes.Query_allConfigPagesUnsupportedBrowserDisclaimerArgs;
}

export const UnsupportedBrowserWarning: FC<Props> = ({
    node,
    className,
    ...props
}) => {
    const { data } = useStaticQuery<
        GatsbyTypes.UnsupportedBrowserWarningQuery
    >(graphql`
        query UnsupportedBrowserWarning {
            data: allConfigPagesUnsupportedBrowserDisclaimer {
                nodes {
                    field_body {
                        processed
                    }
                }
            }
        }
    `);

    return (
        <div className={cx(styles.component, className)} {...props}>
            <div
                dangerouslySetInnerHTML={{
                    __html: data.nodes[0].field_body?.processed || ""
                }}
            />
        </div>
    );
};

export default UnsupportedBrowserWarning;
