import { graphql, PageProps } from "gatsby";
import React from "react";
import * as styles from "./EmbeddedContent.module.scss";

import { BreadCrumbs } from "../../types/breadcrumbs";
import { Layout } from "../../components/Layout";
import { SEO } from "../../components/SEO";
import TitleBlock from "../../components/TitleBlock";
import ArticleLayout from "../../components/ArticleLayout/ArticleLayout";
import Paragraphs, { ParagraphNode } from "../../components/Paragraphs";
import { stripHtml } from "../../lib/stringUtils";

interface Props extends PageProps {
    data: GatsbyTypes.EmbeddedContentQuery;
    pageContext: {
        id: string;
        breadcrumb?: BreadCrumbs;
    };
}

// eslint-disable-next-line complexity
export const EmbeddedContentTemplate: React.FC<Props> = ({
    data,
    pageContext,
    location
}) => {
    const { pageData } = data;

    if (!pageData) {
        return null;
    }

    const url = location.href;
    const breadcrumb = pageContext.breadcrumb?.crumbs;

    const seoTitle = pageData.title || "RUSI";

    const title = pageData.title || "";
    const lead = pageData.field_abstract?.value;

    const { relationships } = pageData;

    const { field_sections } = relationships ?? {};

    return (
        <Layout>
            <SEO title={seoTitle} description={lead && stripHtml(lead)} />
            <ArticleLayout className={styles.layout}>
                <TitleBlock
                    variant="basic-page"
                    title={title}
                    breadcrumb={breadcrumb}
                    summary={lead}
                    url={url}
                    className={styles.titleblock}
                />
                {field_sections && (
                    <Paragraphs
                        className={styles.sections}
                        data={field_sections as ParagraphNode}
                    />
                )}
            </ArticleLayout>
        </Layout>
    );
};

export default EmbeddedContentTemplate;

export const pageQuery = graphql`
    query EmbeddedContent($path: String!) {
        pageData: nodeEmbeddedContent(path: { alias: { eq: $path } }) {
            __typename
            id
            title
            field_abstract {
                value
            }
            relationships {
                field_sections {
                    ...FieldSections
                }
            }
        }
    }
`;
