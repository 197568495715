// extracted by mini-css-extract-plugin
export var centered = "PullQuoteParagraph-module--centered--f3103";
export var component = "PullQuoteParagraph-module--component--11527";
export var content = "PullQuoteParagraph-module--content--55930";
export var contentWrapper = "PullQuoteParagraph-module--contentWrapper--9549e";
export var credit = "PullQuoteParagraph-module--credit--45850";
export var creditName = "PullQuoteParagraph-module--creditName--4efc2";
export var creditText = "PullQuoteParagraph-module--creditText--65582";
export var fadePop = "PullQuoteParagraph-module--fade-pop--b0ef4";
export var growHeightIn = "PullQuoteParagraph-module--grow-height-in--e3d92";
export var icon = "PullQuoteParagraph-module--icon--be827";
export var image = "PullQuoteParagraph-module--image--3d011";
export var imageWrapper = "PullQuoteParagraph-module--imageWrapper--03710";
export var normal = "PullQuoteParagraph-module--normal--d8f73";
export var subCredit = "PullQuoteParagraph-module--subCredit--7b40e";