import cx from "classnames";
import React, { FC, HTMLAttributes, useState } from "react";

import { StrategicAction } from "../StrategicAction";
import * as styles from "./StrategicPriority.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    data?: {
        id: string;
        field_group_title?: string;
        relationships?: {
            field_ecp_items?: ActionPointsData[];
        };
    };
}

type ActionPointsData = {
    id: string;
    field_action_number?: number;
    field_ecp_title?: string;
    field_status?: number;
    field_ecp_due_date: string;
    field_last_updated: string;
    field_overview?: {
        processed: string;
    };
    field_responsible_organisations?: {
        processed: string;
    };
    field_progress?: {
        processed: string;
    };
} | null;

export const progressClasses: Array<
    "" | "completed" | "inProgress" | "overdue" | "noDueDate" | "inactive"
> = ["", "completed", "inProgress", "overdue", "noDueDate", "inactive"];

const filterItems = (
    items: ActionPointsData[],
    val: number
): ActionPointsData[] =>
    items.filter(item => item && item?.field_status === val);

const orderProgressMarkers = (items: ActionPointsData[]): ActionPointsData[] =>
    items.length > 0
        ? [1, 2, 3, 4, 5].reduce(
              (prev: ActionPointsData[] | [], current) => [
                  ...prev,
                  ...filterItems(items, current)
              ],
              []
          )
        : [];

const getActionNumMinMax = (items: Array<ActionPointsData | null>): string => {
    if (!items[0]?.field_action_number) {
        return "";
    }

    let currentMin = items[0]?.field_action_number;
    let currentMax = items[0]?.field_action_number;
    items.forEach(item => {
        if (
            item?.field_action_number &&
            item.field_action_number < currentMin
        ) {
            currentMin = item.field_action_number;
        }
        if (
            item?.field_action_number &&
            item.field_action_number > currentMax
        ) {
            currentMax = item.field_action_number;
        }
    });

    return `actions ${currentMin}-${currentMax}`;
};

export const StrategicPriority: FC<Props> = ({ data, className, ...props }) => {
    const [openState, setOpenState] = useState("closed");
    if (!data) {
        return null;
    }

    const toggleOpen = () => {
        const newVal = openState === "closed" ? "open" : "closed";
        setOpenState(newVal);
    };

    const openClass = openState === "closed" ? "closed" : "open";

    const actionsStr = getActionNumMinMax(
        data.relationships?.field_ecp_items || []
    );

    const orderedMarkers =
        data.relationships?.field_ecp_items &&
        orderProgressMarkers(data.relationships?.field_ecp_items);

    return (
        <div className={cx(styles.component, className)} {...props}>
            <div className={styles.titleArea}>
                <button
                    className={cx(styles.toggleButton, styles[openClass])}
                    onClick={toggleOpen}
                ></button>
                <span className={styles.mobileOnly}>{actionsStr}</span>
                <h5>{data.field_group_title}</h5>
                <span className={styles.desktopOnly}>{actionsStr}</span>
            </div>
            <div className={cx(styles.progressOverview, styles[openClass])}>
                <div className={styles.progressBar}>
                    {orderedMarkers?.map((item, index) => (
                        <div
                            key={item?.id || index}
                            className={cx(
                                styles.progressUnit,
                                styles[progressClasses[item?.field_status ?? 0]]
                            )}
                        ></div>
                    ))}
                </div>
            </div>
            <div className={cx(styles.actions, styles[openClass])}>
                {openClass === "open" &&
                    data.relationships?.field_ecp_items?.map((item, index) => (
                        <StrategicAction
                            key={item?.id || index}
                            data={item ?? undefined}
                            openState={openState}
                            setOpenState={setOpenState}
                        />
                    ))}
            </div>
        </div>
    );
};

export default StrategicPriority;
