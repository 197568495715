import React, { FC, HTMLAttributes } from "react";
import { graphql } from "gatsby";
import { LatestDynamicContent } from "../../components/LatestDynamicContent";
import { RelatedArticle } from "../../components/RelatedArticle";
import { RelatedInTheNews } from "../../components/RelatedInTheNews";

interface Props extends HTMLAttributes<HTMLElement> {
    node: GatsbyTypes.LatestNewsParagraphDataFragment;
    contentType?: string;
    contentTitle?: string;
    personFirstName?: string;
}

export const LatestNewsParagraph: FC<Props> = ({
    node,
    contentType,
    contentTitle,
    personFirstName,
    ...props
}) => {
    const {
        field_title,
        field_is_on_this_page_anchor,
        field_link,
        latest_news
    } = node;

    if (!node || !latest_news?.items?.length) return null;

    return (
        <LatestDynamicContent
            heading={field_title}
            linkUrl={field_link?.url}
            linkText={field_link?.title}
            includedInPageNav={field_is_on_this_page_anchor}
            resultsPerPage={3}
            infinite={false}
            hideSection={(latest_news?.items?.length || 0) <= 0}
            variant="row"
            {...props}
        >
            <ul>
                {latest_news?.items?.map(
                    // eslint-disable-next-line complexity
                    article =>
                        article && (
                            <li key={article.id}>
                                {article.__typename === "node__in_the_news" ? (
                                    <RelatedInTheNews
                                        primaryTag={article.field_primary_tag}
                                        leadQuote={article.field_focus}
                                        author={
                                            article.relationships
                                                ?.field_author?.[0]
                                                ?.field_full_name
                                        }
                                        authorPosition={
                                            article.relationships
                                                ?.field_author?.[0]
                                                ?.field_position
                                        }
                                        date={article.date}
                                        machineDate={article.machineDate}
                                        heading={article.title || ""}
                                        image={
                                            article.relationships
                                                ?.field_external_publication
                                                ?.relationships
                                                ?.field_external_publication_logo
                                                ?.relationships
                                                ?.field_media_image
                                                ?.childImageKitAsset?.fluid
                                        }
                                        externalPublisher={
                                            article.relationships
                                                ?.field_external_publication
                                                ?.name
                                        }
                                        url={article.path?.alias}
                                    />
                                ) : (
                                    <RelatedArticle
                                        variant="whiteBg"
                                        heading={article.title || ""}
                                        date={article.date}
                                        machineDate={article.machineDate}
                                        readTime={article.field_read_time}
                                        url={article.path?.alias}
                                        restrictedString={
                                            article.field_premium
                                                ? "members only"
                                                : ""
                                        }
                                        publicationName={
                                            article.relationships
                                                ?.field_content_type?.name
                                        }
                                        publicationLink={
                                            article.relationships
                                                ?.field_content_type?.path
                                                ?.alias
                                        }
                                        image={
                                            article.relationships
                                                ?.field_signpost_image
                                                ?.relationships
                                                ?.field_media_image
                                                ?.childImageKitAsset?.fluid ||
                                            article.relationships
                                                ?.field_media_image
                                                ?.relationships
                                                ?.field_media_image
                                                ?.childImageKitAsset?.fluid
                                        }
                                    />
                                )}
                            </li>
                        )
                )}
            </ul>
        </LatestDynamicContent>
    );
};

export const fragment = graphql`
    fragment LatestNewsBase on paragraph__latest_news_signpost {
        id
        field_title
        field_is_on_this_page_anchor
        field_link {
            title
            url
        }
        relationships {
            field_filter {
                name
            }
        }
    }

    fragment LatestNewsParagraphData on paragraph__latest_news_signpost {
        ...LatestNewsBase
        latest_news(limit: 3) {
            items {
                ...LatestNewsItemArticle
                ...LatestNewsItemInTheNews
            }
        }
    }

    fragment LatestNewsByAuthor on paragraph__latest_news_signpost {
        ...LatestNewsBase
        latest_news(authorId: $id) {
            items {
                ...LatestNewsItemArticle
                ...LatestNewsItemInTheNews
            }
        }
    }

    fragment LatestNewsByTopic on paragraph__latest_news_signpost {
        ...LatestNewsBase
        latest_news(facetName: $contentType, facetValue: $title) {
            items {
                ...LatestNewsItemArticle
                ...LatestNewsItemInTheNews
            }
        }
    }

    fragment LatestNewsByNetworks on paragraph__latest_news_signpost {
        ...LatestNewsBase
        latest_news(facetName: $contentType, facetValue: $title) {
            items {
                ...LatestNewsItemArticle
                ...LatestNewsItemInTheNews
            }
        }
    }

    fragment LatestNewsItemInTheNews on node__in_the_news {
        __typename
        id
        title
        field_primary_tag
        created
        path {
            alias
        }
        field_focus
        date: created(formatString: "D MMMM YYYY")
        machineDate: created
        relationships {
            field_author {
                field_position
                field_first_names
                field_full_name
            }
            field_external_publication {
                name
                relationships {
                    field_external_publication_logo {
                        relationships {
                            field_media_image {
                                childImageKitAsset {
                                    fluid(
                                        maxWidth: 256
                                        transformations: ["ar-1-1"]
                                    ) {
                                        ...ImageKitFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    fragment LatestNewsItemArticle on node__article {
        __typename
        id
        title
        field_read_time
        date: created(formatString: "D MMMM YYYY")
        machineDate: created
        field_premium
        path {
            alias
        }
        relationships {
            field_author {
                field_position
                field_first_names
            }
            field_content_type {
                name
                path {
                    alias
                }
            }
            field_publication_tag {
                name
                path {
                    alias
                }
            }
            field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 512) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            field_signpost_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 512) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default LatestNewsParagraph;
