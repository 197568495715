import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";

import { IconSprite } from "../IconSprite";
import * as styles from "./FooterSocial.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    menuItems: GatsbyTypes.GlobalElementsQuery["socialMenuItems"]["nodes"];
}

type SocialProviderName =
    | "facebook"
    | "linkedin"
    | "rss"
    | "twitter"
    | "youtube";

interface SocialIconProps {
    name: SocialProviderName;
    href: string;
}

const allowedSocialProviders = [
    "facebook",
    "twitter",
    "linkedin",
    "youtube",
    "rss"
];

const isValidTitle = (title: string) =>
    !!allowedSocialProviders.find(i => i === title.toLowerCase());

const SocialIcon: FC<SocialIconProps> = ({ name, href }) => (
    <a
        rel="noopener noreferrer"
        target="_blank"
        className={styles.socialLink}
        href={href}
    >
        <IconSprite name={name} className={cx(styles.icon, styles[name])} />
    </a>
);

export const FooterSocial: FC<Props> = ({ className, menuItems, ...props }) => (
    <div className={cx(styles.component, className)} {...props}>
        <label>Connect with us</label>
        <div className={styles.links}>
            {menuItems.map(({ id, link, title }) =>
                title && isValidTitle(title) && link?.uri ? (
                    <SocialIcon
                        key={id}
                        name={title.toLowerCase() as SocialProviderName}
                        href={link.uri}
                    />
                ) : null
            )}
        </div>
    </div>
);

export default FooterSocial;
