import cx from "classnames";
import Img, { FluidObject } from "gatsby-image";
import React, { FC, HTMLAttributes } from "react";

import * as styles from "./Sponsors.module.scss";

interface UrlData {
    linkText?: string | null;
    link?: string | null;
}
interface Props extends HTMLAttributes<HTMLElement> {
    image?: FluidObject | null;
    rawImage?: FluidObject | null;
    name?: string | null;
    body?: string | null;
    url?: UrlData | null;
}

interface SponsorsListProps extends HTMLAttributes<HTMLElement> {
    heading?: string | null;
}

export const Sponsor: FC<Props> = ({
    name,
    body,
    image,
    rawImage,
    url,
    className,
    ...props
}) => (
    <li className={cx(styles.sponsor, className)} {...props}>
        {!image && rawImage && (
            <figure className={cx(styles.imageWrapper, "hideOnPrint")}>
                <img src={rawImage?.src} alt={name ?? "company image"} loading="lazy" sizes="(max-width: 267px) 100vw, 267px" />
            </figure>
        )}
        {!rawImage?.src && image && (
            <figure className={cx(styles.imageWrapper, "hideOnPrint")}>
                <Img fluid={image} alt={name ?? "company image"} />
            </figure>
        )}
        <div>
            {name && <h3>{name}</h3>}
            {body && <p>{body}</p>}
            {url?.link && (
                <a className={styles.link} href={url.link}>
                    {url.linkText}
                </a>
            )}
        </div>
    </li>
);

export const SponsorsList: FC<SponsorsListProps> = ({
    heading,
    className,
    children,
    ...props
}) => (
    <section className={className} {...props}>
        {heading && <h2>{heading}</h2>}
        <ul>{children}</ul>
    </section>
);
