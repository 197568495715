import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";

import { ReactComponent as RusiCrest } from "../../assets/rusi-crest.svg";
import { IconSprite } from "../IconSprite";
import * as styles from "./ContentGate.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    topbarText?: string;
    variant?: string;
    hideIcon?: boolean;
}

export const ContentGate: FC<Props> = ({
    topbarText = "members only content",
    variant = "locked",
    hideIcon = false,
    className,
    children,
    ...props
}) => (
    <div
        className={cx(styles.component, styles[variant], className)}
        {...props}
    >
        <div className={styles.topbar}>
            {!hideIcon && (
                <IconSprite
                    name={variant === "locked" ? "lock" : "unlocked"}
                    className={styles.icon}
                />
            )}
            <span className={styles.topbarText}>{topbarText}</span>
            <figure className={styles.crest}>
                <RusiCrest />
            </figure>
        </div>

        <div>{children}</div>
    </div>
);

export default ContentGate;
