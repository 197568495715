export const numericalSort = (a: { value: string }, b: { value: string }) => {
    const valA = Number(a.value.split(" ")[0]);
    const valB = Number(b.value.split(" ")[0]);
    if (valA < valB) {
        return -1; //valA comes first
    }
    if (valA > valB) {
        return 1; // valB comes first
    }
    return 0; // values must be equal
};

export const reverseNumericalSort = (
    a: { value: string },
    b: { value: string }
) => {
    const valA = Number(a.value.split(" ")[0]);
    const valB = Number(b.value.split(" ")[0]);
    if (valA < valB) {
        return 1; //valB comes first
    }
    if (valA > valB) {
        return -1; // valA comes first
    }
    return 0; // values must be equal
};
