/*
    Used for dynamically populated lists of related events or conferences - RelatedEventRowDynamic
    Accepts record data from the algolia_events algolia index, extracts the required data and passes it to RelatedEvent
*/

import React, { FC, useMemo } from "react";
import { Hit } from "react-instantsearch-core";
import { getImageURL } from "../../lib/imageKit";
import { EventHit } from "../../types/data";
import { convertTimeStampToDate } from "../../utilities/dateUtils";
import RelatedEvent from "./RelatedEvent";

const getEventTimes = (startTimeStamp: number, endTimeStamp: number) => {
    const startDate = convertTimeStampToDate(startTimeStamp);
    const endDate = convertTimeStampToDate(endTimeStamp);

    const startTime = startDate.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit"
    });

    const endTime = endDate.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit"
    });

    return { startTime, endTime };
};

// eslint-disable-next-line complexity
export const RelatedEventContainer: FC<{ hit: Hit<EventHit> }> = ({ hit }) => {
    const {
        title: heading,
        url,
        event_type_name: typeName,
        event_type_path: typeLink,
        event_date_start,
        event_date_end,
        signpost_media_image_uri,
        media_image_uri,
        open_to_name: restrictedString,
        field_time_locale: locale
    } = hit;

    const searchImage = useMemo(() => {
        const imageToUse = signpost_media_image_uri || media_image_uri;

        return imageToUse && getImageURL(imageToUse, 135, 88);
    }, [media_image_uri, signpost_media_image_uri]);

    const startDate = event_date_start
        ? convertTimeStampToDate(event_date_start)
        : "";

    const fullDate = startDate
        ? startDate.toLocaleDateString("en-GB", {
              weekday: "long",
              day: "numeric",
              month: "long",
              year: "numeric"
          })
        : "";

    const machineDate = startDate ? startDate.toISOString() : "";

    const titleDate = startDate
        ? startDate.toLocaleDateString("en-GB", {
              day: "numeric",
              month: "long"
          })
        : "";

    const { startTime, endTime } = getEventTimes(
        event_date_start,
        event_date_end
    );

    const cardProps = {
        heading,
        url,
        typeName,
        typeLink,
        titleDate,
        fullDate,
        startTime,
        endTime,
        locale,
        restrictedString,
        searchImage,
        machineDate
    };

    const variant =
        typeName.toUpperCase() === "CONFERENCES" ? "conference" : "event";

    return <RelatedEvent variant={variant} {...cardProps} />;
};

export default RelatedEventContainer;
