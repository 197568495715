import React, {
    FC,
    HTMLAttributes,
    useMemo,
    useState,
    ChangeEvent,
    useCallback,
    useEffect
} from "react";

import { toLocaleDate } from "../../lib/dateUtils";
import { getNextNYears, getYearsSince } from "../../utilities/dateUtils";
import { SearchFilterContainer } from "../SearchFilterContainer";
import * as styles from "./DateRangeFilter.module.scss";
import {
    ammendDayOfMonth,
    convertDateRangeToTimeStamp,
    dateMinusNYears
} from "./helpers";
import {
    AdditionalDateFilter,
    TimestampRange,
    UpdateDateRangeProps,
    Range
} from "./types";

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];

interface Props {
    currentRefinement?: TimestampRange;
    refine: (refinement: TimestampRange) => void;
    additionalFilters?: AdditionalDateFilter[];
    futureYearsInCustom?: number;
    className?: string;
}

interface MonthYearSelectProps extends HTMLAttributes<HTMLElement> {
    label: string;
    months: string[];
    years: number[];
    onDateRangeChange: (
        minMax: "min" | "max",
        fieldName: string,
        newVal: number
    ) => void;
    defaultVal?: string;
    isMax?: boolean;
    disabled?: boolean;
}

const MonthYearSelect: FC<MonthYearSelectProps> = ({
    months,
    years,
    label,
    defaultVal,
    isMax,
    onDateRangeChange,
    disabled = false
}) => {
    const defaults = useMemo(() => {
        const defaultDate = new Date(defaultVal ?? Date.now());
        return {
            month: defaultDate.getMonth(),
            year: defaultDate.getFullYear()
        };
    }, [defaultVal]);

    const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
        onDateRangeChange(
            isMax ? "max" : "min",
            e.currentTarget.name,
            parseInt(e.currentTarget.value)
        );
    };

    return (
        <label className={styles.dropdown}>
            <span>{label}</span>
            <select
                name="month"
                onChange={onChange}
                disabled={disabled}
                defaultValue={defaults.month}
            >
                {months.map((month, index) => (
                    <option value={index} key={`${label}-${month}`}>
                        {month}
                    </option>
                ))}
            </select>
            <select
                name="year"
                onChange={onChange}
                disabled={disabled}
                defaultValue={defaults.year}
            >
                {years.map(year => (
                    <option value={year} key={`${label}-${year}`}>
                        {year}
                    </option>
                ))}
            </select>
        </label>
    );
};

export const DateRangeFilter: FC<Props> = ({
    className,
    currentRefinement,
    futureYearsInCustom = 0,
    additionalFilters = [],
    refine,
    ...props
}) => {
    const empty = !currentRefinement?.max && !currentRefinement?.min;

    const [filterCategory, setFilterCategory] = useState<number | "custom">(0);

    const minDateDefaultVal = useMemo(() => dateMinusNYears(1).toString(), []);

    const [dateRangeVal, setDateRangeVal] = useState<Range>({
        min: minDateDefaultVal,
        max: new Date(Date.now()).toString()
    });

    const filterString = useMemo(
        () =>
            filterCategory === "custom"
                ? [currentRefinement?.min, currentRefinement?.max]
                      .map(toLocaleDate)
                      .join(" – ")
                : additionalFilters[filterCategory].label,

        [
            additionalFilters,
            currentRefinement?.max,
            currentRefinement?.min,
            filterCategory
        ]
    );

    const onRadioButtonsChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setFilterCategory(value === "custom" ? value : parseInt(value));
        },
        []
    );

    useEffect(() => {
        if (filterCategory === "custom") {
            refine(convertDateRangeToTimeStamp(dateRangeVal));
            return;
        }

        const { min, max } = additionalFilters[filterCategory] || {};

        refine({ min, max });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterCategory, dateRangeVal]);

    const updateDateRangeVal = useCallback(
        ({ month, year, minMax }: UpdateDateRangeProps) => {
            setDateRangeVal(prevVal => {
                const prevDate = new Date(prevVal[minMax]);

                const newVal = new Date(
                    year ?? prevDate.getFullYear(),
                    month ?? prevDate.getMonth(),
                    1
                );

                const withCorrectDay = ammendDayOfMonth(
                    newVal,
                    minMax === "max"
                );

                return {
                    ...prevVal,
                    [minMax]: new Date(withCorrectDay).toString()
                };
            });
        },
        []
    );

    const onDateRangeChange = useCallback(
        (minMax, fieldName, value) => {
            updateDateRangeVal({
                month: fieldName === "month" ? value : null,
                year: fieldName === "year" ? value : null,
                minMax
            });
        },
        [updateDateRangeVal]
    );

    const years = useMemo(() => {
        const pastYears = getYearsSince(1970);
        const futureYears = getNextNYears(futureYearsInCustom);
        return [...pastYears, ...futureYears];
    }, [futureYearsInCustom]);

    const monthYearSelectProps = { months, years, onDateRangeChange };

    return (
        <SearchFilterContainer
            heading="filter by date"
            currentLabel={filterString}
            active={!empty}
            {...props}
        >
            <div className={styles.inputs}>
                {additionalFilters.map(({ label }, index) => (
                    <label key={label}>
                        <input
                            type="radio"
                            name="date"
                            value={index}
                            checked={filterCategory === index}
                            onChange={onRadioButtonsChange}
                        />
                        <span>{label}</span>
                    </label>
                ))}

                <label>
                    <input
                        type="radio"
                        name="date"
                        value="custom"
                        onChange={onRadioButtonsChange}
                        checked={filterCategory === "custom"}
                    />
                    <span>Date range</span>
                </label>
                <div>
                    <MonthYearSelect
                        label="From"
                        defaultVal={minDateDefaultVal}
                        disabled={filterCategory !== "custom"}
                        {...monthYearSelectProps}
                    />
                    <MonthYearSelect
                        label="To"
                        isMax
                        disabled={filterCategory !== "custom"}
                        {...monthYearSelectProps}
                    />
                </div>
            </div>
        </SearchFilterContainer>
    );
};

export default DateRangeFilter;
