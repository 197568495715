import { TimestampRange } from "../../components/DateRangeFilter/types";

export const getAlgoliaContentTypeName = (
    tagTypeName: string
): string | null => {
    switch (tagTypeName) {
        case "taxonomy_term__content_type":
            return "content_type";
        case "taxonomy_term__event_type":
            return "event_type_name";
        case "taxonomy_term__profile_type":
            return "profile_type_name";
        default:
            return null;
    }
};

export const getEventDateRefinementString = (
    dateRefinementRange?: TimestampRange
): string => {
    if (dateRefinementRange?.min && dateRefinementRange?.max) {
        return `((event_date_start <= ${dateRefinementRange.max}) AND (event_date_end >= ${dateRefinementRange.min}))`;
    } else if (dateRefinementRange?.min) {
        return `(event_date_end >= ${dateRefinementRange.min})`;
    } else if (dateRefinementRange?.max) {
        return `(event_date_start <= ${dateRefinementRange.max})`;
    }
    return "";
};

export const getDateRefinementString = (
    dateRefinementRange?: TimestampRange,
    isEventSearch = false
): string => {
    if (isEventSearch) {
        return getEventDateRefinementString(dateRefinementRange);
    }

    if (dateRefinementRange?.min && dateRefinementRange?.max) {
        return `(authored_date: ${dateRefinementRange.min} TO ${dateRefinementRange.max})`;
    } else if (dateRefinementRange?.min) {
        return `(authored_date >= ${dateRefinementRange.min})`;
    } else if (dateRefinementRange?.max) {
        return `(authored_date <= ${dateRefinementRange.max})`;
    }
    return "";
};

export const createFilterString = (
    contentTypeName: string | null,
    contentTypeValue: string | null,
    ...rest: string[]
): string => {
    const filters = [];

    filters.push(`NOT type: 'external_signpost' AND NOT efs: true`);

    if (contentTypeValue && contentTypeName) {
        filters.push(`${contentTypeName}:'${contentTypeValue}'`);
    }

    filters.push(...rest);

    return filters.filter(Boolean).join(" AND ");
};
