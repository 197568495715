/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, HTMLAttributes, useMemo } from "react";
import { Hit } from "react-instantsearch-core";

import { getImageURL, getImageURLLogo } from "../../lib/imageKit";
import { formatAuthorNames } from "../../lib/searchHelpers";
import { SearchHit } from "../../types/data";
import { convertTimeStampToDate } from "../../utilities/dateUtils";
import Signpost from "../Signpost";

interface Props extends HTMLAttributes<HTMLElement> {
    hit: Hit<SearchHit>;
}

const PERSON_IMAGE_WIDTH = 88;
const PERSON_IMAGE_HEIGHT = 113;
const IMAGE_WIDTH = 268;
const IMAGE_HEIGHT = 176;

// eslint-disable-next-line complexity
const getImage = (hit: Hit<SearchHit>) => {
    const isPerson = hit.type === "rusi_people";
    const isInTheNews =
        hit.type === "in_the_news" || hit.type === "external_publication";

    if (isPerson) {
        const props: [string | null, number, number] = [
            hit?.user_picture_uri,
            PERSON_IMAGE_WIDTH,
            PERSON_IMAGE_HEIGHT
        ];
        //@ts-ignore
        if (typeof props[0] === "string") return getImageURL(...props);
    }

    if (isInTheNews) {
        const props: [string | null, number, number] = [
            hit?.external_publisher_logo_uri,
            IMAGE_WIDTH,
            IMAGE_HEIGHT
        ];
        //@ts-ignore
        if (typeof props[0] === "string") return getImageURLLogo(...props);
    }

    const props: [string | null, number, number] = [
        hit?.signpost_media_image_uri ?? hit.media_image_uri,
        IMAGE_WIDTH,
        IMAGE_HEIGHT
    ];

    //@ts-ignore
    if (typeof props[0] === "string") return getImageURL(...props);
    return "";
};

export const getEventTimeString = ({
    event_date_start,
    event_date_end
}: SearchHit) => {
    const startDate = convertTimeStampToDate(event_date_start || 0);
    const endDate = event_date_end
        ? convertTimeStampToDate(event_date_end)
        : null;

    const startTime = startDate?.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit"
    });

    const endTime =
        endDate &&
        endDate?.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit"
        });

    return { startTime, endTime };
};

// eslint-disable-next-line complexity
export const SearchResult: FC<Props> = ({ hit }) => {
    const img = useMemo(() => getImage(hit), [hit]);

    const isEvent = !!hit.event_date_start;

    const timestamp = isEvent ? hit.event_date_start : hit.authored_date;

    const date = useMemo(
        () => (timestamp ? convertTimeStampToDate(timestamp) : null),
        [timestamp]
    );

    const formattedDate = useMemo(
        () =>
            date?.toLocaleDateString(["utc"], {
                weekday: isEvent ? "long" : undefined,
                day: "numeric",
                month: "long",
                year: "numeric"
            }),
        [date, isEvent]
    );

    const machineDate = useMemo(() => date?.toISOString(), [date]);

    const authorString = hit.field_outside_author || formatAuthorNames(hit);

    const { startTime, endTime } = useMemo(
        () =>
            isEvent
                ? getEventTimeString(hit)
                : { startTime: undefined, endTime: undefined },
        [hit, isEvent]
    );

    const isPerson = hit.type === "rusi_people";

    const includeSummary = hit.type === "project";

    const summary = hit.summary ?? hit.abstract;

    return (
        <Signpost
            backgroundColor="white"
            title={`${hit.person_first_names ?? ""} ${hit.title ?? ""}`}
            date={formattedDate}
            machineDate={machineDate}
            summary={includeSummary ? summary : null}
            url={hit.url ?? ""}
            variant="search"
            closed={hit.field_closed}
            authorString={authorString && !isEvent ? `${authorString}` : null}
            type={
                hit.content_type ??
                hit.article_type ??
                hit.event_type_name ??
                ""
            }
            typeLink={hit.content_type_path ?? hit.event_type_path ?? ""}
            searchImage={img}
            issue={hit.publication_issue}
            volume={hit.publication_volume}
            readTime={hit.read_time}
            openTo={
                isEvent ? hit.open_to_name : hit.premium ? "members only" : ""
            }
            isPerson={isPerson}
            position={isPerson ? hit.position : ""}
            department={isPerson ? hit.staff_department_name : ""}
            startTime={startTime}
            endTime={endTime}
            locale={hit.event_time_locale}
            eventLocation={hit.event_location_freetext}
            externalPublisherName={hit.external_publisher_name}
            externalPublisherFeature={hit.feature_descriptor}
        />
    );
};

export default SearchResult;
