import { graphql } from "gatsby";
import React, { FC, HTMLAttributes } from "react";
import Accordion, { AccordionItem } from "../Accordion/Accordion";

type AccordionItemData = GatsbyTypes.paragraph__accordion;

interface Props extends HTMLAttributes<HTMLElement> {
	node: GatsbyTypes.AccordionParagraphDataFragment;
}

const formatAccordionItemsData = (rawItems: Array<AccordionItemData | null>) =>
	rawItems
		.map((item) => {
			if (!item) {
				return null;
			}
			const { field_accordion_body, field_accordion_title, id } = item;
			return {
				title: field_accordion_title,
				content: field_accordion_body?.processed ?? "",
				id,
			};
		})
		.filter(Boolean) as AccordionItem[];

export const AccordionParagraph: FC<Props> = ({ node, ...props }) => (
	<Accordion
		title={node?.field_accordion_heading}
		items={formatAccordionItemsData(
			(node?.relationships?.field_accordion_items ??
				[]) as Array<AccordionItemData | null>,
		)}
		{...props}
	/>
);

export const fragment = graphql`
    fragment AccordionParagraphData on paragraph__accordion_container {
        __typename
        id
        field_accordion_heading
        relationships {
            field_accordion_items {
                field_accordion_title
                field_accordion_body {
                    processed
                }
                id
            }
        }
    }
`;

export default AccordionParagraph;
