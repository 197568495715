// extracted by mini-css-extract-plugin
export var aboutCfcs = "EcpPageComponent-module--aboutCfcs--72078";
export var aboutCfcsSection = "EcpPageComponent-module--aboutCfcsSection--dbb8b";
export var button = "EcpPageComponent-module--button--19142";
export var component = "EcpPageComponent-module--component--660ec";
export var descriptionWrapper = "EcpPageComponent-module--descriptionWrapper--fac12";
export var fadePop = "EcpPageComponent-module--fade-pop--bad0b";
export var greyBg = "EcpPageComponent-module--greyBg--7d803";
export var growHeightIn = "EcpPageComponent-module--grow-height-in--539eb";
export var methodologyLink = "EcpPageComponent-module--methodologyLink--11792";
export var overview = "EcpPageComponent-module--overview--f85ca";
export var statusTracker = "EcpPageComponent-module--statusTracker--804d5";
export var strategicPriorities = "EcpPageComponent-module--strategicPriorities--3aa1a";
export var strategicPrioritiesBg = "EcpPageComponent-module--strategicPrioritiesBg--1da14";
export var strategicPrioritiesMobileBg = "EcpPageComponent-module--strategicPrioritiesMobileBg--0c14b";
export var strategicPrioritiesSection = "EcpPageComponent-module--strategicPrioritiesSection--d75c3";
export var strategicPrioritiesWrapper = "EcpPageComponent-module--strategicPrioritiesWrapper--fec02";
export var summary = "EcpPageComponent-module--summary--a529e";
export var timeRemaining = "EcpPageComponent-module--timeRemaining--54429";
export var title = "EcpPageComponent-module--title--03e03";