import React, { HTMLAttributes } from "react";
import { Link } from "../Link";

interface Props extends HTMLAttributes<HTMLElement> {
    url?: string | null;
}

export const LinkOrSpan: React.FC<Props> = ({ url, children, ...props }) =>
    url ? (
        <Link to={url} {...props}>
            {children}
        </Link>
    ) : (
        <span {...props}>{children}</span>
    );

export default LinkOrSpan;
