import React, { FC, HTMLAttributes } from "react";
import { graphql } from "gatsby";
import { BrandSubstantiationBlock } from "../../components/BrandSubstantiationBlock";

interface Props extends HTMLAttributes<HTMLElement> {
    node?: GatsbyTypes.BrandSubstantiationBlockDataFragment;
}

export const BrandSubstantiationBlockParagraph: FC<Props> = ({
    node,
    ...rest
}) => {
    if (!node) {
        return null;
    }

    return (
        <BrandSubstantiationBlock
            text={node.field_text?.processed}
            stats={node.relationships?.field_stat_blocks}
        />
    );
};

export default BrandSubstantiationBlockParagraph;

export const fragment = graphql`
    fragment BrandSubstantiationBlockData on paragraph__brand_substantiation_block {
        id
        field_text {
            processed
        }
        relationships {
            field_stat_blocks {
                id
                field_statement
                field_number
            }
        }
    }
`;
