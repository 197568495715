import { graphql } from "gatsby";
import React, { FC, HTMLAttributes } from "react";

import { JournalIssueSignpost } from "../JournalIssueSignpost/JournalIssueSignpost";

type JournalIssueSignpostType =
    | GatsbyTypes.JournalIssueSignpostParagraphDataFragment
    | GatsbyTypes.JournalIssueSignpostFromLibraryFragment;

interface Props extends HTMLAttributes<HTMLElement> {
    node?: JournalIssueSignpostType;
}

const getRelevantData = (node: JournalIssueSignpostType) => {
    switch (node.__typename) {
        case "paragraph__journal_signpost":
            return node;
        case "paragraph__from_library":
            const typed = node as JournalIssueSignpostType;
            return typed?.relationships?.field_reusable_paragraph?.relationships
                ?.paragraphs;
        default:
            return null;
    }
};

export const JournalIssueSignpostParagraph: FC<Props> = ({
    node,
    ...props
}) => {
    if (!node) {
        return null;
    }

    const data = getRelevantData(node);

    if (!data) {
        return null;
    }

    return (
        <JournalIssueSignpost
            heading={data.field_journal_title}
            subtext={data.field_volume_year_and_issue_info}
            image={
                data?.relationships?.field_cover?.relationships
                    ?.field_media_image?.childImageKitAsset?.fixed
            }
            path={data?.field_journal_listing_link?.url ?? ""}
            linkLabel={data?.field_journal_listing_link?.title ?? ""}
            {...props}
        />
    );
};

export const fragment = graphql`
    fragment JournalIssueSignpostParagraphData on paragraph__journal_signpost {
        __typename
        id
        field_journal_title
        field_volume_year_and_issue_info
        field_journal_listing_link {
            title
            url
        }
        relationships {
            field_cover {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            id
                            fixed(width: 62) {
                                ...ImageKitFixed
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const libraryFragment = graphql`
    fragment JournalIssueSignpostFromLibrary on paragraph__from_library {
        __typename
        id
        relationships {
            field_reusable_paragraph {
                label
                relationships {
                    paragraphs {
                        ...JournalIssueSignpostParagraphData
                    }
                }
            }
        }
    }
`;

export default JournalIssueSignpostParagraph;
