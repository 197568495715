import React, { HTMLAttributes } from "react";

import * as styles from "./Agenda.module.scss";

export interface AgendaSession {
    id: string;
    title: string;
    startDate: string;
    endDate: string;
    machineStartDate: string;
    machineEndDate: string;
    summary?: string | null;
}

export interface AgendaDay {
    id: string;
    title: string;
    sessions?: AgendaSession[];
}

export interface AgendaProps extends HTMLAttributes<HTMLElement> {
    title: string;
    days: AgendaDay[];
}

export const Agenda: React.FC<AgendaProps> = ({ title, days, ...props }) => (
    <section className={styles.component} {...props}>
        <h2>{title}</h2>
        <div>
            {days?.map(day =>
                day ? (
                    <div className={styles.tableWrapper} key={day.id}>
                        <h3>{day.title}</h3>
                        <ul className={styles.table}>
                            {day.sessions?.map(session => (
                                <li
                                    key={session?.id || ""}
                                    className={styles.row}
                                >
                                    <AgendaSession
                                        title={session?.title || ""}
                                        endTime={session?.endDate}
                                        startTime={session?.startDate}
                                        machineEndTime={session?.machineEndDate}
                                        machineStartTime={
                                            session?.machineStartDate
                                        }
                                        summary={session?.summary}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null
            )}
        </div>
    </section>
);

interface AgendaSessionProps {
    title: string;
    startTime?: string;
    endTime?: string;
    machineStartTime?: string;
    machineEndTime?: string;
    summary?: string | null;
}

export const AgendaSession: React.FC<AgendaSessionProps> = ({
    title,
    startTime,
    endTime,
    machineStartTime,
    machineEndTime,
    summary
}) => (
    <>
        <div className={styles.heading}>
            <span className={styles.time}>
                <time dateTime={machineStartTime}>{startTime}</time>
                {" - "}
                <time dateTime={machineEndTime}>{endTime}</time>
            </span>
            <h4>{title}</h4>
        </div>
        {summary && (
            <div
                className={styles.summary}
                dangerouslySetInnerHTML={{
                    __html: summary
                }}
            />
        )}
    </>
);
