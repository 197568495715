import React from "react";
import cx from "classnames";
import { graphql } from "gatsby";

import * as styles from "./ParagraphMediaEnquiryGroup.module.scss";

interface Props {
    title: string;
    node: GatsbyTypes.NodeMediaEnquiriesFragment;
    className?: string;
}

export const ParagraphMediaEnquiryGroup: React.FC<Props> = ({
    title,
    node,
    className
}) => {
    return (
        <div className={cx(styles.component, className)}>
            <label>{title}</label>
            <ul className={styles.contactList}>
                {node.relationships?.field_media_contacts?.map(contact => {
                    if (!contact) return null;

                    const {
                        id,
                        field_title,
                        field_email_address,
                        field_job_title,
                        field_phone_number
                    } = contact;

                    return (
                        <li key={id}>
                            <address>
                                <span className={styles.field_title}>
                                    {field_title}
                                </span>
                                {field_job_title && (
                                    <span className={styles.field_job_title}>
                                        {field_job_title}
                                    </span>
                                )}
                                {field_phone_number && (
                                    <span className={styles.field_phone_number}>
                                        <a href={`tel:${field_phone_number}`}>
                                            {field_phone_number}
                                        </a>
                                    </span>
                                )}
                                {field_email_address && (
                                    <span
                                        className={styles.field_email_address}
                                    >
                                        <a
                                            href={`mailto:${field_email_address}`}
                                        >
                                            {field_email_address}
                                        </a>
                                    </span>
                                )}
                            </address>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export const fragment = graphql`
    fragment NodeMediaEnquiries on node__media_enquiries {
        __typename
        id
        relationships {
            field_media_contacts {
                field_email_address
                field_job_title
                field_phone_number
                field_title
                id
            }
        }
    }
`;
