// extracted by mini-css-extract-plugin
export var bigDate = "RelatedEvent-module--bigDate--7fed1";
export var bottom = "RelatedEvent-module--bottom--be5b4";
export var component = "RelatedEvent-module--component--78e8c";
export var conference = "RelatedEvent-module--conference--c4d55";
export var fadePop = "RelatedEvent-module--fade-pop--df158";
export var flex = "RelatedEvent-module--flex--66dee";
export var fullDate = "RelatedEvent-module--fullDate--8334e";
export var growHeightIn = "RelatedEvent-module--grow-height-in--647c7";
export var image = "RelatedEvent-module--image--fbae7";
export var imageDateWrapper = "RelatedEvent-module--imageDateWrapper--bec80";
export var labels = "RelatedEvent-module--labels--14425";
export var mainLink = "RelatedEvent-module--mainLink--602aa";
export var restrictedTo = "RelatedEvent-module--restrictedTo--58a1e";
export var searchImage = "RelatedEvent-module--searchImage--a9ae8";
export var slash = "RelatedEvent-module--slash--ef93a";
export var textWrapper = "RelatedEvent-module--textWrapper--d75ec";
export var timeAndLocation = "RelatedEvent-module--timeAndLocation--6bb47";