import React from "react";
import * as styles from "./LoadingSpinner.module.scss";
export const LoadingSpinner: React.FC = () => {
	return (
		<div className={styles.spinner}>
			<div />
			<div />
			<div />
			<div />
		</div>
	);
};
