import cx from "classnames";
import { graphql } from "gatsby";
import React from "react";

import { BodyText } from "../../components/BodyText";
import { Enquiries } from "../../components/Enquiries";
import { ExternalLinkCard } from "../../components/ExternalLinkCard";
import { Layout } from "../../components/Layout";
import { ProfileTitleBlock } from "../../components/ProfileTitleBlock";
import { SEO } from "../../components/SEO";
import { TagsList } from "../../components/TagsList";
import { BreadCrumbs } from "../../types/breadcrumbs";
import * as styles from "./Person.module.scss";
import { getAreasOfExpertiseTags } from "./helpers";
import ContentTemplate from "../../components/ContentTemplate";
import ArticleLayout, {
    Body,
    Main,
    Sidebar
} from "../../components/ArticleLayout/ArticleLayout";
import { stripHtml } from "../../lib/stringUtils";

interface Props {
    data: GatsbyTypes.PersonQuery;
    pageContext: {
        id: string;
        breadcrumb: BreadCrumbs;
    };
}

interface ProfileWaysToContactProps {
    emailAddress?: string | null;
    twitterUrl?: string | null;
    linkedinUrl?: string | null;
    phoneNumber?: string | null;
    vCard?: string | null;
}

const ProfileWaysToContact: React.FC<ProfileWaysToContactProps> = ({
    emailAddress,
    twitterUrl,
    linkedinUrl,
    phoneNumber,
    vCard
}) => (
    <>
        <label>Ways to contact</label>
        {emailAddress && (
            <ExternalLinkCard
                url={`mailto:${emailAddress}`}
                label="email"
                iconName="email"
            />
        )}
        {twitterUrl && (
            <ExternalLinkCard
                url={twitterUrl}
                label="twitter"
                iconName="twitter"
            />
        )}
        {linkedinUrl && (
            <ExternalLinkCard
                url={linkedinUrl}
                label="linkedin"
                iconName="linkedin"
            />
        )}
        {phoneNumber && (
            <ExternalLinkCard
                url={`tel:${phoneNumber}`}
                label={phoneNumber}
                iconName="phone"
                variant="notBold"
            />
        )}

        {vCard && (
            <ExternalLinkCard url={vCard} label={"vCard"} iconName="card" />
        )}
    </>
);

// eslint-disable-next-line complexity
export const PersonTemplate: React.FC<Props> = ({ data, pageContext }) => {
    const { person } = data;

    if (!person) return <Layout />;

    const text = person.body?.processed || person.body?.value || "";
    const breadcrumb = pageContext.breadcrumb.crumbs;
    const title = `${person.field_first_names ?? ""} ${person.title ?? ""}`;
    const image =
        person?.relationships?.field_user_picture?.childImageKitAsset?.fluid;
    const emailAddress = person?.field_email_address;
    const twitterUrl = person?.field_twitter?.uri;
    const linkedinUrl = person?.field_linkedin?.uri;
    const position = person?.field_position;
    const departments = person?.relationships?.field_staff_department;
    const phoneNumber = person.field_telephone_number;
    const vCard =
        person.relationships?.field_vcard?.relationships?.field_media_file
            ?.fields?.cdn_url;
    const { field_sections, field_main_content } = person.relationships ?? {};

    const enquiriesProps = {
        title: person.relationships?.field_enquiries_block?.field_title,
        contactName:
            person.relationships?.field_enquiries_block?.field_contact_name,
        emailAddress:
            person.relationships?.field_enquiries_block?.field_contact_email,
        phoneNumber: person.relationships?.field_enquiries_block?.field_number
    };

    const areasOfExpertiseTags = getAreasOfExpertiseTags(person.relationships);

    const titleBlockProps = {
        title,
        image,
        emailAddress,
        twitterUrl,
        linkedinUrl,
        vCard,
        position,
        departments,
        breadcrumb
    };

    const waysToConnectProps = {
        emailAddress,
        twitterUrl,
        linkedinUrl,
        phoneNumber,
        vCard
    };

    const showWaysToContact =
        emailAddress || twitterUrl || linkedinUrl || phoneNumber;

    // Metadata
    const metaDescription = person?.body?.summary;

    return (
        <Layout>
            <>
                <SEO
                    title={title}
                    description={metaDescription && stripHtml(metaDescription)}
                />
                <ArticleLayout>
                    {/* <section className={styles.profile}> */}
                    <ProfileTitleBlock {...titleBlockProps} />
                    {areasOfExpertiseTags.length ? (
                        <div
                            className={cx(
                                styles.mobileOnly,
                                styles.sidebarStyleItem
                            )}
                        >
                            <label>Areas of expertise</label>
                            <TagsList
                                tags={areasOfExpertiseTags}
                                variant="horizontal"
                            />
                            <hr />
                        </div>
                    ) : null}
                    <Body>
                        <Main>
                            <BodyText html={text} />
                            <ContentTemplate
                                hideBorderTop={!text}
                                sections={[
                                    {
                                        fields: {
                                            sections: field_main_content ?? []
                                        }
                                    }
                                ]}
                            />
                            {showWaysToContact && (
                                <div
                                    className={cx(
                                        styles.mobileOnly,
                                        styles.sidebarStyleItem
                                    )}
                                >
                                    <ProfileWaysToContact
                                        {...waysToConnectProps}
                                    />
                                </div>
                            )}
                        </Main>
                        <Sidebar>
                            {areasOfExpertiseTags.length ? (
                                <aside aria-label="areas of expertise">
                                    <label>Areas of expertise</label>
                                    <TagsList
                                        tags={areasOfExpertiseTags}
                                        variant="vertical"
                                    />

                                    <hr />
                                </aside>
                            ) : null}

                            {showWaysToContact && (
                                <aside>
                                    <ProfileWaysToContact
                                        {...waysToConnectProps}
                                    />
                                    <hr />
                                </aside>
                            )}
                            {enquiriesProps.title && (
                                <Enquiries {...enquiriesProps} />
                            )}
                        </Sidebar>
                    </Body>

                    <ContentTemplate
                        sections={field_sections ?? []}
                        lastSection
                        contentType="People"
                        contentTitle={person.title}
                        personFirstName={person.field_first_names}
                    />
                </ArticleLayout>
            </>
        </Layout>
    );
};

export default PersonTemplate;

export const pageQuery = graphql`
    query Person($id: String!) {
        person: nodeRusiPeople(id: { eq: $id }) {
            id
            path {
                alias
            }
            body {
                processed
                value
            }
            body {
                summary
            }
            field_first_names
            field_position
            field_research_focus
            field_telephone_number
            field_email_address
            field_twitter {
                uri
            }
            field_linkedin {
                uri
            }
            title
            relationships {
                field_sections {
                    id
                    field_paragraph_container_style
                    field_background
                    fields: relationships {
                        sections: field_content_paragr {
                            ...FieldSectionsPerson
                        }
                    }
                }
                field_main_content {
                    ...FieldMainContent
                }
                field_staff_department {
                    name
                    path {
                        alias
                    }
                }
                field_user_picture {
                    childImageKitAsset {
                        fluid(
                            maxWidth: 169
                            transformations: ["fo-face", "ar-10-13"]
                        ) {
                            ...ImageKitFluid
                        }
                    }
                }
                field_enquiries_block {
                    field_title
                    field_contact_email
                    field_contact_name
                    field_number
                }
                field_region {
                    name
                    id
                    path {
                        alias
                    }
                }
                field_topics {
                    name
                    id
                    path {
                        alias
                    }
                }
                field_vcard {
                    relationships {
                        field_media_file {
                            fields {
                                cdn_url
                            }
                        }
                    }
                }
            }
        }
    }
`;
