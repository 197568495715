import { navigate } from "gatsby";
import cx from "classnames";
import React, {
    FC,
    HTMLAttributes,
    useState,
    useCallback,
    FormEvent,
    useRef,
    useEffect
} from "react";
import { SearchBoxProvided } from "react-instantsearch-core";
import { IconSprite } from "../IconSprite";
import { TextInput } from "../TextInput";
import * as styles from "./SearchBox.module.scss";

interface SearchFormProps extends Partial<SearchBoxProvided> {
    submitCallback?: (query: string) => void;
    open?: boolean;
}

const delay = 500;

export const SearchForm: React.FC<SearchFormProps> = ({
    submitCallback,
    currentRefinement,
    refine,
    open
}) => {
    const [value, setValue] = useState(currentRefinement || "");
    const timer = useRef<NodeJS.Timeout | null>(null);

    const submit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            if (submitCallback) submitCallback(value);
        },
        [submitCallback, value]
    );

    const onChangeDebounced = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value;
            timer.current && clearTimeout(timer.current);
            if (refine) timer.current = setTimeout(() => refine(value), delay);

            setValue(value);
        },
        [refine]
    );

    useEffect(() => {
        currentRefinement && setValue(currentRefinement);
    }, [currentRefinement]);

    useEffect(() => {
        return () => {
            timer.current && clearTimeout(timer.current);
        };
    }, []);

    return (
        <form onSubmit={submit} className={styles.form}>
            <TextInput
                placeholder={"Search rusi.org"}
                type="search"
                value={value}
                onChange={onChangeDebounced}
                focus={open}
            />
            <button type="submit" className={styles.button}>
                <IconSprite aria-label="Search" name="search" />
            </button>
        </form>
    );
};

interface Props extends HTMLAttributes<HTMLElement> {
    open: boolean;
    handleClose: () => void;
}

export const SearchBox: FC<Props> = ({
    className,
    open,
    handleClose,
    ...props
}) => {
    const submitCallback = useCallback(
        (queryString: string) =>
            navigate(`/search`, { state: { query: queryString } }),
        []
    );

    if (!open) {
        return null;
    }

    return (
        <div
            className={cx([styles.component, { [styles.open]: open }])}
            {...props}
        >
            <div className={styles.content}>
                <button
                    title="Close"
                    onClick={handleClose}
                    className={styles.close}
                >
                    Close
                    <IconSprite aria-label="close" name="close" />
                </button>
                <SearchForm open={open} submitCallback={submitCallback} />
            </div>
        </div>
    );
};

export default SearchBox;
