import React, { createContext, useContext } from "react";

// A context for TextParagraph
export interface TextParagraphContext {
    footNotes: Array<{
        id: string;
        title: string;
        body: {
            processed: string;
        };
    }>;
}
export const TextParagraphContext = createContext<TextParagraphContext>({
    footNotes: []
});

export const useTextParagraphContext = (): TextParagraphContext =>
    useContext(TextParagraphContext);
