// extracted by mini-css-extract-plugin
export var body = "ExternalPublication-module--body--86cc1";
export var component = "ExternalPublication-module--component--8d4f8";
export var desktopOnly = "ExternalPublication-module--desktopOnly--84aeb";
export var experts = "ExternalPublication-module--experts--8e855";
export var fadePop = "ExternalPublication-module--fade-pop--c9b3f";
export var featuredExpertsTitle = "ExternalPublication-module--featuredExpertsTitle--a1267";
export var growHeightIn = "ExternalPublication-module--grow-height-in--0afbc";
export var horizontalrule = "ExternalPublication-module--horizontalrule--599d2";
export var keywordTags = "ExternalPublication-module--keywordTags--ec8e5";
export var layout = "ExternalPublication-module--layout--cf7c6";
export var mobileOnly = "ExternalPublication-module--mobileOnly--4cacb";
export var node = "ExternalPublication-module--node--25719";
export var padding = "ExternalPublication-module--padding--b7d15";
export var primaryTag = "ExternalPublication-module--primaryTag--9380c";
export var sections = "ExternalPublication-module--sections--24a42";
export var shortHr = "ExternalPublication-module--shortHr--8bc1e";
export var sidebar = "ExternalPublication-module--sidebar--d55a4";
export var social = "ExternalPublication-module--social--166de";
export var sticky = "ExternalPublication-module--sticky--2bb3f";