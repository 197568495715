// extracted by mini-css-extract-plugin
export var button = "InPageNavigation-module--button--5389e";
export var buttonTitle = "InPageNavigation-module--buttonTitle--f8396";
export var component = "InPageNavigation-module--component--82753";
export var dropdown = "InPageNavigation-module--dropdown--75d81";
export var fadePop = "InPageNavigation-module--fade-pop--bdf30";
export var growHeightIn = "InPageNavigation-module--grow-height-in--7986b";
export var icon = "InPageNavigation-module--icon--f1c54";
export var navigationLink = "InPageNavigation-module--navigationLink--0b486";
export var navigationSection = "InPageNavigation-module--navigationSection--018d4";
export var open = "InPageNavigation-module--open--857e8";