import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";

import { stripHtml, truncateStringAtSpace } from "../../lib/stringUtils";
import * as styles from "./BodyText.module.scss";
import { TextAreaParsed } from "../TextAreaParsed";

interface Props extends HTMLAttributes<HTMLElement> {
    fadeOut?: boolean;
    html: string;
    footnotes?: GatsbyTypes.FieldFootnoteFragment[];
}

const MAX_FADED_OUT_LENGTH = 480;

const truncateText = (text: string) =>
    truncateStringAtSpace(stripHtml(text), MAX_FADED_OUT_LENGTH);

export const BodyText: FC<Props> = ({
    fadeOut = false,
    className,
    html,
    footnotes = [],
    ...props
}) => {
    const text = fadeOut ? truncateText(html) : html;

    return fadeOut ? (
        <p className={cx(className, styles.fadeOut)}>{text}</p>
    ) : (
        <TextAreaParsed
            {...props}
            className={className}
            html={text}
            footnotes={footnotes}
        />
    );
};

export default BodyText;
