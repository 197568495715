import cx from "classnames";
import BackgroundImage from "gatsby-background-image";
import { FluidObject } from "gatsby-image";
import React, { FC } from "react";

import { Link } from "../Link";
import { LinkOrSpan } from "../LinkOrSpan";
import * as styles from "./RelatedEvent.module.scss";
import { truncateStringAtSpace } from "../../lib/stringUtils";

interface Props {
    url: string | null;
    typeName?: string | null;
    typeLink?: string | null;
    titleDate?: string | null;
    fullDate?: string | null;
    heading?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    locale?: string | null;
    location?: string | null;
    restrictedString?: string | null;
    image?: FluidObject;
    searchImage?: string | null;
    variant?: "event" | "conference";
    machineDate?: string | null;
    className?: string;
}

// eslint-disable-next-line complexity
export const RelatedEvent: FC<Props> = ({
    url,
    typeName,
    typeLink,
    titleDate,
    fullDate,
    machineDate,
    heading,
    startTime,
    endTime,
    locale,
    location,
    image,
    searchImage,
    restrictedString,
    variant = "event",
    className,
    ...props
}) => {
    return (
        <div
            className={cx(styles.component, className, styles[variant])}
            {...props}
        >
            <Link
                className={styles.mainLink}
                to={url ?? ""}
                aria-label={`${heading}`}
            />
            <div className={styles.imageDateWrapper}>
                <div className={styles.textWrapper}>
                    <div className={cx(styles.flex, styles.labels)}>
                        {typeName && (
                            <LinkOrSpan url={typeLink} tabIndex={-1}>
                                {typeName}
                            </LinkOrSpan>
                        )}
                    </div>
                    {titleDate && machineDate && (
                        <time dateTime={machineDate} className={styles.bigDate}>
                            {titleDate}
                        </time>
                    )}
                    {restrictedString && (
                        <span className={styles.restrictedTo}>
                            {restrictedString}
                        </span>
                    )}
                </div>
                {image && (
                    <img
                        className={styles.image}
                        src={image}
                        alt={heading ?? ""}
                    />
                )}

                {!image && searchImage && (
                    <div>
                        <img
                            className={styles.searchImage}
                            src={searchImage}
                            alt={heading ?? ""}
                        />
                    </div>
                )}
            </div>
            <hr />
            {heading && (
                <h3>{truncateStringAtSpace(heading || "", 80, "...")}</h3>
            )}
            <div className={styles.bottom}>
                {fullDate && machineDate && (
                    <time
                        aria-label="event date"
                        dateTime={machineDate}
                        className={styles.fullDate}
                    >
                        {fullDate}
                    </time>
                )}
                <div className={cx(styles.flex, styles.timeAndLocation)}>
                    {startTime && (
                        <time aria-label="event start time">{startTime}</time>
                    )}
                    {endTime && (
                        <>
                            <span>{"-"}</span>
                            <time aria-label="event end time">{endTime}</time>
                        </>
                    )}
                    {locale && <span>{locale}</span>}
                    {startTime && location && (
                        <span className={styles.slash}>{"/"}</span>
                    )}
                    {location && (
                        <span aria-label="event location">{location}</span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RelatedEvent;
