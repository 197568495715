import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";

import * as styles from "./Enquiries.module.scss";

interface Props {
    title?: string | null;
    emailAddress?: string | null;
    contactName?: string | null;
    phoneNumber?: string | null;
    className?: string;
}

export const Enquiries: FC<Props> = ({
    title,
    contactName,
    emailAddress,
    phoneNumber,
    className,
    ...props
}) => (
    <aside className={cx(styles.component, className)} {...props}>
        {title && <label>{title}</label>}
        {contactName && <small>{contactName}</small>}
        {phoneNumber && (
            <small>
                <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </small>
        )}
        {emailAddress && (
            <small>
                <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
            </small>
        )}
    </aside>
);

export default Enquiries;
