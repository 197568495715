import cx from "classnames";
import React, { FC, HTMLAttributes, useState, useCallback } from "react";

import { IconSprite } from "../IconSprite";
import * as styles from "./Accordion.module.scss";

export interface AccordionItem {
    title: string | null;
    content: string | null;
    id: string;
}

interface Props extends HTMLAttributes<HTMLElement> {
    title?: string;
    items: AccordionItem[];
}

const AccordionItemIcons: FC = () => (
    <div className={styles.iconWrapper}>
        <IconSprite
            className={cx(styles.openIcon, styles.plusIcon)}
            name={"plus"}
        />
        <IconSprite className={styles.openIcon} name={"minus"} />
    </div>
);

const AccordionItem: FC<AccordionItem> = ({ title, content }) => {
    const [open, setOpen] = useState(false);

    const toggleOpen = useCallback(() => {
        setOpen(current => !current);
    }, []);

    const openClass = open ? "open" : "closed";

    return (
        <li className={cx(styles[openClass], styles.accordionItem)}>
            <button className={styles.openButton} onClick={toggleOpen}>
                <div className={styles.titleWrapper}>
                    <AccordionItemIcons />
                    <label className={styles.title}>{title}</label>
                </div>
            </button>
            {open && (
                <div
                    className={styles.itemContent}
                    dangerouslySetInnerHTML={{ __html: content ?? "" }}
                />
            )}
        </li>
    );
};

export const Accordion: FC<Props> = ({ title, items, className, ...props }) =>
    items?.length ? (
        <div className={className}>
            {title ? <h2>{title}</h2> : null}
            <ul className={styles.component} {...props}>
                {items.map((item, index) =>
                    item ? (
                        <AccordionItem
                            key={item?.id ?? `accordionItem${index}`}
                            {...item}
                        />
                    ) : null
                )}
            </ul>
        </div>
    ) : null;

export default Accordion;
