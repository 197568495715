import { navigate } from "@reach/router";
import cx from "classnames";
import { Link } from "gatsby";
import React, {
	FC,
	HTMLAttributes,
	useCallback,
	useEffect,
	useState,
} from "react";
import { useAuth } from "../../context/auth/AuthProvider";
import { IconSprite } from "../IconSprite";
import * as styles from "./LoginButton.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
	location?: string;
	hidden?: boolean;
	onOpen?: () => void;
}

export const LoginButton: FC<Props> = ({
	className,
	location,
	hidden,
	onOpen,
	...props
}) => {
	const { isLoggedIn, loginOrMyAccount, logout } = useAuth();
	const [menuOpen, setMenuOpen] = useState(false);
	useEffect(() => {
		if (hidden) setMenuOpen(false);
	}, [hidden]);
	if (!isLoggedIn)
		return (
			<span className={cx(styles.login, className)}>
				<button
					type="button"
					onClick={loginOrMyAccount}
					className={styles.buttonLogin}
					title={isLoggedIn ? "My Profile" : "Log in"}
					{...props}
				>
					Log in / Register
				</button>
			</span>
		);
	const toggleMenu = () => {
		onOpen?.();
		setMenuOpen(!menuOpen);
	};
	return (
		<span className={cx(styles.component, className)}>
			<button
				type="button"
				onClick={toggleMenu}
				className={styles.button}
				title={isLoggedIn ? "My Profile" : "Log in"}
				{...props}
			>
				My Account
				<IconSprite
					name="chevron-right"
					className={
						menuOpen ? styles.subMenuChevronOpen : styles.subMenuChevronClosed
					}
				/>
			</button>
			{menuOpen && (
				<ul className={menuOpen ? styles.subMenuOpen : styles.subMenuClosed}>
					<li className={styles.subMenuItem}>
						<a
							href={`${process.env.GATSBY_MY_RUSI_BASE_URL}/my-dashboard.html`}
							className={styles.subMenuItemLink}
						>
							My dashboard
						</a>
					</li>

					<li className={styles.subMenuItem}>
						<a
							href={`${process.env.GATSBY_MY_RUSI_BASE_URL}/my-dashboard/edit-profile.html`}
							className={styles.subMenuItemLink}
						>
							Edit profile
						</a>
					</li>

					<li className={styles.subMenuItem}>
						<a
							href={`${process.env.GATSBY_MY_RUSI_BASE_URL}/my-dashboard/my-events.html`}
							className={styles.subMenuItemLink}
						>
							My events
						</a>
					</li>

					<li className={styles.subMenuItem}>
						<a
							href={`${process.env.GATSBY_MY_RUSI_BASE_URL}/my-dashboard/my-membership.html`}
							className={styles.subMenuItemLink}
						>
							My membership
						</a>
					</li>

					<li className={styles.subMenuItem}>
						<a
							href={`${process.env.GATSBY_MY_RUSI_BASE_URL}/my-dashboard/invoice-history.html`}
							className={styles.subMenuItemLink}
						>
							Order history
						</a>
					</li>

					<li className={styles.subMenuItem} onBlur={() => setMenuOpen(false)}>
						<button
							type="button"
							onClick={logout}
							
							className={styles.subMenuItemButton}
						>
							Logout
						</button>
					</li>
				</ul>
			)}
		</span>
	);
};

export default LoginButton;
