import cx from "classnames";
import BackgroundImage from "gatsby-background-image";
import { FluidObject } from "gatsby-image";
import React, { FC, HTMLAttributes } from "react";

import { Link } from "../Link";
import { IconSprite } from "../IconSprite";
import { TagsList } from "../TagsList";
import { TagData } from "../TagsList/TagsList";
import { TogglableContent } from "../TogglableContent";
import * as styles from "./SignpostCard.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    heading: string;
    url?: string;
    image?: FluidObject | null;
    variant?: "vertical" | "horizontal";
    backgroundColor?: "white" | "grey";
    linkText?: string;
    summary?: string;
    tags?: Array<TagData | null> | null;
    mobileToggleTagsString?: string;
}

// eslint-disable-next-line complexity
export const SignpostCard: FC<Props> = ({
    heading,
    url = "",
    image,
    className,
    linkText,
    variant = "vertical",
    backgroundColor = "grey",
    children,
    tags,
    summary,
    mobileToggleTagsString,
    ...props
}) => (
    <div
        className={cx(
            styles.component,
            className,
            styles[`${backgroundColor}Bg`],
            styles[image ? "withImage" : "noImage"],
            styles[variant]
        )}
        {...props}
    >
        <Link to={url} className={styles.mainLink}>
            {heading}
        </Link>
        {image && (
            <figure className={styles.imageWrapper}>
                <BackgroundImage
                    className={styles.image}
                    fluid={image}
                    alt={heading}
                />
            </figure>
        )}
        <div className={styles.contentArea}>
            <div className={styles.mainArea}>
                <div>
                    <h2>
                        <span className={styles.title}>{heading}</span>
                    </h2>

                    {summary && <p>{summary}</p>}
                    {children}
                </div>

                <div
                    className={cx(styles.tagsWrapper, {
                        [styles.hasTags]: !!tags?.length,
                        [styles.desktopOnly]: mobileToggleTagsString
                    })}
                >
                    {!!tags?.length && (
                        <TagsList tags={tags} variant="horizontal" />
                    )}
                </div>

                {!!tags?.length && mobileToggleTagsString && (
                    <TogglableContent
                        className={styles.mobileOnly}
                        contentName={mobileToggleTagsString}
                    >
                        <div className={styles.togglableTags}>
                            <hr />
                            <TagsList tags={tags} variant="horizontal" />
                        </div>
                    </TogglableContent>
                )}
            </div>

            <div className={styles.rightCol}>
                {linkText && (
                    <Link to={url} className={styles.cta}>
                        <span>{linkText}</span>
                        <IconSprite name="chevron-right" />
                    </Link>
                )}
            </div>
        </div>
    </div>
);

export default SignpostCard;
