import cx from "classnames";
import React, { HTMLAttributes } from "react";

import * as styles from "./Underline.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {}

export const Underline: React.FC<Props> = ({ className }) => (
    <hr className={cx(styles.component, className)} />
);
