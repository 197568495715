import { PageProps, graphql } from "gatsby";
/* eslint-disable complexity */
import React from "react";
import ArticleLayout, {
	Body,
	Main,
	Sidebar,
} from "../../components/ArticleLayout/ArticleLayout";
import { BodyText } from "../../components/BodyText";
import { ExternalLinkCard } from "../../components/ExternalLinkCard";
import { HeroImage } from "../../components/HeroImage";
import { KeywordTags } from "../../components/KeywordTags";
import Layout from "../../components/Layout";
import { MainImageCredit } from "../../components/MainImageCredit";
import Paragraphs, { ParagraphNode } from "../../components/Paragraphs";
import { PeopleList } from "../../components/PeopleList";
import { SocialShareBlock } from "../../components/SocialShareBlock";
import TitleBlock from "../../components/TitleBlock";
import Season from "../../containers/Season";
import { labelStyle, mobileOnly, section, shortHr } from "./Series.module.scss";

// Helpers
import { organiseKeywordTags } from "../../components/KeywordTags/helpers";

import SEO from "../../components/SEO";
import { stripHtml } from "../../lib/stringUtils";
// Types
import { BreadCrumbs } from "../../types/breadcrumbs";

interface Props extends PageProps {
	data: GatsbyTypes.SeriesQuery;
	pageContext: {
		id: string;
		breadcrumb: BreadCrumbs;
	};
}

export const Series: React.FC<Props> = ({
	pageContext,
	location,
	data: { article },
}) => {
	if (!article) return null;

	const title = article.title || "";
	const summary = article?.field_abstract?.processed;
	const authors = article?.relationships?.field_author ?? [];
	const authorsHeading = article?.field_profile_descriptors ?? ""; // "written by" - appears above author lists
	const membersOnly = article.field_premium;
	const bodyText = article?.body?.processed || article?.body?.value || "";
	const pageUrl = location?.href || "";
	const paragraphsData = article?.relationships?.field_sections;
	const seriesSections = article.relationships?.field_series_paragraph;
	const { field_spotify_link, field_itunes_link } = article;
	const keywordTags = organiseKeywordTags(article.relationships);
	// const relatedContent = article?.relationships?.field_related_content;
	const contentTypeName = article.relationships?.field_content_type?.name;
	const contentTypeLink =
		article.relationships?.field_content_type?.path?.alias;

	// Hero image
	const img =
		article?.relationships?.field_media_image?.relationships?.field_media_image
			?.childImageKitAsset?.fluid;
	const imgCredit =
		article?.relationships?.field_media_image?.relationships?.field_media_image
			?.relationships?.media__image?.[0]?.field_credit ?? "";
	const imgCreditLink =
		article?.relationships?.field_media_image?.relationships?.field_media_image
			?.relationships?.media__image?.[0]?.field_credit_link ?? "";

	// Metadata
	const metaDescription = article.field_abstract?.value;
	const metaImage = img?.src;

	return (
		<Layout>
			<SEO
				title={title}
				image={metaImage}
				description={metaDescription && stripHtml(metaDescription)}
			/>

			<ArticleLayout>
				<TitleBlock
					breadcrumb={pageContext?.breadcrumb?.crumbs}
					contentType={contentTypeName}
					contentTypeUrl={contentTypeLink}
					summary={summary}
					variant="series"
					title={title}
					url={pageUrl}
				/>
				<hr />

				<HeroImage fluid={img} />
				<hr />

				<Body>
					<Main>
						<BodyText html={bodyText} />
						<Paragraphs data={paragraphsData as ParagraphNode} />
						{!!(field_spotify_link || field_itunes_link) && (
							<aside className={mobileOnly}>
								<label className={labelStyle}>Subscribe to this show</label>
								{!!field_spotify_link && (
									<ExternalLinkCard
										iconName="spotify"
										label="Spotify"
										url={field_spotify_link}
									/>
								)}
								{!!field_itunes_link && (
									<ExternalLinkCard
										iconName="itunes"
										label="iTunes"
										url={field_itunes_link}
									/>
								)}
							</aside>
						)}
					</Main>
					<Sidebar>
						{imgCredit && (
							<aside>
								<MainImageCredit text={imgCredit} link={imgCreditLink} />
								<hr />
							</aside>
						)}
						{!!authors.length && (
							<aside>
								{authorsHeading && <label>{authorsHeading}</label>}
								<PeopleList
									variant="side"
									people={authors as GatsbyTypes.PersonDataFragment[]}
								/>
								<hr />
							</aside>
						)}
						{!!(field_spotify_link || field_itunes_link) && (
							<aside>
								<label>Subscribe to this show</label>
								{!!field_spotify_link && (
									<ExternalLinkCard
										iconName="spotify"
										label="Spotify"
										url={field_spotify_link}
									/>
								)}
								{!!field_itunes_link && (
									<ExternalLinkCard
										iconName="itunes"
										label="iTunes"
										url={field_itunes_link}
									/>
								)}
							</aside>
						)}
					</Sidebar>
				</Body>
				<hr />

				{!!seriesSections?.length && (
					<Season
						data={seriesSections as GatsbyTypes.SeriesParagraphFragment[]}
					/>
				)}

				<Body>
					<Main>
						{keywordTags?.length && (
							<>
								<hr className={shortHr} />
								<KeywordTags
									//@ts-ignore:
									tagCategories={keywordTags}
									className={section}
								/>
							</>
						)}

						{!!authors.length && (
							<>
								<PeopleList
									variant="main"
									heading={authorsHeading}
									className={section}
									people={authors as GatsbyTypes.PersonDataFragment[]}
								/>
								<hr />
							</>
						)}
					</Main>
				</Body>

				<SocialShareBlock variant="block" url={pageUrl} />
			</ArticleLayout>
		</Layout>
	);
};

export default Series;

export const pageQuery = graphql`
    query Series($path: String!) {
        article: nodeArticle(path: { alias: { eq: $path } }) {
            title
            field_profile_descriptors
            field_premium
            field_spotify_link
            field_itunes_link
            field_abstract {
                processed
                value
            }
            body {
                processed
                value
            }
            relationships {
                field_sections {
                    ...FieldSections
                }
                field_series_paragraph {
                    ...SeriesParagraph
                }
                field_content_type {
                    name
                    path {
                        alias
                    }
                }
                field_author {
                    ...PersonData
                }

                field_region {
                    id
                    name
                    path {
                        alias
                    }
                }
                field_research_groups {
                    id
                    name
                    path {
                        alias
                    }
                }
                field_topics {
                    id
                    name
                    path {
                        alias
                    }
                }
                field_related_project {
                    id
                    name
                    path {
                        alias
                    }
                }

                field_media_image {
                    relationships {
                        field_media_image {
                            childImageKitAsset {
                                fluid(maxWidth: 1168) {
                                    ...ImageKitFluid
                                }
                            }
                            relationships {
                                media__image {
                                    field_credit
                                    field_credit_link
                                }
                            }
                        }
                    }
                }

                field_journal_signposts {
                    __typename
                    ...JournalIssueSignpostFromLibrary
                    ...JournalIssueSignpostParagraphData
                }
            }
        }
    }
`;
