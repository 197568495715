import React from "react";
import { graphql } from "gatsby";
import { TabbedSection } from "../../components/TabbedSection/TabbedSection";
import { Paragraphs } from "../../components/Paragraphs";
import ArticleLayout, {
    Body,
    Main,
    Sidebar
} from "../../components/ArticleLayout/ArticleLayout";
import { PersonCard } from "../../components/PersonCard";
import { body, component, episodes, paragraphs } from "./Season.module.scss";

interface Props {
    data: GatsbyTypes.SeriesParagraphFragment[];
}

export const Season: React.FC<Props> = ({ data }) => (
    <ArticleLayout className={component}>
        <TabbedSection title="Select a season">
            {data.map(series => {
                const episodeCount = (
                    series.relationships?.field_content_paragr?.filter(
                        ({ __typename }) =>
                            __typename === "paragraph__signposted_content"
                    ) || []
                )
                    .flatMap(({ relationships }) => relationships)
                    .flatMap(({ field_content }) => field_content).length;

                return (
                    <Body className={body} key={series?.field_title}>
                        <Main>
                            {episodeCount > 0 && (
                                <label className={episodes}>
                                    {episodeCount} Episodes
                                </label>
                            )}
                            <Paragraphs
                                className={paragraphs}
                                backgroundColor="grey"
                                data={
                                    series?.relationships?.field_content_paragr
                                }
                            />
                        </Main>
                        <Sidebar>
                            {!!series?.relationships?.host &&
                                (() => {
                                    const {
                                        name = "",
                                        position = "",
                                        path,
                                        title,
                                        relationships
                                    } = series.relationships.host;

                                    return (
                                        <aside>
                                            {/* TODO: can we get this title dynamically? */}
                                            <label>Season host</label>
                                            <PersonCard
                                                variant="sidebar"
                                                darkBackground
                                                name={`${name} ${title}`}
                                                title={position}
                                                department={
                                                    relationships?.company
                                                        ?.title || ""
                                                }
                                                url={path?.alias}
                                                image={
                                                    relationships
                                                        ?.field_user_picture
                                                        ?.childImageKitAsset
                                                        ?.fluid
                                                }
                                            />
                                        </aside>
                                    );
                                })()}
                        </Sidebar>
                    </Body>
                );
            })}
        </TabbedSection>
    </ArticleLayout>
);

export const fragment = graphql`
    fragment SeriesParagraph on paragraph__season {
        field_title
        relationships {
            field_content_paragr {
                __typename
                ...TextParagraphData
                ...SignpostParagraphData
                ...SponsorsParagraphData
            }
            host: field_season_host {
                name: field_first_names
                position: field_position
                title
                relationships {
                    field_user_picture {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 168
                                transformations: ["fo-face", "ar-88-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                    company: field_location {
                        title
                    }
                }
                path {
                    alias
                }
            }
        }
    }
`;
