import cx from "classnames";
import { graphql } from "gatsby";
import React, { FC, HTMLAttributes } from "react";

import { getBytesStr } from "../../lib/numberUtils";
import { IconSprite } from "../IconSprite";
import * as styles from "./DownloadFile.module.scss";
import { PdfData } from "./__generated__/PdfData";

const mimeStringMap: { [key: string]: MimeStringType } = {
	"application/pdf": "PDF",
	"text/plain": "TXT",
	default: "",
};

type Variant = "main" | "sidebar" | "locked" | "unlocked";

type MimeStringType = "PDF" | "TXT" | "";
interface Props extends HTMLAttributes<HTMLElement> {
	pdf?: Array<PdfData | null> | null;
	variant?: Variant;
}

interface AnchorOrSpanProps extends HTMLAttributes<HTMLElement> {
	href: string;
	variant?: Variant;
}

interface LockedStateProps {
	variant: Variant;
}

const AnchorOrSpan: FC<AnchorOrSpanProps> = ({
	className,
	href,
	children,
	variant = "main",
}) =>
	variant === "locked" ? (
		<span className={cx(styles.component, styles[variant], className)}>
			{children}
		</span>
	) : (
		<a
			download
			className={cx(styles.component, styles[variant], className)}
			href={href}
			target="_blank"
			rel="noopener noreferrer"
		>
			{children}
		</a>
	);

const LockedState: FC<LockedStateProps> = ({ variant }) =>
	["locked", "unlocked"].includes(variant) ? (
		<span className={styles.lockedState}>
			<IconSprite
				className={styles.lockedStateIcon}
				name={variant === "locked" ? "lock" : "unlocked"}
			/>
			<span>{variant}</span>
		</span>
	) : null;

export const DownloadFile: FC<Props> = ({ variant = "main", pdf, ...rest }) => {
	const file = pdf?.[0];
	const href = file?.fields?.cdn_url;
	if (!file || !href) {
		return null;
	}

	const mimeStr = mimeStringMap[file.filemime ?? "default"] || "";

	return (
		<AnchorOrSpan
			href={href}
			variant={variant}
			className={styles[variant]}
			{...rest}
		>
			<IconSprite className={styles.icon} name="pdf" />
			<div className={styles.textWrapper}>
				<span className={styles.downloadText}>Read Full Report</span>
				<span className={styles.metadataText}>{`(${mimeStr} ${file?.filesize && getBytesStr(file?.filesize)})`}</span>
			</div>

			<LockedState variant={variant} />
		</AnchorOrSpan>
	);
};

export const fragment = graphql`
    fragment PdfData on file__imagekit {
        id
        filename
        filesize
        filemime
        fields {
            cdn_url
        }
    }
`;

export default DownloadFile;
