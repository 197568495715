import React from "react";
import { graphql } from "gatsby";
import { NewsletterSignup as Component } from "../../components/NewsletterSignup";
import { FluidObject } from "gatsby-image";

interface Props {
    node: GatsbyTypes.NewsletterSignupFragment;
}

export const NewsletterSignup: React.FC<Props> = ({ node }) => {
    const { field_title, field_text, field_link, relationships } = node;

    return (
        <Component
            title={field_title || ""}
            text={field_text?.value}
            buttonText="Sign up"
            url={field_link?.uri || ""}
            variant="paragraph"
            image={
                relationships?.field_media?.relationships?.field_media_image
                    ?.childImageKitAsset?.fluid as FluidObject
            }
        />
    );
};

export const fragment = graphql`
    fragment NewsletterSignup on paragraph__newsletter_sign_up {
        __typename
        id
        field_title
        field_text {
            processed
            value
        }
        field_link {
            uri
            title
        }
        relationships {
            field_media {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
