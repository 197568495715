import { getLastDayOfMonth } from "../../utilities/dateUtils";
import { AdditionalDateFilter, TimestampRange, Range } from "./types";

export const ammendDayOfMonth = (inputDate: Date, useEndOfMonth?: boolean) => {
    const dayOfMonth = useEndOfMonth ? getLastDayOfMonth(inputDate) : 1;
    return new Date(inputDate).setDate(dayOfMonth);
};

export const convertDateToTimeStamp = (date: Date) => date.getTime() / 1000;

export const convertDateStringToTimeStamp = (dateString: string) =>
    convertDateToTimeStamp(new Date(dateString));

export const convertDateRangeToTimeStamp = (range: Range): TimestampRange => ({
    min: range.min ? convertDateStringToTimeStamp(range.min) : undefined,
    max: range.max ? convertDateStringToTimeStamp(range.max) : undefined
});

export const dateMinusNYears = (n: number) => {
    const dateNow = new Date(Date.now());
    const newYear = dateNow.getFullYear() - n;

    return new Date(newYear, dateNow.getMonth(), 1);
};
