import React, { createContext, useState, useCallback, useMemo } from "react";

interface AuthContextValue {
    addToInPageNav: (newItem: NavItem) => void;
    removeFromInPageNav: (newItem: NavItem) => void;
    navItems: NavItem[];
}

export interface NavItem {
    title: string;
    id: string;
}

export const InPageNavContext = createContext<Partial<AuthContextValue>>({});

export const InPageNavProvider: React.FC = props => {
    const [navItems, setNavItems] = useState<NavItem[]>([]);

    const addToInPageNav = useCallback((newItem: NavItem) => {
        setNavItems(prev =>
            prev.find(i => i.id === newItem.id || i.title === newItem.title)
                ? prev
                : [...prev, newItem]
        );
    }, []);

    const removeFromInPageNav = useCallback(({ id }: NavItem) => {
        setNavItems(prev => [...prev].filter(i => i.id !== id));
    }, []);

    const value = useMemo(
        () => ({ addToInPageNav, removeFromInPageNav, navItems }),
        [addToInPageNav, removeFromInPageNav, navItems]
    );

    return (
        <InPageNavContext.Provider value={value} {...props}>
            {props.children}
        </InPageNavContext.Provider>
    );
};
