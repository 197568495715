import React, { FC, FormEvent, useCallback } from "react";
import { MenuProvided } from "react-instantsearch-core";
import { SearchFilterContainer } from "../SearchFilterContainer";

import * as styles from "./FilterMenu.module.scss";

export type AlgoliaFacetName =
    | "periodical_name"
    | "project_name"
    | "location_title"
    | "region_name"
    | "topics_name"
    | "networks_name"
    | "content_type"
    | "research_expert"
    | "type";

interface Props extends Partial<MenuProvided> {
    transformItemLabel?: (key: string) => string;
    heading: string;
    className?: string;
    seeAllLabel?: string;
}

const noop = (str: string) => str;

export const FilterMenu: FC<Props> = ({
    className,
    currentRefinement = [],
    refine,
    items,
    heading,
    seeAllLabel = "All",
    transformItemLabel = noop
}) => {
    const handleSubmit = useCallback((e: FormEvent) => {
        e.preventDefault();
    }, []);

    return (
        <SearchFilterContainer
            heading={heading}
            currentLabel={
                !!currentRefinement?.length
                    ? (currentRefinement as string[]).join(", ")
                    : seeAllLabel
            }
            active={!!currentRefinement.length}
            className={className}
        >
            <form onSubmit={handleSubmit}>
                <div className={styles.filterValues}>
                    {items?.map(item => (
                        <label key={item.label}>
                            <input
                                checked={currentRefinement.includes(
                                    item.label as never
                                )}
                                type="checkbox"
                                value={item.value}
                                onChange={() => refine?.(item.value)}
                            />
                            <span>{item.label}</span>
                        </label>
                    ))}
                </div>
            </form>
        </SearchFilterContainer>
    );
};

export default FilterMenu;
