import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import * as styles from "./DangerousHTML.module.scss";

interface Props {
    html: string;
    className?: string;
}

const Component: React.FC<Props> = ({ html, ...rest }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!html || !ref.current) return;

        const htmlString = document
            .createRange()
            .createContextualFragment(html);
        ref.current.innerHTML = "";
        ref.current.appendChild(htmlString);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Helmet>
                {/* HACK: Get pym resized embedded tools loading correctly */}
                <script src="https://pym.nprapps.org/pym-loader.v1.min.js" />
            </Helmet>
            <div ref={ref} className={styles.component} {...rest} />
        </>
    );
};

export const DangerousHTML = React.memo(Component);
