import { graphql } from "gatsby";
import React, { FC, HTMLAttributes, useMemo, useState } from "react";
import { Configure, RefinementListExposed } from "react-instantsearch-core";
import { CustomInfiniteHits } from "../../components/CustomInfiniteHits";
import { DateRangeFilter } from "../../components/DateRangeFilter";
import { convertDateToTimeStamp } from "../../components/DateRangeFilter/helpers";
import {
    AdditionalDateFilter,
    TimestampRange
} from "../../components/DateRangeFilter/types";
import { JournalIssueFilter } from "../../components/JournalIssueFilter";
import { SearchComponent } from "../../components/SearchComponent";
import {
    CustomRefinementList,
    CustomSearchForm,
    CustomStats
} from "../../components/SearchComponent/customAlgoliaWidgets";
import { AlgoliaIndexName } from "../../components/SearchComponent/SearchComponent";
import { FiltersLayout } from "../../components/SearchFilterContainer";
import { useInPageNavId } from "../../context/InPageNav/useInPageNavId";
import { sortAlphabeticallyByPropName } from "../../utilities/arrayUtils";
import {
    createFilterString,
    getAlgoliaContentTypeName,
    getDateRefinementString
} from "./helpers";

interface Props extends HTMLAttributes<HTMLElement> {
    node: GatsbyTypes.InSectionSearchFragment;
}

const getAdditionalFilters = (
    isEventSearch: boolean
): AdditionalDateFilter[] => {
    const now = new Date(Date.now());
    const nowTimeStamp = convertDateToTimeStamp(now);

    if (isEventSearch) {
        return [
            {
                label: "Upcoming events",
                max: null,
                min: nowTimeStamp
            },
            {
                label: "Past events",
                min: null,
                max: nowTimeStamp
            }
        ];
    }

    return [
        {
            label: "All dates",
            min: null,
            max: null
        },
        {
            label: "Last 6 months",
            min: convertDateToTimeStamp(
                new Date(new Date(now).setMonth(now.getMonth() - 6))
            ),
            max: nowTimeStamp
        },
        {
            label: "Last 12 months",
            min: convertDateToTimeStamp(
                new Date(new Date(now).setMonth(now.getMonth() - 12))
            ),
            max: nowTimeStamp
        }
    ];
};

// eslint-disable-next-line complexity
export const InSectionSearch: FC<Props> = ({ node, ...props }) => {
    const id = useInPageNavId(node);
    const {
        field_title,
        field_show_date_filter,
        field_is_event_search,
        field_additional_config,
        field_algolia_index = "global_by_date",
        field_journal_search,
        relationships
    } = node;

    const { field_content_type_tag, field_filters } = relationships ?? {};

    const [dateRefinement, setDateRefinement] = useState<
        TimestampRange | undefined
    >();

    const dateRefinementString = useMemo(
        () => getDateRefinementString(dateRefinement, field_is_event_search),
        [dateRefinement, field_is_event_search]
    );

    const { indexName, contentTypeName } = useMemo(() => {
        const now = new Date(Date.now());
        now.setHours(0, 0, 0, 0);
        const nowTimeStamp = convertDateToTimeStamp(now);

        const switchIndex =
            ["algolia_event", "algolia_event_desc"].includes(
                field_algolia_index
            ) && dateRefinement?.min
                ? dateRefinement?.min >= nowTimeStamp
                : false;

        return {
            indexName: switchIndex ? "algolia_event" : field_algolia_index,
            contentTypeName: getAlgoliaContentTypeName(
                field_content_type_tag?.__typename ?? ""
            )
        };
    }, [
        field_algolia_index,
        field_content_type_tag?.__typename,
        dateRefinement
    ]);

    const filterString = useMemo(
        () =>
            createFilterString(
                contentTypeName,
                field_content_type_tag?.name ?? "",
                dateRefinementString,
                ...(field_additional_config ?? [])
            ),
        [
            field_content_type_tag?.name,
            contentTypeName,
            dateRefinementString,
            field_additional_config
        ]
    );

    const additionalDateFilters = useMemo(
        () => getAdditionalFilters(Boolean(field_is_event_search)),
        [field_is_event_search]
    );

    if (!node) {
        return null;
    }

    return (
        <section id={id} {...props}>
            <SearchComponent
                indexName={(indexName ?? "global_by_date") as AlgoliaIndexName}
            >
                <Configure
                    filters={filterString}
                    hitsPerPage={10}
                    sortFacetValuesBy="alpha"
                />

                <label>{field_title}</label>
                <CustomSearchForm />
                <FiltersLayout>
                    {!!field_filters?.length &&
                        field_filters.map((filterProps, index) => (
                            <CustomRefinementList
                                key={`filter-${index}`}
                                limit={100}
                                transformItems={items =>
                                    sortAlphabeticallyByPropName(items, "value")
                                }
                                {...(filterProps as Readonly<
                                    RefinementListExposed
                                >)}
                            />
                        ))}

                    {field_show_date_filter && (
                        <DateRangeFilter
                            refine={setDateRefinement}
                            currentRefinement={dateRefinement}
                            additionalFilters={additionalDateFilters}
                            futureYearsInCustom={field_is_event_search ? 3 : 0}
                        />
                    )}

                    {field_journal_search && (
                        <JournalIssueFilter heading="filter volume/issue" />
                    )}
                </FiltersLayout>
                <CustomStats />
                <hr />
                <CustomInfiniteHits />
            </SearchComponent>
        </section>
    );
};

export default InSectionSearch;

export const fragment = graphql`
    fragment InSectionSearch on paragraph__in_section_search_listing {
        id
        field_title
        field_show_date_filter
        field_is_on_this_page_anchor
        field_additional_config
        field_algolia_index
        field_is_event_search
        field_journal_search
        relationships {
            field_content_type_tag {
                __typename
                ... on taxonomy_term__content_type {
                    name
                }
                ... on taxonomy_term__event_type {
                    name
                }
                ... on taxonomy_term__profile_type {
                    name
                }
            }
            field_filters {
                heading: field_title
                seeAllLabel: field_all_label
                attribute: field_algolia_taxonomy_field
            }
        }
    }
`;
