import cx from "classnames";
import { graphql, PageProps } from "gatsby";
import React, { useMemo } from "react";
import ArticleLayout, {
    Body,
    Main,
    Sidebar
} from "../../components/ArticleLayout/ArticleLayout";
import { BodyText } from "../../components/BodyText";
import ContentTemplate from "../../components/ContentTemplate";
import { HeroImage } from "../../components/HeroImage";
import { InPageNavigationContainer } from "../../components/InPageNavigation";
import { Layout } from "../../components/Layout";
import { MainImageCredit } from "../../components/MainImageCredit";
import { SEO } from "../../components/SEO";
import TitleBlock from "../../components/TitleBlock";
import { ContentTypeName, getFacetName } from "../../lib/dynamicContentHelpers";
import { stripHtml } from "../../lib/stringUtils";
import { BreadCrumbs } from "../../types/breadcrumbs";
import { UpcomingEventContextProvider } from "../ResearchGroup/UpcomingEvent";
import * as styles from "./Project.module.scss";

interface Props extends PageProps {
    data: GatsbyTypes.ProjectQuery;
    pageContext: {
        id: string;
        breadcrumb: BreadCrumbs;
    };
}

// eslint-disable-next-line complexity
export const ProjectTemplate: React.FC<Props> = ({
    data,
    location,
    pageContext
}) => {
    const { project } = data;
    const contentType = project?.relationships?.field_content_type?.name;

    const facetName = useMemo(
        () =>
            getFacetName((contentType as ContentTypeName) || "Projects") || "",
        [contentType]
    );

    if (!project) return <Layout />;

    const text = project.body?.processed || project.body?.value || "";
    const breadcrumb = pageContext.breadcrumb.crumbs;

    const title = project.title ?? "";

    const { field_sections, field_overview } = project.relationships || {};

    const summary = stripHtml(project.field_abstract?.processed ?? "");
    const contentTypeUrl =
        project.relationships?.field_content_type?.path?.alias;

    const mainImage =
        project?.relationships?.field_media_image?.relationships
            ?.field_media_image?.childImageKitAsset?.fluid;
    const mainImageCredit =
        project.relationships?.field_media_image?.field_credit;
    const mainImageCreditLink =
        project.relationships?.field_media_image?.field_credit_link;

    const titleBlockProps = {
        title,
        summary,
        contentType,
        contentTypeUrl,
        breadcrumb,
        closedProject: data.project?.closed,
        url: location.href
    };

    // Metadata
    const metaDescription = project.field_abstract?.value;
    const metaImage = mainImage?.src;

    return (
        <UpcomingEventContextProvider
            value={{
                facet: facetName,
                value: title
            }}
        >
            <Layout>
                <SEO
                    title={project.title}
                    description={metaDescription && stripHtml(metaDescription)}
                    image={metaImage}
                />

                <ArticleLayout>
                    <TitleBlock variant="topic" {...titleBlockProps} />
                    <div className={styles.inPageNavWrapper}>
                        <hr />
                        <InPageNavigationContainer />
                        <hr className={styles.mobileOnly} />
                    </div>

                    <HeroImage fluid={mainImage} className={styles.heroImage} />
                    <hr />
                    {mainImageCredit && (
                        <>
                            <MainImageCredit
                                className={styles.mobileOnly}
                                text={mainImageCredit}
                                link={mainImageCreditLink}
                            />
                            <hr
                                className={cx(
                                    styles.mobileOnly,
                                    styles.noMarginBottom
                                )}
                            />
                        </>
                    )}

                    <Body>
                        <Main>
                            <BodyText html={text} />
                            <ContentTemplate
                                firstSection
                                sections={field_overview as any[]}
                                contentType={contentType}
                                contentTitle={title}
                            />
                        </Main>
                        <Sidebar>
                            {mainImageCredit && (
                                <MainImageCredit
                                    text={mainImageCredit ?? ""}
                                    link={mainImageCreditLink}
                                    className={styles.desktopOnly}
                                />
                            )}
                        </Sidebar>
                    </Body>
                    <ContentTemplate
                        sections={field_sections as any[]}
                        contentType={contentType}
                        contentTitle={title}
                    />
                </ArticleLayout>
            </Layout>
        </UpcomingEventContextProvider>
    );
};

export default ProjectTemplate;

export const pageQuery = graphql`
    query Project($path: String!, $title: String, $contentType: String) {
        project: nodeProject(path: { alias: { eq: $path } }) {
            id
            path {
                alias
            }
            title
            closed: field_closed
            field_abstract {
                processed
                value
            }

            body {
                processed
                value
            }
            relationships {
                field_overview {
                    __typename
                    ...SponsorsParagraphData
                }
                field_sections {
                    id
                    field_paragraph_container_style
                    field_background
                    fields: relationships {
                        sections: field_content_paragr {
                            ...FieldSectionsTopicRegion
                        }
                    }
                }
                field_content_type {
                    name
                    path {
                        alias
                    }
                }
                field_media_image {
                    field_credit
                    field_credit_link
                    relationships {
                        field_media_image {
                            childImageKitAsset {
                                fluid(maxWidth: 1200) {
                                    ...ImageKitFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
