// extracted by mini-css-extract-plugin
export var aboutCfcs = "EcpMethodologyPageComponent-module--aboutCfcs--26a00";
export var aboutCfcsSection = "EcpMethodologyPageComponent-module--aboutCfcsSection--586e4";
export var closed = "EcpMethodologyPageComponent-module--closed--02ec9";
export var component = "EcpMethodologyPageComponent-module--component--9cd47";
export var descriptionFromCms = "EcpMethodologyPageComponent-module--descriptionFromCms--73342";
export var fadePop = "EcpMethodologyPageComponent-module--fade-pop--86ec7";
export var fullMethodologyText = "EcpMethodologyPageComponent-module--fullMethodologyText--dce1d";
export var greyBg = "EcpMethodologyPageComponent-module--greyBg--b42eb";
export var growHeightIn = "EcpMethodologyPageComponent-module--grow-height-in--f8787";
export var methodologySection = "EcpMethodologyPageComponent-module--methodologySection--648be";
export var methodologyWrapper = "EcpMethodologyPageComponent-module--methodologyWrapper--df66b";
export var newsletter = "EcpMethodologyPageComponent-module--newsletter--c29a1";
export var open = "EcpMethodologyPageComponent-module--open--95fd0";
export var openMethodologyButton = "EcpMethodologyPageComponent-module--openMethodologyButton--b1e48";
export var people = "EcpMethodologyPageComponent-module--people--d2381";
export var sectionSeparatorLine = "EcpMethodologyPageComponent-module--sectionSeparatorLine--1e9ae";
export var social = "EcpMethodologyPageComponent-module--social--8be48";
export var title = "EcpMethodologyPageComponent-module--title--4aefb";