import cx from "classnames";
import React, { HTMLAttributes } from "react";

import { Button } from "../Button";
import * as styles from "./RegisterCta.module.scss";

export interface Props extends HTMLAttributes<HTMLElement> {
    heading: string;
    cost?: string;
    priceDetail?: string;
    description?: string;
    disabled?: boolean;
    buttonText: string;
    url: string;
    variant?: "button" | "link";
    backgroundColor?: "grey" | "white";
}

export const RegisterCta: React.FC<Props> = ({
    heading,
    priceDetail,
    description,
    disabled,
    buttonText,
    url,
    children,
    variant = "button",
    backgroundColor = "grey",
    className
}) => {
    return (
        <div
            className={cx(
                className,
                styles.component,
                styles[`${backgroundColor}Bg`],
                "hideOnPrint"
            )}
        >
            <h4>{heading}</h4>
            {priceDetail && <p>{priceDetail}</p>}
            {children}
            <a href={url} className={styles[variant]}>
                {variant === "button" ? (
                    <Button disabled={disabled}>{buttonText}</Button>
                ) : (
                    <span>{buttonText}</span>
                )}
            </a>
            {variant === "link" && <hr />}
            {description && <small>{description}</small>}
        </div>
    );
};
