/* eslint-disable complexity */
import cx from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "../Link";

import { navigate } from "@reach/router";
import { useAuth } from "../../context/auth/AuthProvider";
import { IconSprite } from "../IconSprite";
import { Node } from "../Layout/types";
import { LoginButton } from "../LoginButton";
import MainMenu from "../MainMenu";
import MegaMenu from "../MegaMenu";
import { SearchBox } from "../SearchBox";
import SearchButton from "../SearchButton";
import { UnsupportedBrowserWarning } from "../UnsupportedBrowserWarning";
import * as styles from "./Header.module.scss";

export const MENU_FOCUS_TIMEOUT = 250; // ms
const PADDING = 8; // height of purple line at top of page

export interface HeaderProps {
	siteTitle?: string | null;
	sticky?: boolean;
	menuItems: Node[];
	researchMenuItems: Node[];
	setHeaderHeight: (number: number) => void;
	showBrowserWarning?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
	siteTitle = "",
	sticky = false,
	showBrowserWarning = false,
	menuItems,
	setHeaderHeight,
	researchMenuItems,
}) => {
	const [searchOpen, setSearchOpen] = useState(false);
	const [activeMenu, setActiveMenu] = useState<string>();
	const [menuOpen, setMenuOpen] = useState(false);
	const focusTimeout = useRef<NodeJS.Timeout>();

	const handleMenuUnfocus = useCallback(() => {
		focusTimeout.current = setTimeout(() => {
			setActiveMenu(undefined);
		}, 1000);
	}, []);

	const handleMenuRefocus = () => {
		if (focusTimeout.current) {
			clearTimeout(focusTimeout.current);
			focusTimeout.current = undefined;
		}
	};

	const onMouseEnter = (id: string) => {
		setActiveMenu(id);
		handleMenuRefocus();
	};

	// Clear timeout on unmount
	useEffect(
		() => () => {
			handleMenuRefocus();
		},
		[],
	);

	const handleSearch = useCallback(() => {
		setSearchOpen(true);
		setActiveMenu(undefined);
	}, []);

	const researchMenuTopLevel = researchMenuItems.filter(
		({ drupal_parent_menu_item }) => drupal_parent_menu_item === null,
	);

	useEffect(() => {
		if (menuOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "unset";
		}
		return () => {
			document.body.style.overflow = "unset";
		};
	}, [activeMenu, menuOpen]);
	return (
		<>
			<SearchBox open={searchOpen} handleClose={() => setSearchOpen(false)} />
			<header
				className={cx(styles.component, "hideOnPrint", {
					[styles.sticky]: sticky,
					[styles.open]: !!activeMenu,
					[styles.stretch]: menuOpen,
				})}
			>
				<div
					ref={(elem) => {
						elem && setHeaderHeight(elem.clientHeight + PADDING);
					}}
				>
					{showBrowserWarning && <UnsupportedBrowserWarning />}

					<div className={styles.contents}>
						<Link to="/" className={styles.logo} aria-label="home">
							<StaticImage
								alt={siteTitle ?? ""}
								objectFit="contain"
								className={cx(
									{
										[styles.sticky]: sticky,
									},
									styles.logoImage,
								)}
								src={"../../images/rusi-primary-logo-single-purple.png"}
							/>
						</Link>
						<nav
							aria-label="main menu dropdown"
							className={cx(styles.navigation, {
								[styles.sticky]: sticky,
								[styles.open]: !!activeMenu,
							})}
						>
							<div>
								{menuOpen ? (
									<button
										type="button"
										onClick={() => setMenuOpen(!menuOpen)}
										className={styles.menuButton}
									>
										<IconSprite
											name="close"
											style={{
												transform: "scale(1.5)",
											}}
										/>
									</button>
								) : (
									<button
										type="button"
										onClick={() => setMenuOpen(!menuOpen)}
										className={cx(styles.menuButton, styles.open)}
									>
										<IconSprite
											name="menu"
											style={{
												transform: "scale(1.5)",
											}}
										/>
									</button>
								)}

								{researchMenuTopLevel?.map((node) => {
									const active = node.id === activeMenu;
									const disabled = activeMenu && activeMenu !== node.id;

									return (
										<Link
											key={node.id}
											to={node.link?.uri || ""}
											onMouseEnter={() => onMouseEnter(node.id || "")}
											onMouseLeave={handleMenuUnfocus}
											activeClassName={styles.active}
											partiallyActive
											className={cx(styles.menuItem, {
												[styles.active]: active,
												[styles.disabled]: disabled,
											})}
										>
											{node.title}
											<IconSprite name={active ? "minus" : "plus"} />
										</Link>
									);
								})}

								<MainMenu
									activeMenu={activeMenu}
									menuItems={menuItems}
									onMouseLeaveLink={handleMenuUnfocus}
									onMouseEnterLink={handleMenuRefocus}
									setActiveMenu={setActiveMenu}
								/>
							</div>
							<div className={styles.searchLoginContainer}>
								<SearchButton hidden={menuOpen} onClick={handleSearch} />
								<LoginButton
									hidden={!!activeMenu}
									onOpen={() => setActiveMenu(undefined)}
								/>
							</div>
						</nav>
					</div>
				</div>

				<MegaMenu
					open={!!activeMenu}
					onMouseEnter={handleMenuRefocus}
					onMouseLeave={handleMenuUnfocus}
					activeMenu={activeMenu}
					menuItems={menuItems}
					researchMenu={researchMenuItems}
				/>

				<div className={cx(styles.dropdown, { [styles.open]: menuOpen })}>
					<nav>
						<ul>
							{researchMenuTopLevel?.map((node) => (
								<li key={node.id}>
									<Link to={node.link?.uri || ""} partiallyActive>
										{node.title}
									</Link>
								</li>
							))}
							{menuItems.map((node) => (
								<li key={node.id}>
									<Link to={node.link?.uri || ""}>{node.title}</Link>
								</li>
							))}
							<li className={styles.isBtn}>
								<LoginButton
									hidden={menuOpen}
									onOpen={() => setActiveMenu(undefined)}
								/>
							</li>
							<li className={styles.isBtn}>
								<SearchButton onClick={handleSearch} />
							</li>
						</ul>
					</nav>
				</div>
			</header>
		</>
	);
};

export default Header;
