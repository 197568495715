import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";

import { Button } from "../Button";
import { ContentGate } from "../ContentGate";
import * as styles from "./ExternalArticleButton.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    heading?: string;
    url: string;
    unlockedText?: string;
    buttonText?: string;
    linkInfo?: string;
    subtext?: string;
}

export const ExternalArticleButton: FC<Props> = ({
    heading,
    url,
    buttonText = "Continue Reading",
    unlockedText = "this content is available to you",
    linkInfo,
    subtext,
    className,
    ...props
}) => (
    <ContentGate variant="unlocked" topbarText={unlockedText} {...props}>
        <div className={cx(styles.component, className)}>
            {heading && <h2>{heading}</h2>}
            <a href={url} referrerPolicy="no-referrer-when-downgrade">
                <Button>{buttonText}</Button>
            </a>
            {linkInfo && <p>{linkInfo}</p>}
            {linkInfo && subtext && <hr />}
            {subtext && <p className={styles.subtext}>{subtext}</p>}
        </div>
    </ContentGate>
);

export default ExternalArticleButton;
