import { graphql, PageProps } from "gatsby";
import React from "react";
import cx from "classnames";
import * as styles from "./BasicPage.module.scss";

import { BreadCrumbs } from "../../types/breadcrumbs";
import { BodyText } from "../../components/BodyText";
import { HeroImage } from "../../components/HeroImage";
import { Layout } from "../../components/Layout";
import { MainImageCredit } from "../../components/MainImageCredit";
import { SEO } from "../../components/SEO";
import TitleBlock from "../../components/TitleBlock";
import ArticleLayout, {
    Body,
    Main,
    Sidebar
} from "../../components/ArticleLayout/ArticleLayout";
import ContentTemplate from "../../components/ContentTemplate";
import { InPageNavigationContainer } from "../../components/InPageNavigation";
import { RelatedContentRow } from "../../components/RelatedContentRow";
import Paragraphs from "../../components/Paragraphs";
import { DownloadFile } from "../../components/DownloadFile";
import { stripHtml } from "../../lib/stringUtils";
import { FootnoteList } from "../../components/FootnoteList";

interface Props extends PageProps {
    data: GatsbyTypes.BasicPageQuery;
    pageContext: {
        id: string;
        breadcrumb?: BreadCrumbs;
    };
}

// eslint-disable-next-line complexity
export const BasicPageTemplate: React.FC<Props> = ({
    data,
    pageContext,
    location
}) => {
    const { pageData } = data;

    if (!pageData) {
        return null;
    }

    const url = location.href;
    const breadcrumb = pageContext.breadcrumb?.crumbs;

    const seoTitle = pageData.title || "RUSI";

    const title = pageData.title || "";
    const text = pageData.body?.processed || pageData.body?.value || "";
    const lead = pageData.field_abstract?.value;
    const img =
        pageData.relationships?.field_media_image?.relationships
            ?.field_media_image?.childImageKitAsset?.fluid;
    const imgCredit =
        pageData.relationships?.field_media_image?.relationships
            ?.field_media_image?.relationships?.media__image?.[0]
            ?.field_credit ?? "";
    const imgCreditLink =
        pageData.relationships?.field_media_image?.relationships
            ?.field_media_image?.relationships?.media__image?.[0]
            ?.field_credit_link ?? "";

    const { relationships } = pageData;

    const pdf = relationships?.field_pdf;

    const relatedContent = relationships?.field_related_content;

    const { field_sections, field_main_content, field_secondary_content, field_footnotes: footnotes = [] } = relationships ?? {};

    const renderBody = !!(
        text ||
        !!field_main_content?.length ||
        !!field_secondary_content?.length ||
        !!footnotes?.length ||
        imgCredit
    );

    // Metadata
    const metaImageBanner = img?.src;
    const metaImageSignpost =
        pageData.relationships?.field_signpost_image?.relationships
            ?.field_media_image?.childImageKitAsset?.fixed?.src;
    return (
        <Layout>
            <SEO
                title={seoTitle}
                image={metaImageSignpost || metaImageBanner}
                description={lead && stripHtml(lead)}
            />
            <ArticleLayout>
                <TitleBlock
                    variant="basic-page"
                    title={title}
                    breadcrumb={breadcrumb}
                    summary={lead}
                    url={url}
                />

                <InPageNavigationContainer />

                {pdf && (
                    <DownloadFile
                        className={styles.topDownloadLink}
                        pdf={pdf}
                    />
                )}

                {img && <HeroImage fluid={img} />}
                <hr className={styles.noMarginBottom} />

                {img && imgCredit && (
                    <>
                        <MainImageCredit
                            className={styles.mobileOnly}
                            text={imgCredit}
                            link={imgCreditLink}
                        />
                        <hr className={styles.mobileOnly} />
                    </>
                )}
                {renderBody && (
                    <Body>
                        <Main>
                            <BodyText html={text} />
                            <ContentTemplate
                                hideBorderTop={!text}
                                firstSection
                                sections={[
                                    {
                                        fields: {
                                            sections: field_main_content ?? []
                                        }
                                    }
                                ]}
                                paragraphTextFootnotes={
                                    footnotes as GatsbyTypes.FieldFootnoteFragment[]
                                }
                            />
                        </Main>
                        <Sidebar>
                            <MainImageCredit
                                className={styles.mobileOnly}
                                text={imgCredit}
                                link={imgCreditLink}
                            />
                            <Paragraphs
                                className={styles.sidebarParagraphs}
                                paragraphTextFootnotes={
                                    footnotes as GatsbyTypes.FieldFootnoteFragment[]
                                }
                                data={field_secondary_content}
                            />
                        </Sidebar>
                    </Body>
                )}

                <ContentTemplate
                    sections={field_sections ?? []}
                    hideBorderTop={!renderBody}
                    lastSection={!relatedContent?.length}
                    paragraphTextFootnotes={
                        footnotes as GatsbyTypes.FieldFootnoteFragment[]
                    }
                />
                <FootnoteList items={footnotes} />
            </ArticleLayout>
            <div className={styles.relatedContentWrapper}>
                <RelatedContentRow
                    className={styles.section}
                    items={relatedContent}
                />
            </div>
        </Layout>
    );
};

export default BasicPageTemplate;

export const pageQuery = graphql`
    query BasicPage($path: String!) {
        pageData: nodeBasicPage(path: { alias: { eq: $path } }) {
            __typename
            id
            title
            field_profile_descriptors
            field_abstract {
                value
            }
            body {
                summary
                processed
                value
            }
            relationships {
                field_sections {
                    id
                    field_paragraph_container_style
                    field_background
                    fields: relationships {
                        sections: field_content_paragr {
                            ...FieldSections
                        }
                    }
                }
                field_footnotes {
                    ...FieldFootnote
                }
                field_main_content {
                    ...FieldMainContent
                }
                field_author {
                    ...PersonData
                }
                field_pdf {
                    ...PdfData
                }
                field_media_image {
                    relationships {
                        field_media_image {
                            relationships {
                                media__image {
                                    field_credit
                                    field_credit_link
                                }
                            }
                            childImageKitAsset {
                                fluid(maxWidth: 1168) {
                                    ...ImageKitFluid
                                }
                            }
                        }
                    }
                }

                field_signpost_image {
                    relationships {
                        field_media_image {
                            childImageKitAsset {
                                fixed(width: 1200, transformations: ["h-630"]) {
                                    ...ImageKitFixed
                                }
                            }
                        }
                    }
                }
                field_related_content {
                    # TODO ADD PROJECT PAGES
                    ...basicPageRelatedContentItem
                    ...topicRegionRelatedContentItem
                }
                field_secondary_content {
                    __typename
                    ...ProfileReferencesData
                    ...IconLinksData
                }
            }
        }
    }
`;
