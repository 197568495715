/* eslint-disable complexity */
import React from "react";
import { Hit } from "react-instantsearch-core";
import { RelatedArticle } from "../../components/RelatedArticle";
import { RelatedEvent } from "../../components/RelatedEvent";
import { getImageURL } from "../../lib/imageKit";
import { convertTimeStampToDate } from "../../utilities/dateUtils";
import { RelatedArticleHit, RelatedEventHit } from "./RelatedContent.types";
import { getEventTimeString } from "../../components/SearchResult/SearchResult";

const IMAGE_WIDTH = 480;

export const RelatedContentSignpost: React.FC<{
    hit: Hit<RelatedEventHit | RelatedArticleHit>;
}> = ({ hit }) => {
    const signpostImage =
        hit.signpost_media_image_uri &&
        getImageURL(hit.signpost_media_image_uri, IMAGE_WIDTH * 2);

    const heroImage =
        hit.media_image_uri &&
        getImageURL(hit.media_image_uri, IMAGE_WIDTH * 2);

    const searchImage = signpostImage ?? heroImage;

    switch (hit.type) {
        case "article": {
            const date = convertTimeStampToDate(hit.authored_date);

            const formattedDate = date?.toLocaleDateString(["utc"], {
                day: "numeric",
                month: "long",
                year: "numeric"
            });

            const machineDate = date.toISOString();

            return (
                <RelatedArticle
                    heading={hit.title}
                    date={formattedDate}
                    machineDate={machineDate}
                    url={hit.url}
                    variant="whiteBg"
                    readTime={hit.read_time}
                    restrictedString={hit.premium ? "Members only" : null}
                    publicationName={hit.content_type}
                    publicationLink={hit.content_type_path}
                    searchImage={searchImage}
                />
            );
        }
        case "events": {
            const startDate = hit.event_date_start
                ? convertTimeStampToDate(hit.event_date_start)
                : "";

            const fullDate = startDate
                ? startDate.toLocaleDateString("en-GB", {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                      year: "numeric"
                  })
                : "";

            const machineDate = startDate ? startDate.toISOString() : "";

            const titleDate = startDate
                ? startDate.toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long"
                  })
                : "";

            const { startTime, endTime } = getEventTimeString(hit);

            return (
                <RelatedEvent
                    url={hit.url}
                    typeName={hit.event_type_name}
                    typeLink={hit.event_type_path}
                    titleDate={titleDate}
                    heading={hit.title}
                    location={hit.event_location_freetext}
                    searchImage={searchImage}
                    variant={"event"}
                    fullDate={fullDate}
                    machineDate={machineDate}
                    startTime={startTime}
                    endTime={endTime}
                    locale={hit.field_time_locale}
                    restrictedString={hit.open_to_name}
                />
            );
        }
        case "conference": {
            const startDate = hit.event_date_start
                ? convertTimeStampToDate(hit.event_date_start)
                : "";

            const fullDate = startDate
                ? startDate.toLocaleDateString("en-GB", {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                      year: "numeric"
                  })
                : "";

            const machineDate = startDate ? startDate.toISOString() : "";
            const titleDate = startDate
                ? startDate.toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long"
                  })
                : "";

            const { startTime, endTime } = getEventTimeString(hit);

            return (
                <RelatedEvent
                    url={hit.url}
                    typeName={hit.event_type_name}
                    typeLink={hit.event_type_path}
                    titleDate={titleDate}
                    heading={hit.title}
                    location={hit.event_location_freetext}
                    searchImage={searchImage}
                    variant={"conference"}
                    fullDate={fullDate}
                    machineDate={machineDate}
                    startTime={startTime}
                    endTime={endTime}
                    locale={hit.field_time_locale}
                    restrictedString={hit.open_to_name}
                />
            );
        }
        default:
            return null;
    }
};
