// extracted by mini-css-extract-plugin
export var actions = "StrategicPriority-module--actions--92476";
export var closed = "StrategicPriority-module--closed--f2271";
export var completed = "StrategicPriority-module--completed--c3e6e";
export var component = "StrategicPriority-module--component--6ccad";
export var desktopOnly = "StrategicPriority-module--desktopOnly--01b32";
export var fadePop = "StrategicPriority-module--fade-pop--ad6af";
export var growHeightIn = "StrategicPriority-module--grow-height-in--80456";
export var inProgress = "StrategicPriority-module--inProgress--f2865";
export var inactive = "StrategicPriority-module--inactive--c64aa";
export var mobileOnly = "StrategicPriority-module--mobileOnly--bb9ab";
export var noDueDate = "StrategicPriority-module--noDueDate--786de";
export var open = "StrategicPriority-module--open--baf4e";
export var overdue = "StrategicPriority-module--overdue--5ada9";
export var progressBar = "StrategicPriority-module--progressBar--794d9";
export var progressOverview = "StrategicPriority-module--progressOverview--6ca0e";
export var progressUnit = "StrategicPriority-module--progressUnit--acf8a";
export var titleArea = "StrategicPriority-module--titleArea--28ecb";
export var toggleButton = "StrategicPriority-module--toggleButton--89eef";