import { graphql } from "gatsby";
import React, { FC, HTMLAttributes } from "react";
import { useAuth } from "../../context/auth/AuthProvider";

import DangerousHTML from "../DangerousHTML";
import { MembershipGateContainer } from "../MembershipGateContainer";

interface Props extends HTMLAttributes<HTMLElement> {
	node?: GatsbyTypes.EmbedParagraphDataFragment;
}

// NOTE: Some scripts can throw an error on mount when running the dev environment, but not present in the production build.
export const Component: FC<Props> = ({ node }) => {
	const { isLoggedIn } = useAuth();
	if (!node?.field_embed_code?.processed) return null;

	return node?.field_gated_content && isLoggedIn ? (
		<MembershipGateContainer variant="media" />
	) : (
		<DangerousHTML html={node.field_embed_code.processed} />
	);
};

export const IframeEmbedParagraph = React.memo(Component);

export const fragment = graphql`
    fragment EmbedParagraphData on paragraph__iframe_embed {
        __typename
        id
        field_gated_content
        field_embed_code {
            processed
        }
    }
`;

export default IframeEmbedParagraph;
