import React, { FC, HTMLAttributes } from "react";
import cx from "classnames";
import * as styles from "./TitleForDynamicParagraphs.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {}

export const TitleForDynamicParagraphs: FC<Props> = ({
    className,
    ...props
}) => (
    <>
        <div className={cx(styles.component, className)} {...props}>
            {props.children}
        </div>
        <hr className={styles.hr} />
    </>
);

export default TitleForDynamicParagraphs;
