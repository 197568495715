import { graphql } from "gatsby";
import React, { FC, HTMLAttributes, useMemo } from "react";
import {
    getJoiningStr,
    filterAuthors
} from "../../components/ArticleMeta/ArticleMeta";
import { HeroArticle } from "../../components/HeroArticle";

interface Props extends HTMLAttributes<HTMLElement> {
    node?: GatsbyTypes.HeroArticleDataFragment;
}

const getAuthorString = (authors: Array<Author | null>) =>
    !authors?.length
        ? undefined
        : authors.reduce(
              (prev, current, index) =>
                  `${prev}${getJoiningStr(
                      authors.length,
                      index
                  )} ${current.field_first_names ?? ""} ${current.title ?? ""}`,
              ""
          );

// eslint-disable-next-line complexity
export const HeroArticleParagraph: FC<Props> = ({ node, ...rest }) => {
    const { field_title, relationships } = node ?? {};

    const {
        __typename,
        field_outside_author,
        created,
        fallbackTitle,
        path,
        dateRange,
        relationships: contentItemRelationships
    } = relationships?.field_content_item ?? {};

    const { field_content_type, field_author, field_media_image } =
        contentItemRelationships ?? {};

    const authorString = useMemo(
        () =>
            field_outside_author ??
            getAuthorString(filterAuthors(field_author)),
        [field_outside_author, field_author]
    );

    if (!node) return null;

    return (
        <HeroArticle
            path={path?.alias}
            contentType={field_content_type?.name}
            hideDate={__typename === "node__article"}
            title={field_title || fallbackTitle}
            date={created ?? dateRange?.eventDate}
            image={
                field_media_image?.relationships?.field_media_image?.image
                    ?.fluid
            }
            backgroundImage={
                field_media_image?.relationships?.field_media_image
                    ?.backgroundImage?.fluid
            }
            author={authorString}
            machineDate={dateRange?.machineDate ?? ""}
            {...rest}
        />
    );
};

export const fragment = graphql`
    fragment HeroArticleItemArticleData on node__article {
        __typename
        fallbackTitle: title
        field_outside_author
        created(formatString: "D MMMM Y")
        machineDate: created
        path {
            alias
        }
        relationships {
            field_content_type {
                name
            }
            field_author {
                field_first_names
                title
            }

            field_media_image {
                relationships {
                    field_media_image {
                        image: childImageKitAsset {
                            fluid(
                                maxWidth: 568
                                transformations: ["ar-568-370"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                        backgroundImage: childImageKitAsset {
                            fluid(
                                maxWidth: 1600
                                transformations: ["ar-1600-480"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
    }

    fragment HeroArticleItemEventsData on node__events {
        __typename
        id
        fallbackTitle: title
        dateRange: field_event_date_range {
            eventDate: value(formatString: "dddd, DD MMMM Y")
            machineDate: value
        }
        path {
            alias
        }
        relationships {
            field_content_type: field_event_type {
                name
            }
            field_media_image: field_signpost_image {
                relationships {
                    field_media_image {
                        image: childImageKitAsset {
                            fluid(
                                maxWidth: 568
                                transformations: ["ar-568-370"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                        backgroundImage: childImageKitAsset {
                            fluid(
                                maxWidth: 1600
                                transformations: ["ar-1600-480"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
    }

    fragment HeroArticleItemConferenceData on node__conference {
        __typename
        id
        fallbackTitle: title
        dateRange: field_event_date_range {
            eventDate: value(formatString: "dddd, DD MMMM Y")
            machineDate: value
        }
        path {
            alias
        }
        relationships {
            field_content_type: field_event_type {
                name
            }
            field_media_image: field_signpost_image {
                relationships {
                    field_media_image {
                        image: childImageKitAsset {
                            fluid(
                                maxWidth: 568
                                transformations: ["ar-568-370"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                        backgroundImage: childImageKitAsset {
                            fluid(
                                maxWidth: 1600
                                transformations: ["ar-1600-480"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
    }

    fragment HeroArticleData on paragraph__hero_article {
        field_title
        relationships {
            field_content_item {
                ...HeroArticleItemArticleData
                ...HeroArticleItemEventsData
                ...HeroArticleItemConferenceData
            }
        }
    }
`;
