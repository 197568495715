import React, { FC, HTMLAttributes, useMemo } from "react";
import cx from "classnames";
import * as styles from "./RelatedContentHits.module.scss";
import { BasicDoc, connectHits, HitsProvided } from "react-instantsearch-core";
import { RelatedContentSignpost } from "../../containers/RelatedContent/RelatedContentSignpost";

interface Props extends HTMLAttributes<HTMLElement>, HitsProvided<BasicDoc> {
    titleToExclude?: string;
}

const removeExcludedTitle = (
    hits: Array<{ title: string }>,
    titleToExclude?: string
) => hits.filter(hit => hit.title !== titleToExclude).slice(0, 3);

const Hits: FC<Props> = ({ titleToExclude, hits, className, ...props }) => {
    const filteredHits = useMemo(
        () => removeExcludedTitle(hits, titleToExclude),
        [titleToExclude, hits]
    );

    return (
        <ul>
            {filteredHits.map(hit => (
                <li key={hit.title}>
                    <RelatedContentSignpost hit={hit} />
                </li>
            ))}
        </ul>
    );
};

export const RelatedContentHits = connectHits(Hits);

export default RelatedContentHits;
