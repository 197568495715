import { graphql } from "gatsby";
import React, { FC, HTMLAttributes } from "react";
import { ExternalLinkCard } from "../../components/ExternalLinkCard";
import * as styles from "./IconLinks.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    node: GatsbyTypes.IconLinksDataFragment;
}

export const IconLinks: FC<Props> = ({ node, ...props }) => {
    const links = node?.relationships?.field_icon_links_icons;

    if (!links?.length) {
        return null;
    }

    const { field_title } = node;

    return (
        <div {...props}>
            {field_title && (
                <label className={styles.label}>{field_title}</label>
            )}

            {links.map(({ field_link, field_icon }) => (
                <ExternalLinkCard
                    key={field_link?.title}
                    url={field_link?.uri}
                    label={field_link?.title}
                    iconName={field_icon}
                />
            ))}
        </div>
    );
};

export default IconLinks;

export const fragment = graphql`
    fragment IconLinksData on paragraph__icon_links {
        id
        field_title
        relationships {
            field_icon_links_icons {
                field_link {
                    title
                    uri
                }
                field_icon
            }
        }
    }
`;
