export const DRUPAL_MENU_PREFIX = "menu_link_content:";

interface Node {
    id: GatsbyTypes.Maybe<string>;
    drupal_parent_menu_item: GatsbyTypes.Maybe<string>;
    children?: Node[];
    [key: string]: unknown;
}

export function getTopLevelMenuItems<Type extends Node>(nodes: Type[]): Type[] {
    return nodes.filter(
        ({ drupal_parent_menu_item }) => !drupal_parent_menu_item
    );
}

export function populateMenu<Type extends Node>(
    nodes: Type[],
    allNodes: Type[]
): Type[] {
    nodes.forEach(node => {
        const children: Node[] = allNodes.filter(
            ({ drupal_parent_menu_item }) =>
                drupal_parent_menu_item?.includes(node.id || "will not match")
        );

        children.forEach(node => {
            node.children = allNodes.filter(({ drupal_parent_menu_item }) =>
                drupal_parent_menu_item?.includes(node.id || "will not match")
            );
        });

        node.children = children;
    });

    return nodes;
}

export function shapeMenuNodes<Type extends Node>(nodes: Type[]): Type[] {
    const topLevelMenu = getTopLevelMenuItems(nodes);
    return populateMenu(topLevelMenu, nodes);
}
