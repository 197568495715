import { graphql, PageProps } from "gatsby";
import React from "react";
import { BreadCrumbs } from "../../types/breadcrumbs";
import { EcpPageComponent } from "../../components/EcpPageComponent";
import { EcpMethodologyPageComponent } from "../../components/EcpMethodologyPageComponent";
interface Props extends PageProps {
    data: GatsbyTypes.EcpQuery;
    pageContext: {
        id: string;
        breadcrumb: BreadCrumbs;
    };
}

export const EcpTemplate: React.FC<Props> = ({ data: { ecp } }) => {
    const Component = ecp?.field_is_methodology_page
        ? EcpMethodologyPageComponent
        : EcpPageComponent;

    return <Component node={ecp} />;
};

export default EcpTemplate;

export const pageQuery = graphql`
    query Ecp($path: String!) {
        ecp: nodeEcp(path: { alias: { eq: $path } }) {
            __typename
            id
            title
            body {
                processed
                summary
            }
            field_link {
                title
                url
                absoluteUrl
            }
            field_ecp_completed
            field_ecp_in_progress
            field_ecp_overdue
            field_ecp_no_due_date
            field_ecp_inactive
            field_end_date(difference: "ms")
            relationships {
                field_ecp_groups {
                    id
                    field_group_title
                    relationships {
                        field_ecp_items {
                            id
                            field_ecp_title
                            field_action_number
                            field_status
                            field_responsible_organisations {
                                processed
                            }
                            field_ecp_due_date
                            field_last_updated
                            field_overview {
                                processed
                            }
                            field_progress {
                                processed
                            }
                        }
                    }
                }
                field_sections {
                    relationships {
                        field_author_people {
                            ...PersonData
                        }
                    }
                }
            }
            field_additional_text_area {
                processed
            }
            field_is_methodology_page
            field_methodology_details {
                processed
            }
        }
    }
`;
