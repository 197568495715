import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton
} from "react-share";

import { IconSprite } from "../IconSprite";
import * as styles from "./SocialShareBlock.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    variant?: "normal" | "header" | "block";
    title?: string;
    url: string;
}

export const SocialShareBlock: FC<Props> = ({
    variant = "normal",
    title,
    url,
    className,
    ...props
}) => (
    <div
        aria-hidden
        className={cx(
            styles.component,
            styles[variant],
            className,
            "hideOnPrint"
        )}
        {...props}
    >
        {variant === "block" ? "Share this page" : "Share"}
        <div className={styles.icons}>
            <FacebookShareButton className={styles.link} url={url}>
                <IconSprite className={styles.icon} name="facebook" />
            </FacebookShareButton>
            <TwitterShareButton className={styles.link} url={url} title={title}>
                <IconSprite className={styles.icon} name="twitter" />
            </TwitterShareButton>
            <LinkedinShareButton className={styles.link} url={url}>
                <IconSprite className={styles.icon} name="linkedin" />
            </LinkedinShareButton>
            <EmailShareButton className={styles.link} url={url}>
                <IconSprite className={styles.icon} name="email" />
            </EmailShareButton>
        </div>
    </div>
);

export default SocialShareBlock;
