import React, { FC, HTMLAttributes } from "react";
import cx from "classnames";
import * as styles from "./BrandSubstantiationBlock.module.scss";
import { IndentedContent } from "../IndentedContent";
import { ReactComponent as RusiCrest } from "../../assets/rusi-crest.svg";

interface StatBlockData {
    id?: string;
    field_statement?: string | null;
    field_number?: string | null;
}

interface Props extends HTMLAttributes<HTMLElement> {
    text?: string;
    stats?: StatBlockData[];
}

interface StatBlockProps extends StatBlockData {
    colorNumber: number;
}

const StatBlock: FC<StatBlockProps> = ({
    field_statement,
    field_number,
    colorNumber
}) => (
    <li className={cx(styles.stat, styles[`color${colorNumber}`])}>
        <div className={styles.filter}>
            <figure className={styles.crest}>
                <RusiCrest />
            </figure>
            <span className={styles.figure}>{field_number}</span>
            <p>{field_statement}</p>
        </div>
    </li>
);

export const BrandSubstantiationBlock: FC<Props> = ({
    text,
    stats,
    ...props
}) => {
    let currentColorNumber = 0;
    return (
        <IndentedContent html={text} hideBackgroundOnMobile {...props}>
            <ul className={styles.stats}>
                {!!stats?.length &&
                    stats.map((stat, index) => {
                        if (stat) {
                            currentColorNumber++;
                            if (currentColorNumber > 4) currentColorNumber = 1;
                            return (
                                <StatBlock
                                    key={stat.id}
                                    colorNumber={currentColorNumber}
                                    {...stat}
                                />
                            );
                        }

                        return null;
                    })}
            </ul>
        </IndentedContent>
    );
};

export default BrandSubstantiationBlock;
