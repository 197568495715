// Wrapper for Algolia's InfinateHits component
// Doesn't change behaviour, just styles

import React, { FC, HTMLAttributes } from "react";
import cx from "classnames";
import * as styles from "./CustomInfiniteHits.module.scss";
import { InfiniteHits } from "react-instantsearch-dom";
import { SearchResult } from "../SearchResult";

interface Props extends HTMLAttributes<HTMLElement> {
    loadMoreString?: string;
}

export const CustomInfiniteHits: FC<Props> = ({
    className,
    loadMoreString = "Load more results",
    ...props
}) => (
    <InfiniteHits
        className={cx(className, styles.component)}
        hitComponent={SearchResult}
        translations={{ loadMore: loadMoreString }}
        {...props}
    />
);

export default CustomInfiniteHits;
