import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { FluidObject } from "gatsby-image";
import { BreadCrumbs } from "../types/breadcrumbs";
import TitleBlock from "../components/TitleBlock";
import ContentTemplate from "../components/ContentTemplate";
import SEO from "../components/SEO";
import LandingPageLayout from "../components/LandingPageLayout";
import { stripHtml } from "../lib/stringUtils";

interface Props {
    data: GatsbyTypes.LandingPageSecondaryQuery;
    pageContext: {
        id: string;
        breadcrumb?: BreadCrumbs;
    };
}

// eslint-disable-next-line complexity
export const LandingPageSecondary: React.FC<Props> = ({
    data: { data },
    pageContext
}) => {
    if (!data) return null;

    const { title, relationships, field_abstract, field_primary_link } = data;
    const abstract = field_abstract?.processed || "";
    const image =
        relationships?.field_media_image?.relationships?.field_media_image
            ?.childImageKitAsset?.fluid;
    const breadcrumb = pageContext.breadcrumb?.crumbs;

    const paragraphData = data.relationships?.field_sections;

    const firstSection =
        paragraphData?.[0]?.fields?.sections?.[0]?.__typename ===
        "paragraph__tabs_container";

    const sections =
        paragraphData?.[paragraphData.length - 1]?.fields?.sections;

    const lastSection =
        sections?.[sections.length - 1]?.__typename !==
        "paragraph__tabs_container";

    // Metatags
    const metaImage = image?.src;
    const metaDescription = field_abstract?.value;

    return (
        <Layout>
            <SEO
                title={title}
                image={metaImage}
                description={metaDescription && stripHtml(metaDescription)}
            />
            <LandingPageLayout>
                <TitleBlock
                    variant={"publication"}
                    breadcrumb={breadcrumb}
                    title={title || ""}
                    contentType={field_primary_link?.title ?? ""}
                    contentTypeUrl={field_primary_link?.uri ?? ""}
                    summary={abstract}
                    htmlSummary={true}
                    image={image as FluidObject}
                />
                <ContentTemplate
                    hideBorderTop={firstSection}
                    firstSection={firstSection}
                    lastSection={lastSection}
                    sections={paragraphData ?? []}
                />
            </LandingPageLayout>
        </Layout>
    );
};

export const query = graphql`
    query LandingPageSecondary($path: String) {
        data: nodeLandingPageLevel2(path: { alias: { eq: $path } }) {
            id
            field_primary_link {
                title
                uri: url
            }
            relationships {
                field_media_image {
                    relationships {
                        field_media_image {
                            childImageKitAsset {
                                fluid(maxWidth: 500) {
                                    ...ImageKitFluid
                                }
                            }
                        }
                    }
                }

                field_sections {
                    id
                    field_paragraph_container_style
                    field_background
                    fields: relationships {
                        sections: field_content_paragr {
                            ...FieldSections
                        }
                    }
                }
            }
            field_abstract {
                processed
                value
            }
            field_primary_tag
            title
        }
    }
`;

export default LandingPageSecondary;
