import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";

import { Link } from "../Link";
import { IconSprite } from "../IconSprite";
import * as styles from "./LinkSet.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    columns?: 3 | 4;
    heading?: string;
    containerStyle?: string;
}

interface LinkSetItemProps extends HTMLAttributes<HTMLElement> {
    url?: string;
    label?: string;
}

export const LinkSetItem: FC<LinkSetItemProps> = ({ url, label }) =>
    url && label ? (
        <Link className={styles.linkGridItem} to={url} aria-label={label}>
            <span>{label}</span>
            <IconSprite className={styles.icon} name="chevron-right" />
        </Link>
    ) : null;

export const LinkSet: FC<Props> = ({
    heading,
    columns = 3,
    containerStyle,
    className,
    ...props
}) => (
    <section className={cx(className, styles[containerStyle])} {...props}>
        {heading && <h2 className={styles.heading}>{heading}</h2>}
        <div
            className={cx(
                styles.items,
                styles[columns === 4 ? "fourColumns" : "threeColumns"]
            )}
        >
            {props.children}
        </div>
    </section>
);

export default LinkSet;
