import React, { FC, HTMLAttributes } from "react";
import cx from "classnames";
import * as styles from "./ReadTime.module.scss";
import { IconSprite } from "../IconSprite";

interface Props extends HTMLAttributes<HTMLElement> {
    readTime?: string | null;
}

export const ReadTime: FC<Props> = ({ readTime, className, ...props }) =>
    readTime ? (
        <span className={cx(styles.component, className)} {...props}>
            <IconSprite aria-label="read time" name="clock" />
            <b>{readTime}</b>
        </span>
    ) : null;

export default ReadTime;
