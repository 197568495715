// extracted by mini-css-extract-plugin
export var arrowIcon = "HeroArticle-module--arrowIcon--ca504";
export var background = "HeroArticle-module--background--56d3b";
export var backgroundImage = "HeroArticle-module--backgroundImage--0a364";
export var bottom = "HeroArticle-module--bottom--8777e";
export var component = "HeroArticle-module--component--0e5c0";
export var content = "HeroArticle-module--content--c044d";
export var fadePop = "HeroArticle-module--fade-pop--76d28";
export var filter = "HeroArticle-module--filter--551ee";
export var growHeightIn = "HeroArticle-module--grow-height-in--ddcd8";
export var image = "HeroArticle-module--image--64ed8";
export var openLink = "HeroArticle-module--openLink--2c960";
export var textWrapper = "HeroArticle-module--textWrapper--0bfff";