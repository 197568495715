import {
    connectRefinementList,
    connectSearchBox,
    connectStateResults
} from "react-instantsearch-core";
import { FilterMenu } from "../FilterMenu";
import { SearchForm } from "../SearchBox/SearchBox";
import { SearchStateResults } from "../SearchStateResults";
import { SearchStats } from "../SearchStats";

export const CustomStats = connectStateResults(SearchStats);

export const CustomSearchForm = connectSearchBox(SearchForm);

export const CustomRefinementList = connectRefinementList(FilterMenu);

export const CustomStateResults = connectStateResults(SearchStateResults);
