import React, { FC, HTMLAttributes, useMemo } from "react";
import { useAuth } from "../../context/auth/AuthProvider";

import { stripHtml } from "../../lib/stringUtils";
import { Button } from "../Button";
import { ContentGate } from "../ContentGate";
import { Link } from "../Link";
import * as styles from "./MembershipGate.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
	boxHeading: string;
	body: string;
	buttonTitle: string;
	buttonLink: string;
	footer: string;
	heading: string;
	membershipLinkText?: string;
	membershipLinkPath?: string;
	hideLogin?: boolean;
}

export const MembershipGate: FC<Props> = ({
	boxHeading,
	heading,
	body,
	buttonTitle,
	buttonLink,
	footer,
	membershipLinkText,
	membershipLinkPath,
	hideLogin = false,
	...props
}) => {
	const { loginOrMyAccount } = useAuth();
	const footerText = useMemo(() => stripHtml(footer), [footer]);

	return (
		<ContentGate {...props}>
			<div className={styles.component}>
				<h2>{heading}</h2>
				<Link to={buttonLink} className={styles.button}>
					<Button>{buttonTitle}</Button>
				</Link>
				{membershipLinkPath &&
					membershipLinkPath?.length > 0 &&
					membershipLinkText?.length &&
					membershipLinkText?.length > 0 && (
						<Link to={membershipLinkPath}>{membershipLinkText}</Link>
					)}
				<div
					className={styles.body}
					// rome-ignore lint/security/noDangerouslySetInnerHtml: this is a trusted source
					dangerouslySetInnerHTML={{ __html: body }}
				/>
				<footer>
					{!hideLogin && (
						<p>
							{`${footerText} `}

							<button
								type="button"
								className={styles.loginButton}
								onClick={loginOrMyAccount}
							>
								Log In
							</button>
						</p>
					)}
				</footer>
			</div>
		</ContentGate>
	);
};

export default MembershipGate;
