import React, { FC, HTMLAttributes } from "react";
import cx from "classnames";
import { component } from "./LandingPageLayout.module.scss";

export const LandingPageLayout: FC<HTMLAttributes<HTMLElement>> = ({
    className,
    ...rest
}) => <article className={cx(className, component)} {...rest} />;

export default LandingPageLayout;
