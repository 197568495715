/*
    Used for dynamically populated lists content - LatestDynamicContent
    Accepts record data from the algolia index, extracts the required data and passes it to RelatedArticle
*/

import React, { FC, HTMLAttributes, useMemo } from "react";
import { getImageURL } from "../../lib/imageKit";
import { SearchHit } from "../../types/data";
import { convertTimeStampToDate } from "../../utilities/dateUtils";
import RelatedArticle from "./RelatedArticle";

interface ContainerProps extends HTMLAttributes<HTMLElement> {
    searchHit: SearchHit;
}

const formatDates = (authored_date: number | null) => {
    if (!authored_date) {
        return { machineDate: undefined, date: undefined };
    }

    const jsDate = convertTimeStampToDate(authored_date);

    const date = jsDate.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric"
    });

    const machineDate = jsDate.toISOString();

    return { date, machineDate };
};

// When used dynamically with algolia
export const RelatedArticleContainer: FC<ContainerProps> = ({
    searchHit,
    ...rest
}) => {
    const {
        url,
        title: heading,
        content_type: publicationName,
        content_type_path: publicationLink,
        authored_date,
        premium,
        read_time: readTime,
        signpost_media_image_uri,
        media_image_uri
    } = searchHit;

    const { date, machineDate } = useMemo(() => formatDates(authored_date), [
        authored_date
    ]);

    const rawImage = signpost_media_image_uri ?? media_image_uri;

    const searchImage = useMemo(
        () => rawImage && getImageURL(rawImage, 268, 176),
        [rawImage]
    );

    const relatedArticleProps = {
        url,
        heading,
        publicationName,
        publicationLink,
        date,
        machineDate,
        restrictedString: premium ? "members only" : "",
        readTime,
        searchImage
    };
    return (
        <RelatedArticle variant="whiteBg" {...relatedArticleProps} {...rest} />
    );
};

export default RelatedArticleContainer;
