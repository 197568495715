import cx from "classnames";
import Image, { FluidObject } from "gatsby-image";
import React, { FC, HTMLAttributes } from "react";
import * as styles from "./PersonCard.module.scss";
import { Link } from "../Link";

type PersonCardVariant =
    | "profile"
    | "search"
    | "team"
    | "keyContact"
    | "sidebar"
    | "ecp";

interface Props extends HTMLAttributes<HTMLElement> {
    name: string;
    variant?: PersonCardVariant;
    title?: string;
    position?: string;
    department: string;
    director?: boolean;
    hideLink?: boolean;
    image?: FluidObject;
    url?: string;
    darkBackground?: boolean;
    email?: string;
}

interface PersonImageProps {
    image?: FluidObject | null;
}

const PersonImage: FC<PersonImageProps> = ({ image }) => {
    return image ? (
        <div className={cx(styles.imageWrapper, "hideOnPrint")}>
            <Image fluid={image} />
        </div>
    ) : null;
};

interface ProfileLinkProps {
    path: string | null | undefined;
}

const ProfileLink: FC<ProfileLinkProps> = ({ path }) =>
    path ? (
        <span
            className={cx(styles.profileLink, "hideOnPrint")}
        >{`View profile`}</span>
    ) : null;

interface ArticleWrapperProps extends HTMLAttributes<HTMLElement> {
    to: string; // Just so that it can be passed to link
}

// If there is no path, this article replaces link
const ArticleWrapper: FC<ArticleWrapperProps> = ({ className, children }) => (
    <article className={cx(className, "printAvoidPageBreakInside")}>
        {children}
    </article>
);

// eslint-disable-next-line complexity
export const PersonCard: FC<Props> = ({
    name,
    title,
    department,
    position,
    hideLink = false,
    variant = "profile",
    director = false,
    className,
    darkBackground,
    image,
    url,
    email,
    ...props
}) => {
    const positionLevel = director ? "director" : "teamMember";

    const variantClassName = `${variant}Variant`;

    const WrappingComponent = url ? Link : ArticleWrapper;

    const showLinkText = !hideLink && variant !== "keyContact";

    return (
        <WrappingComponent
            className={cx(
                styles.component,
                styles[variantClassName],
                {
                    [styles.withLink]: !!url,
                    [styles.withImage]: !!image,
                    [styles.noImage]: !image,
                    [styles.darkBackground]: darkBackground
                },
                className
            )}
            to={url ?? ""}
            {...props}
        >
            <div className={styles.content}>
                {variant === "ecp" && image && <PersonImage image={image} />}
                <div className={styles.text}>
                    <div className={styles.info}>
                        <h3 className={styles.name}>{name}</h3>
                        {title && (
                            <p
                                className={cx(
                                    styles.position,
                                    styles[positionLevel]
                                )}
                            >
                                {title}
                            </p>
                        )}
                        {position && (
                            <p className={styles.position}>{position}</p>
                        )}
                        {department && (
                            <p className={styles.department}>{department}</p>
                        )}
                    </div>

                    {showLinkText && <ProfileLink path={url} />}
                    {email && (
                        <a className={styles.email} href={email}>
                            Get in touch
                        </a>
                    )}
                </div>

                {variant === "keyContact" && <ProfileLink path={url} />}
                {variant !== "ecp" && (
                    <div>
                        <PersonImage image={image} />
                    </div>
                )}
            </div>
        </WrappingComponent>
    );
};

export default PersonCard;
