/* eslint-disable complexity */
import React from "react";
import cx from "classnames";
import { graphql } from "gatsby";
import * as styles from "./Speakers.module.scss";
import { PersonCard } from "../../components/PersonCard";
import { useInPageNavId } from "../../context/InPageNav/useInPageNavId";

interface Props extends GatsbyTypes.paragraph__speaker_container {
    containerStyle?: string;
    className?: string;
}

export const Speakers: React.FC<Props> = ({
    containerStyle,
    field_title,
    field_variant,
    field_is_on_this_page_anchor,
    relationships,
    className
}) => {
    const { field_content_paragr } = relationships || {};

    const id = useInPageNavId({ field_is_on_this_page_anchor, field_title });

    const isKeyContact = field_variant === "key_contact";

    return (
        <div
            id={id}
            className={cx(
                styles.component,
                className,
                styles[isKeyContact ? "keyContact" : "notKeyContact"]
            )}
        >
            <div className={styles.content}>
                <h2>{field_title}</h2>
                {!isKeyContact && <hr />}
                <div className={styles.grid}>
                    {field_content_paragr?.map(node => {
                        const {
                            field_first_names = "",
                            field_position = "",
                            title: surname = "",
                            path,
                            relationships
                        } = node?.relationships?.field_speakers || {};

                        switch (node.__typename) {
                            case "paragraph__speaker_group":
                                return (
                                    <PersonCard
                                        className={styles.card}
                                        director={field_position === "director"}
                                        key={node?.id}
                                        title={field_position}
                                        name={`${field_first_names} ${surname}`}
                                        department={
                                            relationships
                                                ?.field_staff_department?.[0]
                                                ?.name || ""
                                        }
                                        variant={
                                            isKeyContact ? "keyContact" : "team"
                                        }
                                        image={
                                            relationships?.field_user_picture
                                                ?.childImageKitAsset?.fluid
                                        }
                                        url={path?.alias}
                                    />
                                );
                            case "paragraph__speaker_heading":
                                return (
                                    <>
                                        <h3 className={styles.fullWidth}>
                                            {node.field_title}
                                        </h3>
                                        <hr className={styles.fullWidth} />
                                    </>
                                );
                            case "paragraph__speakers":
                                return (
                                    <PersonCard
                                        className={styles.card}
                                        key={node?.id}
                                        name={node?.field_speakers_name}
                                        title="" // not available on paragraph__speakers
                                        department="" // not available on paragraph__speakers
                                        variant="team"
                                        url={node?.field_link?.uri}
                                        image={
                                            node?.relationships
                                                ?.field_speaker_image
                                                ?.relationships
                                                ?.field_media_image
                                                ?.childImageKitAsset?.fluid
                                        }
                                    />
                                );
                            default:
                                return null;
                        }
                    })}
                </div>
            </div>
        </div>
    );
};

export const fragment = graphql`
    fragment SpeakerContainer on paragraph__speaker_container {
        id
        field_title
        field_is_on_this_page_anchor
        field_variant
        relationships {
            field_content_paragr {
                ...SpeakerHeading
                ...SpeakerGroup
                ...Speaker
            }
        }
    }

    fragment SpeakerHeading on paragraph__speaker_heading {
        id
        __typename
        field_title
    }

    fragment SpeakerGroup on paragraph__speaker_group {
        id
        __typename
        relationships {
            field_speakers {
                title
                field_first_names
                field_position
                path {
                    alias
                }
                relationships {
                    field_staff_department {
                        name
                        path {
                            alias
                        }
                    }
                    field_user_picture {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 88
                                transformations: ["fo-face", "ar-88-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
    }

    fragment Speaker on paragraph__speakers {
        id
        __typename
        field_speakers_name
        relationships {
            field_speaker_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(
                                maxWidth: 88
                                transformations: ["fo-face", "ar-88-112"]
                            ) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
        field_link {
            uri
            title
        }
    }
`;
