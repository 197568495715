import { graphql } from "gatsby";
import React, { FC, HTMLAttributes, useMemo } from "react";
import { MembershipGateContainer } from "../MembershipGateContainer";
import VideoEmbed from "../VideoEmbed/VideoEmbed";

interface Props extends HTMLAttributes<HTMLElement> {
    node?: GatsbyTypes.VideoEmbedParagraphDataFragment;
}

// eslint-disable-next-line complexity
export const VideoEmbedParagraph: FC<Props> = ({
    node,
    className,
    ...props
}) => {
    const embedMarkup = node?.relationships?.field_video_embed?.value;

    // const showGate = useMemo(() => {
    //     const eventTypeName =
    //         node?.relationships?.node__events?.[0]?.relationships
    //             ?.field_event_type?.name;
    //     if (
    //         !eventTypeName ||
    //         !node?.field_gated_content ||
    //         !auth?.checkCanAccess
    //     ) {
    //         return false;
    //     }

    //     return !auth.checkCanAccess(eventTypeName);
    // }, [auth, node]);

    if (!embedMarkup || !node || !node?.id) {
        return null;
    }

    return true && !node ? (
        <MembershipGateContainer variant="media" />
    ) : (
        <VideoEmbed
            heading={node.field_title ?? ""}
            caption={node.field_caption ?? ""}
            transcript={node.field_transcript ?? ""}
            embedMarkup={embedMarkup}
            id={node.id}
            {...props}
        />
    );
};

export const fragment = graphql`
    fragment VideoEmbedParagraphData on paragraph__video_embed {
        __typename
        id
        field_title
        field_transcript
        field_caption
        field_gated_content
        relationships {
            field_video_embed {
                __typename
                field_media_oembed_video
                value
            }
            node__events {
                relationships {
                    field_event_type {
                        name
                    }
                }
            }
        }
    }
`;

export default VideoEmbedParagraph;
