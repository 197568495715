import humanizeDuration from "humanize-duration";

export const getBytesStr = (bytes: number) => {
    if (bytes < 1024) {
        return `${bytes}B`;
    }
    const kb = Math.round(bytes / 1024);
    if (kb < 1024) {
        return `${kb}KB`;
    }

    return `${Math.round(kb / 1024)}MB`;
};

export const addLeadingZeros = (number: number | string, width: number) => {
    width -= number.toString().length;
    if (width > 0) {
        return (
            new Array(width + (/\./.test(number) ? 2 : 1)).join("0") + number
        );
    }
    return `${number}`; // always return a string
};

export const humnaniseDuration = (countdownTo: number) =>
    humanizeDuration(countdownTo) // e.g "1 year, 6 months, 15 days"
        .split(", ") // e.g ["1 year", "6 months", "15 "days"]
        .map(x => x.split(" ")) // e.g ["1", "year", "6", "months", "15", "days"]
        .filter(x =>
            ["year", "years", "month", "months", "day", "days"].includes(x[1])
        ); // e.g [["1", "year"], ["6", "months"], ["15", "days"]]
