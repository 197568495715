import cx from "classnames";
import React, { FC, InputHTMLAttributes, useEffect, useRef } from "react";

import * as styles from "./TextInput.module.scss";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    type?: "search" | "text" | "password" | "email";
    placeholder?: string;
    focus?: boolean;
}

export const TextInput: FC<Props> = ({
    className,
    placeholder,
    type,
    focus = false,
    ...props
}) => {
    const ref = useRef<HTMLInputElement | undefined>();

    useEffect(() => {
        if (focus && ref.current) {
            ref.current.focus();
        }
    }, [focus]);

    return (
        <input
            ref={ref}
            placeholder={placeholder ?? ""}
            className={cx(styles.component, className)}
            type={type ?? "text"}
            {...props}
        />
    );
};

export default TextInput;
