// extracted by mini-css-extract-plugin
export var bottomStrip = "VideoEmbed-module--bottomStrip--87c42";
export var caption = "VideoEmbed-module--caption--1ffd8";
export var closedTranscript = "VideoEmbed-module--closedTranscript--cb452";
export var component = "VideoEmbed-module--component--26b41";
export var fadePop = "VideoEmbed-module--fade-pop--f7644";
export var growHeightIn = "VideoEmbed-module--grow-height-in--7599f";
export var hidden = "VideoEmbed-module--hidden--f0232";
export var iframeWrapper = "VideoEmbed-module--iframeWrapper--b7f8e";
export var openTranscript = "VideoEmbed-module--openTranscript--0bcdf";
export var showing = "VideoEmbed-module--showing--d18d1";
export var transcriptButton = "VideoEmbed-module--transcriptButton--1e624";
export var withHeading = "VideoEmbed-module--withHeading--009aa";