import { graphql } from "gatsby";
import React, { FC, HTMLAttributes } from "react";
import { useInPageNavId } from "../../context/InPageNav/useInPageNavId";

import { Sponsor, SponsorsList } from "../Sponsors/Sponsors";
import { FluidObject } from "gatsby-image";

interface Props extends HTMLAttributes<HTMLElement> {
	node?: GatsbyTypes.SponsorsParagraphDataFragment;
}

export const SponsorsParagraph: FC<Props> = ({ node, ...props }) => {
	const id = useInPageNavId(node);

	if (!node) return null;

	const listTitle = node.field_title;

	const items = node.relationships?.items?.map((item) =>    
		item
			? {
					id: item.id,
					name: item.name,
					body: item.body,
					url: item.url,
                    rawImage: {
                        src: item.relationships?.field_media?.relationships?.field_media_image?.uri?.url,
                    },
					image:
						item.relationships?.field_media?.relationships?.field_media_image
							?.childImageKitAsset?.fluid as FluidObject,
			  }
			: null,
	);
    
	if (!items?.length) return null;
	return (
		<SponsorsList heading={listTitle} id={id} {...props}>
			{items.map((item) => {
				if (!item) return null;

				const { id, ...rest } = item;
				return <Sponsor key={id} {...rest} />;
			})}
		</SponsorsList>
	);
};

// Exporting a fragment allows it to be used in any GraphQL query

export const fragment = graphql`
    fragment SponsorsParagraphData on paragraph__sponsors {
        id
        field_title
        field_is_on_this_page_anchor
        relationships {
            items: field_content_paragr {
                id
                name: field_sponsor_name
                body: field_spon
                url: field_sponsor_url {
                    linkText: title
                    link: uri
                }
                relationships {
                    field_media {
                        relationships {
                            field_media_image {
                                childImageKitAsset {
                                    fluid(maxWidth: 267) {
                                        ...ImageKitFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default SponsorsParagraph;
