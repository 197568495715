import { useEffect, useState } from "react";

const proxyFetch = (url: string) =>
    fetch(`${process.env.GATSBY_CORS_PROXY_URL}?url=${url}`, {
        headers: { "x-api-key": `${process.env.GATSBY_CORS_PROXY_KEY}` }
    });

export const useFetchXML = (uri: string) => {
    const [data, setData] = useState<Document>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>();

    useEffect(() => {
        setError(undefined);

        if (!data) {
            proxyFetch(uri)
                .then(response => response.text())
                .then(str =>
                    new window.DOMParser().parseFromString(str, "text/xml")
                )
                .then(data => {
                    setData(data);
                })
                .catch((err: Error) => {
                    setError(err.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [uri, data]);

    return { data, loading, error };
};

export default useFetchXML;
