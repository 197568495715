import React, { useEffect, useMemo } from "react";
import { graphql, PageProps } from "gatsby";
import { FluidObject } from "gatsby-image";
import { BreadCrumbs } from "../../types/breadcrumbs";
import Layout from "../../components/Layout";
import ImageTitleBlock from "../../components/ImageTitleBlock";
import ArticleLayout, {
    Body,
    Main,
    Sidebar
} from "../../components/ArticleLayout/ArticleLayout";
import { BreadCrumb } from "../../components/BreadCrumb";
import { Paragraphs, ParagraphNode } from "../../components/Paragraphs";
import ContentTemplate from "../../components/ContentTemplate";
import { InPageNavigationContainer } from "../../components/InPageNavigation";
import SEO from "../../components/SEO";
import * as styles from "./ResearchGroup.module.scss";
import { UpcomingEventContextProvider } from "./UpcomingEvent";
import { ContentTypeName, getFacetName } from "../../lib/dynamicContentHelpers";
import { stripHtml } from "../../lib/stringUtils";

interface ResearchGroupProps extends PageProps {
    data: GatsbyTypes.ResearchGroupQuery;
    pageContext: {
        id: string;
        breadcrumb: BreadCrumbs;
    };
}

export const ResearchGroup: React.FC<ResearchGroupProps> = ({
    data: { data },
    location,
    pageContext
}) => {
    const { field_abstract, relationships, title = "" } = data || {};
    const abstract = field_abstract?.value;
    const category = relationships?.field_content_type?.name;
    const categoryUrl = relationships?.field_content_type?.path?.alias;
    const mainContent = relationships?.field_main_content;
    const sidebarContent = relationships?.field_secondary_content;
    const sectionsContent = relationships?.field_sections;
    const heroImage =
        relationships?.field_media_image?.relationships?.field_media_image
            ?.childImageKitAsset?.fluid;
    const url = location.href;
    const contentType = relationships?.field_content_type?.name;

    const facetName = useMemo(
        () =>
            getFacetName(
                (contentType as ContentTypeName) || "Research Groups"
            ) || "",
        [contentType]
    );

    // Metadata
    const metaDescription = field_abstract?.value;
    const metaImage = heroImage?.src;

    return (
        <UpcomingEventContextProvider
            value={{
                facet: facetName,
                value: title
            }}
        >
            <Layout>
                <SEO
                    title={title}
                    description={metaDescription && stripHtml(metaDescription)}
                    image={metaImage}
                />
                <ArticleLayout>
                    <BreadCrumb crumbs={pageContext.breadcrumb.crumbs} />
                    <ImageTitleBlock
                        category={category}
                        categoryUrl={categoryUrl}
                        image={heroImage as FluidObject}
                        summary={abstract}
                        title={title}
                        url={url}
                        variant="research group"
                        className={styles.noMarginBottom}
                    />
                    <InPageNavigationContainer className={styles.inPageNav} />
                    <hr className={styles.noMarginBottom} />
                    <Body>
                        <Main>
                            <Paragraphs data={mainContent as ParagraphNode} />
                        </Main>
                        <Sidebar className={styles.sidebar}>
                            <Paragraphs
                                data={sidebarContent as ParagraphNode}
                            />
                        </Sidebar>
                    </Body>
                    <ContentTemplate
                        sections={sectionsContent as unknown[]}
                        contentType={contentType}
                        contentTitle={title}
                        lastSection
                    />
                </ArticleLayout>
            </Layout>
        </UpcomingEventContextProvider>
    );
};

export default ResearchGroup;

export const query = graphql`
    query ResearchGroup($path: String!, $contentType: String, $title: String) {
        data: nodeResearchGroup(path: { alias: { eq: $path } }) {
            title
            field_abstract {
                processed
                value
            }
            relationships {
                field_sections {
                    id
                    field_background
                    field_paragraph_container_style
                    fields: relationships {
                        sections: field_content_paragr {
                            ...FieldSectionsTopicRegion
                        }
                    }
                }
                field_main_content {
                    ...FieldMainContent
                }
                field_secondary_content {
                    ...FieldSecondaryContent
                }
                field_media_image {
                    relationships {
                        field_media_image {
                            childImageKitAsset {
                                fluid(maxWidth: 1200) {
                                    ...ImageKitFluid
                                }
                            }
                        }
                    }
                }
                field_content_type {
                    name
                    path {
                        alias
                    }
                }
            }
        }
    }

    fragment ResearchGroupSignpost on node__research_group {
        __typename
        id
        title
        path {
            alias
        }
        relationships {
            field_content_type {
                name
                path {
                    alias
                }
            }
            field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 330) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
