/* eslint-disable complexity */

export type ContentTypeName =
    | "Topics"
    | "Regions and Country Groups"
    | "Research Groups"
    | "Projects"
    | "People"
    | "Networks";

export type FacetName =
    | "topics_name"
    | "region_name"
    | "research_groups_name"
    | "related_project_name"
    | "author_surname"
    | "networks_name";

export const getFacetName = (
    contentTypeName: ContentTypeName
): FacetName | undefined => {
    switch (contentTypeName) {
        case "Topics":
            return "topics_name";
        case "Regions and Country Groups":
            return "region_name";
        case "Research Groups":
            return "research_groups_name";
        case "Projects":
            return "related_project_name";
        case "People":
            return "author_surname";
        case "Networks":
            return "networks_name";

        default:
            return undefined;
    }
};
