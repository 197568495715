import React, { FC, HTMLAttributes } from "react";
import cx from "classnames";
import { Button } from "../Button";
import { TextInput } from "../TextInput";
import * as styles from "./NewsletterSignup.module.scss";
import { ReactComponent as WorldMap } from "./assets/world-map.svg";
import Image, { FluidObject } from "gatsby-image";

import qs from "qs";

interface Props extends HTMLAttributes<HTMLElement> {
    variant: "footer" | "paragraph";
    title: string;
    text?: string;
    buttonText?: string;
    url: string;
    image?: FluidObject;
}

export const NewsletterSignup: FC<Props> = ({
    title,
    text,
    url,
    buttonText,
    variant,
    image
}) => {
    const query = qs.parse(url.split("?")[1]);
    const emailId = `${variant}-email`;

    const Form = () => (
        <form
            className={cx(styles.form, styles[variant])}
            action={url.split("?")[0]}
            name="mc-embedded-subscribe-form"
            target="_blank"
        >
            {Object.entries(query).map(([key, value]) => (
                <input type="hidden" key={key} name={key} value={value} />
            ))}
            <div>
                <h3>{title}</h3>
                <label htmlFor={emailId}>{text}</label>
            </div>
            <div>
                {/* Prevents form bot signups */}
                <div className={styles.hidden} aria-hidden="true">
                    <input
                        type="text"
                        name="b_722b72d453b7a1a6e69ceef05_0c9bbb5ef0"
                        tabIndex={-1}
                        value=""
                        readOnly
                    />
                </div>
                <Button type="submit">
                    <span>{buttonText ?? "Sign up"}</span>
                </Button>
            </div>
        </form>
    );

    if (variant === "paragraph")
        return (
            <div className={cx(styles.wrapper, "hideOnPrint")}>
                <div className={styles.shadow} />
                <div className={cx(styles.component, styles.paragraph)}>
                    <div className={styles.background}>
                        {image && (
                            <Image fluid={image} className={styles.image} />
                        )}
                    </div>
                    <div className={styles.filter}>
                        <Form />
                    </div>
                </div>
            </div>
        );

    return (
        <div className={cx(styles.component, styles.footer, "hideOnPrint")}>
            <figure className={styles.map}>
                <WorldMap />
            </figure>
            <Form />
        </div>
    );
};

export default NewsletterSignup;
