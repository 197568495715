// extracted by mini-css-extract-plugin
export var component = "PersonCard-module--component--9457f";
export var content = "PersonCard-module--content--721eb";
export var darkBackground = "PersonCard-module--darkBackground--e6ca8";
export var department = "PersonCard-module--department--2047c";
export var director = "PersonCard-module--director--9c558";
export var ecpVariant = "PersonCard-module--ecpVariant--6f2e4";
export var email = "PersonCard-module--email--a37ea";
export var fadePop = "PersonCard-module--fade-pop--af48d";
export var growHeightIn = "PersonCard-module--grow-height-in--20d49";
export var imageWrapper = "PersonCard-module--imageWrapper--cc64a";
export var info = "PersonCard-module--info--6c3fc";
export var keyContactVariant = "PersonCard-module--keyContactVariant--4b51e";
export var name = "PersonCard-module--name--845dc";
export var noImage = "PersonCard-module--noImage--2f184";
export var position = "PersonCard-module--position--c89c8";
export var profileLink = "PersonCard-module--profileLink--f7b86";
export var profileVariant = "PersonCard-module--profileVariant--bd7f9";
export var searchVariant = "PersonCard-module--searchVariant--45eaa";
export var sidebarVariant = "PersonCard-module--sidebarVariant--9d7e2";
export var teamVariant = "PersonCard-module--teamVariant--50a47";
export var text = "PersonCard-module--text--51096";
export var withImage = "PersonCard-module--withImage--b5220";
export var withLink = "PersonCard-module--withLink--247e7";