import React from "react";
import cx from "classnames";
import {
    body,
    sidebar,
    component,
    main,
    desktopOnly,
    removePaddingOnMobile
} from "./ArticleLayout.module.scss";

interface Props {
    className?: string;
    hideOnMobile?: boolean;
    noPaddingOnMobile?: boolean;
}

/** Two column layout wrapper for articles, etc. */
export const ArticleLayout: React.FC<Props> = ({ className, ...props }) => (
    <article className={cx(component, className)} {...props} />
);

/** Main content section, placed after title block, in page navigation, etc. */
export const Body: React.FC<Props> = ({
    noPaddingOnMobile,
    className,
    ...props
}) => (
    <div
        className={cx(
            body,
            { [removePaddingOnMobile]: noPaddingOnMobile },
            className
        )}
        {...props}
    />
);

/** Left-aligned 8 column section. */
export const Main: React.FC<Props> = ({ className, ...props }) => (
    <div className={cx(main, className)} {...props} />
);

/** Right-aligned 4 column sidebar section, hidden on mobile */
export const Sidebar: React.FC<Props> = ({
    hideOnMobile = true,
    className,
    ...props
}) => (
    <div
        className={cx(sidebar, hideOnMobile && desktopOnly, className)}
        {...props}
    />
);

/** Sticky sidebar section - does not yet work as intended */
export const Sticky: React.FC<Props> = ({ className, ...props }) => (
    <div className={className} {...props} />
);

export default ArticleLayout;
