// extracted by mini-css-extract-plugin
export var backgroundCoins = "ProgressChart-module--backgroundCoins--4db35";
export var coin = "ProgressChart-module--coin--8c466";
export var coinColumn = "ProgressChart-module--coinColumn--34fa3";
export var coinRow = "ProgressChart-module--coinRow--2446a";
export var coinWrapper = "ProgressChart-module--coinWrapper--559ba";
export var completed = "ProgressChart-module--completed--18056";
export var desktopDisplay = "ProgressChart-module--desktopDisplay--f2189";
export var fadePop = "ProgressChart-module--fade-pop--972bc";
export var growHeightIn = "ProgressChart-module--grow-height-in--ac225";
export var header = "ProgressChart-module--header--9a326";
export var inProgress = "ProgressChart-module--inProgress--d352a";
export var inactive = "ProgressChart-module--inactive--0eeaa";
export var labelContainer = "ProgressChart-module--labelContainer--2d8d8";
export var mobileDisplay = "ProgressChart-module--mobileDisplay--433a7";
export var noDueDate = "ProgressChart-module--noDueDate--1e10d";
export var offsetRow = "ProgressChart-module--offsetRow--e35f3";
export var overdue = "ProgressChart-module--overdue--66a64";
export var percentageText = "ProgressChart-module--percentageText--db862";
export var progressChart = "ProgressChart-module--progressChart--b7a75";
export var progressChartContainer = "ProgressChart-module--progressChartContainer--bd34f";
export var row = "ProgressChart-module--row--b86e4";
export var rowContainer = "ProgressChart-module--rowContainer--cc3bb";
export var rowLabel = "ProgressChart-module--rowLabel--0bcde";
export var rowWrapper = "ProgressChart-module--rowWrapper--b3704";