import cx from "classnames";
import Image, { FluidObject } from "gatsby-image";
import React, { FC, HTMLAttributes } from "react";

import SocialShareBlock from "../SocialShareBlock";
import * as styles from "./HeroImage.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    variant?: "default" | "banner";
    fluid?: FluidObject;
    contentType?: string;
    title?: string;
    altText?: string;
    body?: string | null;
    url?: string;
}

export const HeroImage: FC<Props> = ({
    fluid,
    contentType,
    variant = "default",
    altText = "",
    title = "",
    body,
    url,
    className,
    ...rest
}) => (
    <div className={cx(styles.component, className, "hideOnPrint")} {...rest}>
        {fluid && (
            <Image className={styles.image} fluid={fluid} alt={altText} />
        )}
        {variant === "banner" && (
            <div className={styles.contents}>
                <div className={styles.innerContents}>
                    <span
                        className={styles.type}
                        aria-label={`${contentType}:`}
                    >
                        {contentType}
                    </span>
                    <h1 className={styles.title}>{title}</h1>
                    {body && <p className={styles.lead}>{body}</p>}
                </div>
                <div>
                    <SocialShareBlock
                        title={title}
                        variant="header"
                        url={url || ""}
                    />
                </div>
            </div>
        )}
    </div>
);

export default HeroImage;
