/* eslint-disable complexity */

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

interface Props {
    description?: string | null;
    lang?: string;
    meta?: Array<JSX.IntrinsicElements["meta"]>;
    image?: string;
    title?: string | null;
    url?: string;
}

export const SEO: React.FC<Props> = ({
    description,
    lang = "en",
    meta = [],
    image,
    title,
    url
}) => {
    const { site } = useStaticQuery<GatsbyTypes.SEOQuery>(
        graphql`
            query SEO {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        image
                        siteUrl
                    }
                }
            }
        `
    );

    const metaDescription =
        description || site?.siteMetadata?.description || "";

    const src =
        image || `${site?.siteMetadata?.siteUrl}${site?.siteMetadata?.image}`;

    const fullUrl =
        site?.siteMetadata?.siteUrl && url
            ? `${site?.siteMetadata?.siteUrl}${site?.siteMetadata?.siteUrl}`
            : null;
    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title || ""}
            titleTemplate={`%s | ${site?.siteMetadata?.title}`}
            meta={
                [
                    {
                        name: `og:url`,
                        content: fullUrl
                    },
                    {
                        name: `description`,
                        content: metaDescription
                    },
                    {
                        property: `og:image`,
                        content: src
                    },
                    {
                        property: `og:title`,
                        content: title
                    },
                    {
                        property: `og:description`,
                        content: metaDescription
                    },
                    {
                        property: `og:type`,
                        content: `website`
                    },

                    {
                        name: `twitter:card`,
                        content: "summary_large_image"
                    },
                    {
                        name: `twitter:site`,
                        content: site?.siteMetadata?.author
                    },
                    {
                        name: `twitter:image`,
                        content: src
                    },
                    {
                        name: `twitter:creator`,
                        content: site?.siteMetadata?.author
                    },
                    {
                        name: `twitter:title`,
                        content: title
                    },
                    {
                        name: `twitter:description`,
                        content: metaDescription
                    },
                    ...meta
                ] as Array<JSX.IntrinsicElements["meta"]>
            }
        />
    );
};

export default SEO;
