import { TagData } from "../../components/AreasOfExpertise/AreasOfExpertise";
import { PersonQuery_person_relationships } from "./__generated__/PersonQuery";

export const getAreasOfExpertiseTags = (
    personRelationships: PersonQuery_person_relationships | null
): TagData[] => {
    if (!personRelationships) return [];

    const topics = personRelationships?.field_topics ?? [];
    const regions = personRelationships?.field_region ?? [];
    const countries = personRelationships?.field_country ?? [];

    return [...regions, ...topics, ...countries].map((item) => ({
        label: item.name,
        id: item.id,
        url: item.path?.alias ?? "",
    }));
};
