import cx from "classnames";
import { graphql } from "gatsby";
import React, { FC, HTMLAttributes, useState } from "react";
import * as styles from "./FootnoteList.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    items: GatsbyTypes.FieldFootnoteFragment[];
}

export const FootnoteList: FC<Props> = ({ items = [], ...props }) => {
    return (
        <div id={"footnotes"} className={styles.component}>
            <h3>Footnotes</h3>
            <div className={styles.list}>
                {items?.map((item, i) => {
                    return (
                        <div className={styles.footnoteRow} key={i}>
                            <span className={styles.footnoteKey}>
                                {item.title}:
                            </span>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: item.body?.processed ?? ""
                                }}
                            ></div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default FootnoteList;
