import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";
import { addLeadingZeros, humnaniseDuration } from "../../lib/numberUtils";
import * as styles from "./TimeRemaining.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    countdownTo: number;
}

export const TimeRemaining: FC<Props> = ({
    countdownTo,
    className,
    ...props
}) => {
    if (!countdownTo) {
        return null;
    }

    const countdown = humnaniseDuration(countdownTo);
    return (
        <div className={cx(styles.component, className)} {...props}>
            <div>
                <h5>countdown</h5>
                {countdown.map((time, i) => (
                    <span key={i} className={styles.number}>
                        {addLeadingZeros(time[0], 2) // The count
                        }
                    </span>
                ))}
                {countdown.map((time, i) => (
                    <span key={i}>
                        {
                            time[1] // The unit
                        }
                    </span>
                ))}
            </div>
        </div>
    );
};

export default TimeRemaining;
