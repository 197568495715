// extracted by mini-css-extract-plugin
export var author = "Signpost-module--author--54575";
export var body = "Signpost-module--body--71010";
export var bottom = "Signpost-module--bottom--7c6e9";
export var component = "Signpost-module--component--8428f";
export var content = "Signpost-module--content--43782";
export var date = "Signpost-module--date--4c42d";
export var department = "Signpost-module--department--99835";
export var eventLocation = "Signpost-module--eventLocation--02912";
export var eventMetaDetails = "Signpost-module--eventMetaDetails--f419c";
export var eventTime = "Signpost-module--eventTime--b7a0e";
export var externalPublisherName = "Signpost-module--externalPublisherName--ba1b4";
export var fadePop = "Signpost-module--fade-pop--5a916";
export var growHeightIn = "Signpost-module--grow-height-in--77bbf";
export var image = "Signpost-module--image--43bb2";
export var indented = "Signpost-module--indented--43ae1";
export var isItn = "Signpost-module--isItn--1a640";
export var isPerson = "Signpost-module--isPerson--67de3";
export var labels = "Signpost-module--labels--8fd43";
export var link = "Signpost-module--link--9cab5";
export var linkText = "Signpost-module--linkText--fc147";
export var mainLink = "Signpost-module--mainLink--041c7";
export var noImage = "Signpost-module--noImage--d8c59";
export var openTo = "Signpost-module--openTo--96186";
export var position = "Signpost-module--position--19020";
export var search = "Signpost-module--search--09989";
export var searchImage = "Signpost-module--searchImage--0ccc5";
export var title = "Signpost-module--title--a7107";
export var type = "Signpost-module--type--a4412";
export var volAndIssue = "Signpost-module--volAndIssue--6c56a";
export var whiteBg = "Signpost-module--whiteBg--459f8";
export var withImage = "Signpost-module--withImage--54b78";