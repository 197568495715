import React from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../../components/Layout";
import LandingPageTitleBlock from "../../components/LandingPageTitleBlock";
import { FluidObject } from "gatsby-image";
import ContentTemplate from "../../components/ContentTemplate";
import LandingPageLayout from "../../components/LandingPageLayout";
import SEO from "../../components/SEO";
import ArticleLayout, {
    Body,
    Main,
    Sidebar
} from "../../components/ArticleLayout/ArticleLayout";
import Paragraphs, { ParagraphNode } from "../../components/Paragraphs";
import * as styles from "./LandingPagePrimary.module.scss";
import { stripHtml } from "../../lib/stringUtils";

interface CustomPageContext {
    isHomepage: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
interface Props extends PageProps<object, CustomPageContext> {
    data: GatsbyTypes.LandingPageQuery;
}

// eslint-disable-next-line complexity
export const LandingPage: React.FC<Props> = ({
    data: { data },
    pageContext
}) => {
    if (!data) return null;

    const { title, abstract, relationships } = data;
    const { field_sections, field_main_content, field_secondary_content } =
        relationships ?? {};

    const image =
        relationships?.field_media_image?.relationships?.field_media_image
            ?.childImageKitAsset?.fluid;

    const renderBody = !!(
        !!field_main_content?.length || !!field_secondary_content?.length
    );

    // Metatags
    const metaImage = image?.src;
    const metaDescription = abstract?.value && stripHtml(abstract.value);

    return (
        <Layout>
            <SEO
                title={title}
                image={metaImage}
                description={metaDescription}
            />
            {!pageContext.isHomepage && (
                <LandingPageTitleBlock
                    title={title || ""}
                    subtitle={abstract?.value || ""}
                    image={image as FluidObject}
                />
            )}
            {renderBody && (
                <ArticleLayout>
                    <Body>
                        <Main>
                            <ContentTemplate
                                hideBorderTop
                                firstSection
                                sections={[
                                    {
                                        fields: {
                                            sections: field_main_content ?? []
                                        }
                                    }
                                ]}
                            />
                        </Main>
                        <Sidebar>
                            <Paragraphs
                                className={styles.sidebarParagraphs}
                                data={field_secondary_content as ParagraphNode}
                            />
                        </Sidebar>
                    </Body>
                </ArticleLayout>
            )}
            <LandingPageLayout>
                <ContentTemplate
                    hideBorderTop={!renderBody}
                    firstSection={!renderBody}
                    lastSection
                    landingPageSpacing
                    sections={field_sections as ParagraphNode}
                />
            </LandingPageLayout>
        </Layout>
    );
};

export default LandingPage;

export const query = graphql`
    query LandingPage($id: String!) {
        data: nodeLandingPageLevel1(id: { eq: $id }) {
            title
            abstract: field_abstract {
                value
                processed
            }
            relationships {
                field_secondary_content {
                    ...FieldSecondaryContent
                }
                field_main_content {
                    ...FieldMainContent
                }
                field_sections {
                    id
                    field_paragraph_container_style
                    field_background
                    fields: relationships {
                        sections: field_content_paragr {
                            ...FieldSections
                        }
                    }
                }
                field_media_image {
                    relationships {
                        field_media_image {
                            childImageKitAsset {
                                fluid(maxWidth: 1200) {
                                    ...ImageKitFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
