export const parseXML = (data: Document, keys: string[]) => {
    const items = [...data.querySelectorAll("item")];

    // TODO: Infer this using generics
    type FieldKeys = typeof keys[number];

    const itemList: Array<
        {
            [Property in FieldKeys]?: string;
        }
    > = [];

    items.forEach((el, i) => {
        keys.forEach(field => {
            itemList[i] = {
                ...itemList[i],
                [field]: el.querySelector(field)?.innerHTML
            };
        });
    });

    return { items: itemList };
};
