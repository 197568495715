import React, { FC, HTMLAttributes } from "react";
import cx from "classnames";
import * as styles from "./SidebarSignpost.module.scss";
import Image, { FluidObject } from "gatsby-image";
import { IconSprite } from "../IconSprite";
import { Link } from "../Link";

interface Props extends HTMLAttributes<HTMLElement> {
    title: string;
    url: string;
    image?: FluidObject;
    summary?: string;
    linkText?: string;
}

export const SidebarSignpost: FC<Props> = ({
    title,
    url,
    image,
    summary,
    linkText = "View",
    className,
    ...props
}) => {
    if (!url) {
        return null;
    }
    return (
        <Link
            to={url}
            className={cx(
                styles.component,
                className,
                styles[image ? "withImage" : "noImage"]
            )}
            {...props}
        >
            <div className={styles.imageTitleWrapper}>
                <h4>{title}</h4>
                {!!image && <Image fluid={image} className={styles.image} />}
            </div>

            {!!summary && <p>{summary}</p>}
            {!!linkText && (
                <div className={styles.link}>
                    <hr />
                    <span>
                        <span className={styles.linkText}>{linkText}</span>
                        <IconSprite name="chevron-right" />
                    </span>
                </div>
            )}
        </Link>
    );
};
export default SidebarSignpost;
