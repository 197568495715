// extracted by mini-css-extract-plugin
export var arrowIcon = "RelatedInTheNews-module--arrowIcon--d60c0";
export var author = "RelatedInTheNews-module--author--9a286";
export var bottom = "RelatedInTheNews-module--bottom--3052c";
export var component = "RelatedInTheNews-module--component--0f066";
export var desktopOnly = "RelatedInTheNews-module--desktopOnly--a44ae";
export var fadePop = "RelatedInTheNews-module--fade-pop--6fa32";
export var growHeightIn = "RelatedInTheNews-module--grow-height-in--527f5";
export var imageTagWrapper = "RelatedInTheNews-module--imageTagWrapper--c9233";
export var imageWrapper = "RelatedInTheNews-module--imageWrapper--b9869";
export var mainLink = "RelatedInTheNews-module--mainLink--4fd10";
export var mobileOnly = "RelatedInTheNews-module--mobileOnly--d8ab4";
export var position = "RelatedInTheNews-module--position--cf554";
export var primaryTag = "RelatedInTheNews-module--primaryTag--b18b3";
export var quote = "RelatedInTheNews-module--quote--1168c";
export var quoteIcon = "RelatedInTheNews-module--quoteIcon--43adf";
export var searchImage = "RelatedInTheNews-module--searchImage--b47bb";
export var withImage = "RelatedInTheNews-module--withImage--66519";