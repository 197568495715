import cx from "classnames";
import React, { FC, InputHTMLAttributes, useEffect, useRef } from "react";

import parse, {
    HTMLReactParserOptions,
    DOMNode,
    Element,
    domToReact
} from "html-react-parser";
import { Footnote } from "../../components/Footnote";
import { TextParagraphContext } from "../../containers/TextParagraph/TextParagraph.context";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    html: string;
    footnotes: GatsbyTypes.FieldFootnoteFragment[];
}

export const TextAreaParsed: FC<Props> = ({
    className,
    footnotes,
    html,
    ...props
}) => {
    const options: HTMLReactParserOptions = {
        replace: ({ attribs, children }: DOMNode) => {
            if (!attribs) {
                return null;
            }
            if (attribs.class === "embedded-entity") {
                return (
                    <>
                        <Footnote attribs={attribs}>{children}</Footnote>
                    </>
                );
            }
            return null;
        }
    };

    const parsedHtml = parse(html, options);

    return (
        <TextParagraphContext.Provider value={{ footNotes: footnotes }}>
            <div {...props} className={className}>
                {parsedHtml}
            </div>
        </TextParagraphContext.Provider>
    );
};

export default TextAreaParsed;
