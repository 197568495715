/* eslint-disable complexity */
import cx from "classnames";
import Image, { FluidObject } from "gatsby-image";
import React from "react";

import { IconSprite } from "../IconSprite";
import { Link } from "../Link";
import { ReadTime } from "../ReadTime";
import * as styles from "./Signpost.module.scss";

export type SignpostBackgroundColors = "grey" | "white";

export type SignpostVariants = "normal" | "indented" | "search" | "simple";

export interface SignpostProps {
	backgroundColor?: SignpostBackgroundColors;
	variant?: SignpostVariants;
	title?: string;
	summary?: string | null;
	date?: string;
	machineDate?: string;
	url?: string;
	image?: FluidObject;
	imgSrc?: string;
	imgAlt?: string;
	type?: string;
	typeLink?: string;
	volume?: string | null;
	issue?: string | null;
	linkText?: string | null;
	authorString?: string | null;
	openTo?: string | null;
	searchImage?: string | null;
	readTime?: string | null;
	position?: string | null;
	department?: string | null;
	startTime?: string | null;
	endTime?: string | null;
	locale?: string | null;
	eventLocation?: string | null;
	externalPublisherName?: string | null;
	externalPublisherFeature?: string | null;
	isPerson?: boolean;
	className?: string;
	closed?: boolean | null;
}

export const Signpost: React.FC<SignpostProps> = ({
	title,
	summary,
	date,
	machineDate,
	url,
	image,
	imgSrc,
	imgAlt,
	type,
	typeLink,
	volume,
	issue,
	openTo,
	authorString,
	searchImage,
	readTime,
	position,
	department,
	startTime,
	endTime,
	locale,
	eventLocation,
	externalPublisherName,
	externalPublisherFeature,
	isPerson = false,
	backgroundColor = "grey",
	variant = "normal",
	linkText = "View online",
	className,
	closed,
	...rest
}) => {
	if (!url || !title) return null;
	const showLabels =
		variant !== "simple" && (!!type || !!volume || !!issue || !!openTo);

	return (
		<div
			{...rest}
			className={cx(
				className,
				styles.component,
				styles[`${backgroundColor}Bg`],
				styles[variant],
				styles[isPerson ? "isPerson" : "notPerson"],
				styles[externalPublisherName ? "isItn" : "notItn"],
				styles[
					image || (variant === "search" && searchImage)
						? "withImage"
						: "noImage"
				],
			)}
		>
			<Link
				to={url}
				className={styles.mainLink}
				aria-label={`${type}: ${title}`}
			/>
			{image && variant !== "search" && (
				<Image
					fluid={{ ...image, media: "(max-width: 650px)" }}
					className={styles.image}
				/>
			)}
			{imgSrc && variant !== "search" && (
				<img className={styles.image} src={imgSrc} alt={imgAlt} />
			)}
			<div className={styles.content}>
				<div className={styles.body}>
					{showLabels && (
						<div className={styles.labels}>
							{type && typeLink && (
								<Link to={typeLink} className={styles.type} tabIndex={-1}>
									{type}
								</Link>
							)}
							{type && !typeLink && (
								<label className={styles.type}>{type}</label>
							)}
							{closed && <label>Closed</label>}
							{openTo && <label className={styles.openTo}>{openTo}</label>}

							<span className={styles.volAndIssue}>
								{volume && <label>{volume}</label>}
								{issue && <label>{issue}</label>}
							</span>
						</div>
					)}
					<span className={styles.title}>{title}</span>
					{summary && <p>{summary}</p>}
					<div>
						{position && <p className={styles.position}>{position}</p>}
						{department && <p className={styles.department}>{department}</p>}
					</div>
				</div>
				<div className={styles.bottom}>
					{authorString && <p className={styles.author}>{authorString}</p>}
					{externalPublisherName && (
						<p>
							{externalPublisherFeature}{" "}
							<span className={styles.externalPublisherName}>
								{externalPublisherName}
							</span>
						</p>
					)}
					<div className={styles.date}>
						{date && machineDate && <time dateTime={machineDate}>{date}</time>}
						<ReadTime readTime={readTime} />
					</div>
					{(!!startTime || !!eventLocation) && (
						<div className={styles.eventMetaDetails}>
							{startTime && (
								<span className={styles.eventTime}>
									{startTime && <time>{startTime}</time>}
									{endTime && (
										<>
											<span>{"-"}</span>
											<time>{endTime}</time>
										</>
									)}
									{locale && <span>{locale}</span>}
								</span>
							)}
							{eventLocation && (
								<span className={styles.eventLocation}>{eventLocation}</span>
							)}
						</div>
					)}
					{variant !== "search" && (
						<Link to={url} className={styles.link}>
							<span>
								<span className={styles.linkText}>{linkText}</span>
								<IconSprite name="chevron-right" />
							</span>
						</Link>
					)}
				</div>
			</div>
			{variant === "search" && searchImage && (
				<img className={styles.searchImage} src={searchImage} alt={title} />
			)}
			{variant === "search" && image && (
				<Image
					fluid={{ ...image, media: "(max-width: 650px)" }}
					className={styles.searchImage}
				/>
			)}
		</div>
	);
};
