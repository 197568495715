import { AgendaDay, AgendaSession } from "../../components/Agenda/Agenda";
import {
    AgendaParagraphData_relationships_agenda_relationships_days,
    AgendaParagraphData_relationships_agenda_relationships_days_relationships_sessions
} from "./__generated__/AgendaParagraphData";

type FormatAgendaSessionInput = Array<AgendaParagraphData_relationships_agenda_relationships_days_relationships_sessions | null>;
type FormatAgendaSessionReturn = AgendaSession[];

export const formatAgendaSessions: (
    i: FormatAgendaSessionInput
) => FormatAgendaSessionReturn = data =>
    data.map(session => ({
        id: session?.id || "",
        title: session?.title || "",
        startDate: session?.times?.startDate,
        endDate: session?.times?.endDate,
        machineStartDate: session?.times?.machineStartDate,
        machineEndDate: session?.times?.machineEndDate,
        summary: session?.summary?.processed
    }));

type FormatAgendaDaysInput = Array<AgendaParagraphData_relationships_agenda_relationships_days | null>;
type FormatAgendaDaysReturn = AgendaDay[];

export const formatAgendaDays: (
    input?: FormatAgendaDaysInput
) => FormatAgendaDaysReturn = data => {
    if (!data) return [];

    return data.map(day => ({
        id: day?.id || "",
        title: day?.title || "",
        sessions: formatAgendaSessions(day?.relationships?.sessions || [])
    }));
};
