/* eslint-disable complexity */
import { graphql } from "gatsby";
import React, { FC, HTMLAttributes } from "react";
import { RelatedEvent } from "../../components/RelatedEvent";
import { RelatedEventRowDynamic } from "../../components/RelatedEventRowDynamic";
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';

interface Props extends HTMLAttributes<HTMLElement> {
    node: GatsbyTypes.UpcomingEventsDataFragment;
    contentType?: string;
    contentTitle?: string;
}

interface UpcomingEventProps {
    key: string;
    event: any;
}

const getEventTimes = (startTimeStamp: string, endTimeStamp: string) => {
    const startDate = new Date(startTimeStamp);
    const endDate = new Date(endTimeStamp);

    const startTime = startDate.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit"
    });

    const endTime = endDate.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit"
    });

    return { startTime, endTime };
};

// eslint-disable-next-line complexity
export const UpcomingEventsDynamic: FC<Props> = ({
    node,
    contentType,
    contentTitle,
    ...props
}) => {
    const { field_title, field_link } = node ?? {};
    const { title: linkTitle, uri: linkUri } = field_link ?? {};

    const queryClient = new QueryClient();
    return (
        <QueryClientProvider client={queryClient}>
            <EventList field_title={field_title} linkTitle={linkTitle} linkUri={linkUri} />
        </QueryClientProvider>
    );
};

const EventList: FC<UpcomingEventProps> = ({
    field_title,
    linkTitle,
    linkUri
}) => {

    const { isLoading, error, data } = useQuery({
        cacheTime: 600000, // 10 mins - milliseconds
        queryKey: ["latestEvents"],
        queryFn: () =>
            fetch(`${process.env.GATSBY_RM_EVENTFOLIO_URL}`).then(
                (res) => res.json(),
            )
    });


    if (isLoading) return "Loading...";

    if (error) return "";

    return (
        <RelatedEventRowDynamic
            heading={field_title}
            linkTitle={linkTitle}
            linkUrl={linkUri}
        >
            <ul>
                {data?.map(
                    event =>
                        event && <UpcomingEventItem key={event.id} event={event} />
                )}
            </ul>
        </RelatedEventRowDynamic>
    )
}


const UpcomingEventItem: FC<UpcomingEventProps> = ({ event }) => {
    const image = event.image
    const variant = "event";

    const typeLink = null;
    const typeName = null;

    const startDate = new Date(`${event.date}T${event.start_time}`);
    const endDate = new Date(`${event.end_date}T${event.end_time}`);

    const fullDate = startDate
        ? startDate.toLocaleDateString("en-GB", {
            weekday: "long",
            day: "numeric",
            month: "long",
            year: "numeric",
            timeZone: event.timezone ? event.timezone : "Europe/London"
        })
        : "";

    const machineDate = startDate ? startDate.toISOString() : "";
    const titleDate = startDate
        ? startDate.toLocaleDateString("en-GB", {
            day: "numeric",
            month: "long"
        })
        : "";

    const { startTime, endTime } = getEventTimes(startDate, endDate);
    const openTo = "";

    return (
        <li>
            <RelatedEvent
                endTime={endTime}
                fullDate={fullDate}
                machineDate={machineDate}
                titleDate={titleDate}
                startTime={startTime}
                //location={event.full_location}
                restrictedString={openTo}
                typeLink={typeLink}
                typeName={typeName}
                variant={variant}
                heading={event.name}
                url={event.url || ""}
                image={image}
            />
        </li>
    );
};





export default UpcomingEventsDynamic;

export const fragment = graphql`
    fragment UpcomingEventsData on paragraph__upcoming_events {
        field_title
        field_link {
            title
            uri
        }
        events(limit: 10) {
            items {
                __typename
                ...UpcomingEvent
                ...UpcomingConference
            }
        }
        relationships {
            field_filter {
                name
            }
        }
    }

    fragment UpcomingConference on node__conference {
        id
        title
        path {
            alias
        }
        field_open_to
        field_time_locale
        field_event_date_range {
            value
            end_value
        }
        relationships {
            field_event_type {
                name
                path {
                    alias
                }
            }
            field_signpost_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 400) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 400) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
    }

    fragment UpcomingEvent on node__events {
        id
        title
        path {
            alias
        }
        field_open_to
        field_time_locale
        field_event_date_range {
            value
            end_value
        }
        relationships {
            field_event_type {
                name
                path {
                    alias
                }
            }
            field_signpost_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 400) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 400) {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
