import { graphql } from "gatsby";
import React, { FC, HTMLAttributes } from "react";
import { AboutBlock } from "../../components/AboutBlock";

interface Props extends HTMLAttributes<HTMLElement> {
    node?: GatsbyTypes.AboutBlockDataFragment;
}

export const AboutBlockParagraph: FC<Props> = ({ node, ...rest }) => {
    if (!node) {
        return null;
    }

    const { field_title, field_statement, field_link } = node;

    return (
        <AboutBlock
            label={field_title}
            statement={field_statement}
            buttonText={field_link?.title}
            buttonLink={field_link?.url}
        />
    );
};

export default AboutBlockParagraph;

export const fragment = graphql`
    fragment AboutBlockData on paragraph__about_block {
        id
        field_title
        field_statement
        field_link {
            url
            title
        }
    }
`;
