// extracted by mini-css-extract-plugin
export var component = "ProfileTitleBlock-module--component--64757";
export var departmentLink = "ProfileTitleBlock-module--departmentLink--5924f";
export var eventSeriesLink = "ProfileTitleBlock-module--eventSeriesLink--7e753";
export var fadePop = "ProfileTitleBlock-module--fade-pop--bf5c9";
export var flex = "ProfileTitleBlock-module--flex--16ae3";
export var growHeightIn = "ProfileTitleBlock-module--grow-height-in--00547";
export var image = "ProfileTitleBlock-module--image--90643";
export var imageWrapper = "ProfileTitleBlock-module--imageWrapper--decaf";
export var socialLinks = "ProfileTitleBlock-module--socialLinks--ae1d3";
export var text = "ProfileTitleBlock-module--text--a9fc6";
export var underline = "ProfileTitleBlock-module--underline--8c5d8";