// extracted by mini-css-extract-plugin
export var bold = "ExternalLinkCard-module--bold--549ec";
export var component = "ExternalLinkCard-module--component--2685b";
export var fadePop = "ExternalLinkCard-module--fade-pop--fde88";
export var growHeightIn = "ExternalLinkCard-module--grow-height-in--35764";
export var iconWrapper = "ExternalLinkCard-module--iconWrapper--dff8a";
export var itunes = "ExternalLinkCard-module--itunes--568e0";
export var label = "ExternalLinkCard-module--label--ad37d";
export var notBold = "ExternalLinkCard-module--notBold--7835f";
export var phone = "ExternalLinkCard-module--phone--4625d";
export var spotify = "ExternalLinkCard-module--spotify--b7e16";