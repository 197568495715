// extracted by mini-css-extract-plugin
export var desktopOnly = "Project-module--desktopOnly--04e3a";
export var fadePop = "Project-module--fade-pop--30baf";
export var fullWidthContent = "Project-module--fullWidthContent--4988b";
export var greyBg = "Project-module--greyBg--6518f";
export var growHeightIn = "Project-module--grow-height-in--4bd1a";
export var heroImage = "Project-module--heroImage--2f5db";
export var inPageNav = "Project-module--inPageNav--6e9fe";
export var inPageNavWrapper = "Project-module--inPageNavWrapper--43cf8";
export var main = "Project-module--main--67546";
export var mobileOnly = "Project-module--mobileOnly--3c2e9";
export var noMarginBottom = "Project-module--noMarginBottom--fd8c5";
export var paragraphs = "Project-module--paragraphs--705d3";
export var placeholder = "Project-module--placeholder--9aba6";
export var related = "Project-module--related--eecc9";
export var sectionDivider = "Project-module--sectionDivider--5d973";
export var topHr = "Project-module--topHr--df750";
export var twoColumnLayout = "Project-module--twoColumnLayout--eb550";
export var underline = "Project-module--underline--97c93";