import { graphql } from "gatsby";
import React, { FC, HTMLAttributes } from "react";
import { PeopleList } from "../../components/PeopleList";

interface Props extends HTMLAttributes<HTMLElement> {
    node: GatsbyTypes.ProfileReferencesDataFragment;
}

export const ProfileReferences: FC<Props> = ({ node, ...props }) => {
    const people = node?.relationships?.field_profile_reference_profile;
    if (!people?.length) {
        return null;
    }

    return (
        <PeopleList
            variant="side"
            heading={node?.field_title}
            people={people}
            {...props}
        />
    );
};

export default ProfileReferences;

export const fragment = graphql`
    fragment ProfileReferencesData on paragraph__profile_references {
        id
        field_title
        relationships {
            field_profile_reference_profile {
                ...PersonData
            }
        }
    }
`;
