import cx from "classnames";
import Image, { FluidObject } from "gatsby-image";
import React from "react";

import { Crumb } from "../../types/breadcrumbs";
import { ArticleMeta, ArticleMetaProps } from "../ArticleMeta/ArticleMeta";
import { BreadCrumb } from "../BreadCrumb";
import { LeadParagraph } from "../LeadParagraph";
import { LinkOrSpan } from "../LinkOrSpan";
import SocialShareBlock from "../SocialShareBlock";
import * as styles from "./TitleBlock.module.scss";

// TODO: Fix interface
export interface TitleBlockProps extends ArticleMetaProps {
    variant?:
        | "landing" // purple image, title, summary
        | "secondary-landing" // breadcrumb, underline, title, summary
        | "publication" // breadcrumb, underline, members only, title, summary, image block
        | "topic" // breadcrumb, underline, topic, title, summary, sharing
        | "region" // breadcrumb, underline, topic, title, summary, sharing
        | "article" // breadcrumb, underline, content type, issue, members only, title, article meta, sharing block
        | "event" // breadcrumb, underline, content type, corporate members, title, event meta
        | "in-the-news" // breadcrumb, content type, title share block, date, subtext
        | "series"
        | "basic-page"; // breadcrumb, content type, title share block, date, subtext
    title: string;
    summary?: string | null;
    htmlSummary?: boolean;
    breadcrumb?: Crumb[];
    contentType?: string;
    contentTypeUrl?: string;
    image?: FluidObject;
    url?: string;
    subText?: string;
    volume?: string;
    issue?: string;
    restrictedTo?: string;
    closedProject?: boolean;
}

// eslint-disable-next-line complexity
export const TitleBlock: React.FC<TitleBlockProps> = ({
    variant = "article",
    summary,
    htmlSummary = false,
    breadcrumb,
    className,
    authorText,
    authors,
    date,
    title,
    readTime,
    contentType,
    contentTypeUrl,
    volume,
    issue,
    subText,
    restrictedTo,
    closedProject,
    url = "",
    image
}) => {
    const showSummary =
        !!summary &&
        [
            "landing",
            "topic",
            "region",
            "secondary-landing",
            "publication",
            "basic-page"
        ].includes(variant);

    return (
        <header className={cx(styles.component, styles[variant], className)}>
            {breadcrumb && <BreadCrumb crumbs={breadcrumb} />}
            {variant !== "landing" && (
                <hr className={cx(styles.underline, "hideOnPrint")} />
            )}
            <div className={styles.flex}>
                <div>
                    {(contentType || volume || issue) && (
                        <div className={styles.contentTypeWrapper}>
                            {contentType && (
                                <LinkOrSpan
                                    className={styles.contentType}
                                    url={contentTypeUrl}
                                >
                                    {contentType}
                                </LinkOrSpan>
                            )}
                            {closedProject && <label>Closed</label>}
                            <span className={styles.issueVol}>
                                {volume && <span>{volume}</span>}
                                {issue && <span>{issue}</span>}
                            </span>
                            {restrictedTo && (
                                <span className={styles.restrictedTo}>
                                    {restrictedTo}
                                </span>
                            )}
                        </div>
                    )}
                    {variant === "publication" && restrictedTo && (
                        <span className={styles.restrictedTo}>
                            {restrictedTo}
                        </span>
                    )}
                    <h1>{title}</h1>
                    {showSummary && !htmlSummary ? (
                        <p className={styles.summary}>{summary}</p>
                    ) : (
                        showSummary &&
                        !!summary && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: summary
                                }}
                            />
                        )
                    )}

                    {variant === "series" && summary && (
                        <LeadParagraph>{summary}</LeadParagraph>
                    )}

                    <ArticleMeta
                        authors={authors}
                        authorText={authorText}
                        readTime={readTime}
                        date={date}
                    />
                    {subText && <p className={styles.subText}>{subText}</p>}
                </div>
                <div>
                    {image && (
                        <Image
                            fluid={image}
                            className={cx(styles.image, "hideOnPrint")}
                        />
                    )}
                    {url && (
                        <SocialShareBlock
                            title={title}
                            variant="normal"
                            url={url}
                        />
                    )}
                </div>
            </div>
        </header>
    );
};
