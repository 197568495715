const DEFAULT_BASE64_WIDTH = 30;

const DEFAULT_FIXED_WIDTH = 400;
const DEFAULT_FLUID_MAX_WIDTH = 650;

export const generateImageURL = (
    baseUrl: string,
    s3Uri: string,
    transformations: string[] = [],
    chained: string[] = []
) => {
    const fileName =
        s3Uri && s3Uri.startsWith("s3://") ? s3Uri.substring(5) : "";
    const allTransformations = ["tr", transformations.join(","), ...chained]
        .filter(Boolean)
        .join(":");

    return [
        baseUrl,
        allTransformations === "tr" ? null : allTransformations,
        fileName
    ]
        .filter(Boolean)
        .join("/");
};

export const getImageURL = (s3Uri: string, width: number, height?: number) => {
    const transforms = [`w-${width}`];
    if (height) {
        transforms.push(`h-${height}`);
    }
    return generateImageURL(
        process.env.GATSBY_IMAGEKIT_ROOT || "",
        s3Uri,
        transforms
    );
};

export const getImageURLLogo = (
    s3Uri: string,
    width: number,
    height?: number
) => {
    const transforms = [`w-${width}`];
    if (height) {
        transforms.push(`h-${height}`);
    }
    transforms.push(`cm-pad_resize`);
    //transforms.push(`fo-right`);
    return generateImageURL(
        process.env.GATSBY_IMAGEKIT_ROOT || "",
        s3Uri,
        transforms
    );
};
