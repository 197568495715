/*
    Used for dynamically populated lists content - LatestDynamicContent
    Accepts record data from the algolia index, extracts the required data and passes it to RelatedInTheNews
*/

import React, { HTMLAttributes, FC, useMemo } from "react";
import { getImageURL } from "../../lib/imageKit";
import { SearchHit } from "../../types/data";
import { convertTimeStampToDate } from "../../utilities/dateUtils";

import RelatedInTheNews from "./RelatedInTheNews";

interface ContainerProps extends HTMLAttributes<HTMLElement> {
    searchHit: SearchHit;
}

const getAuthorString = (
    firstNames: string | string[] | null,
    surnames: string | string[] | null
) => {
    if (!Array.isArray(surnames)) {
        return `${firstNames} ${surnames}`;
    }

    return (surnames as string[])
        .map(
            (surname: string, index: number) =>
                `${(firstNames as string[])[index]} ${surname}`
        )
        .join(", ");
};

export const RelatedInTheNewsContainer: FC<ContainerProps> = ({
    searchHit,
    ...rest
}) => {
    const {
        title: heading,
        authored_date,
        url,
        author_surname,
        author_first_names,
        focus: leadQuote,
        primary_tag: primaryTag,
        author_position: authorPosition,
        external_publisher_logo_uri: rawImage
    } = searchHit;

    const author = getAuthorString(author_first_names, author_surname);

    const date = authored_date
        ? convertTimeStampToDate(authored_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "long",
              year: "numeric"
          })
        : "";

    const machineDate = authored_date
        ? convertTimeStampToDate(authored_date).toISOString()
        : "";

    const searchImage = useMemo(
        () => rawImage && getImageURL(rawImage, 96, 96),
        [rawImage]
    );

    const relatedInTheNewsProps = {
        heading,
        leadQuote,
        date,
        machineDate,
        url,
        author,
        primaryTag,
        searchImage,
        authorPosition
    };

    return <RelatedInTheNews {...relatedInTheNewsProps} {...rest} />;
};

export default RelatedInTheNewsContainer;
