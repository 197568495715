export const getCurrentYear = () => new Date(Date.now()).getFullYear();

export const getYearsSince = (startYear: number) => {
    const currentYear = getCurrentYear();
    const years = [];
    let startFrom = startYear;

    while (startFrom <= currentYear) {
        years.push(startFrom++);
    }
    return years;
};

export const getNextNYears = (n: number) => {
    if (!n) return [];

    const currentYear = getCurrentYear();
    const years = [];
    let startFrom = currentYear + 1;
    let currentCount = 0;

    while (currentCount <= n) {
        years.push(startFrom++);
        currentCount++;
    }
    return years;
};

export const isDate = (date: any) => typeof date?.getMonth === "function";

export const getLastDayOfMonth = (date: Date) =>
    new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

export const convertTimeStampToDate = (timestamp: number) =>
    new Date(timestamp * 1000);
