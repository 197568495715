import React, { FC, HTMLAttributes } from "react";
import cx from "classnames";
import * as styles from "./HeroArticle.module.scss";
import Image, { FluidObject } from "gatsby-image";
import { IconSprite } from "../IconSprite";
import { Link } from "../Link";

interface Props extends HTMLAttributes<HTMLElement> {
    contentType?: string;
    title?: string;
    author?: string;
    date?: string;
    linkText?: string;
    image?: FluidObject;
    backgroundImage?: FluidObject;
    path?: string;
    machineDate: string;
    hideDate?: boolean;
}

// eslint-disable-next-line complexity
export const HeroArticle: FC<Props> = ({
    contentType,
    title,
    author,
    date,
    machineDate,
    linkText = "Read more",
    image,
    backgroundImage,
    className,
    path,
    hideDate,
    ...rest
}) => (
    <Link
        to={path ?? ""}
        className={cx(styles.component, className)}
        {...rest}
        aria-label={`${contentType}: ${title}`}
    >
        <div className={styles.background}>
            {backgroundImage && (
                <Image
                    // A source element that has a following sibling source element or img element with a srcset attribute must have a media attribute and/or type attribute.
                    fluid={{
                        ...backgroundImage,
                        media: "(max-width: 1600px)"
                    }}
                    loading="eager"
                    className={styles.backgroundImage}
                />
            )}
            <div className={styles.filter}></div>
        </div>

        <div className={styles.content}>
            <div className={styles.textWrapper}>
                {contentType && <label>{contentType}</label>}

                {title && <h1>{title}</h1>}
                <div className={styles.bottom}>
                    <div>
                        {author && <p>{author}</p>}
                        {!hideDate && date && (
                            <time dateTime={machineDate}>{date}</time>
                        )}
                    </div>
                    <div className={styles.openLink}>
                        <span>{linkText}</span>
                        <IconSprite
                            className={styles.arrowIcon}
                            name="long-arrow"
                        />
                    </div>
                </div>
            </div>
            {image && (
                <Image
                    className={styles.image}
                    loading="eager"
                    // A source element that has a following sibling source element or img element with a srcset attribute must have a media attribute and/or type attribute.
                    fluid={{
                        ...image,
                        media: "(max-width: 568px)"
                    }}
                />
            )}
        </div>
    </Link>
);

export default HeroArticle;
