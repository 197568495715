import { graphql, PageProps } from "gatsby";
import React, { useMemo } from "react";
import ArticleLayout, {
    Body,
    Main,
    Sidebar
} from "../../components/ArticleLayout/ArticleLayout";
import { BodyText } from "../../components/BodyText";
import ContentTemplate from "../../components/ContentTemplate";
import { HeroImage } from "../../components/HeroImage";
import { InPageNavigationContainer } from "../../components/InPageNavigation";
import { Layout } from "../../components/Layout";
import { MainImageCredit } from "../../components/MainImageCredit";
import { SEO } from "../../components/SEO";
import TitleBlock from "../../components/TitleBlock";
import { stripHtml } from "../../lib/stringUtils";
import { BreadCrumbs } from "../../types/breadcrumbs";
import * as styles from "./TopicRegion.module.scss";
import { UpcomingEventContextProvider } from "../ResearchGroup/UpcomingEvent";
import { ContentTypeName, getFacetName } from "../../lib/dynamicContentHelpers";

interface Props extends PageProps {
    data: GatsbyTypes.TopicRegionQuery;
    pageContext: {
        id: string;
        breadcrumb: BreadCrumbs;
    };
}

// eslint-disable-next-line complexity
export const TopicRegionTemplate: React.FC<Props> = ({
    data: { topicRegion },
    location,
    pageContext
}) => {
    const contentType = topicRegion?.relationships?.field_content_type?.name;

    const facetName = useMemo(
        () => getFacetName((contentType as ContentTypeName) || "Topics") || "",
        [contentType]
    );

    if (!topicRegion) return <Layout />;

    const text = topicRegion.body?.processed || topicRegion.body?.value || "";
    const breadcrumb = pageContext.breadcrumb.crumbs;

    const title = topicRegion.title ?? "";

    const paragraphData = topicRegion.relationships?.field_sections;

    const summary = stripHtml(topicRegion.field_abstract?.processed ?? "");
    const contentTypeUrl =
        topicRegion.relationships?.field_content_type?.path?.alias;

    const mainImage =
        topicRegion?.relationships?.field_media_image?.relationships
            ?.field_media_image?.childImageKitAsset?.fluid;
    const mainImageCredit =
        topicRegion.relationships?.field_media_image?.field_credit;
    const mainImageCreditLink =
        topicRegion.relationships?.field_media_image?.field_credit_link;

    // Metadata
    const metaDescription = topicRegion.field_abstract?.value;
    const metaImage = mainImage?.src;
    const signpostImage =
        topicRegion?.relationships?.field_signpost_image?.relationships
            ?.field_media_image?.childImageKitAsset?.fixed.src;

    const titleBlockProps = {
        title,
        summary,
        contentType,
        contentTypeUrl,
        breadcrumb,
        url: location.href
    };

    return (
        <UpcomingEventContextProvider
            value={{
                facet: facetName,
                value: title
            }}
        >
            <Layout>
                <SEO
                    title={topicRegion.title}
                    description={metaDescription && stripHtml(metaDescription)}
                    image={signpostImage || metaImage}
                />

                <ArticleLayout className={styles.main}>
                    <div>
                        <TitleBlock variant="topic" {...titleBlockProps} />
                        <hr className={styles.noMarginBottom} />
                        <InPageNavigationContainer
                            className={styles.inPageNav}
                        />

                        <hr className={styles.mobileOnly} />

                        <HeroImage
                            fluid={mainImage}
                            className={styles.heroImage}
                        />
                        <hr />
                        {mainImageCredit && (
                            <>
                                <MainImageCredit
                                    className={styles.mobileOnly}
                                    text={mainImageCredit}
                                    link={mainImageCreditLink}
                                />
                                <hr className={styles.mobileOnly} />
                            </>
                        )}
                    </div>

                    <Body>
                        <Main>
                            <BodyText html={text} />
                        </Main>
                        <Sidebar>
                            {mainImageCredit && (
                                <MainImageCredit
                                    text={mainImageCredit ?? ""}
                                    link={mainImageCreditLink}
                                    className={styles.desktopOnly}
                                />
                            )}
                        </Sidebar>
                    </Body>

                    <ContentTemplate
                        sections={paragraphData}
                        contentType={contentType}
                        contentTitle={title}
                        lastSection
                    />
                </ArticleLayout>
            </Layout>
        </UpcomingEventContextProvider>
    );
};

export default TopicRegionTemplate;

export const pageQuery = graphql`
    query TopicRegion($id: String!, $title: String, $contentType: String) {
        topicRegion: nodeTopicRegionPage(id: { eq: $id }) {
            id
            path {
                alias
            }
            title
            field_abstract {
                processed
                value
            }
            body {
                processed
                value
            }
            relationships {
                field_sections {
                    id
                    field_paragraph_container_style
                    field_background
                    fields: relationships {
                        sections: field_content_paragr {
                            ...FieldSectionsTopicRegion
                        }
                    }
                }
                field_content_type {
                    name
                    path {
                        alias
                    }
                }
                field_media_image {
                    field_credit
                    field_credit_link
                    relationships {
                        field_media_image {
                            childImageKitAsset {
                                fluid(maxWidth: 1200) {
                                    ...ImageKitFluid
                                }
                            }
                        }
                    }
                }
                field_signpost_image {
                    relationships {
                        field_media_image {
                            childImageKitAsset {
                                fixed(width: 1200, transformations: ["h-630"]) {
                                    ...ImageKitFixed
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
