// extracted by mini-css-extract-plugin
export var actionName = "StrategicAction-module--actionName--472a4";
export var actionNum = "StrategicAction-module--actionNum--7153e";
export var closed = "StrategicAction-module--closed--deb11";
export var completed = "StrategicAction-module--completed--23fd8";
export var content = "StrategicAction-module--content--3b136";
export var fadePop = "StrategicAction-module--fade-pop--25b11";
export var growHeightIn = "StrategicAction-module--grow-height-in--f9261";
export var icon = "StrategicAction-module--icon--066d6";
export var inProgress = "StrategicAction-module--inProgress--b236d";
export var inactive = "StrategicAction-module--inactive--356c5";
export var lastUpdated = "StrategicAction-module--lastUpdated--9004e";
export var noDueDate = "StrategicAction-module--noDueDate--57c9c";
export var open = "StrategicAction-module--open--55624";
export var overdue = "StrategicAction-module--overdue--73f9f";
export var statusMark = "StrategicAction-module--statusMark--0b5e2";
export var statusName = "StrategicAction-module--statusName--0711a";
export var titleArea = "StrategicAction-module--titleArea--7cedf";
export var toggleButton = "StrategicAction-module--toggleButton--6f85e";