import React from "react";
import Image, { FluidObject } from "gatsby-image";
import * as styles from "./LandingPageTitleBlock.module.scss";

interface Props {
    title: string;
    subtitle: string;
    image: FluidObject;
}

export const LandingPageTitleBlock: React.FC<Props> = ({
    title,
    subtitle,
    image
}) => (
    <div className={styles.component}>
        <div className={styles.background}>
            <Image fluid={image} className={styles.image} />
        </div>
        <div className={styles.filter}>
            <div className={styles.content}>
                <h1 className={styles.title}>{title}</h1>
                <p className={styles.subtitle}>{subtitle}</p>
            </div>
        </div>
    </div>
);
