// extracted by mini-css-extract-plugin
export var background = "Section-module--background--2e83c";
export var closingRule = "Section-module--closingRule--c3238";
export var component = "Section-module--component--3e294";
export var content = "Section-module--content--39ff8";
export var fadePop = "Section-module--fade-pop--63f4b";
export var firstSection = "Section-module--firstSection--5f9c4";
export var grey = "Section-module--grey--6c12c";
export var growHeightIn = "Section-module--grow-height-in--4fa26";
export var hideBorderTop = "Section-module--hideBorderTop--6e2e5";
export var landingPageSpacing = "Section-module--landingPageSpacing--f42d7";
export var leftaligned = "Section-module--leftaligned--804a8";
export var white = "Section-module--white--7c0d5";
export var withClosingRule = "Section-module--withClosingRule--973f1";