import React, { FC, HTMLAttributes } from "react";
import { graphql } from "gatsby";
import { ContentGate } from "../../components/ContentGate";
import { Link } from "../../components/Link";
import { Button } from "../../components/Button";
import DangerousHTML from "../../components/DangerousHTML";
import * as styles from "./MemberPromoCtaBlockParagraph.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    node?: GatsbyTypes.MemberPromoCtaBlockParagraphDataFragment;
}

export const MemberPromoCtaBlockParagraph: FC<Props> = ({ node, ...rest }) => {
    if (!node) {
        return null;
    }

    const {
        field_title,
        field_join_now_button,
        field_list_items,
        field_text
    } = node;

    return (
        <ContentGate hideIcon topbarText="become a member" {...rest}>
            <div className={styles.content}>
                <h2>{field_title}</h2>
                {field_text?.processed && (
                    <DangerousHTML html={field_text.processed} />
                )}

                <hr className={styles.desktopOnly} />
                <div className={styles.bottom}>
                    {!!field_list_items?.length && (
                        <ul>
                            {field_list_items.map((item, index) => (
                                <li key={`${item}-${index}`}>{item}</li>
                            ))}
                        </ul>
                    )}
                    <hr className={styles.mobileOnly} />
                    <div className={styles.buttonLoginWrapper}>
                        {field_join_now_button?.url && (
                            <Link
                                to={field_join_now_button?.url}
                                className={styles.button}
                            >
                                {field_join_now_button?.title}
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </ContentGate>
    );
};

export default MemberPromoCtaBlockParagraph;

export const fragment = graphql`
    fragment MemberPromoCtaBlockParagraphData on paragraph__membership_cta_block {
        id
        field_title
        field_join_now_button {
            title
            url
        }
        field_list_items
        field_text {
            processed
        }
    }
`;
