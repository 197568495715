import React, { FC } from "react";
import cx from "classnames";
import * as styles from "./RelatedEventRowDynamic.module.scss";
import { TitleForDynamicParagraphs } from "../TitleForDynamicParagraphs";
import { Link } from "../Link";

interface Props {
    heading?: string;
    linkTitle?: string;
    linkUrl?: string;
    className?: string;
}

export const RelatedEventRowDynamic: FC<Props> = ({
    className,
    heading,
    linkTitle,
    linkUrl,
    children,
    ...rest
}) => (
    <section
        className={cx(styles.component, className, "hideOnPrint")}
        {...rest}
    >
        <TitleForDynamicParagraphs>
            {heading && <h2>{heading}</h2>}
            {linkTitle && linkUrl && <Link to={linkUrl}>{linkTitle}</Link>}
        </TitleForDynamicParagraphs>
        {children}
    </section>
);

export default RelatedEventRowDynamic;
