import React, { FC, HTMLAttributes } from "react";
import { graphql } from "gatsby";
import { TabbedSection } from "../../components/TabbedSection/TabbedSection";
import { Body, Sidebar } from "../../components/ArticleLayout/ArticleLayout";
import Paragraphs from "../../components/Paragraphs";
import { RegisterCta } from "../../components/RegisterCta/RegisterCta";
import DangerousHTML from "../../components/DangerousHTML";
import * as styles from "./TabsContainer.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    node?: GatsbyTypes.TabsContainerFragment;
    hideUnderline?: boolean;
}

interface BodyElProps extends HTMLAttributes<HTMLElement> {
    useBody: boolean;
}

const BodyEl: FC<BodyElProps> = ({ useBody, children }) =>
    useBody ? <Body noPaddingOnMobile>{children}</Body> : <> {children}</>;

export const TabsContainer: FC<Props> = ({ node, hideUnderline = false }) => {
    if (!node) {
        return null;
    }

    const { field_title: mainTitle, relationships } = node;

    const { field_tabs_container_tabs: tabs } = relationships;

    return (
        <section>
            <TabbedSection title={mainTitle}>
                {tabs.map(tab => {
                    if (!tab) return null;

                    const {
                        field_tab_content_title: contentTitle,
                        field_title: tabTitle,
                        relationships: tabRelationships
                    } = tab;

                    const { field_tab_secondary_content } = tabRelationships;

                    return (
                        <div key={tabTitle}>
                            <BodyEl
                                useBody={!!field_tab_secondary_content?.length}
                            >
                                <div>
                                    {contentTitle && <h2>{contentTitle}</h2>}

                                    <Paragraphs
                                        data={
                                            tabRelationships.field_tab_content
                                        }
                                        backgroundColor="grey"
                                    />
                                </div>
                                <Sidebar hideOnMobile={false}>
                                    {!!field_tab_secondary_content?.length &&
                                        field_tab_secondary_content.map(
                                            secondaryContent => (
                                                <RegisterCta
                                                    key={secondaryContent.id}
                                                    backgroundColor="white"
                                                    heading={
                                                        secondaryContent.field_membership_cta_title
                                                    }
                                                    variant={
                                                        secondaryContent.field_membership_cta_is_primary
                                                            ? "button"
                                                            : "link"
                                                    }
                                                    buttonText={
                                                        secondaryContent
                                                            .field_membership_cta_link
                                                            .title
                                                    }
                                                    url={
                                                        secondaryContent
                                                            .field_membership_cta_link
                                                            .uri
                                                    }
                                                    description={
                                                        secondaryContent.field_membership_cta_description
                                                    }
                                                >
                                                    <DangerousHTML
                                                        html={
                                                            secondaryContent
                                                                .field_membership_cta_price
                                                                .processed
                                                        }
                                                    />
                                                </RegisterCta>
                                            )
                                        )}
                                </Sidebar>
                            </BodyEl>

                            {hideUnderline ? (
                                <div className={styles.noUnderline} />
                            ) : (
                                <hr className={styles.underline} />
                            )}
                        </div>
                    );
                })}
            </TabbedSection>
        </section>
    );
};

export default TabsContainer;

export const fragment = graphql`
    fragment TabsContainer on paragraph__tabs_container {
        id
        field_title
        relationships {
            field_tabs_container_tabs {
                id
                field_tab_content_title
                field_title
                relationships {
                    # TODO: Check this covers all paragraph types
                    field_tab_content {
                        __typename
                        ...PullQuoteParagraphData
                        ...TextParagraphData
                        ...SignpostsWithSubSectionLinks
                        ...SignpostParagraphData
                        ...InSectionSearch
                    }

                    field_tab_secondary_content {
                        id
                        field_membership_cta_description
                        field_membership_cta_is_primary
                        field_membership_cta_link {
                            title
                            uri: url
                        }
                        field_membership_cta_price {
                            processed
                        }
                        field_membership_cta_title
                    }
                }
            }
        }
    }
`;
