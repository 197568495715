import { graphql } from "gatsby";
import React, { FC, HTMLAttributes } from "react";

import { LinkSet, LinkSetItem } from "../LinkSet";

interface Props extends HTMLAttributes<HTMLElement> {
    node?: GatsbyTypes.ButtonLabelLinksParagraphDataFragment;
    containerStyle?: string;
}

export const ButtonLabelLinksParagraph: FC<Props> = ({ node, ...rest }) => {
    if (!node?.field_button_label_links?.length) return null;

    const {
        field_button_label_links: links,
        field_button_label_links_heading: heading
    } = node;

    return (
        <LinkSet heading={heading} {...rest}>
            {links.map((link, index) => (
                <LinkSetItem
                    key={`${node.id}-${index}`}
                    url={link?.absoluteUrl}
                    label={link?.title}
                />
            ))}
        </LinkSet>
    );
};

export const fragment = graphql`
    fragment ButtonLabelLinksParagraphData on paragraph__button_label_links {
        id
        field_button_label_links_heading
        field_button_label_links {
            absoluteUrl
            title
        }
    }
`;

export default ButtonLabelLinksParagraph;
