import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";

import { ProgressChart } from "../../components/ProgressChart";
import ArticleLayout from "../ArticleLayout/ArticleLayout";
import { Button } from "../Button";
import EcpMiniNavigation from "../EcpMiniNavigation";
import { EcpPeople } from "../EcpPeople";
import Layout from "../Layout";
import Section from "../Section";

import { LinkOrSpan } from "../LinkOrSpan";
import { NewsletterSignup } from "../NewsletterSignup";
import { StrategicPriority } from "../StrategicPriority";
import { TimeRemaining } from "../TimeRemaining";
import * as styles from "./EcpPageComponent.module.scss";
import SEO from "../SEO";
import SocialShareBlock from "../SocialShareBlock";
import { useLocation } from "@reach/router";

interface Props extends HTMLAttributes<HTMLElement> {
    node: GatsbyTypes.EcpQuery["ecp"];
}

// Just so that ecp pages work with new NewsLetterSignup, a less hacky solution will be necessary once we know whats happening with designs for those pages
export const ecpNewsletterSignupData = {
    field_button_link_text: "Sign up",
    field_mailchimp_url:
        "https://rusi.us17.list-manage.com/subscribe?u=722b72d453b7a1a6e69ceef05&id=431faeeb90",
    field_subtext: "Sign up for the CFCS newsletter",
    field_title: "CFCS Newsletter"
};

// eslint-disable-next-line complexity
export const EcpPageComponent: FC<Props> = ({ node, className, ...props }) => {
    const { href } = useLocation();

    return (
        <Layout>
            <SEO title={node?.title} description={node?.body?.summary} />
            <ArticleLayout>
                <div className={cx(styles.component, className)} {...props}>
                    <EcpMiniNavigation />
                    <section className={styles.overview}>
                        <h1 className={styles.title}>{node?.title}</h1>
                        <div className={styles.summary}>
                            <div className={styles.descriptionWrapper}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: node?.body?.processed ?? ""
                                    }}
                                ></div>
                                <LinkOrSpan url={node?.field_link?.url}>
                                    <Button
                                        className={styles.button}
                                        variant="tertiary"
                                    >
                                        {node?.field_link?.title ??
                                            "Tracker methodology"}
                                    </Button>
                                </LinkOrSpan>
                            </div>
                            <SocialShareBlock url={href} />
                        </div>
                        <div className={styles.statusTracker}>
                            <ProgressChart
                                progress={{
                                    completed: node?.field_ecp_completed ?? 0,
                                    inProgress:
                                        node?.field_ecp_in_progress ?? 0,
                                    overdue: node?.field_ecp_overdue ?? 0,
                                    noDueDate: node?.field_ecp_no_due_date ?? 0,
                                    inactive: node?.field_ecp_inactive ?? 0
                                }}
                            />
                        </div>
                    </section>
                    <Section layout="fullwidth" background="grey">
                        <section className={styles.strategicPrioritiesSection}>
                            <TimeRemaining
                                className={styles.timeRemaining}
                                countdownTo={parseInt(
                                    node?.field_end_date ?? "0"
                                )}
                            />
                            <div className={styles.strategicPrioritiesMobileBg}>
                                <div
                                    className={
                                        styles.strategicPrioritiesWrapper
                                    }
                                >
                                    <h2>Strategic Priorities</h2>
                                    <div className={styles.strategicPriorities}>
                                        {node?.relationships
                                            ?.field_ecp_groups &&
                                            node?.relationships?.field_ecp_groups.map(
                                                (item, index) => (
                                                    <StrategicPriority
                                                        key={item?.id ?? index}
                                                        data={item}
                                                    />
                                                )
                                            )}
                                    </div>
                                    <LinkOrSpan url="/ecp/methodology">
                                        <Button>View methodology</Button>
                                    </LinkOrSpan>
                                </div>
                            </div>
                        </section>
                    </Section>
                    <Section layout="fullwidth">
                        <EcpPeople data={node?.relationships?.field_sections} />
                    </Section>
                    <Section layout="leftaligned">
                        <div
                            className={styles.aboutCfcs}
                            dangerouslySetInnerHTML={{
                                __html:
                                    node?.field_additional_text_area
                                        ?.processed || ""
                            }}
                        />
                    </Section>
                    <NewsletterSignup
                        title={ecpNewsletterSignupData.field_title}
                        text={ecpNewsletterSignupData.field_subtext}
                        url={ecpNewsletterSignupData.field_mailchimp_url}
                        variant="paragraph"
                    />
                </div>
            </ArticleLayout>
        </Layout>
    );
};

export default EcpPageComponent;
