import cx from "classnames";
import React, { FC, HTMLAttributes, useMemo } from "react";

import * as styles from "./ProgressChart.module.scss";
import coinBlue from "./coin_blue.png";
import coinGreen from "./coin_green.png";
import coinGrey from "./coin_grey.png";
import coinOrange from "./coin_orange.png";
import coinRed from "./coin_red.png";
// import coinStack from "./coin_stack.png";

type ProgressCount = {
    [key: string]: number | null;
};

type ProgressPercentage = {
    [key: string]: number;
};

type ProgressType =
    | "completed"
    | "inProgress"
    | "overdue"
    | "noDueDate"
    | "inactive";

type ProgressLookup = {
    [key in ProgressType]: string;
};

interface Props extends HTMLAttributes<HTMLElement> {
    progress?: ProgressCount;
}

const progressLookup: ProgressLookup = {
    completed: "Completed",
    inProgress: "In progress",
    overdue: "Overdue",
    noDueDate: "No due date",
    inactive: "Inactive"
};

const renderLabel = (progressCategory: ProgressType, value: number) => (
    <div className={cx(styles.labelContainer)}>
        <div className={cx(styles.rowLabel, styles[progressCategory])}>
            <p>{progressLookup[progressCategory]}</p>
        </div>
        <p className={cx(styles.percentageText)}>{value}%</p>
    </div>
);

// Need to be this verbose as src can not be dynamic for locally served images
const coinImageSelection = (progressCategory: ProgressType) => {
    switch (progressCategory) {
        case "completed":
            return (
                <img className={cx(styles.coin)} src={coinGreen} alt="Coin" />
            );
        case "overdue":
            return <img className={cx(styles.coin)} src={coinRed} alt="Coin" />;
        case "noDueDate":
            return (
                <img className={cx(styles.coin)} src={coinBlue} alt="Coin" />
            );
        case "inactive":
            return (
                <img className={cx(styles.coin)} src={coinGrey} alt="Coin" />
            );
        case "inProgress":
            return (
                <img className={cx(styles.coin)} src={coinOrange} alt="Coin" />
            );
        default:
            return (
                <img className={cx(styles.coin)} src={coinGrey} alt="Coin" />
            );
    }
};

const renderCoins = (progressCategory: ProgressType, value: number) => {
    const numberOfCoins = Math.ceil(value / 2) || 1;
    const coins = new Array(numberOfCoins).fill(0);

    return coins.map((item, index) => (
        <div key={`coin-${index}`} className={cx(styles.coinColumn)}>
            <div className={cx(styles.coinWrapper)}>
                {coinImageSelection(progressCategory)}
                {index + 1 === numberOfCoins && (
                    <div className={cx(styles.desktopDisplay)}>
                        {renderLabel(progressCategory, value)}
                    </div>
                )}
            </div>
        </div>
    ));
};

const renderRow = (progressCategory: ProgressType, value: number) => (
    <div key={`row-${progressCategory}`} className={cx(styles.row)}>
        <div className={cx(styles.mobileDisplay)}>
            {renderLabel(progressCategory, value)}
        </div>
        <div className={cx(styles.rowContainer)}>
            <img
                className={cx(styles.backgroundCoins)}
                src={"/images/coin_stack.png"}
                alt="Coin stack"
            />
            <div className={cx(styles.offsetRow)}>
                <div className={cx(styles.rowWrapper)}>
                    <div
                        className={cx(styles.coinRow)}
                        style={{ width: `${Math.ceil(value)}%` }}
                        key={`progress-chart-${progressCategory}`}
                    >
                        {renderCoins(progressCategory, value)}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const renderRows = (progress: ProgressPercentage) => {
    const rows = [];
    for (const property in progress) {
        const value: number = progress[property];
        const progressCategory = property as ProgressType;
        rows.push(renderRow(progressCategory, value));
    }
    return rows;
};

export const ProgressChart: FC<Props> = ({ className, progress, ...props }) => {
    const percentages = useMemo(() => {
        const calculatePercentage = (total: number, value: number | null) =>
            value ? parseInt(((value / total) * 100).toFixed(1)) : 0;
        const total = progress
            ? Object.values(progress).reduce(
                  (a: number | null, b: number | null) => (a || 0) + (b || 0),
                  0
              ) || 0
            : 0;

        return {
            completed: calculatePercentage(total, progress?.completed),
            inProgress: calculatePercentage(total, progress?.inProgress),
            overdue: calculatePercentage(total, progress?.overdue),
            noDueDate: calculatePercentage(total, progress?.noDueDate),
            inactive: calculatePercentage(total, progress?.inactive)
        };
    }, [progress]);

    return (
        <section
            className={cx(styles.progressChartContainer, className)}
            {...props}
        >
            <h4 className={cx(styles.header)}>Progress Completion</h4>
            {progress && (
                <div className={styles.progressChart}>
                    {renderRows(percentages)}
                </div>
            )}
        </section>
    );
};
export default ProgressChart;
