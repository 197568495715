import React, { FC, HTMLAttributes } from "react";
import { graphql } from "gatsby";

import { RelatedContentRow } from "../RelatedContentRow";
import { ItemType } from "../RelatedContentRow/RelatedContentRow";

interface Props extends HTMLAttributes<HTMLElement> {
    node?: GatsbyTypes.paragraph__related_projects_grid_row;
}

export const RelatedProjectsParagraph: FC<Props> = ({ node, ...props }) => {
    const items = node?.relationships?.field_content;

    if (!items?.length) {
        return null;
    }

    return (
        <RelatedContentRow
            items={(items as unknown) as ItemType[]}
            heading={node?.field_title}
            {...props}
        />
    );
};

// Exporting a fragment allows it to be used in any GraphQL query

export const fragment = graphql`
    fragment RelatedProjectsParagraphData on paragraph__related_projects_grid_row {
        id
        field_title
        relationships {
            field_content {
                id
                title
                field_closed
                path {
                    alias
                }
                relationships {
                    field_media_image {
                        relationships {
                            field_media_image {
                                childImageKitAsset {
                                    fluid(
                                        maxWidth: 330
                                        transformations: ["ar-330-216"]
                                    ) {
                                        ...ImageKitFluid
                                    }
                                }
                            }
                        }
                    }
                    field_content_type {
                        name
                        path {
                            alias
                        }
                    }
                }
            }
        }
    }
`;

export default RelatedProjectsParagraph;
