import cx from "classnames";
import React, { FC, HTMLAttributes, useRef, useEffect } from "react";

import { scrollToRef } from "../../lib/domUtils";
import { ecpPageQuery_nodeEcp_relationships_field_ecp_groups_relationships_field_ecp_items } from "../../pages/__generated__/ecpPageQuery";
import { IconSprite } from "../IconSprite";
import { progressClasses } from "../StrategicPriority/StrategicPriority";
import * as styles from "./StrategicAction.module.scss";

type ItemsType = ecpPageQuery_nodeEcp_relationships_field_ecp_groups_relationships_field_ecp_items;
interface Props extends HTMLAttributes<HTMLElement> {
    data?: ItemsType;
    openState: string;
    setOpenState: (openState: string) => void;
}

type ClassStr = "open" | "closed";

const statusText = [
    "",
    "Completed",
    "In progress",
    "Overdue",
    "No due date",
    "Inactive"
];

const getOpenClass = (openState: string, id: string): ClassStr =>
    openState === id ? "open" : "closed";

const formattedDate = (dateStr: string, month = "2-digit"): string => {
    const date = new Date(dateStr);

    return date.toLocaleDateString(undefined, {
        day: "2-digit",
        month,
        year: "numeric"
    });
};

const processedHtmlSection = (headingStr: string, html?: string | null) =>
    html ? (
        <>
            <h5>{headingStr}:</h5>
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </>
    ) : null;

const contentMarkup = ({
    field_overview,
    field_responsible_organisations,
    field_progress,
    field_ecp_due_date,
    field_last_updated
}: ItemsType) => (
    <div>
        {field_ecp_due_date && (
            <>
                <h5>
                    Due: <span>{field_ecp_due_date}</span>
                </h5>
            </>
        )}

        {processedHtmlSection("Overview", field_overview?.processed)}
        {processedHtmlSection("Progress", field_progress?.processed)}
        {processedHtmlSection(
            "Responsible Organisations",
            field_responsible_organisations?.processed
        )}

        <p className={styles.lastUpdated}>
            Last updated: {formattedDate(field_last_updated, "long")}
        </p>
    </div>
);

export const StrategicAction: FC<Props> = ({
    className,
    data,
    openState,
    setOpenState,
    ...props
}) => {
    const mainRef = useRef(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const transitionCallback = () => {
        scrollToRef(mainRef);
    };

    useEffect(() => {
        const el = contentRef.current;
        el?.addEventListener("transitionend", transitionCallback);

        return () => {
            el?.removeEventListener("transitionend", transitionCallback);
        };
    }, [contentRef]);

    if (!data) {
        return null;
    }

    const { id, field_ecp_title, field_action_number, field_status } = data;
    const setOpen = () => {
        const newVal = openState === id ? "open" : id;
        setOpenState(newVal);
    };

    const openClass = getOpenClass(openState, id);
    const progressClass = progressClasses[field_status || 0];

    return (
        <div ref={mainRef} className={className} {...props}>
            <div className={styles.titleArea}>
                <button
                    className={cx(styles.toggleButton, styles[openClass])}
                    onClick={setOpen}
                ></button>
                <div>
                    <span
                        className={styles.actionNum}
                    >{`action ${field_action_number}`}</span>
                    <h6 className={styles.actionName}>{field_ecp_title}</h6>
                </div>

                <span className={cx(styles[progressClass], styles.statusName)}>
                    {statusText[field_status || 0]}
                </span>
                <div className={cx(styles[progressClass], styles.statusMark)}>
                    <IconSprite
                        className={styles.icon}
                        name={progressClass}
                        width={20}
                        height={20}
                    />
                </div>
            </div>
            <div
                ref={contentRef}
                className={cx(styles.content, styles[openClass])}
            >
                {openClass === "open" && contentMarkup(data)}
            </div>
        </div>
    );
};

export default StrategicAction;
