import cx from "classnames";

import Image, { FluidObject } from "gatsby-image";
import React, { FC } from "react";

import { Link } from "../Link";
import { IconSprite } from "../IconSprite";
import { LinkOrSpan } from "../LinkOrSpan";
import * as styles from "./RelatedArticle.module.scss";
import { truncateStringAtSpace } from "../../lib/stringUtils";

interface Props {
    heading: string | null;
    url?: string | null;
    summary?: string | null;
    image?: FluidObject | null;
    publicationName?: string | null;
    publicationLink?: string | null;
    restrictedString?: string | null;
    date?: string | null;
    machineDate?: string | null;
    readTime?: string | null;
    variant?: "whiteBg" | "greyBg";
    searchImage?: string | null;
    className?: string;
    closed?: boolean;
}

// eslint-disable-next-line complexity
export const RelatedArticle: FC<Props> = ({
    heading,
    url,
    summary,
    image,
    closed,
    publicationName,
    publicationLink,
    restrictedString,
    date,
    machineDate,
    readTime,
    searchImage,
    className,
    variant = "greyBg",
    ...props
}) => {
    return (
        <div
            {...props}
            className={cx(
                styles.component,
                className,
                styles[variant],
                styles[image || searchImage ? "withImage" : "noImage"]
            )}
        >
            <Link
                to={url ?? ""}
                className={styles.mainLink}
                aria-label={`${publicationName}: ${heading}`}
            />
            <div className={styles.background}>
                <div className={styles.imageTitleWrapper}>
                    {!!(image || searchImage) && (
                        <figure className={styles.imageWrapper}>
                            {image && (
                                <Image
                                    className={styles.image}
                                    alt={heading || ""}
                                    // A source element that has a following sibling source element or img element with a srcset attribute must have a media attribute and/or type attribute.
                                    fluid={{
                                        ...image,
                                        media: "(max-width: 512px)"
                                    }}
                                />
                            )}
                            {!image && searchImage && (
                                <div
                                    className={styles.searchImage}
                                    style={{
                                        backgroundImage: `url(${searchImage})`
                                    }}
                                />
                            )}
                        </figure>
                    )}
                    <div className={styles.titleContent}>
                        <div className={cx(styles.flex, styles.labels)}>
                            {publicationName && (
                                <LinkOrSpan url={publicationLink} tabIndex={-1}>
                                    {publicationName}
                                </LinkOrSpan>
                            )}
                            {restrictedString && (
                                <small>{restrictedString}</small>
                            )}
                            {closed && <label>Closed</label>}
                        </div>
                        <h3>
                            {truncateStringAtSpace(heading || "", 80, "...")}
                        </h3>
                        {summary && <p>{summary}</p>}
                    </div>
                </div>
                <div className={cx(styles.flex, styles.bottom)}>
                    {date && machineDate && (
                        <time dateTime={machineDate}>{date}</time>
                    )}
                    {readTime && (
                        <span className={styles.readTime}>
                            <IconSprite name="clock" />
                            <b>{readTime}</b>
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};
export default RelatedArticle;
