import React from "react";
import * as styles from "./EcpMiniNavigation.module.scss";
import { TagLink } from "../TagLink";

export const EcpMiniNavigation: React.FC = () => {
    return (
        <div className={styles.component}>
            <div className={styles.links}>
                <TagLink label={"Online Tracker"} url="/ecp" />
                <span />
                <TagLink label={"Methodology"} url="/ecp/methodology" />
            </div>
        </div>
    );
};
