import { graphql } from "gatsby";
import React from "react";
import ArticleLayout, {
    Body,
    Main,
    Sidebar
} from "../components/ArticleLayout/ArticleLayout";
import ContentTemplate from "../components/ContentTemplate";

import Layout from "../components/Layout";
import Paragraphs, { ParagraphNode } from "../components/Paragraphs";
import SEO from "../components/SEO";
import TitleBlock from "../components/TitleBlock";

interface Props {
    data: GatsbyTypes.ErrorPageQuery;
}

const Page: React.FC<Props> = ({ data: { page } }) => {
    if (!page) return null;

    const { title = "", relationships, field_abstract } = page;

    return (
        <Layout>
            <SEO title={title} />
            <ArticleLayout>
                <TitleBlock
                    title={title}
                    variant="secondary-landing"
                    summary={field_abstract?.value}
                />
                <Body>
                    <Main>
                        <Paragraphs
                            data={
                                relationships?.field_main_content as ParagraphNode
                            }
                        />
                    </Main>
                    <Sidebar>
                        <Paragraphs
                            data={
                                relationships?.field_secondary_content as ParagraphNode
                            }
                        />
                    </Sidebar>
                </Body>
                <ContentTemplate
                    sections={relationships?.field_sections as ParagraphNode}
                    lastSection
                />
            </ArticleLayout>
        </Layout>
    );
};

export default Page;

export const query = graphql`
    query ErrorPage {
        page: nodeBasicPage(path: { alias: { eq: "/404" } }) {
            title
            field_abstract {
                value
            }
            body {
                summary
                processed
                value
            }
            relationships {
                field_main_content {
                    ...FieldMainContent
                }
                field_secondary_content {
                    ...FieldSecondaryContent
                }
                field_sections {
                    id
                    field_paragraph_container_style
                    field_background
                    fields: relationships {
                        sections: field_content_paragr {
                            ...FieldSections
                        }
                    }
                }
            }
        }
    }
`;
