import { PageProps, graphql } from "gatsby";
import React from "react";

import { Article } from "../../components/Article";
import Layout from "../../components/Layout";
import { SEO } from "../../components/SEO";
import { stripHtml } from "../../lib/stringUtils";
import { BreadCrumbs } from "../../types/breadcrumbs";

interface Props extends PageProps {
    data: GatsbyTypes.ArticleQuery;
    pageContext: {
        id: string;
        breadcrumb: BreadCrumbs;
    };
}

export const ArticleTemplate: React.FC<Props> = ({
    data,
    pageContext,
    location
}) => {
    const title = data.article?.title || "RUSI";
    const abstract = data.article?.field_abstract?.value;
    const breadcrumb = pageContext.breadcrumb?.crumbs;

    // Metatags
    const metaDescription = abstract && stripHtml(abstract);
    const signpostImage =
        data.article?.relationships?.field_signpost_image?.relationships
            ?.field_media_image?.childImageKitAsset?.fixed.src;
    const bannerImage =
        data.article?.relationships?.field_media_image?.relationships
            ?.field_media_image?.childImageKitAsset?.fluid.src;



    const topics = data.article.relationships?.field_topics || [];
    const interestAreas = data.article.relationships?.field_interest_areas || [];
    const researchGroups = data.article.relationships?.field_research_groups || [];
    const networks = data.article.relationships?.field_networks || [];
    const regions = data.article.relationships?.field_region || [];
    const projects = data.article.relationships?.field_related_project || [];
    const author = data.article.relationships?.field_author || []


    const dataLayerObject = {};
    dataLayerObject.topics = topics.map(function(topic) { return topic.name; });
    dataLayerObject.researchGroups = researchGroups.map(function(researchGroup) { return researchGroup.name; });
    dataLayerObject.region = regions.map(function(region) { return region.name; });
    dataLayerObject.networks = networks.map(function(network) {  return network.name; });
    dataLayerObject.interestAreas = interestAreas.map(function(interest) { return interest.name; });
    dataLayerObject.projects = projects?.map(function(project) { return project.name; });
    dataLayerObject.authors = author?.map(function(author) { return `${author.field_first_names} ${author.title}`; });
    dataLayerObject.publishedDate = data.article.created;
    dataLayerObject.event = 'dataLayerArticlePush';
    dataLayerObject._clear = true;

    if (typeof window !== `undefined`){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataLayerObject);
    }

    return (
        <Layout>
            {data.article && (
                <>
                    <SEO
                        title={title}
                        image={signpostImage || bannerImage}
                        description={metaDescription}
                        url={data.article?.path?.alias}
                    />
                    <Article
                        article={data.article}
                        url={location.href}
                        breadcrumb={breadcrumb}
                    />
                </>
            )}
        </Layout>
    );
};

export default ArticleTemplate;

export const fragment = graphql`
    fragment ArticleData on node__article {
        __typename
        id
        path {
            alias
        }
        field_read_time
        title
        field_metatag {
            description
        }
        created: field_authored_date(formatString: "D MMMM Y")
        field_outside_author
        field_profile_descriptors
        field_premium
        field_abstract {
            value
            format
            processed
        }
        body {
            summary
            processed
            value
        }
        field_publication_issue
        field_publication_volume
        field_tandf_public

        relationships {
            field_media_enquiry_ {
                ...NodeMediaEnquiries
            }
            field_sections {
                ...FieldSections
            }
            field_content_type {
                name
                path {
                    alias
                }
            }
            field_author {
                ...PersonData
            }
            field_pdf {
                ...PdfData
            }
            # tags
            field_region {
                id
                name
                path {
                    alias
                }
            }
            field_research_groups {
                id
                name
                path {
                    alias
                }
            }
            field_topics {
                id
                name
                path {
                    alias
                }
            }
            field_related_project {
                id
                name
                path {
                    alias
                }
            }

            field_networks {
                id
                name
                path {
                    alias
                }
            }

            field_interest_areas {
                id
                name
                path {
                    alias
                }
            }

            field_media_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid(maxWidth: 1168) {
                                ...ImageKitFluid
                            }
                        }
                        relationships {
                            media__image {
                                field_credit
                                field_credit_link
                                field_media_image {
                                    alt
                                }
                            }
                        }
                    }
                }
            }

            field_signpost_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fixed(width: 1200, transformations: ["h-630"]) {
                                ...ImageKitFixed
                            }
                        }
                    }
                }
            }

            field_journal_signposts {
                __typename
                ...JournalIssueSignpostFromLibrary
                ...JournalIssueSignpostParagraphData
            }

            field_footnotes {
                ...FieldFootnote
            }
        }

        # Encrypted data
        fields {
            key
            encrypted_validate {
                ...EncryptedNode
            }
            encrypted_body {
                ...EncryptedNode
            }
            encrypted_taylor_and_francis_url {
                ...EncryptedNode
            }
        }
        encryptedSections {
            ...EncryptedNode
        }
        encryptedPdfs {
            ...EncryptedNode
        }
    }
`;
export const pageQuery = graphql`
    query Article($path: String!) {
        article: nodeArticle(path: { alias: { eq: $path } }) {
            ...ArticleData
        }
    }
`;
