export const chunk = <T extends unknown>(arr: T[], size: number): T[][] =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size)
    );

interface TitleObj {
    title: string;
}

const compareTitles = <T extends TitleObj>(a: T, b: T) => {
    if (a.title < b.title) {
        return -1;
    }
    if (a.title > b.title) {
        return 1;
    }
    return 0;
};

export const sortAlphabeticallyByTitle = <T extends TitleObj>(
    array: T[]
): T[] => array.sort(compareTitles);

export const existsInArray = (haystack: any[], needle: any) =>
    haystack.find(i => i === needle) !== undefined;

export const sortAlphabeticallyByPropName = (
    arr: Array<{ [key: string]: string }>,
    propName: string
) =>
    [...arr].sort((a, b) => {
        if (a[propName] < b[propName]) {
            return -1;
        }
        if (a[propName] > b[propName]) {
            return 1;
        }
        return 0;
    });
