/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import "focus-visible";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState, useMemo } from "react";
import { InPageNavProvider } from "../../context/InPageNav/InPageNavProvider";
import { isBrowserOutdated } from "../../lib/checkBrowserSupport";
import { shapeMenuNodes } from "../../lib/dataUtils";
import { useGatsbyImageFix } from "../../lib/useGatsbyImageFix";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { IconSheet } from "../IconSheet";
import { NewsletterSignup } from "../NewsletterSignup";
import * as styles from "./Layout.module.scss";
import { Node } from "./types";

const INIITIAL_HEADER_HEIGHT = 66;

export const Layout: React.FC = ({ children }) => {
    const data = useStaticQuery<GatsbyTypes.GlobalElementsQuery>(graphql`
        query GlobalElements {
            site {
                siteMetadata {
                    title
                }
            }
            menuItems: allMenuLinkContentMain(
                filter: { enabled: { eq: true } }
                sort: { order: ASC, fields: weight }
            ) {
                nodes {
                    id: drupal_id
                    drupal_parent_menu_item
                    external
                    title
                    field_additional_text
                    link {
                        uri: url
                    }
                }
            }
            researchMenuItems: allMenuLinkContentResearch(
                filter: { enabled: { eq: true } }
                sort: { order: ASC, fields: weight }
            ) {
                nodes {
                    id: drupal_id
                    title
                    external
                    drupal_parent_menu_item
                    link {
                        uri: url
                    }
                }
            }
            bottomMenuItems: allMenuLinkContentMenuFooterMenu(
                filter: { enabled: { eq: true } }
            ) {
                nodes {
                    id: drupal_id
                    external
                    title
                    link {
                        uri: url
                    }
                }
            }
            socialMenuItems: allMenuLinkContentFooterSocialMenu(
                filter: { enabled: { eq: true } }
            ) {
                nodes {
                    id: drupal_id
                    external
                    title
                    link {
                        uri: url
                    }
                }
            }
            exploreRusiMenuItems: allMenuLinkContentFooterListLinks1(
                filter: { enabled: { eq: true } }
            ) {
                nodes {
                    id: drupal_id
                    external
                    title
                    link {
                        uri: url
                    }
                }
            }
            popularLinksMenuItems: allMenuLinkContentFooterListLinks2(
                filter: { enabled: { eq: true } }
            ) {
                nodes {
                    id: drupal_id
                    external
                    title
                    link {
                        uri: url
                    }
                }
            }
            footerText: configPagesFooterText {
                field_copyright_statement
                field_statement
            }
            newsletterSignUpData: configPagesNewsletterSignUp {
                field_button_link_text
                field_mailchimp_url
                field_subtext
                field_title
            }
        }
    `);

    const [sticky, setSticky] = useState(false);
    const [showBrowserWarning, setShowBrowserWarning] = useState(false);
    const [headerHeight, setHeaderHeight] = useState(INIITIAL_HEADER_HEIGHT);
    useGatsbyImageFix();

    useEffect(() => {
        setShowBrowserWarning(isBrowserOutdated());

        const handleScroll = () => {
            const isSticky =
                document.body.scrollTop > 50 ||
                document.documentElement.scrollTop > 50;

            setSticky(isSticky);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const menuItems = useMemo(() => shapeMenuNodes([...data.menuItems.nodes]), [
        data.menuItems.nodes
    ]);

    return (
        <div>
            <IconSheet />

            <div className={styles.printheader}>
                <StaticImage
                    className={styles.rusilogo}
                    alt={""}
                    src={"../../images/rusi-primary-logo-single-purple.png"}
                />
            </div>
            <div className={styles.line} />
            <div className={styles.component}>
                <Header
                    sticky={sticky}
                    siteTitle={data.site?.siteMetadata?.title}
                    menuItems={menuItems}
                    researchMenuItems={
                        [...data.researchMenuItems.nodes] as Node[]
                    }
                    showBrowserWarning={showBrowserWarning}
                    setHeaderHeight={(height = INIITIAL_HEADER_HEIGHT) =>
                        setHeaderHeight(height)
                    }
                />
                <div
                    className={styles.body}
                    style={{ marginTop: headerHeight }}
                >
                    <InPageNavProvider>
                        <main>
                            {children}
                            {data.newsletterSignUpData && (
                                <NewsletterSignup
                                    variant="footer"
                                    title={
                                        data.newsletterSignUpData.field_title ||
                                        ""
                                    }
                                    text={
                                        data.newsletterSignUpData.field_subtext
                                    }
                                    url={
                                        data.newsletterSignUpData
                                            .field_mailchimp_url || ""
                                    }
                                />
                            )}
                        </main>
                    </InPageNavProvider>
                    <Footer data={data} />
                </div>
            </div>
        </div>
    );
};

export default Layout;
