// extracted by mini-css-extract-plugin
export var component = "BasicPage-module--component--3d77f";
export var desktopOnly = "BasicPage-module--desktopOnly--fbc40";
export var fadePop = "BasicPage-module--fade-pop--870b7";
export var growHeightIn = "BasicPage-module--grow-height-in--9d0d4";
export var mobileOnly = "BasicPage-module--mobileOnly--24329";
export var mobileReadingOptions = "BasicPage-module--mobileReadingOptions--21e99";
export var noMarginBottom = "BasicPage-module--noMarginBottom--ccd92";
export var relatedContentWrapper = "BasicPage-module--relatedContentWrapper--ba1ed";
export var section = "BasicPage-module--section--d63a1";
export var sidebarParagraphs = "BasicPage-module--sidebarParagraphs--51a55";
export var topDownloadLink = "BasicPage-module--topDownloadLink--25f71";