import cx from "classnames";
import Image, { FixedObject } from "gatsby-image";
import React, { HTMLAttributes } from "react";

import { Link } from "../Link";
import { IconSprite } from "../IconSprite";
import * as styles from "./JournalIssueSignpost.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    heading?: string | null;
    subtext?: string | null;
    image?: FixedObject | null;
    path: string;
    linkLabel: string;
}

export const JournalIssueSignpost: React.FC<Props> = ({
    heading,
    subtext,
    image,
    path,
    linkLabel,
    className,
    ...rest
}) => (
    <Link
        {...rest}
        className={cx(
            styles.component,
            styles[image ? "withImage" : "noImage"],
            className
        )}
        to={path}
    >
        {image && <Image fixed={image} className={styles.image} />}
        <div className={styles.contents}>
            {heading && <label>{heading}</label>}
            {subtext && <small>{subtext}</small>}
            <Link to={path}>
                <span>{linkLabel}</span>
                <IconSprite name="chevron-right" />
            </Link>
        </div>
    </Link>
);
