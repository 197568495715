import cx from "classnames";
import { graphql } from "gatsby";
import React, { FC, HTMLAttributes } from "react";

import { PersonCard } from "../PersonCard";
import * as styles from "./PeopleList.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    variant?: "main" | "side";
    people?: GatsbyTypes.PersonDataFragment[];
    heading?: string;
}

export const PeopleList: FC<Props> = ({
    variant = "profile",
    people,
    className,
    heading,
    ...props
}) => {
    if (!people || !people.length) {
        return null;
    }

    return (
        <section className={cx(styles[variant], className)} {...props}>
            {heading && <h2 className={styles.heading}>{heading}</h2>}
            {people.map(person => (
                <PersonCard
                    key={person?.path?.alias ?? undefined}
                    name={`${person?.field_first_names} ${person?.title}`}
                    title={person?.field_position || ""}
                    department={
                        person?.relationships?.field_staff_department?.[0]
                            ?.name || ""
                    }
                    url={person?.path?.alias || ""}
                    director={
                        person?.field_position?.toLowerCase() === "director"
                    }
                    variant={variant === "main" ? "profile" : "sidebar"}
                    className={styles.person}
                    image={
                        person.relationships?.field_user_picture
                            ?.childImageKitAsset?.fluid
                    }
                />
            ))}
        </section>
    );
};

export default PeopleList;

export const fragment = graphql`
    fragment PersonData on node__rusi_people {
        id
        title
        field_first_names
        field_email_address
        path {
            alias
        }
        field_position
        relationships {
            field_staff_department {
                name
            }

            field_user_picture {
                childImageKitAsset {
                    fluid(
                        maxWidth: 168
                        transformations: ["fo-face", "ar-88-112"]
                    ) {
                        ...ImageKitFluid
                    }
                }
            }
        }
    }
`;
