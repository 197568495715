// extracted by mini-css-extract-plugin
export var button = "SearchBox-module--button--3c501";
export var close = "SearchBox-module--close--a18b6";
export var component = "SearchBox-module--component--7c5b1";
export var content = "SearchBox-module--content--410ef";
export var fadePop = "SearchBox-module--fade-pop--df5a3";
export var form = "SearchBox-module--form--42f20";
export var growHeightIn = "SearchBox-module--grow-height-in--52f0f";
export var open = "SearchBox-module--open--81ee3";
export var selected = "SearchBox-module--selected--65a65";
export var suggestions = "SearchBox-module--suggestions--dc1a6";