import cx from "classnames";
import React, { HTMLAttributes, useEffect, useMemo, useState } from "react";
import { Node } from "../Layout/types";
import { Link } from "../Link";
import Underline from "../Underline";

import { IconSprite } from "../IconSprite";
import * as styles from "./MegaMenu.module.scss";

export interface MegaMenuProps extends HTMLAttributes<HTMLDivElement> {
	open: boolean;
	columns?: 2 | 3 | 4;
	menuItems: Node[];
	activeMenu?: string;
	researchMenu?: Node[];
	layout?: "columns" | "tabs";
}

export const MegaMenu: React.FC<MegaMenuProps> = ({
	open,
	columns = 4,
	activeMenu,
	menuItems,
	researchMenu,
	layout = "columns",
	...rest
}) => {
	const [activeSubMenu, setActiveSubMenu] = useState<string>();

	const index = useMemo(
		() => menuItems.findIndex(({ id }) => id === activeMenu),
		[activeMenu, menuItems],
	);

	const activeMenuItem = menuItems[index];

	const researchMenuSecondLevel = researchMenu?.filter(
		({ drupal_parent_menu_item }) =>
			drupal_parent_menu_item?.includes(activeMenu || "invalid"),
	);

	const researchMenuThirdLevel = researchMenu?.filter(
		({ drupal_parent_menu_item }) =>
			drupal_parent_menu_item?.includes(activeSubMenu || "invalid"),
	);
	return (
		<div {...rest} className={cx(styles.component, { [styles.open]: open })}>
			{(researchMenuSecondLevel?.length || 0) > 0 && (
				<div className={styles.researchMenu}>
					<ul className={styles.researchMenuTree}>
						{researchMenuSecondLevel?.map((node) => {
							const active = activeSubMenu === node.id;

							return (
								<li key={node.id}>
									<div>
										<Link
											to={node.link?.uri || ""}
											className={cx(styles.link, {
												[styles.open]: active,
											})}
											activeClassName={styles.active}
											partiallyActive
											onMouseEnter={() => setActiveSubMenu(node.id)}
										>
											{node.title}
										</Link>
										<Underline />
									</div>
									<IconSprite
										name="chevron-right"
										className={cx(styles.icon, {
											[styles.active]: active,
										})}
									/>
								</li>
							);
						})}
					</ul>
					<ul className={styles.researchMenuBranch}>
						{researchMenuThirdLevel?.map((node) => (
							<li key={node.id}>
								<Link
									to={node.link?.uri || ""}
									className={styles.link}
									activeClassName={styles.active}
								>
									{node.title}
								</Link>
							</li>
						))}
					</ul>
				</div>
			)}
			{!!activeMenuItem && (
				<div className={styles.content}>
					{activeMenuItem?.children?.map((node) => (
						<Column key={node.id} node={node} />
					))}
				</div>
			)}
			<div className={styles.border} />
		</div>
	);
};

export interface ColumnProps {
	node: Node;
}

export const Column: React.FC<ColumnProps> = ({ node, ...props }) => (
	<div {...props} className={styles.column}>
		<div className={styles.title}>
			{node.title !== "<nolink>" && (
				<>
					<label>
						<Link
							partiallyActive
							activeClassName={styles.active}
							to={node.link?.uri || "/"}
						>
							{node.title}
						</Link>
					</label>
					<Underline />
				</>
			)}
		</div>
		<div className={styles.body}>
			{(node.children?.length || 0) > 0 && (
				<ul className={styles.sub_menu}>
					{node.children?.map(({ link, id, title }) => (
						<li key={id}>
							<Link activeClassName={styles.active} to={link?.uri || "/"}>
								{title}
							</Link>
						</li>
					))}
				</ul>
			)}
		</div>
		<div className={styles.body}>
			{node.field_additional_text && <p>{node.field_additional_text}</p>}
		</div>
	</div>
);
