import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";

import * as styles from "./MainImageCredit.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    text: string;
    link?: string;
}

export const MainImageCredit: FC<Props> = ({
    text,
    link,
    className,
    ...props
}) => (
    <p className={cx(styles.component, className, "hideOnPrint")} {...props}>
        {link ? <a href={link}>{text}</a> : text}
    </p>
);

export default MainImageCredit;
