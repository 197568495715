import React, { HTMLAttributes } from "react";

import { ArticleQuery_article_relationships_field_author as Author } from "../../templates/Article/__generated__/ArticleQuery";
import { ReadTime } from "../ReadTime";
import * as styles from "./ArticleMeta.module.scss";

export const filterAuthors = (authors?: Array<Author | null>): Author[] =>
    (authors?.filter(Boolean) || []) as Author[];

export const getJoiningStr = (length = 0, index = 0) =>
    length > 1 && index !== length - 1
        ? index === length - 2
            ? " and "
            : ", "
        : "";

export interface ArticleMetaProps extends HTMLAttributes<HTMLElement> {
    authorText?: string | null;
    authors?: Array<Author | null>;
    date?: string;
    /* read time in minutes */
    readTime?: string;
}

// eslint-disable-next-line complexity
export const ArticleMeta: React.FC<ArticleMetaProps> = ({
    authors,
    date,
    readTime,
    authorText
}) => {
    if (!(authors?.length || authorText || readTime || date)) {
        return null;
    }

    const filteredAuthors = filterAuthors(authors);
    const hasAuthors = !!filteredAuthors.length;

    return (
        <div className={styles.component}>
            <div>
                {hasAuthors ? (
                    <span aria-label="authored by">
                        {filteredAuthors.map((author, index) => (
                            <span key={author?.id ?? index}>
                                {author.field_first_names} {author.title}
                                {getJoiningStr(filteredAuthors.length, index)}
                            </span>
                        ))}
                    </span>
                ) : authorText ? (
                    <span aria-label="authored by">{authorText}</span>
                ) : null}
                <div className={styles.flex}>
                    {date && (
                        <b aria-label="published date" className={styles.date}>
                            {date}
                        </b>
                    )}
                    <ReadTime readTime={readTime} />
                </div>
            </div>
        </div>
    );
};
