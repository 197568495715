import cx from "classnames";
import React, {
	FC,
	HTMLAttributes,
	useEffect,
	useState,
} from "react";

import { IconSprite } from "../IconSprite/IconSprite";
import * as styles from "./PrintThisPage.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {}

export const PrintThisPage: FC<Props> = ({ className, ...rest }) => {
	const [printRequested, setPrintRequested] = useState(false);

	useEffect(() => {
		if (!printRequested) return;
		window.print();
		setPrintRequested(false);
	}, [printRequested]);

	return (
		<button
			className={cx(styles.component, className)}
			onClick={() => setPrintRequested(true)}
			{...rest}
		>
			<div className={styles.iconWrapper}>
				<IconSprite className={styles.icon} name="print" />
			</div>
			<div className={styles.labelContainer}>
				<span className={styles.label}>Print this page</span>
			</div>
		</button>
	);
};
export default PrintThisPage;
