export const isBrowserOutdated = (): boolean => {
    const ua = navigator.userAgent,
        browser =
            ua
                .match(
                    /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
                )
                ?.map(x => x.toLowerCase()) || [];
    if (browser.includes("msie") || browser.includes("trident")) return true;
    return false;
};
