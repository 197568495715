import React, { FC, HTMLAttributes } from "react";
import cx from "classnames";
import { component } from "./IconSprite.module.scss";

export type IconSpriteName =
    | "close"
    | "clock"
    | "download"
    | "lock"
    | "newsletter"
    | "quote"
    | "search"
    | "home"
    | "chevron-right"
    | "completed"
    | "inactive"
    | "noDueDate"
    | "overdue"
    | "inProgress"
    | "linkedin"
    | "twitter"
    | "email"
    | "facebook"
    | "youtube"
    | "instagram"
    | "rss"
    | "user"
    | "plus"
    | "minus"
    | "menu"
    | "pdf"
    | "location"
    | "calendar"
    | "spotify"
    | "itunes"
    | "phone"
    | "unlocked"
    | "tick"
    | "long-arrow"
    | "print"
    | "card"
    | "";
interface Props extends HTMLAttributes<SVGSVGElement> {
    name: IconSpriteName;
}

export const IconSprite: FC<Props> = ({ name, className, ...props }) => (
    <i className={cx(component, className)}>
        <svg xmlns="http://www.w3.org/2000/svg" {...props}>
            <title>{name}</title>
            <use xlinkHref={`#icon-${name}`} />
        </svg>
    </i>
);

export default IconSprite;
