/* eslint-disable @typescript-eslint/camelcase */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { WindowLocation } from "@reach/router";
import { parse, stringify } from "qs";
import { Hit } from "react-instantsearch-core";

import { SearchHit } from "../types/data";

// This is really passed as any
export const createURL = (state: any) =>
    `?${stringify(state)}`.replace("algolia_global_search", "relevance");
export const locationToSearchState = (location?: WindowLocation) => {
    const result = parse(location?.search?.slice(1) ?? "");
    if (result.sortBy === "relevance") {
        result.sortBy = "algolia_global_search";
    }
    return result;
};
const nodeTypeNames: Record<string, string> = {
    article: "Article",
    events: "Event",
    basic_page: "Page",
    rusi_periodicals: "Periodical",
    rusi_people: "Person",
    rusi_publications: "Publication",
};
export const nodeTypeToName = (type: string) => nodeTypeNames?.[type] ?? type;

interface Facet {
    count: number;
    isRefined: boolean;
    label: string;
    value: string;
}

export const transformFilterNames = (items: Facet[]): Facet[] =>
    items.map((item) => ({
        ...item,
        label: nodeTypeToName(item.label),
    }));

export function arrayify<T>(obj: T | T[]): T[] | null {
    if (obj === null) {
        return null;
    }
    return Array.isArray(obj) ? obj : [obj];
}

const trim = (str?: string | null) => str?.trim();

export const formatAuthorNames = ({
    author_first_names,
    author_surname,
}: Hit<SearchHit>) => {
    const firstNames = arrayify(author_first_names)?.map(trim);
    const surnames = arrayify(author_surname)?.map(trim);
    if (!surnames) {
        return "";
    }
    const [final, ...rest] = surnames
        .map((name, i) => [firstNames?.[i], name].filter(Boolean).join(" "))
        .reverse();

    return [rest.reverse().join(", "), final].filter(Boolean).join(" and ");
};
