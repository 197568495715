import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import cx from "classnames";
import * as styles from "./LatestDynamicContent.module.scss";
import { useInPageNav } from "../../context/InPageNav/useInPageNav";
import { getDomId } from "../../context/InPageNav/helpers";
import { TitleForDynamicParagraphs } from "../TitleForDynamicParagraphs";
import { Link } from "../Link";
import { SearchComponent } from "../SearchComponent";
import { Configure } from "react-instantsearch-core";
import { CustomStateResults } from "../SearchComponent/customAlgoliaWidgets";
import { Hits } from "react-instantsearch-dom";
import { CustomInfiniteHits } from "../CustomInfiniteHits";
import { SearchHit } from "../../types/data";
import { RelatedInTheNewsContainer } from "../RelatedInTheNews";
import { RelatedArticleContainer } from "../RelatedArticle";

interface Props extends HTMLAttributes<HTMLElement> {
    heading?: string;
    linkText?: string;
    linkUrl?: string;
    includedInPageNav?: boolean;
    resultsPerPage?: number;
    infinite?: boolean;
    filterString?: string;
    variant?: "row" | "list";
}

type ResultsState = "loading" | "none" | "present";

interface HitCardContainerProps {
    hit: SearchHit;
}

const HitCardContainer: FC<HitCardContainerProps> = ({ hit }) => {
    const { type } = hit;

    if (type === "in_the_news") {
        return <RelatedInTheNewsContainer searchHit={hit} />;
    }

    return <RelatedArticleContainer searchHit={hit} />;
};

// eslint-disable-next-line complexity
export const AlgoliaDynamicContent: FC<Props> = ({
    heading,
    linkText,
    linkUrl,
    filterString = "",
    includedInPageNav = false,
    resultsPerPage = 10,
    infinite = true,
    variant = "list",
    className,
    ...props
}) => {
    const { addToInPageNav } = useInPageNav();

    const [resultsState, setResultsState] = useState<ResultsState>("loading");

    const id = getDomId(heading ?? "");

    useEffect(() => {
        if (
            resultsState === "present" &&
            addToInPageNav &&
            id &&
            includedInPageNav
        ) {
            addToInPageNav({ title: heading ?? "", id });
        }
    }, [addToInPageNav, heading, resultsState, id, includedInPageNav]);

    if (resultsState === "none") {
        return null;
    }

    return (
        <section
            id={id}
            className={cx(className, styles[variant], "hideOnPrint")}
            {...props}
        >
            <TitleForDynamicParagraphs>
                {heading && <h2>{heading}</h2>}
                {linkText && linkUrl && <Link to={linkUrl}>{linkText}</Link>}
            </TitleForDynamicParagraphs>

            <SearchComponent disableUrl indexName={"global_by_date"}>
                <Configure
                    filters={filterString}
                    hitsPerPage={resultsPerPage}
                />
                <CustomStateResults
                    onStateChange={({ searchResults }) => {
                        if (searchResults) {
                            setResultsState(
                                searchResults.nbHits > 0 ? "present" : "none"
                            );
                        }
                    }}
                />

                {infinite ? (
                    <CustomInfiniteHits loadMoreString="Load more publications" />
                ) : (
                    <Hits hitComponent={HitCardContainer} />
                )}
            </SearchComponent>
        </section>
    );
};

export default AlgoliaDynamicContent;
