/* eslint-disable complexity */
import cx from "classnames";
import React, { useEffect } from "react";
import { ParagraphMediaEnquiryGroup } from "../../containers/ParagraphMediaEnquiryGroup/ParagraphMediaEnquiryGroup";
import RelatedContent from "../../containers/RelatedContent";
import { getFilterString } from "../../containers/RelatedContent/RelatedContent.helpers";
import { useAuth } from "../../context/auth/AuthProvider";

import { Crumb } from "../../types/breadcrumbs";
import { tryParseJson } from "../../utilities/stringUtils";
import { BodyText } from "../BodyText";
import { DownloadFile } from "../DownloadFile";
import { ExternalArticleButtonContainer } from "../ExternalArticleButtonContainer";
import { FootnoteList } from "../FootnoteList";
import { HeroImage } from "../HeroImage";
import { JournalIssueSignpostParagraph } from "../JournalIssueSignpostParagraph";
import { KeywordTags } from "../KeywordTags";
import { organiseKeywordTags } from "../KeywordTags/helpers";
import { LeadParagraph } from "../LeadParagraph";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { MainImageCredit } from "../MainImageCredit";
import { MembershipGateContainer } from "../MembershipGateContainer";
import { MemberGateReason } from "../MembershipGateContainer/MembershipGateContainer";
import { Paragraphs } from "../Paragraphs";
import { PeopleList } from "../PeopleList";
import { ReadingOptions } from "../ReadingOptions";
import SocialShareBlock from "../SocialShareBlock";
import TitleBlock from "../TitleBlock";
import * as styles from "./Article.module.scss";
interface Props {
	article: GatsbyTypes.ArticleDataFragment;
	breadcrumb: Crumb[];
	url: string;
}

// eslint-disable-next-line complexity
export const Article: React.FC<Props> = ({ article, url, breadcrumb }) => {
	const { getEncryptionKeyOrReason, decryptArticle, parseNonEncryptedArticle } =
		useAuth();

	const [isDecrypting, setIsDecrypting] = React.useState(article.field_premium);
	const [encryptedData, setEncryptedData] = React.useState<
		false | EncryptedData
	>(!article.field_premium ? parseNonEncryptedArticle(article) : false);

	const [memberGateReason, setMemberGateReason] =
		React.useState<MemberGateReason | null>(null);
	useEffect(() => {
		const handleDecrypt = async () => {
			const { key, reason } = await getEncryptionKeyOrReason(article);
			if (key) {
				const decryptedData = await decryptArticle(key, article);
				setEncryptedData(decryptedData);
			} else if (reason) {
				setMemberGateReason(reason);
			} else {
				setMemberGateReason("error");
			}
			setIsDecrypting(false);
			return;
		};
		if (isDecrypting) {
			handleDecrypt();
		}
	}, [isDecrypting]);

	if (!article) {
		return null;
	}

	const { body, pdfUrl, taylorAndFrancisUrl, sections }: EncryptedData =
		encryptedData || {
			body: false,
			pdfUrl: false,
			taylorAndFrancisUrl: false,
			sections: [],
		};
	const title = article.title || "";
	const read_time = article.field_read_time || "";
	const lead = article?.field_abstract?.value;

	const img =
		article?.relationships?.field_media_image?.relationships?.field_media_image
			?.childImageKitAsset?.fluid;
	const alt =
		article?.relationships?.field_media_image?.relationships?.field_media_image
			?.relationships?.media__image?.[0]?.field_media_image?.alt ?? "";

	const imgCredit =
		article?.relationships?.field_media_image?.relationships?.field_media_image
			?.relationships?.media__image?.[0]?.field_credit ?? "";
	const imgCreditLink =
		article?.relationships?.field_media_image?.relationships?.field_media_image
			?.relationships?.media__image?.[0]?.field_credit_link ?? "";

	const date = article?.created;

	const field_media_enquiries = article.relationships?.field_media_enquiry_;

	const author_text = article?.field_outside_author;
	const profileDescriptor = article?.field_profile_descriptors ?? ""; // "written by" - appears above author lists
	const authors = article?.relationships?.field_author ?? [];
	const journalIssueSignposts =
		article?.relationships?.field_journal_signposts ?? [];

	const footnotes = article?.relationships?.field_footnotes;

	const organisedKeywordTags = organiseKeywordTags(article.relationships);

	const membersOnly = article.field_premium;

	const { field_publication_issue, field_publication_volume, created } =
		article;

	const contentTypeName = article.relationships?.field_content_type?.name;
	const contentTypeLink =
		article.relationships?.field_content_type?.path?.alias;

	const filterString = getFilterString({
		regions: article.relationships?.field_region,
		researchGroups: article.relationships?.field_research_groups,
		topics: article.relationships?.field_topics,
	});
	return (
		<>
			<article className={cx(styles.component)}>
				<TitleBlock
					variant="article"
					title={title}
					authorText={author_text}
					authors={authors}
					breadcrumb={breadcrumb}
					readTime={read_time}
					date={date}
					url={url}
					contentType={contentTypeName}
					contentTypeUrl={contentTypeLink}
					volume={field_publication_volume}
					issue={field_publication_issue}
					restrictedTo={membersOnly ? "members only" : ""}
				/>

				{pdfUrl && (
					<DownloadFile
						className={cx(styles.topDownloadLink, "hideOnPrint")}
						pdf={pdfUrl}
					/>
				)}

				<HeroImage
					fluid={img}
					altText={alt}
					aria-labelledby="main image credit"
				/>
				<hr />

				{imgCredit && (
					<>
						<MainImageCredit
							aria-label="main image credit"
							className={styles.mobileOnly}
							text={imgCredit}
							link={imgCreditLink}
						/>
						<hr className={cx(styles.mobileOnly, "hideOnPrint")} />
					</>
				)}

				<div className={styles.contentArea}>
					<div>
						{lead && <LeadParagraph>{lead}</LeadParagraph>}
						<BodyText
							className={styles.mainBody}
							html={body || ""}
							footnotes={footnotes as GatsbyTypes.FieldFootnoteFragment[]}
							fadeOut={memberGateReason !== null}
						/>
						{isDecrypting && (
							<div className={styles.decryptingMessageContainer}>
								<h4>
									Please wait while we verify your account can view this
									article.
								</h4>
								<div>
									<LoadingSpinner />
								</div>
							</div>
						)}
						<MembershipGateContainer
							reason={memberGateReason}
							className={styles.section}
						/>

						{sections && (
							<Paragraphs
								className={styles.paragraphs}
								data={sections}
								paragraphTextFootnotes={
									footnotes as GatsbyTypes.FieldFootnoteFragment[]
								}
							/>
						)}

						{taylorAndFrancisUrl && (
							<ExternalArticleButtonContainer
								url={tryParseJson(taylorAndFrancisUrl)}
							/>
						)}

						{organisedKeywordTags?.length && (
							<>
								<hr className={styles.shortHr} />
								<KeywordTags
									className={styles.section}
									tagCategories={organisedKeywordTags}
								/>
							</>
						)}

						{journalIssueSignposts.length ? (
							<section className={cx(styles.mobileOnly, styles.section)}>
								<h5>Issue details</h5>
								{journalIssueSignposts.map((item) =>
									item ? (
										<JournalIssueSignpostParagraph node={item} key={item.id} />
									) : null,
								)}
							</section>
						) : null}

						{authors.length ? (
							<>
								<PeopleList
									variant="main"
									people={authors}
									heading={profileDescriptor}
								/>
								<hr />
							</>
						) : null}
					</div>

					{/* Used for column spacing */}
					<span />

					<div className={styles.sidebar}>
						{imgCredit && (
							<aside aria-label="main image credit">
								<MainImageCredit text={imgCredit} link={imgCreditLink} />
								<hr />
							</aside>
						)}

						{authors.length ? (
							<aside aria-label="authors">
								{profileDescriptor && <label>{profileDescriptor}</label>}
								<PeopleList
									variant="side"
									people={authors as GatsbyTypes.PersonDataFragment[]}
								/>
								<hr />
							</aside>
						) : null}

						{field_media_enquiries ? (
							<aside>
								<ParagraphMediaEnquiryGroup
									title={"Media Enquiries"}
									node={field_media_enquiries}
								/>
							</aside>
						) : null}

						{pdfUrl && (
							<>
								<ReadingOptions
									pdf={[pdfUrl]}
									citation={
										author_text &&
										title &&
										contentTypeName &&
										created &&
										url &&
										`${author_text}, '${title}', ${contentTypeName}, ${created}, RUSI ${url}`
									}
								/>
								<hr />
							</>
						)}

						{journalIssueSignposts.length ? (
							<aside>
								<label>Issue details</label>
								{journalIssueSignposts.map((item) =>
									item ? (
										<JournalIssueSignpostParagraph node={item} key={item.id} />
									) : null,
								)}
							</aside>
						) : null}
					</div>
				</div>

				<div className={styles.desktopOnly}>
					<SocialShareBlock
						className={styles.socialLinks}
						title={title}
						variant="block"
						url={url}
					/>
				</div>

				{field_media_enquiries ? (
					<ParagraphMediaEnquiryGroup
						title={"Media Enquiries"}
						node={field_media_enquiries}
						className={cx(styles.mobileOnly)}
					/>
				) : null}

				{pdfUrl && (
					<ReadingOptions
						className={cx(styles.mobileOnly, styles.mobileReadingOptions)}
						pdf={[pdfUrl]}
					/>
				)}
				<FootnoteList items={footnotes} />
			</article>

			<RelatedContent filterString={filterString} currentContentTitle={title} />
		</>
	);
};

export default Article;
