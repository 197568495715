import cx from "classnames";
import React, { FC } from "react";

import { stripHtml } from "../../lib/stringUtils";
import * as styles from "./LeadParagraph.module.scss";

interface Props {
    copy?: string | null;
    className?: string;
    children?: string | null;
}

export const LeadParagraph: FC<Props> = ({ className, children }) =>
    children ? (
        <p className={cx(styles.component, className)}>{stripHtml(children)}</p>
    ) : null;

export default LeadParagraph;
