import React from "react";
import cx from "classnames";
import * as styles from "./Section.module.scss";

interface SectionProps {
    background?: "grey" | "white";
    layout: "fullwidth" | "leftaligned";
    firstSection?: boolean;
    renderClosingRule?: boolean;
    landingPageSpacing?: boolean;
    hideBorderTop?: boolean;
}

/* This will handle: horizontal rule at top, padding, background colour */
export const Section: React.FC<SectionProps> = ({
    background = "",
    children,
    layout = "fullwidth",
    landingPageSpacing = false,
    firstSection = false,
    renderClosingRule = false,
    hideBorderTop = false
}) => (
    <section
        className={cx(styles.component, styles[layout], styles[background], {
            [styles.firstSection]: firstSection,
            [styles.hideBorderTop]: hideBorderTop,
            [styles.landingPageSpacing]: landingPageSpacing
        })}
    >
        <div className={cx(styles.background, styles[background])} />
        <div
            className={cx(styles.content, {
                [styles.withClosingRule]: renderClosingRule
            })}
        >
            {children}
        </div>

        {renderClosingRule && <hr className={styles.closingRule} />}
    </section>
);
