import React, { FC, HTMLAttributes } from "react";
import { graphql, useStaticQuery } from "gatsby";
import ExternalArticleButton from "../ExternalArticleButton/ExternalArticleButton";
import { stripHtml } from "../../lib/stringUtils";

interface Props extends HTMLAttributes<HTMLElement> {
    url: string;
}

export const ExternalArticleButtonContainer: FC<Props> = ({
    className,
    url,
    ...props
}) => {
    const { data } = useStaticQuery<GatsbyTypes.ExternalArticleGateDataQuery>(externalArticleGateQuery);
    if (!data || !url) {
        return null;
    }

    const {
        field_box_heading,
        field_button_link_text,
        field_subtext,
        field_title,
        field_footer
    } = data;

    const subtext = stripHtml(field_footer?.processed ?? "");

    return (
        <ExternalArticleButton
            url={url}
            unlockedText={field_box_heading}
            heading={field_title}
            buttonText={field_button_link_text}
            linkInfo={field_subtext}
            subtext={subtext}
            {...props}
        />
    );
};

export default ExternalArticleButtonContainer;

const externalArticleGateQuery = graphql`
    query ExternalArticleGateData {
        data: configPagesTaylorAndFrancisMessaging {
            id
            field_box_heading
            field_button_link_text
            field_subtext
            field_title
            field_footer {
                processed
            }
        }
    }
`;
