import React from "react";
import { graphql } from "gatsby";
import { grid } from "./SignpostGridParagraph.module.scss";
import { RelatedArticle } from "../../components/RelatedArticle";
import { RelatedInTheNews } from "../../components/RelatedInTheNews";
import { TitleForDynamicParagraphs } from "../../components/TitleForDynamicParagraphs";
import { RelatedEvent } from "../../components/RelatedEvent";

interface Props {
    node: GatsbyTypes.ParagraphSignpostGridFragment;
}

export const SignpostGridParagraph: React.FC<Props> = ({ node }) => (
    <section>
        {!!node.field_title && (
            <TitleForDynamicParagraphs>
                <h2>{node.field_title}</h2>
                {node.field_link && (
                    <a href={node.field_link.uri}>{node.field_link.title}</a>
                )}
            </TitleForDynamicParagraphs>
        )}
        <div className={grid}>
            {node.relationships?.field_signposted_content?.map(
                signpost =>
                    signpost &&
                    // eslint-disable-next-line complexity
                    (() => {
                        switch (signpost.__typename) {
                            case "node__project":
                                return (
                                    <RelatedArticle
                                        key={signpost.id}
                                        variant="whiteBg"
                                        heading={signpost.title ?? ""}
                                        url={signpost.path?.alias ?? ""}
                                        closed={signpost.field_closed}
                                        image={
                                            signpost.relationships
                                                ?.large_signpost?.relationships
                                                ?.field_media_image
                                                ?.childImageKitAsset?.fluid ??
                                            signpost.relationships
                                                ?.large_signpost_media_image
                                                ?.relationships
                                                ?.field_media_image
                                                ?.childImageKitAsset?.fluid
                                        }
                                        publicationName={
                                            signpost.relationships
                                                ?.field_content_type?.name
                                        }
                                        publicationLink={
                                            signpost.relationships
                                                ?.field_content_type?.path
                                                ?.alias
                                        }
                                    />
                                );
                            case "node__topic_region_page":
                                return (
                                    <RelatedArticle
                                        key={signpost.id}
                                        variant="whiteBg"
                                        heading={signpost.title ?? ""}
                                        url={signpost.path?.alias ?? ""}
                                        image={
                                            signpost.relationships
                                                ?.large_signpost_media_image
                                                ?.relationships
                                                ?.field_media_image
                                                ?.childImageKitAsset?.fluid
                                        }
                                        publicationName={
                                            signpost.relationships
                                                ?.field_content_type?.name
                                        }
                                        publicationLink={
                                            signpost.relationships
                                                ?.field_content_type?.path
                                                ?.alias
                                        }
                                    />
                                );
                            case "node__research_group":
                                return (
                                    <RelatedArticle
                                        key={signpost.id}
                                        variant="whiteBg"
                                        heading={signpost.title ?? ""}
                                        url={signpost.path?.alias ?? ""}
                                        image={
                                            signpost.relationships
                                                ?.large_signpost_media_image
                                                ?.relationships
                                                ?.field_media_image
                                                ?.childImageKitAsset?.fluid
                                        }
                                        publicationName={
                                            signpost.relationships
                                                ?.field_content_type?.name
                                        }
                                        publicationLink={
                                            signpost.relationships
                                                ?.field_content_type?.path
                                                ?.alias
                                        }
                                    />
                                );
                            case "node__article":
                                return (
                                    <RelatedArticle
                                        key={`signpost-grid-${signpost.title}`}
                                        variant="whiteBg"
                                        heading={signpost.title ?? ""}
                                        url={signpost.path?.alias ?? ""}
                                        image={
                                            signpost.relationships
                                                ?.large_signpost?.relationships
                                                ?.field_media_image
                                                ?.childImageKitAsset?.fluid ??
                                            signpost.relationships
                                                ?.large_signpost_media_image
                                                ?.relationships
                                                ?.field_media_image
                                                ?.childImageKitAsset?.fluid
                                        }
                                        date={signpost.created}
                                        machineDate={signpost.machineDate}
                                        readTime={signpost.field_read_time}
                                        publicationName={
                                            signpost.relationships
                                                ?.field_content_type?.name
                                        }
                                        publicationLink={
                                            signpost.relationships
                                                ?.field_content_type?.path
                                                ?.alias
                                        }
                                        restrictedString={
                                            signpost.field_member_only
                                                ? "Members only"
                                                : ""
                                        }
                                    />
                                );

                            case "node__conference":
                                return (
                                    <RelatedEvent
                                        key={`signpost-grid-${signpost.title}`}
                                        fullDate={
                                            signpost.field_event_date_range
                                                ?.long
                                        }
                                        machineDate={
                                            signpost.field_event_date_range
                                                ?.machineDate
                                        }
                                        heading={signpost.title}
                                        image={
                                            signpost.relationships
                                                ?.large_signpost?.relationships
                                                ?.field_media_image
                                                ?.childImageKitAsset?.fluid ??
                                            signpost.relationships
                                                ?.large_signpost_media_image
                                                ?.relationships
                                                ?.field_media_image
                                                ?.childImageKitAsset?.fluid
                                        }
                                        location={signpost.location}
                                        startTime={
                                            signpost.field_event_date_range
                                                ?.time
                                        }
                                        locale={signpost.timezone}
                                        titleDate={
                                            signpost.field_event_date_range
                                                ?.short
                                        }
                                        typeLink={
                                            signpost.relationships
                                                ?.field_event_type?.path?.alias
                                        }
                                        typeName={
                                            signpost.relationships
                                                ?.field_event_type?.name
                                        }
                                        url={signpost.path?.alias ?? ""}
                                        variant="conference"
                                    />
                                );
                            case "node__events":
                                return (
                                    <RelatedEvent
                                        key={`signpost-grid-${signpost.title}`}
                                        fullDate={
                                            signpost.field_event_date_range
                                                ?.long
                                        }
                                        machineDate={
                                            signpost.field_event_date_range
                                                ?.machineDate
                                        }
                                        heading={signpost.title}
                                        image={
                                            signpost.relationships
                                                ?.field_signpost_image
                                                ?.relationships
                                                ?.field_media_image
                                                ?.childImageKitAsset?.fluid ??
                                            signpost.relationships
                                                ?.field_media_image
                                                ?.relationships
                                                ?.field_media_image
                                                ?.childImageKitAsset?.fluid
                                        }
                                        location={signpost.location}
                                        restrictedString={
                                            signpost.relationships
                                                ?.field_open_to_tag?.name
                                        }
                                        startTime={
                                            signpost.field_event_date_range
                                                ?.time
                                        }
                                        locale={signpost.timezone}
                                        titleDate={
                                            signpost.field_event_date_range
                                                ?.short
                                        }
                                        typeName={
                                            signpost.relationships
                                                ?.field_event_type?.name
                                        }
                                        typeLink={
                                            signpost.relationships
                                                ?.field_event_type?.path?.alias
                                        }
                                        url={signpost.path?.alias ?? ""}
                                        variant="event"
                                    />
                                );
                            case "node__in_the_news":
                                const author =
                                    signpost.relationships?.author?.[0];

                                return (
                                    <RelatedInTheNews
                                        key={`signpost-grid-${signpost.title}`}
                                        heading={signpost.title}
                                        url={signpost.path?.alias}
                                        leadQuote={signpost.lead_quote}
                                        date={signpost.created}
                                        author={`${author?.first_name} ${author?.surname}`}
                                        authorPosition={author?.position}
                                        machineDate={signpost.machineDate}
                                        primaryTag={
                                            signpost.relationships
                                                ?.field_content_type?.name
                                        }
                                        externalPublisher={
                                            signpost.relationships
                                                ?.field_external_publication
                                                ?.name
                                        }
                                        image={
                                            signpost.relationships
                                                ?.field_external_publication
                                                ?.relationships
                                                ?.field_external_publication_logo
                                                ?.relationships
                                                ?.field_media_image
                                                ?.childImageKitAsset?.fluid
                                        }
                                    />
                                );
                            default:
                                return null;
                        }
                    })()
            )}
        </div>
    </section>
);

export const fragment = graphql`
    fragment ParagraphSignpostGrid on paragraph__signpost_grid {
        __typename
        id
        field_title
        field_link {
            uri
            title
        }
        relationships {
            __typename
            field_signposted_content {
                __typename
                ...ArticleSignpostData
                ...ExternalSignpostData
                ...ProjectSignpostData
                ...ConferencesSignpostData
                ...EventsSignpostData
                ...basicPageRelatedContentItem
                ...topicRegionRelatedContentItem
                ...InTheNewsSignpost
                ...ResearchGroupSignpost
            }
        }
    }

    fragment InTheNewsSignpost on node__in_the_news {
        id
        title
        created(formatString: "D MMMM Y")
        machineDate: created
        lead_quote: field_focus
        path {
            alias
        }
        relationships {
            content_type: field_content_type {
                name
                path {
                    alias
                }
            }
            author: field_author {
                first_name: field_first_names
                surname: title
                position: field_position
            }

            field_content_type {
                name
                path {
                    alias
                }
            }

            field_external_publication {
                name
                relationships {
                    field_external_publication_logo {
                        relationships {
                            field_media_image {
                                childImageKitAsset {
                                    fluid(
                                        maxWidth: 96
                                        transformations: ["ar-1-1"]
                                    ) {
                                        ...ImageKitFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
