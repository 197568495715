import React, {
    ChangeEvent,
    Dispatch,
    FC,
    FormEvent,
    HTMLAttributes,
    SetStateAction,
    useCallback,
    useEffect,
    useState
} from "react";
import { connectMenu, MenuProvided } from "react-instantsearch-core";
import { SearchFilterContainer } from "../SearchFilterContainer";
import {
    numericalSort,
    reverseNumericalSort
} from "./JournalIssueFilter.helpers";

import * as styles from "./JournalIssueFilter.module.scss";

export type AlgoliaFacetName =
    | "periodical_name"
    | "project_name"
    | "location_title"
    | "region_name"
    | "topics_name"
    | "networks_name"
    | "content_type"
    | "research_expert"
    | "type";

interface Props extends HTMLAttributes<HTMLElement> {
    heading: string;
    seeAllLabel?: string;
}

interface RefinementDropdownProps extends MenuProvided {
    updateState: Dispatch<SetStateAction<string>>;
}

const RefinementDropdown: FC<RefinementDropdownProps> = ({
    items,
    refine,
    currentRefinement,
    updateState
}) => {
    const onChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            refine(e.currentTarget.value);
            updateState(e.currentTarget.value);
        },
        [refine, updateState]
    );

    return (
        <select onChange={onChange} value={currentRefinement ?? ""}>
            <option value="">All</option>
            {items.map(item => (
                <option
                    key={`key-${item.value}`}
                    value={item.isRefined ? currentRefinement : item.value}
                >
                    {item.label}
                </option>
            ))}
        </select>
    );
};

export const CustomRefinementDropdown = connectMenu(RefinementDropdown);

export const JournalIssueFilter: FC<Props> = ({
    className,
    heading,
    seeAllLabel = "All volumes and issues"
}) => {
    const handleSubmit = useCallback((e: FormEvent) => {
        e.preventDefault();
    }, []);

    const [volState, setVolState] = useState<string>("");
    const [issueState, setIssueState] = useState<string>("");
    const [label, setLabel] = useState(seeAllLabel);

    useEffect(() => {
        if (!volState && !issueState) {
            setLabel(seeAllLabel);
        }

        const string = [
            volState ? `Vol ${volState}` : undefined,
            issueState ? `Issue ${issueState}` : undefined
        ]
            .filter(Boolean)
            .join(" / ");

        setLabel(string);
    }, [issueState, seeAllLabel, volState]);

    return (
        <SearchFilterContainer
            heading={heading}
            currentLabel={label || seeAllLabel}
            active={!!(volState || issueState)}
            className={className}
        >
            <form onSubmit={handleSubmit}>
                <div className={styles.filterValues}>
                    <label>
                        <span>Volume / Year</span>
                        <CustomRefinementDropdown
                            updateState={setVolState}
                            limit={100}
                            transformItems={props =>
                                props.sort(reverseNumericalSort)
                            }
                            attribute="field_journal_volume"
                        />
                    </label>
                    <label>
                        <span>Issue Number</span>
                        <CustomRefinementDropdown
                            updateState={setIssueState}
                            limit={20}
                            transformItems={props => props.sort(numericalSort)}
                            attribute="field_publication_issue_meta"
                        />
                    </label>
                </div>
            </form>
        </SearchFilterContainer>
    );
};

export default JournalIssueFilter;
