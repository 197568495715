// extracted by mini-css-extract-plugin
export var desktopOnly = "Person-module--desktopOnly--be895";
export var fadePop = "Person-module--fade-pop--35f72";
export var greyBg = "Person-module--greyBg--22529";
export var growHeightIn = "Person-module--grow-height-in--f2de9";
export var mobileOnly = "Person-module--mobileOnly--90d52";
export var paragraphs = "Person-module--paragraphs--e7ba2";
export var placeholder = "Person-module--placeholder--538c8";
export var profile = "Person-module--profile--172a9";
export var related = "Person-module--related--f0c23";
export var sidebar = "Person-module--sidebar--bbd92";
export var sidebarStyleItem = "Person-module--sidebarStyleItem--fc406";
export var topHr = "Person-module--topHr--4f62c";
export var twoColumnLayout = "Person-module--twoColumnLayout--88fb2";
export var underline = "Person-module--underline--b2c65";