import React, { FC, HTMLAttributes, useState } from "react";
import cx from "classnames";
import * as styles from "./Footnote.module.scss";
import { useTextParagraphContext } from "../../containers/TextParagraph/TextParagraph.context";

interface Props extends HTMLAttributes<HTMLElement> {
    footnotes?: [] | null;
    attribs: [] | null;
}

export const Footnote: FC<Props> = ({ attribs, ...props }) => {

    const { footNotes } = useTextParagraphContext();
    const { id } = props;

    const footNote = footNotes.find(o => o.drupal_id === attribs["data-entity-uuid"]);
    if (!footNote) return null;

    // React state to manage visibility
    const [show, setShow] = useState();

    //strip p tags and new lines.
    let footnoteStripped = footNote.body?.processed.replace(/(<([<p>]+)>)/gi, "");
    footnoteStripped = footnoteStripped.replace(/(<([</p>]+)>)/gi, "<br /><br />");
    footnoteStripped = footnoteStripped.replace(/(?:\r\n|\r|\\n|\n)/g, '');

    // function to toggle the boolean value
    function toggleShow() {
        setShow(!show);
    }

    return (
        <>
            <a
                href={`#footnotes`}
                className={styles.toggleFootnote}
                onClick={toggleShow}
                id={id}
            >
                {footNote?.title}
            </a>
            {show && (
                <span
                    className={styles.footnote}
                    dangerouslySetInnerHTML={{
                        __html: footnoteStripped ?? ""
                    }}
                />
            )}
        </>
    );
};

export default Footnote;
