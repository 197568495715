import cx from "classnames";
import React, { FC, HTMLAttributes, useEffect, useState } from "react";

import { IconSprite } from "../IconSprite/IconSprite";
import * as styles from "./CiteThisPage.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    citation: string;
}

export const CiteThisPage: FC<Props> = ({ className, citation, ...rest }) => {
    const [printRequested, setCiteRequested] = useState(false);

    useEffect(() => {
        if (!printRequested) return;
        window.print();
        setCiteRequested(false);
    }, [printRequested]);

    return (
        <button
            title="Click and paste to cite"
            className={cx(styles.component, className)}
            onClick={() => navigator.clipboard.writeText(citation)}
            {...rest}
        >
            <div className={styles.iconWrapper}>
                <IconSprite className={styles.icon} name="quotes" />
            </div>
            <div>
                <span className={styles.label}>Cite this</span>
            </div>
        </button>
    );
};
export default CiteThisPage;
