import cx from "classnames";
import BackgroundImage from "gatsby-background-image";
import { FluidObject } from "gatsby-image";
import React from "react";
import { LinkOrSpan } from "../LinkOrSpan";

import SocialShareBlock from "../SocialShareBlock";
import {
    component,
    content,
    researchGroup,
    link
} from "./ImageTitleBlock.module.scss";

interface Props {
    title: string;
    summary?: string;
    url: string;
    image: FluidObject;
    variant?: "conference" | "research group";
    category?: string | null;
    categoryUrl?: string | null;
    className?: string;
}

export const ImageTitleBlock: React.FC<Props> = ({
    title,
    summary,
    url,
    image,
    className,
    variant = "conference",
    category,
    categoryUrl
}) => (
    <BackgroundImage className={cx(component, className)} fluid={image}>
        <div
            className={cx(content, {
                [researchGroup]: variant === "research group"
            })}
        >
            <header>
                <LinkOrSpan url={categoryUrl} className={link}>
                    <label>{category}</label>
                </LinkOrSpan>
                <h1>{title}</h1>
                {summary && <p>{summary}</p>}
            </header>
            <div>
                <SocialShareBlock variant="header" url={url} />
            </div>
        </div>
    </BackgroundImage>
);
