import React from "react";
import { ParagraphNode, Paragraphs } from "../Paragraphs";
import Section from "../Section";

interface Props {
    sections: any[]; // TODO: Update types
    firstSection?: boolean;
    lastSection?: boolean;
    landingPageSpacing?: boolean;
    contentType?: string;
    contentTitle?: string;
    hideBorderTop?: boolean;
    paragraphTextFootnotes?: GatsbyTypes.FieldFootnoteFragment[];
    isNetworksPage?: boolean;
}

export const ContentTemplate: React.FC<Props> = ({
    sections,
    landingPageSpacing = false,
    hideBorderTop = false,
    firstSection = false,
    lastSection = false,
    paragraphTextFootnotes = [],
    isNetworksPage = false,
    ...rest
}) => {
    return (
        <>
            {sections.map((section, index) => {
                const data: ParagraphNode = section?.fields?.sections || [];

                if (!section || !data) return null;

                return (
                    <Section
                        key={`content-section-${index}`}
                        background={section.field_background}
                        layout={section.field_paragraph_container_style}
                        firstSection={firstSection}
                        hideBorderTop={hideBorderTop}
                        renderClosingRule={
                            !isNetworksPage &&
                            lastSection &&
                            index === sections.length - 1
                        }
                        landingPageSpacing={landingPageSpacing}
                    >
                        <Paragraphs
                            data={data}
                            wrapInDiv={false}
                            backgroundColor={section.field_background}
                            paragraphTextFootnotes={paragraphTextFootnotes}
                            isNetworksPage={isNetworksPage}
                            {...rest}
                        />
                    </Section>
                );
            })}
        </>
    );
};
