import React, { ReactElement, useEffect, useState } from "react";
import cx from "classnames";
import {
    component,
    button,
    active,
    background,
    inner,
    content
} from "./TabbedSection.module.scss";

/**
 * Helper function to replace the current URL with a new one without reloading the page.
 */
const replaceState = (params: URLSearchParams) => {
    const newUrl = [window.location.pathname, params.toString()]
        .filter(Boolean)
        .join("?");

    //  manually call `window.history.replaceState` to avoid the router
    // triggering a refresh.
    window.history.replaceState(null, "", newUrl);
};

/**
 * `navigate` from `@reach/router` will reload the page. This is a workaround to
 * prevent that.
 *
 * inspired by: https://github.com/kentcdodds/kentcdodds.com/blob/main/app/utils/misc.tsx#L296
 *
 * from this GH issue: https://github.com/remix-run/react-router/issues/8908
 *
 * ⚠️ This can only handle one TabbedSection per page.  We will have to
 * refactor this if we need more than one.
 */
const useTrackCurrentTab = () => {
    // track the index here for the component to use
    const [index, _setIndex] = useState(0);

    // using an initialiser in the setState won't work as the params aren't set
    // at that point. So we need to use useEffect to set the initial value.
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const currentTab = params.get("tab");

        if (currentTab !== null) {
            _setIndex(parseInt(currentTab));
            replaceState(params);
        }
    }, []);

    const setIndex = (index: number) => {
        const params = new URLSearchParams(window.location.search);
        params.set("tab", index.toString());

        _setIndex(index);
        replaceState(params);
    };

    return [index, setIndex] as const;
};

interface TabProps {
    hidden: boolean;
}

export const Tab: React.FC<TabProps> = props => <div {...props} />;

interface TabbedSectionProps {
    title: string;
    className?: string;
    children: ReactElement<TabProps> | Array<ReactElement<TabProps>>;
}

export const TabbedSection: React.FC<TabbedSectionProps> = ({
    title,
    className,
    children
}) => {
    const [index, setIndex] = useTrackCurrentTab();

    return (
        <div className={cx(component, className)}>
            {title && <h2>{title}</h2>}
            <nav aria-label="in page navigation">
                {React.Children.map(children, (child, i) => (
                    <button
                        key={child.key}
                        title={child.key}
                        onClick={() => setIndex(i)}
                        className={cx(button, {
                            [active]: i === index
                        })}
                    >
                        {child.key}
                    </button>
                ))}
            </nav>
            <section className={content}>
                <div className={background} />
                <div className={inner}>
                    {React.Children.map(children, (child, i) => (
                        <div hidden={i !== index}>{child}</div>
                    ))}
                </div>
            </section>
        </div>
    );
};
