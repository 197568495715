import cx from "classnames";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import React, { FC, HTMLAttributes } from "react";

import { stripHtml } from "../../lib/stringUtils";
import { IconSprite } from "../IconSprite";
import * as styles from "./PullQuoteParagraph.module.scss";

interface ContentProps {
	quote: string;
}

interface Props extends HTMLAttributes<HTMLElement> {
	node?: GatsbyTypes.PullQuoteParagraphDataFragment | null;
}

const getCredit = (
	node?: GatsbyTypes.PullQuoteParagraphDataFragment | null,
) => {
	if (!node) return undefined;

	if (node?.field_quote_credit) return node?.field_quote_credit;

	return `${
		node?.relationships?.field_pull_quote_author?.field_first_names ?? ""
	} ${node?.relationships?.field_pull_quote_author?.title ?? ""}`;
};

// eslint-disable-next-line complexity
const getSubCredit = (
	node?: GatsbyTypes.PullQuoteParagraphDataFragment | null,
) => {
	if (!node) {
		return undefined;
	}

	if (node?.field_quote_sub_credit) {
		return node?.field_quote_sub_credit;
	}

	const linkedPersonPosition =
		node?.relationships?.field_pull_quote_author?.field_position;
	const linkedPersonDept =
		node?.relationships?.field_pull_quote_author?.relationships
			?.field_staff_department?.[0]?.name;
	const hasBoth = linkedPersonPosition && linkedPersonDept;
	return linkedPersonPosition || linkedPersonPosition
		? `${linkedPersonPosition ?? ""} ${hasBoth ? " / " : ""} ${
				linkedPersonDept ?? ""
		  }`
		: undefined;
};

const getImage = (node?: GatsbyTypes.PullQuoteParagraphDataFragment | null) =>
	node?.relationships?.field_media?.relationships?.field_media_image
		?.childImageKitAsset?.fluid ??
	node?.relationships?.field_pull_quote_author?.relationships
		?.field_user_picture?.childImageKitAsset?.fluid;	

const getPlainImage = (
	node?: GatsbyTypes.PullQuoteParagraphDataFragment | null,
) => ({
	src: node?.relationships?.field_pull_quote_author?.relationships
		?.field_user_picture?.uri?.url,
	alt: node?.relationships?.field_pull_quote_author?.title,
});

// const getImage = (node?: GatsbyTypes.PullQuoteParagraphDataFragment | null) =>
// 	node?.relationships?.field_media?.relationships?.field_media_image
// 		?.childImageKitAsset?.fluid ??
// 	node?.relationships?.field_pull_quote_author?.relationships
// 		?.field_user_picture?.childImageKitAsset?.fluid;

const PullQuoteMain: FC<Props> = ({ node }) => {
	const credit = getCredit(node);
	const subCredit = getSubCredit(node);
	const image = getImage(node);
	const plainImage = getPlainImage(node);
	const quote = node?.field_text?.processed ?? "";	

	return (
		<>
			<div className={styles.contentWrapper}>
				<IconSprite name="quote" className={styles.icon} />
				<PullQuoteContent quote={quote} />
			</div>
			<div className={styles.credit}>
				{!plainImage.src && image && (
					<figure className={cx(styles.imageWrapper, "hideOnPrint")}>
						<Image className={styles.image} fluid={image} />
					</figure>
				)}
				{!image && plainImage.src && (
					<figure className={cx(styles.imageWrapper, "hideOnPrint")}>
						<img
							className={styles.image}
							src={plainImage.src}
							alt={plainImage.alt}
						/>
					</figure>
				)}
				<div className={styles.creditText}>
					{credit && <p className={styles.creditName}>{credit}</p>}
					{subCredit && <p className={styles.subCredit}>{subCredit}</p>}
				</div>
			</div>
		</>
	);
};

const PullQuoteContent: FC<ContentProps> = ({ quote }) => (
	<blockquote className={styles.content}>{stripHtml(quote)}</blockquote>
);

export const PullQuoteParagraph: FC<Props> = ({
	node,
	className,
	...props
}) => {
	const quote = node?.field_text?.processed;
	const centered = node?.field_quote_style === "centered";

	const styleClass = centered ? "centered" : "normal";

	if (!quote) {
		return null;
	}
	return (
		<div
			className={cx(styles.component, styles[styleClass], className)}
			{...props}
		>
			{centered ? (
				<PullQuoteContent quote={quote} />
			) : (
				<PullQuoteMain node={node} />
			)}
		</div>
	);
};

export const fragment = graphql`
    fragment PullQuoteParagraphData on paragraph__pull_quote {
        __typename
        id
        field_quote_credit
        field_quote_sub_credit
        field_text {
            processed
        }
        field_quote_style
        relationships {
            field_media {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            id
                            fluid(
                                maxWidth: 56
                                transformations: ["ar-1-1", "fo-face", "r-max"]
                            ) {
                                __typename
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
            field_pull_quote_author {
                id
                title
                field_first_names
                path {
                    alias
                }
                field_position
                relationships {
                    field_staff_department {
                        name
                    }

                    field_user_picture {
                        childImageKitAsset {
                            id
                            fluid(
                                maxWidth: 56
                                transformations: ["ar-1-1", "fo-face", "r-max"]
                            ) {
                                __typename
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default PullQuoteParagraph;
