import React, { FC, HTMLAttributes } from "react";
import cx from "classnames";
import * as styles from "./AboutBlock.module.scss";
import { Button } from "../Button";
import { Link } from "../Link";

interface Props extends HTMLAttributes<HTMLElement> {
    label?: string;
    statement?: string;
    buttonText?: string;
    buttonLink?: string;
}

export const AboutBlock: FC<Props> = ({
    label,
    statement,
    buttonText,
    buttonLink,
    className,
    ...props
}) => (
    <section className={cx(styles.component, className)} {...props}>
        {label && <label>{label}</label>}

        <div className={styles.statementButtonWrapper}>
            {statement && <p>{statement}</p>}
            {!!(buttonText && buttonLink) && (
                <Link to={buttonLink}>{buttonText}</Link>
            )}
        </div>
    </section>
);

export default AboutBlock;
