import React, { FC, HTMLAttributes } from "react";
import { graphql, useStaticQuery } from "gatsby";
import LockedDownload from "../LockedDownload/LockedDownload";

interface Props extends HTMLAttributes<HTMLElement> {
    node?: GatsbyTypes.LockedDownloadParagraphDataFragment;
}

export const LockedDownloadParagraph: FC<Props> = ({ node, ...rest }) => {
    const disclaimerBox = useStaticQuery<GatsbyTypes.DisclaimerBoxDataQuery>(
        disclaimerBoxQuery
    );

    if (!node?.relationships?.field_media?.relationships?.field_media_file) {
        return null;
    }

    const { id, body, ...disclaimerProps } = disclaimerBox.data;

    return (
        <LockedDownload
            terms={body?.processed ?? ""}
            pdf={[
                node?.relationships?.field_media?.relationships
                    ?.field_media_file
            ]}
            {...disclaimerProps}
            {...rest}
        />
    );
};

export const fragment = graphql`
    fragment LockedDownloadParagraphData on paragraph__locked_download {
        __typename
        id
        relationships {
            field_media {
                id
                relationships {
                    field_media_file {
                        id
                        filemime
                        filesize
                        filename
                        fields {
                            cdn_url
                        }
                    }
                }
            }
        }
    }
`;

const disclaimerBoxQuery = graphql`
    query DisclaimerBoxData {
        data: configPagesDownloadDisclaimer {
            body: field_body {
                processed
            }
            topbarText: field_box_heading
            buttonLabel: field_button_link_text
            checkBoxLabel: field_terms_text
            heading: field_title
            id
        }
    }
`;

export default LockedDownloadParagraph;
