import { graphql, useStaticQuery } from "gatsby";
import React, { FC, HTMLAttributes } from "react";

import MembershipGate from "../MembershipGate/MembershipGate";
export type MemberGateReason =
	| "no-account"
	| "non-member"
	| "upgrade"
	| "error";

interface Props extends HTMLAttributes<HTMLElement> {
	reason: MemberGateReason | null;
	variant?: "article" | "media";
}

// eslint-disable-next-line complexity
export const MembershipGateContainer: FC<Props> = ({
	variant = "article",
	reason,
	...props
}) => {
	const { noAccount, noMembership, insufficientMembership } =
		useStaticQuery<GatsbyTypes.MembershipGateDataQuery>(membershipGateQuery);

	if (reason === "no-account") {
		const {
			field_body,
			field_button,
			field_footer,
			field_heading,
			field_media,
			field_membership_link,
			field_box_heading,
		} = noAccount;

		return (
			<MembershipGate
				boxHeading={field_box_heading}
				body={field_body?.processed ?? ""}
				buttonTitle={field_button?.title ?? ""}
				buttonLink={field_button?.url ?? ""}
				footer={field_footer?.processed ?? ""}
				heading={variant === "article" ? field_heading : field_media}
				// membershipLinkText={field_membership_link?.title ?? ""}
				// membershipLinkPath={field_membership_link?.url ?? ""}
				{...props}
			/>
		);
	}

	if (reason === "non-member") {
		const {
			field_body,
			field_button,
			field_footer,
			field_heading,
			field_media,
			field_membership_link,
			field_box_heading,
		} = noMembership;

		return (
			<MembershipGate
				boxHeading={field_box_heading}
				body={field_body?.processed ?? ""}
				buttonTitle={field_button?.title ?? ""}
				buttonLink={field_button?.url ?? ""}
				footer={field_footer?.processed ?? ""}
				heading={variant === "article" ? field_heading : field_media}
				hideLogin
				{...props}
			/>
		);
	}

	if (reason === "upgrade") {
		const {
			field_body,
			field_button,
			field_footer,
			field_heading,
			field_media,
			field_box_heading,
		} = insufficientMembership;

		return (
			<MembershipGate
				boxHeading={field_box_heading}
				body={field_body?.processed ?? ""}
				buttonTitle={field_button?.title ?? ""}
				buttonLink={field_button?.url ?? ""}
				footer={field_footer?.processed ?? ""}
				heading={variant === "article" ? field_heading : field_media}
				hideLogin
				{...props}
			/>
		);
	}

	if (reason === "error") {
		return null;
	}

	return null;
};

const membershipGateQuery = graphql`
    query MembershipGateData {
        noAccount: configPagesGcmNoAccount {
            field_box_heading
            field_media
            field_body {
                processed
            }
            field_button {
                title
                url
            }
            field_footer {
                processed
            }
            field_heading
            field_membership_link {
                title
                url
            }
        }
        noMembership: configPagesGcmNoMembership {
            field_box_heading
            field_media
            field_body {
                processed
            }
            field_button {
                title
                url
            }
            field_footer {
                processed
            }
            field_heading
            field_membership_link {
                title
                url
            }
        }
        insufficientMembership: configPagesGcmInsufficientMembership {
            field_box_heading
            field_media
            field_body {
                processed
            }
            field_button {
                title
                url
            }
            field_footer {
                processed
            }
            field_heading
            field_membership_link {
                title
                url
            }
        }
    }
`;

export default MembershipGate;
