/* eslint-disable complexity */
import React, { FC, HTMLAttributes } from "react";
import { AboutBlockParagraph } from "../../containers/AboutBlockParagraph";
import AgendaParagraph from "../../containers/AgendaParagraph";
import { BrandSubstantiationBlockParagraph } from "../../containers/BrandSubstantiationBlockParagraph";
import { HeroArticleParagraph } from "../../containers/HeroArticleParagraph";
import IconLinks from "../../containers/IconLinks";
import InSectionSearch from "../../containers/InSectionSearch";
import { IndentedContentParagraph } from "../../containers/IndentedContentParagraph";
import { LatestNewsParagraph } from "../../containers/LatestNewsParagraph";
import LatestPublicationsDynamic from "../../containers/LatestPublicationsDynamic/LatestPublicationsDynamic";
import { MemberPromoCtaBlockParagraph } from "../../containers/MemberPromoCtaBlockParagraph";
import NewsletterSignup from "../../containers/NewsletterSignup";
import ProfileReferences from "../../containers/ProfileReferences";
import RSSFeed from "../../containers/RSSFeed";
import { SignpostGridParagraph } from "../../containers/SignpostGridParagraph/SignpostGridParagraph";
import SignpostsWithSubSectionLinks from "../../containers/SignpostsWithSubSectionLinks";
import SingleSignpost from "../../containers/SingleSignpost/SingleSignpost";
import Speakers from "../../containers/Speakers";
import TabsContainer from "../../containers/TabsContainer";
import { TextParagraph } from "../../containers/TextParagraph";
import { UpcomingEventParagraph } from "../../containers/UpcomingEventParagraph";
import UpcomingEventsDynamic from "../../containers/UpcomingEventsDynamic";
import { AccordionParagraph } from "../AccordionParagraph";
import { ButtonLabelLinksParagraph } from "../ButtonLabelLinksParagraph";
import { IframeEmbedParagraph } from "../IframeEmbedParagraph";
import IframeGrid from "../IframeGrid";
import { ImageParagraph } from "../ImageParagraph";
import { LockedDownloadParagraph } from "../LockedDownloadParagraph";
import { PullQuoteParagraph } from "../PullQuoteParagraph";
import { RelatedProjectsParagraph } from "../RelatedProjectsParagraph";
import { SignpostParagraph } from "../SignpostParagraph";
import { SponsorsParagraph } from "../SponsorsParagraph";
import { VideoEmbedParagraph } from "../VideoEmbedParagraph";
import { ParagraphNode } from "./Paragraphs.types";

interface ParentElProps extends HTMLAttributes<HTMLElement> {
	wrapInDiv?: boolean;
}
interface Props extends HTMLAttributes<HTMLElement> {
	data?: ParagraphNode;
	containerStyle?: string;
	backgroundColor?: "grey" | "white";
	wrapInDiv?: boolean;
	contentType?: string;
	contentTitle?: string;
	personFirstName?: string;
	paragraphTextFootnotes?: GatsbyTypes.FieldFootnoteFragment[];
	isNetworksPage?: boolean;
}

const ParentEl: FC<ParentElProps> = ({ wrapInDiv, className, children }) =>
	wrapInDiv ? <div className={className}>{children}</div> : <>{children}</>;

export const Paragraphs: FC<Props> = ({
	data = [],
	containerStyle,
	className,
	contentType,
	contentTitle,
	personFirstName,
	paragraphTextFootnotes = [],
	backgroundColor = "white",
	wrapInDiv = true,
	isNetworksPage = false,
}) => {
	if (!data?.length) return null;
	return (
		<ParentEl wrapInDiv={wrapInDiv} className={className}>
			{data?.filter(Boolean).map((item) => {
				const typedItem = item as ParagraphFragment;
				const type = typedItem.__typename || item.internal?.type;
				switch (type) {
					case "paragraph__text_area":
						return (
							<TextParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.TextParagraphDataFragment}
								containerStyle={containerStyle}
								footnotes={paragraphTextFootnotes}
							/>
						);
					case "paragraph__pull_quote":
						return (
							<PullQuoteParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.PullQuoteParagraphDataFragment}
							/>
						);
					case "paragraph__video_embed":
						return (
							<VideoEmbedParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.VideoEmbedParagraphDataFragment}
							/>
						);
					case "paragraph__accordion_container":
						return (
							<AccordionParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.AccordionParagraphDataFragment}
							/>
						);
					case "paragraph__media_image":
						return (
							<ImageParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.ImageParagraphDataFragment}
							/>
						);
					case "paragraph__iframe_embed":
						return (
							<IframeEmbedParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.EmbedParagraphDataFragment}
							/>
						);
					case "paragraph__signposted_content":
						return (
							<SignpostParagraph
								key={`paragraphItem-${typedItem.id}`}
								backgroundColor={backgroundColor}
								node={typedItem as GatsbyTypes.SignpostParagraphDataFragment}
							/>
						);
					case "paragraph__sponsors":
						return (
							<SponsorsParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.SponsorsParagraphDataFragment}
							/>
						);
					case "paragraph__agenda":
						return (
							<AgendaParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.AgendaParagraphDataFragment}
							/>
						);
					case "paragraph__button_label_links":
						return (
							<ButtonLabelLinksParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={
									typedItem as GatsbyTypes.ButtonLabelLinksParagraphDataFragment
								}
								containerStyle={containerStyle}
							/>
						);
					case "paragraph__indented_signposts_block":
						return (
							<IndentedContentParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={
									typedItem as GatsbyTypes.IndentedContentParagraphDataFragment
								}
							/>
						);
					case "paragraph__topic_upcoming_event":
						return (
							<UpcomingEventParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={
									typedItem as GatsbyTypes.UpcomingEventParagraphDataFragment
								}
							/>
						);
					case "paragraph__topic_latest_publications":
						return (
							<LatestPublicationsDynamic
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.LatestPublicationsDataFragment}
								contentTitle={contentTitle}
								contentType={contentType}
								personFirstName={personFirstName}
							/>
						);
					case "paragraph__locked_download":
						return (
							<LockedDownloadParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={
									typedItem as GatsbyTypes.LockedDownloadParagraphDataFragment
								}
							/>
						);
					case "paragraph__speaker_container":
						return (
							<Speakers
								key={`paragraphItem-${typedItem.id}`}
								{...(typedItem as GatsbyTypes.paragraph__speaker_container)}
								containerStyle={containerStyle}
							/>
						);
					case "paragraph__latest_news_signpost":
						return (
							<LatestNewsParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.paragraph__latest_news_signpost}
								contentTitle={contentTitle}
								contentType={contentType}
								personFirstName={personFirstName}
							/>
						);
					case "paragraph__related_projects_grid_row":
						return (
							<RelatedProjectsParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={
									typedItem as GatsbyTypes.paragraph__related_projects_grid_row
								}
							/>
						);
					case "paragraph__newsletter_sign_up":
						return (
							<NewsletterSignup
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.NewsletterSignupFragment}
							/>
						);
					case "paragraph__profile_references":
						return (
							<ProfileReferences
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.ProfileReferencesDataFragment}
							/>
						);
					case "paragraph__icon_links":
						return (
							<IconLinks
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.IconLinksDataFragment}
							/>
						);
					case "paragraph__upcoming_events":
						return (
							<UpcomingEventsDynamic
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.UpcomingEventsDataFragment}
								contentTitle={contentTitle}
								contentType={contentType}
							/>
						);
					case "paragraph__signposts_with_sub_section_links":
						return (
							<SignpostsWithSubSectionLinks
								key={`paragraphItem-${typedItem.id}`}
								node={
									typedItem as GatsbyTypes.SignpostsWithSubSectionLinksFragment
								}
							/>
						);
					case "paragraph__in_section_search_listing":
						return (
							<InSectionSearch
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.InSectionSearchFragment}
							/>
						);
					case "paragraph__tabs_container":
						return (
							<TabsContainer
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.TabsContainerFragment}
								hideUnderline={isNetworksPage}
							/>
						);
					case "paragraph__signpost_grid":
						return (
							<SignpostGridParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.ParagraphSignpostGridFragment}
							/>
						);
					case "paragraph__single_signpost":
						return (
							<SingleSignpost
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.SingleSignpostDataFragment}
							/>
						);
					case "paragraph__rss_feed":
						return (
							<RSSFeed
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.ParagraphRSSFeedFragment}
							/>
						);
					case "paragraph__about_block":
						return (
							<AboutBlockParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.AboutBlockDataFragment}
							/>
						);
					case "paragraph__brand_substantiation_block":
						return (
							<BrandSubstantiationBlockParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={
									typedItem as GatsbyTypes.BrandSubstantiationBlockDataFragment
								}
							/>
						);
					case "paragraph__membership_cta_block":
						return (
							<MemberPromoCtaBlockParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={
									typedItem as GatsbyTypes.MemberPromoCtaBlockParagraphDataFragment
								}
							/>
						);
					case "paragraph__hero_article":
						return (
							<HeroArticleParagraph
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.HeroArticleDataFragment}
							/>
						);
					case "paragraph__iframe_grid":
						return (
							<IframeGrid
								key={`paragraphItem-${typedItem.id}`}
								node={typedItem as GatsbyTypes.ParagraphIframeGridFragment}
							/>
						);
					default:
						return null;
				}
			})}
		</ParentEl>
	);
};

export default Paragraphs;
