interface Params {
    regions?: Array<{ name: string }>;
    // relatedProjects?: Array<{ name: string }>;
    topics?: Array<{ name: string }>;
    researchGroups?: Array<{ name: string }>;
}

// eslint-disable-next-line complexity
export const getFilterString = ({
    regions,
    // relatedProjects,
    topics,
    researchGroups
}: Params): string => {
    const initialString = `(type:article)`;
    const regionsString =
        regions && regions.length >= 0
            ? getFacetFilterString(regions, "name", "region_name")
            : null;
    const topicsString =
        topics && topics.length >= 0
            ? getFacetFilterString(topics, "name", "topics_name")
            : null;
    const researchGroupsString =
        researchGroups && researchGroups.length >= 0
            ? getFacetFilterString(
                  researchGroups,
                  "name",
                  "research_groups_name"
              )
            : null;

    const filterString = [regionsString, topicsString, researchGroupsString]
        .filter(Boolean)
        .join(" OR ");

    return `${initialString} ${filterString ? ` AND (${filterString}) ` : ""}`;
};

export const getFacetFilterString = (
    array: Array<{ [x: string]: string | number }>,
    key: string,
    facetName: string
): string => {
    return array?.length > 0
        ? `(${array
              .map(x => (x ? x[key] : null))
              .filter(Boolean)
              .map(x => `${facetName}:"${x}"`)
              .join(" OR ")})`
        : "";
};
