import React, { FC, HTMLAttributes, useCallback, useState } from "react";
import cx from "classnames";
import * as styles from "./TogglableContent.module.scss";
import { IconSprite } from "../IconSprite";

interface Props extends HTMLAttributes<HTMLElement> {
    contentName: string;
    contentHeight?: number;
}

export const TogglableContent: FC<Props> = ({
    className,
    contentName,
    contentHeight = 350,
    children,
    ...props
}) => {
    const [open, setOpen] = useState(false);

    const clickHandler = useCallback((e: MouseEvent) => {
        e.preventDefault();
        setOpen(prev => !prev);
    }, []);

    return (
        <div className={className} {...props}>
            <button className={styles.button} onClick={clickHandler}>
                <IconSprite
                    name={open ? "minus" : "plus"}
                    className={styles.icon}
                />
                <span>{`${open ? "Hide" : "Show"} ${contentName}`}</span>
            </button>
            <div
                className={cx(styles.content, { [styles.open]: open })}
                style={{ maxHeight: open ? contentHeight : 0 }}
            >
                {children}
            </div>
        </div>
    );
};

export default TogglableContent;
