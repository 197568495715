// extracted by mini-css-extract-plugin
export var active = "MegaMenu-module--active--1f882";
export var body = "MegaMenu-module--body--af7c2";
export var border = "MegaMenu-module--border--31999";
export var column = "MegaMenu-module--column--8e3d9";
export var component = "MegaMenu-module--component--5760e";
export var content = "MegaMenu-module--content--37752";
export var fadePop = "MegaMenu-module--fade-pop--b3efd";
export var growHeightIn = "MegaMenu-module--grow-height-in--ce0da";
export var icon = "MegaMenu-module--icon--df27a";
export var link = "MegaMenu-module--link--af7be";
export var open = "MegaMenu-module--open--56c9f";
export var researchMenu = "MegaMenu-module--researchMenu--33834";
export var researchMenuBranch = "MegaMenu-module--researchMenuBranch--a91aa";
export var researchMenuTree = "MegaMenu-module--researchMenuTree--6e3e0";
export var sub_menu = "MegaMenu-module--sub_menu--44960";
export var title = "MegaMenu-module--title--89ee0";