import cx from "classnames";
import { Link } from "../Link";
import React, { FC, HTMLAttributes, useMemo } from "react";

import { Crumb } from "../../types/breadcrumbs";
import * as styles from "./BreadCrumb.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    crumbs: Crumb[];
}

// First item is alway "home", last itekm is current page, we want neither
const filterCrumbs = (crumbs: Crumb[]): Crumb[] => {
    const copy = [...crumbs];
    copy.pop();
    copy.shift();
    return copy;
};

const addCaps = (str: string) =>
    str.toLowerCase() === "rusi" //RUSI should always be fulluy in uppercase
        ? "RUSI"
        : str[0].toUpperCase() + str.substr(1);

const capitaliseFirstLetters = (str: string) =>
    str
        .split("-")
        .map(addCaps)
        .join(" ");

const formatCrumbText = (crumbs: Crumb[]): Crumb[] =>
    crumbs.map(crumb => ({
        ...crumb,
        crumbLabel: capitaliseFirstLetters(crumb.crumbLabel)
    }));

export const BreadCrumb: FC<Props> = ({ crumbs, className, ...props }) => {
    const filteredCrumbs = useMemo(() => filterCrumbs(crumbs), [crumbs]);
    const capitalisedCrumbs = useMemo(() => formatCrumbText(filteredCrumbs), [
        filteredCrumbs
    ]);
    return (
        <div className={styles.component} aria-hidden>
            <ul className={cx(styles.breadcrumb, className)} {...props}>
                {capitalisedCrumbs.map(({ crumbLabel, pathname }, index) => (
                    <li key={`crumb${index}`}>
                        <Link to={pathname}>{crumbLabel}</Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default BreadCrumb;
