import React from "react";
import { graphql } from "gatsby";
import { SignpostCard } from "../../components/SignpostCard";
import * as styles from "./SignpostsWithSubSectionLinks.module.scss";
import cx from "classnames";

interface Props {
    node: GatsbyTypes.SignpostsWithSubSectionLinksFragment;
}

export const SignpostsWithSubSectionLinks: React.FC<Props> = ({ node }) => {
    const variant = node?.field_layout ?? "grid";
    return (
        <section className={styles[variant]}>
            {node?.field_title && (
                <>
                    <h2>{node?.field_title}</h2>
                    <hr />
                </>
            )}
            <div className={styles.items}>
                {node.relationships?.field_signposts?.map(signpost =>
                    signpost ? (
                        <SignpostCard
                            variant={
                                variant === "list" ? "horizontal" : "vertical"
                            }
                            backgroundColor="white"
                            key={signpost.id}
                            heading={
                                signpost.field_signpost_primary_link?.title ||
                                ""
                            }
                            summary={signpost.field_signpost_summary}
                            url={signpost.field_signpost_primary_link?.uri}
                            // @ts-ignore:
                            tags={signpost.field_signpost_links || []}
                            image={
                                signpost.relationships?.field_signpost_image
                                    ?.relationships?.field_media_image
                                    ?.childImageKitAsset?.fluid
                            }
                            linkText={signpost.field_call_to_action?.title}
                        />
                    ) : null
                )}
            </div>
        </section>
    );
};
export const fragment = graphql`
    fragment SignpostsWithSubSectionLinks on paragraph__signposts_with_sub_section_links {
        __typename
        id
        field_title
        field_layout
        relationships {
            field_signposts {
                ...SignpostWithSubSectionLinks
            }
        }
    }

    fragment SignpostWithSubSectionLinks on paragraph__signpost_with_sub_section_links {
        id
        field_signpost_summary
        field_call_to_action {
            title
        }
        field_signpost_primary_link {
            title
            uri: url
        }
        field_signpost_links {
            id: url
            label: title
            url
        }
        relationships {
            field_signpost_image {
                relationships {
                    field_media_image {
                        childImageKitAsset {
                            fluid {
                                ...ImageKitFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
