// extracted by mini-css-extract-plugin
export var component = "SignpostCard-module--component--19aff";
export var contentArea = "SignpostCard-module--contentArea--22677";
export var cta = "SignpostCard-module--cta--e6e42";
export var desktopOnly = "SignpostCard-module--desktopOnly--88551";
export var fadePop = "SignpostCard-module--fade-pop--f759f";
export var flex = "SignpostCard-module--flex--d27ce";
export var greyBg = "SignpostCard-module--greyBg--85748";
export var growHeightIn = "SignpostCard-module--grow-height-in--c50d7";
export var hasTags = "SignpostCard-module--hasTags--8664c";
export var horizontal = "SignpostCard-module--horizontal--0b60d";
export var image = "SignpostCard-module--image--2554b";
export var imageTitleWrapper = "SignpostCard-module--imageTitleWrapper--cc480";
export var imageWrapper = "SignpostCard-module--imageWrapper--9df19";
export var mainArea = "SignpostCard-module--mainArea--47d2a";
export var mainLink = "SignpostCard-module--mainLink--ab156";
export var mobileOnly = "SignpostCard-module--mobileOnly--00e7a";
export var noImage = "SignpostCard-module--noImage--f16ee";
export var rightCol = "SignpostCard-module--rightCol--815b3";
export var tagsWrapper = "SignpostCard-module--tagsWrapper--925bb";
export var textWrapper = "SignpostCard-module--textWrapper--33161";
export var title = "SignpostCard-module--title--854e7";
export var togglableTags = "SignpostCard-module--togglableTags--ca9c0";
export var vertical = "SignpostCard-module--vertical--c7e7b";
export var whiteBg = "SignpostCard-module--whiteBg--02048";
export var withImage = "SignpostCard-module--withImage--6fe47";