import cx from "classnames";
import React, { FC, HTMLAttributes, useState } from "react";
import ArticleLayout from "../ArticleLayout/ArticleLayout";

import { ecpNewsletterSignupData } from "../EcpPageComponent/EcpPageComponent";
import { EcpPeople } from "../EcpPeople";
import Layout from "../Layout";
import Section from "../Section";
import { NewsletterSignup } from "../NewsletterSignup";
import { SocialLinks } from "../SocialLinks";
import * as styles from "./EcpMethodologyPageComponent.module.scss";
import EcpMiniNavigation from "../EcpMiniNavigation";
import SEO from "../SEO";
import SocialShareBlock from "../SocialShareBlock";
import { useLocation } from "@reach/router";

interface Props extends HTMLAttributes<HTMLElement> {
    node: GatsbyTypes.EcpQuery["ecp"];
}

export const EcpMethodologyPageComponent: FC<Props> = ({
    node,
    className,
    ...props
}) => {
    const { href } = useLocation();
    const [methodologyOpen, setMethodologyOpen] = useState(false);
    const methodologyOpenClass = methodologyOpen ? "open" : "closed";

    return (
        <Layout>
            <SEO title={node?.title} description={node?.body?.summary} />
            <ArticleLayout>
                <div className={cx(styles.component, className)} {...props}>
                    <EcpMiniNavigation />
                    <Section layout="leftaligned" firstSection hideBorderTop>
                        <h1 className={styles.title}>{node?.title}</h1>
                        <div
                            className={styles.descriptionFromCms}
                            dangerouslySetInnerHTML={{
                                __html: node?.body?.processed ?? ""
                            }}
                        />
                        <SocialShareBlock url={href} variant="block" />
                    </Section>
                    <Section layout="leftaligned">
                        <h2>Methodology: In Detail</h2>
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    node?.field_methodology_details?.[0]
                                        ?.processed ?? ""
                            }}
                        />

                        <button
                            className={cx(
                                styles.openMethodologyButton,
                                styles[methodologyOpenClass]
                            )}
                            onClick={() => setMethodologyOpen(!methodologyOpen)}
                        >
                            Read methodology in full
                        </button>
                        <div
                            className={cx(
                                styles.fullMethodologyText,
                                styles[methodologyOpenClass]
                            )}
                            dangerouslySetInnerHTML={{
                                __html:
                                    node?.field_methodology_details?.[1]
                                        ?.processed ?? ""
                            }}
                        />
                    </Section>

                    <Section layout="fullwidth" background="grey">
                        <EcpPeople data={node?.relationships?.field_sections} />
                    </Section>

                    <Section layout="leftaligned">
                        <div
                            className={styles.aboutCfcs}
                            dangerouslySetInnerHTML={{
                                __html:
                                    node?.field_additional_text_area
                                        ?.processed || ""
                            }}
                        />
                    </Section>
                    <Section layout="fullwidth">
                        <NewsletterSignup
                            title={ecpNewsletterSignupData.field_title}
                            text={ecpNewsletterSignupData.field_subtext}
                            url={ecpNewsletterSignupData.field_mailchimp_url}
                            variant="paragraph"
                        />
                    </Section>
                </div>
            </ArticleLayout>
        </Layout>
    );
};
export default EcpMethodologyPageComponent;
