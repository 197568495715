import { graphql } from "gatsby";
import parse, {
    HTMLReactParserOptions,
    DOMNode,
    Element,
    domToReact
} from "html-react-parser";
import cx from "classnames";
import * as styles from "./TextParagraph.module.scss";
import React, { FC, HTMLAttributes, createContext } from "react";
import { useInPageNavId } from "../../context/InPageNav/useInPageNavId";
import { Footnote } from "../../components/Footnote";
import { TextParagraphContext } from "./TextParagraph.context";
import { TextAreaParsed } from "../../components/TextAreaParsed";

interface Props extends HTMLAttributes<HTMLElement> {
    node?: GatsbyTypes.TextParagraphDataFragment;
    containerStyle?: string;
    footnotes: GatsbyTypes.FieldFootnoteFragment[];
}

export const TextParagraph: FC<Props> = ({
    node,
    containerStyle = "fullwidth",
    className,
    footnotes,
    ...props
}) => {
    const id = useInPageNavId(node);
    const text = node?.field_text?.processed;

    if (!text) {
        return null;
    }

    return (
        <div id={id} {...props} className={cx(styles.component, className)}>
            {node?.field_title && <h2>{node?.field_title}</h2>}
            <TextAreaParsed
                {...props}
                className={className}
                html={text}
                footnotes={footnotes}
            />
        </div>
    );
};

// Exporting a fragment allows it to be used in any GraphQL query
export const fragment = graphql`
    fragment TextParagraphData on paragraph__text_area {
        __typename
        id
        field_is_on_this_page_anchor
        field_title
        field_text {
            processed
            value
        }
    }
`;

export default TextParagraph;
