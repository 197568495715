import cx from "classnames";
import React, { FC, HTMLAttributes, useState } from "react";

import * as styles from "./VideoEmbed.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    heading: string;
    caption: string;
    transcript: string;
    embedMarkup: string;
    id: string;
}

interface TranscriptTogglerProps {
    transcriptShowing: boolean;
    toggleTranscript: () => void;
}

const shouldShowTranscript = (
    transcriptVisible: boolean,
    transcript?: string | null
) => {
    return !!(transcript && transcriptVisible);
};

const TranscriptToggler: FC<TranscriptTogglerProps> = ({
    transcriptShowing,
    toggleTranscript
}) => (
    <button
        onClick={toggleTranscript}
        className={cx(
            styles.transcriptButton,
            styles[transcriptShowing ? "showing" : "hidden"]
        )}
    >
        {`${transcriptShowing ? "Hide" : "View"} Transcript`}
    </button>
);

// eslint-disable-next-line complexity
export const VideoEmbed: FC<Props> = ({
    heading,
    caption,
    transcript,
    embedMarkup,
    className,
    id,
    ...props
}) => {
    const [transcriptVisible, setTranscriptVisible] = useState(false);

    if (!embedMarkup) {
        return null;
    }

    const toggleTranscript = () => {
        setTranscriptVisible(!transcriptVisible);
    };

    const transcriptShowing = shouldShowTranscript(
        transcriptVisible,
        transcript
    );

    const transcriptClass = transcriptShowing
        ? "openTranscript"
        : "closedTranscript";

    const transcriptId = `${id}_transcript`;

    const wrapperProps: HTMLAttributes<HTMLDivElement> = {
        className: styles.iframeWrapper
    };

    if (transcript) {
        wrapperProps["aria-describedby"] = transcriptId;
    }

    return (
        <div
            className={cx(
                styles.component,
                { [styles.withHeading]: !!heading },
                className,
                "hideOnPrint"
            )}
            {...props}
        >
            {heading && (
                <>
                    <h2>{heading}</h2>
                    <hr />
                </>
            )}

            <div
                dangerouslySetInnerHTML={{ __html: embedMarkup }}
                {...wrapperProps}
            />

            <div className={styles.bottomStrip}>
                {caption && <p className={styles.caption}>{caption}</p>}
                {transcript && (
                    <TranscriptToggler
                        transcriptShowing={transcriptShowing}
                        toggleTranscript={toggleTranscript}
                    />
                )}
            </div>

            <p className={styles[transcriptClass]} id={transcriptId}>
                {transcript}
            </p>
        </div>
    );
};

export default VideoEmbed;
