import cx from "classnames";
import React, { Dispatch, SetStateAction } from "react";

import { Link } from "../Link";
import { IconSprite } from "../IconSprite";
import { Node } from "../Layout/types";
import * as styles from "./MainMenu.module.scss";

export interface MainMenuProps {
    activeMenu?: string;
    setActiveMenu: Dispatch<SetStateAction<string | undefined>>;
    onMouseEnterLink?: () => void;
    onMouseLeaveLink?: () => void;
    menuItems: Node[];
}

export const MainMenu: React.FC<MainMenuProps> = ({
    activeMenu,
    setActiveMenu,
    onMouseLeaveLink,
    onMouseEnterLink,
    menuItems
}) => (
    <nav className={styles.component} aria-label="main">
        {menuItems.map((node, index) => {
            const active = activeMenu === node.id;
            const disabled = activeMenu && activeMenu !== node.id;

            return (
                <Link
                    key={node.id}
                    to={node.link?.uri ?? ""}
                    className={cx(styles.menu_item, {
                        [styles.active]: active,
                        [styles.disabled]: disabled
                    })}
                    aria-label={node.title || ""}
                    activeClassName={styles.active}
                    partiallyActive
                    onMouseLeave={onMouseLeaveLink}
                    onMouseEnter={() => {
                        onMouseEnterLink && onMouseEnterLink();
                        if (node.children && node.children.length > 0) {
                            setActiveMenu(node.id || undefined);
                        }
                    }}
                >
                    {node.title}
                </Link>
            );
        })}
    </nav>
);
