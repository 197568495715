import React from "react";
import cx from "classnames";
import { Configure } from "react-instantsearch-core";
import { RelatedContentHits } from "../../components/RelatedContentHits";
import { SearchComponent } from "../../components/SearchComponent";
import Section from "../../components/Section";
import { component, title, underline } from "./RelatedContent.module.scss";

interface Props {
    filterString: string;
    hitsPerPage?: number;
    currentContentTitle?: string;
}

export const RelatedContent: React.FC<Props> = ({
    filterString,
    currentContentTitle,
    hitsPerPage = 3
}) => (
    <div className={cx(component, "hideOnPrint")}>
        <Section background="grey" layout="fullwidth" hideBorderTop>
            <hr className={underline} />
            <h2 className={title}>Explore our related content</h2>

            <SearchComponent disableUrl indexName={"global_by_date"}>
                <Configure
                    filters={filterString}
                    hitsPerPage={
                        currentContentTitle ? hitsPerPage + 1 : hitsPerPage
                    }
                />
                <RelatedContentHits titleToExclude={currentContentTitle} />
            </SearchComponent>
        </Section>
    </div>
);
