import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";

import { IconSprite, IconSpriteName } from "../IconSprite/IconSprite";
import * as styles from "./ExternalLinkCard.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    url: string;
    label: string;
    iconName: IconSpriteName;
    variant?: "bold" | "notBold";
}

export const ExternalLinkCard: FC<Props> = ({
    url,
    label,
    iconName,
    variant = "bold",
    className,
}) => {
    return (
        <a
            className={cx(styles.component, styles[variant], className)}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
        >
            <div className={styles.iconWrapper}>
                <IconSprite className={styles[iconName]} name={iconName} />
            </div>
            <div>
                <span className={styles.label}>{label}</span>
            </div>
        </a>
    );
};
export default ExternalLinkCard;
