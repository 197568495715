import { graphql } from "gatsby";
import React, { HTMLAttributes } from "react";
import { SidebarSignpost } from "../../components/SidebarSignpost";

interface Props extends HTMLAttributes<HTMLElement> {
    node?: GatsbyTypes.SingleSignpostDataFragment;
}

export const SingleSignpost: React.FC<Props> = ({ node }) => {
    const data = node?.relationships?.field_content_item;
    if (!data) return null;

    return (
        <SidebarSignpost
            title={data.title ?? ""}
            url={data.field_external_url?.uri ?? ""}
            image={
                data.relationships?.field_signpost_image?.relationships
                    ?.field_media_image?.childImageKitAsset?.fluid
            }
            summary={data.body?.summary ?? ""}
            linkText={data.field_external_url?.title}
        />
    );
};

export default SingleSignpost;

export const fragment = graphql`
    fragment SingleSignpostData on paragraph__single_signpost {
        id
        relationships {
            field_content_item {
                ...ExternalSignpostData
            }
        }
    }
`;
