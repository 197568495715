import { graphql } from "gatsby";
import React from "react";
import { Agenda } from "../../components/Agenda/Agenda";
import { formatAgendaDays } from "./helpers";
import { useInPageNavId } from "../../context/InPageNav/useInPageNavId";

interface AgendaParagraphProps {
    node: GatsbyTypes.AgendaParagraphDataFragment;
}

export const AgendaParagraph: React.FC<AgendaParagraphProps> = ({ node }) => {
    const id = useInPageNavId(node);

    if (!node) return null;

    return (
        <Agenda
            id={id}
            key={node.id}
            title={node.title || ""}
            days={formatAgendaDays(node.relationships?.days || [])}
        />
    );
};

export const fragment = graphql`
    fragment AgendaParagraphData on paragraph__agenda {
        id
        title: field_title
        field_title
        field_is_on_this_page_anchor
        relationships {
            days: field_content_paragr {
                id
                title: field_title
                relationships {
                    sessions: field_content_paragr {
                        id
                        title
                        times {
                            endDate: end_value(formatString: "HH:mm")
                            startDate: value(formatString: "HH:mm")
                            machineEndDate: end_value(formatString: "HH:mm")
                            machineStartDate: value(formatString: "HH:mm")
                        }
                        summary: body {
                            processed
                        }
                    }
                }
            }
        }
    }
`;
