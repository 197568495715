// extracted by mini-css-extract-plugin
export var component = "Networks-module--component--85462";
export var desktopOnly = "Networks-module--desktopOnly--4c4f6";
export var fadePop = "Networks-module--fade-pop--3b7ed";
export var growHeightIn = "Networks-module--grow-height-in--7ad12";
export var mobileOnly = "Networks-module--mobileOnly--ea97a";
export var mobileReadingOptions = "Networks-module--mobileReadingOptions--4d480";
export var noMarginBottom = "Networks-module--noMarginBottom--da954";
export var relatedContentWrapper = "Networks-module--relatedContentWrapper--23392";
export var section = "Networks-module--section--57b30";
export var sidebarParagraphs = "Networks-module--sidebarParagraphs--81b21";
export var topDownloadLink = "Networks-module--topDownloadLink--86e45";
export var underline = "Networks-module--underline--b05f1";