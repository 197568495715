// extracted by mini-css-extract-plugin
export var component = "TitleBlock-module--component--670cf";
export var contentCatagory = "TitleBlock-module--contentCatagory--0c776";
export var contentType = "TitleBlock-module--contentType--33d80";
export var contentTypeWrapper = "TitleBlock-module--contentTypeWrapper--93715";
export var eventSeriesLink = "TitleBlock-module--eventSeriesLink--a332c";
export var fadePop = "TitleBlock-module--fade-pop--fab53";
export var flex = "TitleBlock-module--flex--175c7";
export var growHeightIn = "TitleBlock-module--grow-height-in--8d320";
export var image = "TitleBlock-module--image--17bcb";
export var issueVol = "TitleBlock-module--issueVol--6deae";
export var region = "TitleBlock-module--region--bbe56";
export var restrictedTo = "TitleBlock-module--restrictedTo--bfe5d";
export var subText = "TitleBlock-module--subText--a8321";
export var summary = "TitleBlock-module--summary--6f427";
export var topLabels = "TitleBlock-module--topLabels--8d0ab";
export var topic = "TitleBlock-module--topic--d0d5c";
export var underline = "TitleBlock-module--underline--1f8b3";