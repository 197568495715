import cx from "classnames";
import React, {
    FC,
    HTMLAttributes,
    useState,
    useCallback,
    ChangeEvent
} from "react";

import { Button } from "../Button";
import { ContentGate } from "../ContentGate";
import { DownloadFile } from "../DownloadFile";
import { PdfData } from "../DownloadFile/__generated__/PdfData";
import { IconSprite } from "../IconSprite";
import * as styles from "./LockedDownload.module.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    topbarText?: string;
    heading?: string;
    terms: string;
    checkboxLabel?: string;
    buttonLabel?: string;
    pdf: Array<PdfData | null> | null;
    agreedText?: string;
    confirmationText?: string;
}

export const LockedDownload: FC<Props> = ({
    topbarText = "BEFORE YOU DOWNLOAD THIS",
    heading = "Please read our terms to unlock this download",
    terms,
    checkboxLabel = "I confirm I have read and agree to the terms",
    buttonLabel = "Unlock download",
    agreedText = "terms agreed",
    confirmationText = "Thank you. Please download using the link below.",
    pdf,
    className,
    ...props
}) => {
    const [locked, setLocked] = useState(true);
    const [checkboxChecked, setCheckboxChecked] = useState(false);

    const updateDownloadLocked = useCallback(() => {
        if (checkboxChecked) {
            setLocked(false);
        }
    }, [checkboxChecked]);

    const onCheckboxStateChange = useCallback((event: ChangeEvent) => {
        const checked = event.target.checked;
        setCheckboxChecked(checked);
    }, []);

    return (
        <>
            <ContentGate
                className={styles.contentGate}
                variant="unlocked"
                topbarText={topbarText}
            >
                <div className={styles.content}>
                    <h2>{heading}</h2>
                    <div
                        className={styles.terms}
                        dangerouslySetInnerHTML={{ __html: terms }}
                    />
                    <hr />
                    <div className={styles.bottom}>
                        {locked && (
                            <>
                                <div className={styles.wrapper}>
                                    <input
                                        id={"unlock-download-checkbox"}
                                        type="checkbox"
                                        onChange={onCheckboxStateChange}
                                    />
                                    <label htmlFor="unlock-download-checkbox">
                                        {checkboxLabel}
                                    </label>
                                </div>
                                <Button onClick={updateDownloadLocked}>
                                    {buttonLabel}
                                </Button>
                            </>
                        )}

                        {!locked && (
                            <>
                                <div className={styles.agreed}>
                                    <IconSprite
                                        className={styles.icon}
                                        name="tick"
                                    />
                                    <span>{agreedText}</span>
                                </div>
                                <p>{confirmationText}</p>
                            </>
                        )}
                    </div>
                </div>
            </ContentGate>

            <DownloadFile pdf={pdf} variant={locked ? "locked" : "unlocked"} />
        </>
    );
};

export default LockedDownload;
