import { useEffect } from "react";

export const useGatsbyImageFix = (): void => {
    useEffect(() => {
        const elements = document.getElementsByClassName(
            "gatsby-image-wrapper"
        );

        [...elements].forEach(element => {
            [...element.children].forEach(child => {
                if (child.nodeName === "NOSCRIPT") {
                    child.innerHTML = "";
                }
            });
        });
    }, []);
};
