import { navigate } from "gatsby-link";
import React, { useEffect } from "react";

import Layout from "../components/Layout";

const Page: React.FC = () => {
	useEffect(() => {
		const redirectPage = localStorage.getItem("redirectPage");
		document.cookie = "AWSELBAuthSessionCookie-0=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
		document.cookie = "AWSALBAuthNonce=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
		if (redirectPage === null) navigate("/");
		else {
			localStorage.removeItem("redirectPage");
			navigate(redirectPage);
		}
	}, []);
	return (
		<Layout>
			{null}
		</Layout>
	);
};

export default Page;
