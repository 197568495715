import { useEffect } from "react";
import { getDomId } from "./helpers";
import { useInPageNav } from "./useInPageNav";

interface NodeData {
    field_is_on_this_page_anchor?: boolean;
    field_title?: string;
}

export const useInPageNavId = (node?: NodeData) => {
    const { addToInPageNav } = useInPageNav();

    const { field_is_on_this_page_anchor, field_title: title } = node ?? {};

    const id = getDomId(title ?? "");

    useEffect(() => {
        if (field_is_on_this_page_anchor && title && id && addToInPageNav) {
            addToInPageNav({ id, title });
        }
    }, [addToInPageNav, field_is_on_this_page_anchor, id, title]);

    return id;
};
