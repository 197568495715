export const toTimestamp = (date: string) =>
    Math.floor(new Date(date).getTime() / 1000);

export const toISODate = (timestamp: number) =>
    new Date(timestamp * 1000).toISOString().substring(0, 10);

export const toLocaleDate = (timestamp?: number) =>
    timestamp
        ? new Date(timestamp * 1000).toLocaleDateString("en-GB", {
              year: "numeric",
              month: "short"
          })
        : "";
