import { convertDateToTimeStamp } from "../components/DateRangeFilter/helpers";

type ArrayType = ReadonlyArray<
    GatsbyTypes.Maybe<Pick<GatsbyTypes.taxonomy_term__event_type, "name">>
>;

export const getFilterStringForUpcomingEvents = (
    filters: ArrayType
): string => {
    const nowTimestamp = convertDateToTimeStamp(new Date(Date.now()));

    const string: string[] = [];
    filters?.forEach(filter => {
        if (filter?.name) {
            string.push(`event_type_name:"${filter.name}"`);
        }
    });

    const dateFilter = `authored_date >= ${nowTimestamp}`;

    return string.length > 0
        ? `${string.join(" OR ")} AND ${dateFilter}`
        : dateFilter;
};
