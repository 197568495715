import cx from "classnames";
import React, { FC, HTMLAttributes } from "react";

import TagsList, { TagData } from "../TagsList/TagsList";
import * as styles from "./KeywordTags.module.scss";

export interface TagCatagoryData {
    name: string;
    items: Array<TagData | null>;
    id: string;
}

interface Props extends HTMLAttributes<HTMLElement> {
    tagCategories: Array<TagCatagoryData | null>;
}

export const KeywordTags: FC<Props> = ({
    tagCategories,
    className,
    ...props
}) => {
    const tagsExist = tagCategories.find(i => i?.items && i.items?.length);
    if (!tagsExist) return null;

    return (
        <section
            className={cx(styles.component, className, "hideOnPrint")}
            {...props}
        >
            <label className={styles.label}>keywords</label>
            {tagCategories.map((tagCategory, index) =>
                tagCategory?.items?.length ? (
                    <div
                        className={styles.tagsCategoryRow}
                        key={`tagCategory-${index}`}
                    >
                        <label>{tagCategory.name}</label>
                        <TagsList tags={tagCategory.items} />
                    </div>
                ) : null
            )}
        </section>
    );
};

export default KeywordTags;
