import React, { FC, HTMLAttributes, useEffect } from "react";
import cx from "classnames";
import * as styles from "./LatestDynamicContent.module.scss";
import { useInPageNav } from "../../context/InPageNav/useInPageNav";
import { getDomId } from "../../context/InPageNav/helpers";
import { TitleForDynamicParagraphs } from "../TitleForDynamicParagraphs";
import { Link } from "../Link";

interface Props extends HTMLAttributes<HTMLElement> {
    heading?: string;
    linkText?: string;
    linkUrl?: string;
    includedInPageNav?: boolean;
    resultsPerPage?: number;
    infinite?: boolean;
    filterString?: string;
    hideSection?: boolean;
    variant?: "row" | "list";
}

// eslint-disable-next-line complexity
export const LatestDynamicContent: FC<Props> = ({
    heading,
    linkText,
    linkUrl,
    filterString = "",
    includedInPageNav = false,
    resultsPerPage = 10,
    infinite = true,
    variant = "list",
    hideSection,
    className,
    children,
    ...rest
}) => {
    const { addToInPageNav } = useInPageNav();
    const id = getDomId(heading ?? "");

    useEffect(() => {
        if (!hideSection && id && addToInPageNav) {
            addToInPageNav({ title: heading ?? "", id });
        }
    }, [hideSection, addToInPageNav, id, heading]);

    return (
        <section
            id={id}
            className={cx(className, styles[variant], "hideOnPrint")}
            {...rest}
        >
            <TitleForDynamicParagraphs>
                {heading && <h2>{heading}</h2>}
                {linkText && linkUrl && <Link to={linkUrl}>{linkText}</Link>}
            </TitleForDynamicParagraphs>
            {children}
        </section>
    );
};

export default LatestDynamicContent;
