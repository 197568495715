import { ArticleQuery_article_relationships } from "../../templates/Article/__generated__/ArticleQuery";
import { EventQuery_event_relationships } from "../../templates/Event/__generated__/EventQuery";

type FieldName =
    | "field_topics"
    | "field_region"
    | "field_research_groups"
    | "field_related_project"
    | "field_networks";

const fieldToLabelMap = {
    field_topics: "Topics",
    field_region: "Regions and Country Groups",
    field_research_groups: "Research Groups",
    field_related_project: "Projects",
    field_networks: "Networks"
};

const fieldNames: FieldName[] = [
    "field_topics",
    "field_region",
    "field_research_groups",
    "field_related_project",
    "field_networks"
];

export const organiseKeywordTags = (
    nodeRelationships:
        | ArticleQuery_article_relationships
        | EventQuery_event_relationships
        | null
) => {
    if (!nodeRelationships) return [];

    return fieldNames.map(fieldName =>
        nodeRelationships[fieldName]?.length
            ? {
                  name: fieldToLabelMap[fieldName],
                  items: nodeRelationships[fieldName].map(item => ({
                      label: item.name,
                      id: item.id,
                      url: item.path?.alias ?? ""
                  }))
              }
            : null
    );
};
