// extracted by mini-css-extract-plugin
export var bottomNavLink = "Footer-module--bottomNavLink--63931";
export var component = "Footer-module--component--d929b";
export var copyright = "Footer-module--copyright--a775b";
export var crest = "Footer-module--crest--439e5";
export var fadePop = "Footer-module--fade-pop--cf12f";
export var firstFooterMenu = "Footer-module--firstFooterMenu--b6665";
export var footerBottom = "Footer-module--footerBottom--e48a7";
export var footerMenu = "Footer-module--footerMenu--e4695";
export var footerSection = "Footer-module--footerSection--ec6c8";
export var growHeightIn = "Footer-module--grow-height-in--63394";
export var largeScreenCrest = "Footer-module--largeScreenCrest--315e6";
export var mainFooterArea = "Footer-module--mainFooterArea--0ef4c";
export var menuItemsWrapper = "Footer-module--menuItemsWrapper--e56f2";
export var midSection = "Footer-module--midSection--215f2";
export var missionStatement = "Footer-module--missionStatement--06753";
export var secondFooterMenu = "Footer-module--secondFooterMenu--faa44";
export var social = "Footer-module--social--6e211";
export var underline = "Footer-module--underline--6fa33";